/**
 * Events error base class
 */
abstract class EventsError extends Error {
  public humanMessage: string;

  constructor(message: string) {
    super(message);
    this.name = "EventsError";
    this.humanMessage = "Unknown Error";
  }
}

export { EventsError };
