import _ from "lodash";

export type Props = {
  show?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => any;
};

function LoginEmailInput(props: Props) {
  const {
    show = true,
    disabled = false,
    value = ``,
    onChange = () => {},
  } = props;

  // const { closeKeyboard } = useKeyboard();

  const showStyle = {};
  const hideStyle = { width: 0, height: 0, overflow: "hidden" };
  const style: React.CSSProperties = show ? showStyle : hideStyle;

  return (
    <input
      type="text"
      placeholder={`Email`}
      value={value}
      onChange={e => onChange(_.trim(e.target.value))}
      disabled={disabled}
      autoCapitalize={`none`}
      // ref={emailRef}
      className={`w-full text-center flex items-center justify-center rounded-md outline-0  mb-2`}
      style={{
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        fontSize: `20px`,
        lineHeight: `24px`,
        height: `50px`,
        borderWidth: `1px`,
        borderColor: `#E7EAF7`, //showErrors && !emailIsValid ? `red` : `#E7EAF7`,
        color: `#0E2A48`,
        ...style,
      }}
    />
  );
}

export { LoginEmailInput };
