import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import useCachePrimer from "../useCachePrimer";
import { UpdateUserSettingsDocument, UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables } from "../../generatedQueries";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    UpdateUserSettingsMutation["updateUserSettings"],
    unknown,
    UpdateUserSettingsMutationVariables
  >,
  "mutationFn"
>;

export default function useUpdateUserSettings(options?: Options) {
  const auth = useMultiAuth();
  const cachePrimer = useCachePrimer();

  const mutation = useMutation<
    UpdateUserSettingsMutation["updateUserSettings"],
    unknown,
    UpdateUserSettingsMutationVariables
  >(
    async ({ userSettings }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: UpdateUserSettingsDocument,
        variables: {
          userSettings,
        },
        requestHeaders,
        // signal,
      })).updateUserSettings;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onSuccess: (resp, variables, context) => {
        cachePrimer.UserSettings.replaceByKey(
          auth.session?.sessionDetails.userId ?? -1,
          resp?.updatedUserSettings ?? []
        );

        if (options?.onSuccess) {
          options.onSuccess(resp, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    updatedUserSettings: mutation.data?.updatedUserSettings ?? null,
  };
}
