import { getAuthEndpoint } from "../../config/config";
import { event } from "../../events/core/helpers";
import { OAuthStarted } from "../../events/event-types/auth/OAuthStarted";
import { toValidInteger } from "../../lib/utils";

export type Status = `IDLE` | `IN_PROGRESS` | `ERROR` | `SUCCESS` | `CANCELLED`;

export type TokenResponse = {
  idToken: string;
  expiresAt: number;
};

class AuthRequest {
  public prompt = async (args: {
    provider: `apple` | `google`;
  }): Promise<void> => {
    const { provider } = args;

    const callbackUrl = `${window.location.protocol}//${window.location.host}/login/callback/${provider}`;
    const authUrl = `${getAuthEndpoint()}/auth/login/users/${provider}?return_to_uri=${encodeURIComponent(
      callbackUrl
    )}`;

    event(new OAuthStarted({
      provider,
    }));

    window.location.href = authUrl;
  };

  public handleCallback = async (): Promise<{ 
    status: Status; 
    tokenResponse?: TokenResponse | null, 
    error?: string | null | undefined;
    internalError?: string | null | undefined;
  }> => {
    const searchParams = new URLSearchParams(window.location.search);

    const idToken = searchParams.get(`id_token`) as string | null | undefined;
    const expiresAt = searchParams.get(`expires_at`) as string | null | undefined;
    const error = searchParams.get(`meterez_error`) as string | null | undefined;
    const internalError = searchParams.get(`meterez_internal_error`) as string | null | undefined;

    if (!idToken || !toValidInteger(expiresAt)) {
      return {
        status: `ERROR`,
        error,
        internalError,
      };
    }

    // TODO See if there's a way/reason to handle "cancelled" here
    // event(
    //   new OAuthCancelled({
    //     provider,
    //     tokenResponse,
    //   })
    // );

    return {
      status: `SUCCESS`,
      tokenResponse: {
        idToken,
        expiresAt: toValidInteger(expiresAt) as number,
      },
    };
  }
}

export { AuthRequest };
