import _ from "lodash";
import { useCallback, useMemo } from "react";

import useUpdateUserSettings from "./useUpdateUserSettings";
import useUserSettings from "./useUserSettings";
import { moneyIntToStr, strToMoneyInt } from "../../lib/utils";

export type Options = {
  queryOptions?: Parameters<typeof useUserSettings>[0];
  mutationOptions?: Parameters<typeof useUpdateUserSettings>[0];
};

export type UserSettingsProperties = {
  // walletAutoReload: boolean;
  // walletAutoReloadThreshold: number | null;
  // walletAutoReloadAmount: number | null;
  // walletAutoReloadPaymentMethodId: number | null;
  notificationsSmsEnabled: boolean;
  //
  // developerToolsEnabled: boolean;
};

export const defaultSettings: UserSettingsProperties = {
  notificationsSmsEnabled: true,
};

export enum UserSettingKey {
  // "wallet.auto_reload" = "wallet.auto_reload",
  // "wallet.auto_reload_threshold" = "wallet.auto_reload_threshold",
  // "wallet.auto_reload_amount" = "wallet.auto_reload_amount",
  // "wallet.auto_reload_payment_method_id" = "wallet.auto_reload_payment_method_id",
  "notifications.sms_enabled" = "notifications.sms_enabled",
  //
  // "developer.tools_enabled" = "developer.tools_enabled",
}

export type UserSettingValue = string | null | undefined;

export type UserSettingsKeyValuePair = {
  key: UserSettingKey;
  value: UserSettingValue;
};

const valuesMap = [
  /**
   * Wallet Reload - On / Off
   */
  // {
  //   property: "walletAutoReload",
  //   key: UserSettingKey["wallet.auto_reload"],
  //   toValue: (value: UserSettingValue) => value === "true",
  //   toStringValue: (value: UserSettingsProperties["walletAutoReload"]) =>
  //     value ? "true" : "false",
  // },
  /**
   * Wallet Reload - Threshold
   */
  // {
  //   property: "walletAutoReloadThreshold",
  //   key: UserSettingKey["wallet.auto_reload_threshold"],
  //   toValue: (value: UserSettingValue) =>
  //     _.isString(value) ? strToMoneyInt(value) : null,
  //   toStringValue: (
  //     value: UserSettingsProperties["walletAutoReloadThreshold"]
  //   ) => (_.isNumber(value) ? moneyIntToStr(value) : null),
  // },
  /**
   * Wallet Reload - Amount
   */
  // {
  //   property: "walletAutoReloadAmount",
  //   key: UserSettingKey["wallet.auto_reload_amount"],
  //   toValue: (value: UserSettingValue) =>
  //     _.isString(value) ? strToMoneyInt(value) : null,
  //   toStringValue: (value: UserSettingsProperties["walletAutoReloadAmount"]) =>
  //     _.isNumber(value) ? moneyIntToStr(value) : null,
  // },
  /**
   * Wallet Reload - Payment Method ID
   */
  // {
  //   property: "walletAutoReloadPaymentMethodId",
  //   key: UserSettingKey["wallet.auto_reload_payment_method_id"],
  //   toValue: (value: UserSettingValue) =>
  //     _.isString(value) ? _.toNumber(value) : null,
  //   toStringValue: (
  //     value: UserSettingsProperties["walletAutoReloadPaymentMethodId"]
  //   ) => (_.isNumber(value) ? _.toString(value) : null),
  // },
  /**
   * Notifications - SMS On / Off
   */
  {
    property: "notificationsSmsEnabled",
    key: UserSettingKey["notifications.sms_enabled"],
    toValue: (value: UserSettingValue) => _.isString(value) ? value === "true" : true,
    toStringValue: (value: UserSettingsProperties["notificationsSmsEnabled"]) =>
      value ? "true" : "false",
  },
  //
  /**
   * Developer Tools
   */
  // {
  //   property: "developerToolsEnabled",
  //   key: UserSettingKey["developer.tools_enabled"],
  //   toValue: (value: UserSettingValue) => value === "true",
  //   toStringValue: (value: UserSettingsProperties["developerToolsEnabled"]) =>
  //     value === true ? "true" : "false",
  // },
];

export default function useManageUserSettings(options?: Options) {
  const queryHook = useUserSettings({
    ...options?.queryOptions,
  });
  const query = queryHook.query;
  const userSettingsKeyValuePairs =
    queryHook.userSettings as UserSettingsKeyValuePair | null;

  const mutationHook = useUpdateUserSettings({
    ...options?.mutationOptions,
  });
  const mutation = mutationHook.mutation;
  const updatedUserSettingsKeyValuePairs =
    mutationHook.updatedUserSettings as UserSettingsKeyValuePair | null;

  const userSettings: UserSettingsProperties | undefined = useMemo(() => {
    const userSettings = _.isArray(userSettingsKeyValuePairs) ? 
      toProperties(userSettingsKeyValuePairs) as UserSettingsProperties
      : {};

    return {
      ...defaultSettings,
      ...userSettings,
    } as UserSettingsProperties;
  }, [userSettingsKeyValuePairs]);

  const updatedUserSettings: Partial<UserSettingsProperties> | undefined =
    useMemo(() => {
      const updatedUserSettings = _.isArray(updatedUserSettingsKeyValuePairs) ? 
        toProperties(updatedUserSettingsKeyValuePairs)
        : {};

      return {
        ...defaultSettings,
        ...updatedUserSettings,
      } as UserSettingsProperties;
    }, [updatedUserSettingsKeyValuePairs]);

  const updateUserSettings = useCallback(
    (newUserSettings: Partial<UserSettingsProperties>) => {
      // @ts-ignore
      return mutation.mutate({
        userSettings: toKeyValuePairs({
          ...userSettings,
          ...newUserSettings,
        }),
      });
    },
    [mutation, userSettings]
  );

  return useMemo(() => {
    return {
      query,
      mutation,
      updateUserSettings,
      isLoading: query.isLoading,
      isSaving: mutation.isLoading,
      isSaved: mutation.isSuccess,
      isLoadError: query.isError,
      isSaveError: mutation.isError,
      userSettings,
      updatedUserSettings,
    };
  }, [query, mutation, updateUserSettings, userSettings, updatedUserSettings]);
}

export function toProperties(
  userSettingsKeyValuePairs: UserSettingsKeyValuePair[]
): Partial<UserSettingsProperties> {
  return _.reduce(
    userSettingsKeyValuePairs,
    (properties, { key, value }) => {
      const { property, toValue } = _.find(valuesMap, {
        key,
      }) as typeof valuesMap[number];

      return { ...properties, [property]: toValue(value) };
    },
    {} as Partial<UserSettingsProperties>
  );
}

export function toKeyValuePairs(
  userSettingsProperties: Partial<UserSettingsProperties>
): UserSettingsKeyValuePair[] {
  return _.map(userSettingsProperties, (value, property) => {
    const { key, toStringValue } = _.find(valuesMap, {
      property,
    }) as typeof valuesMap[number];

    // @ts-ignore
    return { key, value: toStringValue(value) };
  });
}
