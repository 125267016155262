import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";

export type Props = {
  show?: boolean;
  showOtherOptions?: boolean;
  disabled?: boolean;
  isSending?: boolean;
  onPress?: () => any;
  onCancel?: () => any;
};

function SendLoginCodeButton(props: Props) {
  const {
    show = false,
    showOtherOptions = true,
    disabled = false,
    isSending = false,
    onPress = () => {},
    onCancel = () => {},
  } = props;

  // const navigation = useNavigation();

  if (!show) return null;

  return (
    <div
      style={{
        width: `100%`,
      }}
    >
      <FullWidthButton
        contentTop={isSending ? `Sending...` : `Send Login Code`}
        disabled={disabled}
        disabledIfOffline
        onClick={onPress}
      />

      <div style={{ height: `10px` }} />

      <div
        className={`flex flex-row justify-center items-center`}
        style={{
          width: `100%`,
        }}
      >
        {showOtherOptions && (
          <div
            className={`mx-3`}
            style={{
              
            }}
          >
            <FullWidthButton
              contentTop={`Other Options`}
              type={`tertiary-transparent`}
              disabled={false}
              disabledIfOffline
              onClick={onCancel}
            />
          </div>
        )}
        
        {/* <div
          className={`mx-3`}
          style={{
            
          }}
        >
          <FullWidthButton
              contentTop={`Help`}
              type={`tertiary-transparent`}
              disabled={false}
              disabledIfOffline
              onClick={() => {
                // TODO Help screen
              }}
            />
        </div> */}
      </div>
    </div>
  );
}

export { SendLoginCodeButton };
