import { DeletePaymentMethodMutationVariables } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  mutationVariables: DeletePaymentMethodMutationVariables;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class DeletePaymentMethodRequest extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationVariables, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `DELETE_PAYMENT_METHOD.REQUEST`,
      data: {
        mutationVariables,
      },
    });
  }
}

export { DeletePaymentMethodRequest };
