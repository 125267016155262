import type { Breadcrumb } from "@sentry/react";
export type EventCategory = string;
export type EventType = string;

export type SentryBreadcrumb = Breadcrumb;
export type SentryBreadcrumbPartial = Omit<SentryBreadcrumb, `event_id` | `category` | `timestamp`> & {
  // Supposed Recognized Categories: exception, console, navigation, xhr, ui.click (probably more, but can't find the docs)
  category: `exception` | `console` | `navigation` | `xhr` | `ui.click`;
}

export type Event<TData = any> = {
  eventId?: string;
  occurredAt?: number; // Timestamp w/ microtime
  category: EventCategory;
  subCategory: EventCategory | null;
  type: EventType;
  data?: TData;

  toSentryBreadcrumb?: () => SentryBreadcrumbPartial;
};

export enum EventsBatchStatus {
  PENDING_RETRY = `PENDING_RETRY`,
  SUBMITTED = `SUBMITTED`,
  FAILED = `FAILED`,
}

export type EventsBatch = {
  batchId: string;
  createdAt: number; // Timestamp
  lastAttempt: number | null; // Timestamp
  numAttempts: number;
  events: Event[];
  status?: EventsBatchStatus;
};

export type EventsQueue = {
  schemaVersion: string;
  new: {
    lastChangedAt: number | null;
    lastStoredAt: number | null;
    events: Event[];
  };
  pendingSubmission: {
    lastChangedAt: number | null;
    lastStoredAt: number | null;
    eventsBatches: EventsBatch[];
  };
};

export type Storage = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  [key: string]: any;
};

export type Submitter = {
  isEnabled: () => boolean;
  submit: (eventsBatches: EventsBatch[]) => Promise<SubmitterResponse>;
  onError?: (e: unknown) => any;
};

export type SubmitterResponse = {
  batchId: string;
  success: boolean;
}[];

export type AuthProvider = {
  provider: `apple` | `google` | `email` | `phone_number`;
  credential: string;
  identityId?: number;
  userId?: number;
  authenticated: boolean;
  lastUsed: number;
};

export type UnauthenticatedCredentials = {
  knownProviders: AuthProvider[];
};
