import { SearchParkablesQuery } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  parkables?: SearchParkablesQuery["parkables"];
};

export type ConstructorArgs = {
  queryResponse: SearchParkablesQuery["parkables"];
} & Partial<EventConstructorArgs>;

class SearchParkablesResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { queryResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
      type: `SEARCH_PARKABLES.RESPONSE`,
      data: {
        parkables: queryResponse,
      },
    });
  }
}

export { SearchParkablesResponse };
