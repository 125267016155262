import { useCallback, useEffect, useState } from "react";
import { FullWidthButton } from "../../../components/buttons/FullWidthButton";
import useUpdateUser from "../../../queries/users/useUpdateUser";
import { Layout } from "../../components/Layout";
import _ from "lodash";
import { useHandleError } from "../../../lib/error-handling";
import { useMultiAuth } from "../../../auth/core/useMultiAuth";
import useUser from "../../../queries/users/useUser";
import usePrevious from "../../../lib/usePrevious";

function Name() {
  const handleError = useHandleError();
  const auth = useMultiAuth();
  const { user } = useUser();
  const { mutation } = useUpdateUser({
    onError: (error) => {
      handleError(error);
    },
  });

  const [firstName, setFirstName] = useState(user?.first_name ?? ``);
  const [lastName, setLastName] = useState(user?.last_name ?? ``);

  const prevUser = usePrevious(user);
  useEffect(() => {
    if (!prevUser && !!user && (!_.trim(user?.first_name ?? ``) || !!_.trim(user?.last_name ?? ``))) {
      setFirstName(_.trim(user?.first_name ?? ``));
      setLastName(_.trim(user?.last_name ?? ``));
    }
  }, [user]);

  const submitDisabled = !_.trim(firstName) || !_.trim(lastName) || mutation.isLoading;

  const onSubmit = useCallback(() => {
    if (submitDisabled) return;

    mutation.mutate({
      id: auth.session?.sessionDetails.userId ?? -1,
      values: {
        first_name: firstName,
        last_name: lastName,
      },
    });
  }, [submitDisabled, mutation, auth, firstName, lastName]);

  return (
    <form
      className={`w-full flex flex-col items-center`}
      style={{
        // minHeight: `100vh`,
        // minHeight: `-webkit-fill-available`,
        minHeight: `100dvh`,
      }}
      onSubmit={e => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <Layout
        headingText={`Introduce Yourself`}
        subHeadingText={`Please provide your First and Last Name.`}
        content={
          <div
            className={`flex flex-grow flex-col justify-center`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `14px`,
              lineHeight: `18px`,
            }}
          >
            <div
              className={`mb-4`}
            >
              <input
                name="first_name"
                id="first_name"
                type="text"
                placeholder="ie. First Name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(_.trim(event.target.value));
                }}
                disabled={mutation.isLoading}
                maxLength={50}
                // autoComplete="off"
                autoFocus={false}
                className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `20px`,
                  lineHeight: `24px`,
                  height: `50px`,
                  borderWidth: `1px`,
                  borderColor: `#E7EAF7`,
                  color: `#0E2A48`,
                }}
              />
            </div>
            <div>
              <input
                  name="last_name"
                  id="last_name"
                  type="text"
                  placeholder="ie. Last Name"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  disabled={mutation.isLoading}
                  maxLength={50}
                  className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
                  // autoComplete="off"
                  autoFocus={false}
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 700,
                    fontSize: `20px`,
                    lineHeight: `24px`,
                    height: `50px`,
                    borderWidth: `1px`,
                    borderColor: `#E7EAF7`,
                    color: `#0E2A48`,
                  }}
                />
            </div>
          </div>
        }
        mainButton={
          <FullWidthButton
            contentTop={mutation.isLoading ? `Saving...`:`Save Name`}
            onClick={onSubmit}
            disabled={submitDisabled}
            disabledIfOffline
            showOfflineIndicator
          />
        }
      />
    </form>
  );
}

export { Name };