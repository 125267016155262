import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { CreateVehicleDocument, CreateVehicleMutation, CreateVehicleMutationVariables, Vehicles_Insert_Input } from "../../generatedQueries";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import useCachePrimer from "../useCachePrimer";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { CreateVehicleRequest } from "../../events/event-types/vehicles/CreateVehicleRequest";
import { CreateVehicleResponse } from "../../events/event-types/vehicles/CreateVehicleResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    CreateVehicleMutation["insert_vehicles_one"],
    unknown,
    CreateVehicleMutationVariables
  >,
  "mutationFn"
>;

async function createVehicle(args: { values: Vehicles_Insert_Input, signal?: AbortSignal | null }) {
  const { values, signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: CreateVehicleDocument,
    variables: {
      values,
    },
    requestHeaders,
    signal,
  })).insert_vehicles_one;
}

export default function useCreateVehicle(options?: Options) {
  const cachePrimer = useCachePrimer();
  
  const mutation = useMutation<
    CreateVehicleMutation["insert_vehicles_one"],
    unknown,
    CreateVehicleMutationVariables
  >(
    async ({ values }) => {
      return await createVehicle({ values });
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new CreateVehicleRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (vehicle, variables, context) => {
        event(
          new CreateVehicleResponse({
            mutationResponse: vehicle,
          })
        );

        cachePrimer.Vehicles.replaceById([vehicle]);

        if (options?.onSuccess) {
          options.onSuccess(vehicle, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    vehicle: mutation.data ?? null,
  };
}
