import { useNavigate } from "react-router-dom";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useEffect, useState } from "react";
import { ChooseOrEnterPlate } from "../components/vehicle/ChooseOrEnterPlate";
import { Confirm } from "../components/vehicle/Confirm";
import useCreateVehicle from "../../../../queries/vehicles/useCreateVehicle";
import _ from "lodash";
import useVehicles from "../../../../queries/vehicles/useVehicles";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { Progress } from "../components/Progress";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../../../queries/query-keys";
import { useHandleError } from "../../../../lib/error-handling";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import { useScrollToTopOnChange } from "../../../../lib/useScrollToTopOnChange";
import { useLiveNow } from "../../../../lib/useLiveNow";
import { isActive } from "../../../../lib/utils";
import { Auth } from "../../../../auth/core/Auth";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";

export type Props = {

};

function Vehicle(props: Props) {
  const {  } = props;

  const queryClient = useQueryClient();
  const handleError = useHandleError();

  const navigate = useNavigate();
  const session = useSessionInProgress();

  const { parkableTransactions } = useRecentParkableTransactions();
  const { query, vehicles } = useVehicles();
  const vehicleId = session.sessionInProgress?.sessionParameters.vehicleId;
  const now = useLiveNow();
  const isParked = parkableTransactions.filter(pt => pt.vehicle_id === vehicleId && isActive(pt, now)).length > 0;
  const errorMessage = isParked ? `This vehicle is already parked` : ``;

  const [plateText, setPlateText] = useState<string>(``);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(`US`);
  const [selectedState, setSelectedState] = useState<string | null>(`PA`);
  const { mutation, vehicle: createdVehicle } = useCreateVehicle({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async (data) => {
      const userId = (await Auth.getInstance().getSession())?.sessionDetails.userId;
      queryClient.invalidateQueries(queryKeys.Vehicles(userId ?? -1));

      query.refetch();
      setPlateText(``);
      session.set({
        vehicleId: data?.id,
      });
    }
  });

  // Existing vehicles that matches the plate number currently entered
  const existingVehicle = _.find(vehicles, { license_plate: plateText });
  
  useEffect(() => {
    if (!session.stateRef.current.sessionInProgress) {
      navigate("/sessions", { replace: true });
      return;
    }

    const { parkableId } = session.stateRef.current.sessionInProgress.sessionParameters;

    if (!parkableId) {
      navigate("/sessions", { replace: true });
    }
  }, []);

  let vehicle = vehicleId ? _.find(vehicles, { id: vehicleId }) : null;
  if (!vehicle && createdVehicle && vehicleId === createdVehicle.id) {
    vehicle = createdVehicle;
  }

  let uiState = `CHOOSE_OR_ENTER_PLATE`;
  if (vehicleId) {
    uiState = `CONFIRM`;
  }

  useScrollToTopOnChange(uiState);

  if (!session.sessionInProgress) {
    return null;
  }

  return (
    <ScreenLayout
      header={
        <TitleHeader title={[`New`, `Session`]} />
      }
      content={
        <div
          className={`w-full flex flex-grow flex-col items-center`}
        >
          <Progress />

          {uiState === `CHOOSE_OR_ENTER_PLATE` && (
            <ChooseOrEnterPlate
              vehiclesQuery={query}
              vehicles={vehicles}
              recentParkableTransactions={parkableTransactions}
              onClickVehicle={(vehicle) => {
                session.set({
                  vehicleId: vehicle.id,
                });
              }}
              plateText={plateText}
              onChangePlateText={(plateText) => {
                setPlateText(plateText.replace(/[^a-z0-9]/gi, ''));
                if (!mutation.isIdle) {
                  mutation.reset();
                }
              }}
              selectedCountry={selectedCountry}
              onSelectCountry={setSelectedCountry}
              selectedState={selectedState}
              onSelectState={setSelectedState}
              disabled={mutation.isLoading}
              mutation={mutation}
              defaultVehicleName={existingVehicle?.name ?? "My Vehicle"}
            />
          )}

          {uiState === `CONFIRM` && (
            <Confirm
              vehicleId={vehicleId}
              errorMessage={errorMessage}
              onCancel={() => {
                session.set({
                  vehicleId: undefined,
                })
              }}
            />
          )}

          <div
            className={`w-full flex flex-col items-center`}
          >
            {uiState === `CONFIRM` && (
              <FullWidthButton
                contentTop={`Continue`}
                onClick={() => session.navigate(`TIME`)}
                disabled={!vehicle || isParked}
              />
            )}

            <div className={`mb-4`} />

            <FullWidthButton
              contentTop={`Cancel Parking`}
              type={`tertiary-transparent`}
              onClick={() => {
                event(
                  new ButtonPress({
                    buttonName: `Cancel Parking`,
                    buttonSlug: `CREATE_PARKABLE_TRANSACTION.CANCEL`,
                    subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                  })
                );

                session.cancel();
                navigate(`sessions`);
              }}
              disabled={mutation.isLoading}
            />
          </div>
        </div>
      }
    />
  );
}

export { Vehicle };