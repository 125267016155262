import { CreateSupportTicketGuestMutation, CreateSupportTicketMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  supportTicketId?: number;
  isGuest: boolean;
};

export type ConstructorArgs = {
  mutationResponse:
    | CreateSupportTicketMutation["createSupportTicket"]
    | CreateSupportTicketGuestMutation["createSupportTicketGuest"];
  isGuest: boolean;
} & Partial<EventConstructorArgs>;

class CreateSupportTicketResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, isGuest, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.SUPPORT_TICKETS,
      type: `CREATE_SUPPORT_TICKET.RESPONSE`,
      data: {
        supportTicketId: mutationResponse?.id,
        isGuest,
      },
    });
  }
}

export { CreateSupportTicketResponse };
