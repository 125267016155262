import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { ReactComponent as IconMeter } from "../../../../../src/assets/icons/icon-meter.svg";
import { ReactComponent as IconMeterWhite } from "../../../../../src/assets/icons/icon-meter-white.svg";
import { ReactComponent as IconSedan } from "../../../../../src/assets/icons/vehicles/icon-vehicle-sedan-example.svg";
import { ReactComponent as IconSedanWhite } from "../../../../../src/assets/icons/vehicles/icon-vehicle-sedan-example-white.svg";
import { ReactComponent as IconSteeringWheel } from "../../../../../src/assets/icons/icon-steering-wheel.svg";
import { ReactComponent as IconClock } from "../../../../../src/assets/icons/icon-clock.svg";
import { ReactComponent as IconClockWhite } from "../../../../../src/assets/icons/icon-clock-white.svg";
import { ReactComponent as IconCard } from "../../../../../src/assets/icons/icon-cc-generic.svg";
import { ReactComponent as IconCardWhite } from "../../../../../src/assets/icons/icon-cc-generic-white.svg";
import { ReactComponent as IconEdit } from "../../../../../src/assets/icons/icon-edit.svg";
import { ReactComponent as IconEditWhite } from "../../../../../src/assets/icons/icon-edit-white.svg";
import { ReactComponent as IconArrow } from "../../../../../src/assets/icons/arrow-right-solid.svg";

export type Props = {

};

function Progress(props: Props) {
  const {} = props;

  const session = useSessionInProgress();

  if (!session.sessionInProgress) return null;

  const isExtend = !!session.sessionInProgress?.parkableTransactionId;
  const { parkableId, vehicleId, time, paymentMethodId } = session.sessionInProgress.sessionParameters;

  const vehicleDisabled = !parkableId;
  const timeDisabled = !parkableId || !vehicleId;
  const paymentDisabled = !parkableId || !vehicleId || !time;
  const reviewDisabled = false; // !parkableId || !vehicleId || !time || !paymentMethodId;

  return (
    <div
      className={`w-full flex justify-around`}
      style={{

      }}
    >
      {!isExtend && (
        <>
          <Breadcrumb
            to="/sessions/new/meter" 
            disabled={false}
            // activeIcon={<IconMeterWhite style={{ height: `30px` }} />}
            inactiveIcon={<IconMeter style={{ height: `30px` }} />}
          />

          <IconArrow
            className={/*`absolute`*/`relative opacity-50`}
            style={{
              height: `12px`,
              top: `15px`,
              // right: `6px`,
            }}
          />
        </>
      )}

      {!isExtend && (
        <>
          <Breadcrumb
            to="/sessions/new/plate" 
            disabled={vehicleDisabled}
            // activeIcon={<IconSedanWhite style={{ height: `30px` }} />}
            inactiveIcon={<IconSteeringWheel style={{ height: `30px` }} />}
          />

          <IconArrow
            className={/*`absolute`*/`relative opacity-50`}
            style={{
              height: `12px`,
              top: `15px`,
              // right: `-6px`,
            }}
          />
        </>
      )}

      <Breadcrumb
        to={isExtend ? `/sessions/extend/${session.sessionInProgress?.parkableTransactionId}/time` : "/sessions/new/time"}
        disabled={isExtend ? false : timeDisabled}
        // activeIcon={<IconClockWhite style={{ height: `30px` }} />}
        inactiveIcon={<IconClock style={{ height: `30px` }} />}
      />

      <IconArrow
        className={/*`absolute`*/`relative opacity-50`}
        style={{
          height: `12px`,
          top: `15px`,
          right: `3px`,
        }}
      />

      {!isExtend && (
        <>
          <Breadcrumb
            to="/sessions/new/payment" 
            disabled={paymentDisabled}
            // activeIcon={<IconCardWhite style={{ height: `30px` }} />}
            inactiveIcon={<IconCard style={{ height: `12px` }} />}
          />

          <IconArrow
            className={/*`absolute`*/`relative opacity-50`}
            style={{
              height: `12px`,
              top: `15px`,
              right: `0px`,
            }}
          />
        </>
      )}

      <Breadcrumb
        to={isExtend ? `/sessions/extend/${session.sessionInProgress?.parkableTransactionId}/review` : "/sessions/new/review"} 
        disabled={reviewDisabled}
        // activeIcon={<IconEditWhite style={{ height: `30px` }} />}
        inactiveIcon={<IconEdit style={{ height: `30px` }} />}
        showArrow={false}
      />
    </div>
  );
}

export type BreadcrumbProps = {
  to: string;
  disabled: boolean;
  activeIcon?: ReactNode;
  inactiveIcon: ReactNode;
  showArrow?: boolean;
};

function Breadcrumb(props: BreadcrumbProps) {
  const { to, disabled, activeIcon, inactiveIcon, showArrow = true } = props;

  return (
    <div
      className={/*`basis-0 flex-grow`*/` mb-4 relative`}
      style={{
        // backgroundColor: 'red'
      }}
    >
      <NavLink
        to={to}
        className={({ isActive }) => `flex items-center justify-center rounded-md basis-0 flex-grow select-none ${disabled ? `pointer-events-none opacity-20`:``} ${isActive ? ``:`opacity-50`}`}
        style={({ isActive }) => ({
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `14px`,
          lineHeight: `18px`,
          color: isActive ? `#fff` : `#0d2a48`,
          // backgroundColor: isActive ? `#0E2A48` : `#fff`,
          // borderColor: isActive ? `#0E2A48` : `#E7EAF7`,
          // borderWidth: `1px`,
          height: `40px`,
          minWidth: `50px`,
          // backgroundColor: 'red'
        })}
      >
        {({ isActive }) => isActive ? (activeIcon ?? inactiveIcon): inactiveIcon}
      </NavLink>

      {/* {showArrow && (
        <IconArrow
          className={`absolute opacity-50`}
          style={{
            height: `12px`,
            top: `15px`,
            right: `-6px`,
          }}
        />
      )} */}
    </div>
  );
}

export { Progress };