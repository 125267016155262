import { CreateParkableTransactionMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  parkableTransactionId?: number;
};

export type ConstructorArgs = {
  mutationResponse: CreateParkableTransactionMutation["createParkableTransaction"];
} & Partial<EventConstructorArgs>;

class CreateParkableTransactionResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
      type: `CREATE_PARKABLE_TRANSACTION.RESPONSE`,
      data: {
        parkableTransactionId: mutationResponse?.parkableTransaction?.id,
      },
    });
  }
}

export { CreateParkableTransactionResponse };
