import _ from "lodash";

import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  cardAccount: {
    number: string;
    expiryMonth: string;
    expiryYear: string;
    avsZip: string;
    avsStreet?: string;
    cvv: string;
  };
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class VaultCardRequest extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { cardAccount, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `VAULT_CARD.REQUEST`,
      data: {
        cardAccount: VaultCardRequest.maskCardAccount(cardAccount),
      },
    });
  }

  public static maskCardAccount = (cardAccount: Data["cardAccount"]) => {
    const lastFour = _.toString(
      cardAccount.number.slice(cardAccount.number.length - 4)
    );

    return {
      ...cardAccount,
      cvv: `xxx`,
      number: `xxxx-xxxx-xxxx-${lastFour}`,
    };
  };
}

export { VaultCardRequest };
