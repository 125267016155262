import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import useCachePrimer from "../useCachePrimer";
import { UpdateUserDocument, UpdateUserMutation, UpdateUserMutationVariables } from "../../generatedQueries";
import { Auth } from "../../auth/core/Auth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    UpdateUserMutation["update_users_by_pk"],
    unknown,
    UpdateUserMutationVariables
  >,
  "mutationFn"
>;

export default function useUpdateUser(options?: Options) {
  const cachePrimer = useCachePrimer();

  const mutation = useMutation<
    UpdateUserMutation["update_users_by_pk"],
    unknown,
    UpdateUserMutationVariables
  >(
    async ({ id, values }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: UpdateUserDocument,
        variables: {
          id,
          values: {
            ...values,
          },
        },
        requestHeaders,
        // signal,
      })).update_users_by_pk;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onSuccess: (user, variables, context) => {
        cachePrimer.User.replaceById(user);

        if (options?.onSuccess) {
          options?.onSuccess(user, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    user: mutation.data ?? null,
  };
}
