import { Ref } from "react";
import { API, LoginCodeInput as BaseLoginCodeInput } from "../../../../components/inputs/login-code/LoginCodeInput";

export type Props = {
  show?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => any;
  onComplete?: (value: string) => any;
  inputRef?: Ref<API>;
};

function LoginCodeInput(props: Props) {
  const {
    show = true,
    disabled = false,
    value = ``,
    onChange = () => {},
    onComplete = () => {},
    inputRef = {},
  } = props;

  // const showStyle = {};
  // const hideStyle = { width: 0, height: 0, overflow: "hidden" };
  // const style: StyleProp<any> = show ? showStyle : hideStyle;

  // I think we're doing this because the ConfirmationCodeInput auto-focuses itself
  if (!show) return null;

  return (
    <div
      className={`test w-full flex flex-row justify-center items-center relative`}
      style={{
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <BaseLoginCodeInput
        disabled={disabled}
        inputRef={ref => inputRef.current = ref}
        onChange={(value) => {
          onChange(value);
        }}
        onComplete={(value) => {
          onComplete(value);
        }}
      />

      {disabled && (
        <div
          className={`absolute top-0 right-0 bottom-0 left-0`}
          style={{

          }}
        />
      )}
    </div>
  );
}

export { LoginCodeInput };
