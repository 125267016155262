import { request } from 'graphql-request';
import { useQuery } from "@tanstack/react-query";
import { ConfigDocument, ConfigQuery } from "../../generatedQueries";
import { environment, getGraphqlEndpoint, setTimeDiscrepancy } from '../../config/config';
import { useEffect, useMemo } from 'react';
import { divide } from '../../lib/utils';
import moment from 'moment-timezone';
import _ from 'lodash';
import queryKeys from '../query-keys';
import { Auth } from '../../auth/core/Auth';
import { useMultiAuth } from '../../auth/core/useMultiAuth';
import { getDeviceHeaders } from '../../http/getDeviceHeaders/getDeviceHeaders';

async function fetchConfig(args: { signal?: AbortSignal | null }) {
  const { signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: ConfigDocument,
    variables: {},
    requestHeaders,
    signal,
  }));
}

function useServerConfig() {
  const auth = useMultiAuth();

  const query = useQuery<ConfigQuery>(
    queryKeys.Config(),
    async ({ signal }) => {
      return await fetchConfig({ signal });
    },
    {
      enabled: !!auth.session,
      staleTime: environment.isProduction ? 5 * 60 * 1000 : 0,
      refetchOnMount: false,
      // onSuccess(data) {

      // },
      // onError: (error) => {
      //   if (options?.onError) {
      //     options.onError(error);
      //   }
      //   // else {
      //   //   handleError(error);
      //   // }
      // },
    }
  );

  const config = useMemo(() => {
    return {
      serverNow: query.data?.config?.serverNow ?? null,
      auth: query.data?.configV2?.config?.auth,
    };
  }, [query.data]);

  const timeDiscrepancy = getTimeDiscrepancy(query);

  useEffect(() => {
    if (_.isNumber(timeDiscrepancy)) {
      setTimeDiscrepancy(timeDiscrepancy);
    }
  }, [timeDiscrepancy]);

  return {
    query,
    config,
    timeDiscrepancy,
    timeDiscrepancyLastUpdated: query.dataUpdatedAt ?? null,
  };
}

function getTimeDiscrepancy(
  query: ReturnType<typeof useServerConfig>["query"]
) {
  if (!query.data?.config?.serverNow || !_.isNumber(query.dataUpdatedAt)) return null;

  const lastServerNow = moment.utc(query.data?.config?.serverNow);
  const lastUpdated = moment.utc(query.dataUpdatedAt);
  const requestDurOffset = 1; // 1 sec to account for request duration
  const timeDiscrepancy = Math.floor(
    divide(lastUpdated.diff(lastServerNow) - requestDurOffset, 1000)
  );

  return timeDiscrepancy;
}

export { useServerConfig };