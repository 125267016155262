import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import queryKeys from "../query-keys";
import { ParkingAuthoritiesDocument, ParkingAuthoritiesQuery } from "../../generatedQueries";
import request from "graphql-request";
import { getGraphqlEndpoint } from "../../config/config";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { Auth } from "../../auth/core/Auth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export default function useParkingAuthorities() {
  const auth = useMultiAuth();

  const query = useQuery<ParkingAuthoritiesQuery["parking_authorities"]>(
    queryKeys.ParkingAuthorities(),
    async ({ signal }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: ParkingAuthoritiesDocument,
        variables: {},
        requestHeaders,
        signal,
      })).parking_authorities;
    },
    {
      enabled: !!auth.session,
      staleTime: 24 * 60 * 60 * 1000, // 24hrs
    }
  );

  // useThrowQueryErrorToBoundary(query);

  const parkingAuthorities = useMemo(() => {
    if (!_.isArray(query.data)) return [];

    return _.chain(query.data).sortBy("name").value();
  }, [query.data]);

  return {
    query,
    parkingAuthorities,
  };
}
