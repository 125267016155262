import 'rsuite/dist/rsuite.min.css';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './nav/lib/router';
import { ErrorModalProvider } from './lib/error-handling/error-modal';
import QueryClientProvider from './queries/QueryClientProvider';
import useDefaultQueryErrorHandler from './lib/error-handling/useDefaultQueryErrorHandler';
import useActiveRef from './lib/useActiveRef';
import ErrorBoundary from './lib/error-handling/ErrorBoundary';
import { useEffect } from 'react';
import { AlertModalProvider } from './components/modals/alert-modal';
import { PortalProvider } from '@gorhom/portal';
import env from './config/env';
import { AppLoadingSkeleton } from './components/skeletons/AppLoadingSkeleton';

function App() {
  return (
    <ErrorBoundary identifier="app-wrapper">
      <PortalProvider>
        <ErrorModalProvider>
          <AlertModalProvider>
            <QueryClientWrapper>
              <RouterProvider router={router} />
            </QueryClientWrapper>
          </AlertModalProvider>
        </ErrorModalProvider>
      </PortalProvider>
    </ErrorBoundary>
  )
}

export type QueryClientWrapperProps = {
  children: React.ReactNode;
};



/**
 * We need an inner component to get the context right for the error handler
 */
function QueryClientWrapper(props: QueryClientWrapperProps) {
  const { children } = props;

  const handleQueryError = useDefaultQueryErrorHandler();
  const handleQueryErrorRef = useActiveRef(handleQueryError);

  // Test error boundary for query error
  useEffect(() => {
    setTimeout(() => {
      // handleQueryErrorRef.current(new Error("Test Error"))
    }, 1000);
  }, []);
  // throw new Error("Test Error");

  return (
    <QueryClientProvider
      onQueryError={(error, query) => {
        handleQueryErrorRef.current(error, { query })
      }}
    >
      {children}
    </QueryClientProvider>
  );
}

export default App
