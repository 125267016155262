import { useState } from "react";
import useInterval from "../../lib/useInterval";
import { Portal } from "@gorhom/portal";
import _ from "lodash";

export type Props = {
  show?: boolean;
  text?: string | string[];
  subText?: string;
};

function UiBlock(props: Props) {

  const { show, text: title, subText } = props;

  let titleParts: (string | null)[] = [];
  let titleEls: JSX.Element | JSX.Element[] = [];

  if (
    _.isArray(title) &&
    _.filter(title, (et) => _.isString(et)).length === 0
  ) {
    titleEls = title as JSX.Element | JSX.Element[];
  } else {
    if (_.isArray(title)) {
      titleParts = title as (string | null)[];
    } else if (_.isString(title)) {
      titleParts = [title];
    }

    titleEls = titleParts.map((part: string | null, i: number) => (
      <span
        className={`inline-block`}
        style={{
          fontFamily: "Roboto",
          fontWeight: 900,
          fontSize: `21px`,
          lineHeight: `21px`,
          color:
            i % 2 === 0 ? "rgba(14, 42, 72, 1)" : "rgba(10, 132, 255, 1)",
          marginRight: 7,
        }}
        key={`${i}${part}`}
      >
        {part}
      </span>
    ));
  }

  // const [show, setShow] = useState(true);

  // useInterval(() => {
  //   setShow(!show);
  // }, 1000);

  // if (!show) return null;

  return (
    <Portal hostName="ui-block">
      {!!show && (
        <div
          className={`ui-block fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center text-center`}
          style={{
            // backgroundColor: `rgba(0,0,0,0.8)`,
            backgroundColor: `rgba(255,255,255,0.8)`,
            zIndex: 9999999,
          }}
        >
          {!!titleEls && (
            <div
              className={`w-full`}
              style={{
                maxWidth: `80%`,
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `18px`,
                lineHeight: `24px`,
                // color: `#fff`,
                color: `#0d2b48`,
                textTransform: 'uppercase',
              }}
            >
              {titleEls}

              {!!subText && (
                <div
                  className={`w-full mt-2`}
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 700,
                    fontSize: `18px`,
                    lineHeight: `26px`,
                    // color: `#fff`,
                    color: `#0d2b48`,
                    textTransform: 'none',
                  }}
                >
                  {subText}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Portal>
  );
}

export { UiBlock };