import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { Layout } from "../layout/Layout";
import { LoginCodeInput } from "../login-code-input/LoginCodeInput";
import { SendLoginCodeButton } from "../send-login-code-button/SendLoginCodeButton";
import { VerifyLoginCodeButton } from "../verify-login-code-button/VerifyLoginCodeButton";
import { usePasswordlessState } from "../../../core/usePasswordlessState";
import { API } from "../../../../components/inputs/login-code/LoginCodeInput";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useMultiAuth } from "../../../core/useMultiAuth";
import { callWithRetry, parsePhoneNumber, toValidInteger } from "../../../../lib/utils";
import usePrevious from "../../../../lib/usePrevious";
import { event } from "../../../../events/core/helpers";
import { OtpSendStarted } from "../../../../events/event-types/auth/OtpSendStarted";
import { OtpSendFailed } from "../../../../events/event-types/auth/OtpSendFailed";
import { OtpSendSucceeded } from "../../../../events/event-types/auth/OtpSendSucceeded";
import { OtpVerifyStarted } from "../../../../events/event-types/auth/OtpVerifyStarted";
import { OtpVerifyFailed } from "../../../../events/event-types/auth/OtpVerifyFailed";
import { OtpVerifySucceeded } from "../../../../events/event-types/auth/OtpVerifySucceeded";
import { useUnauthenticatedCredentials } from "../../../../events/useUnauthenticatedCredentials";
import { makeSessionDetails } from "../../../core/Auth";

export type State = {
  uiState: `ENTER_PHONE_NUMBER` | `OTP_SENT`;
  isSending: boolean;
  isVerifying: boolean;
  isSuccess: boolean;
  errorMessage: string | null;
  credential: string;
  loginCode: string;
};

export type Props = {
  provider: `phone_number` | `email`;
  helperTexts: {
    ENTER_CREDENTIAL: (string | ReactNode)[][];
    OTP_SENT: (string | ReactNode)[][];
  };
  helperSubtext: {
    ENTER_CREDENTIAL: string | null | undefined;
    OTP_SENT: string | null | undefined;
  };
  credentialComponent: (
    args: ReturnType<typeof usePasswordlessState>
  ) => ReactNode;
  onCancel?: () => any;
};

function Login(props: Props) {
  const {
    provider,
    helperTexts,
    helperSubtext,
    credentialComponent,
    onCancel = () => {},
  } = props;

  const loginCodeInputRef = useRef<API | null>(null);

  const hookRet = usePasswordlessState({ provider, onCancel });
  const { providers } = useMultiAuth();
  const { allProviders } = providers;
  const { set: setUnauthenticatedCredentials } =
    useUnauthenticatedCredentials();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [isFetchingSendRecaptchaToken, setIsFetchingSendRecaptchaToken] = useState(false);
  const [isFetchingVerifyRecaptchaToken, setIsFetchingVerifyRecaptchaToken] = useState(false);

  const showAllLoginProviders = true;
  const showOtherOptions = showAllLoginProviders
    ? true
    : allProviders.filter((p) => p !== provider).length > 0;

  const {
    state,
    isCredentialValid,
    isLoginCodeValid,
    resetState,
    setLoginCode,
    sendLoginCode: _sendLoginCode,
    verifyLoginCode: _verifyLoginCode,
  } = hookRet;

  const {
    uiState,
    isSending,
    isVerifying,
    isSuccess,
    errorMessage,
    credential,
    loginCode,
  } = state;

  const getRecaptchaToken = useCallback(async () => {

    //
    // Temporarily Disabled until we figure out the errors and if they're affecting users

    return { recaptchaToken: `EXECUTE_RECAPTCHA_FAILED - Error: Temporarily Disabled`, error: null };

    //
    //

    // try {
    //   const recaptchaToken = await callWithRetry(
    //     async () => executeRecaptcha('meterez_park_send_otp_on_submit'),
    //     3,
    //     500,
    //     false
    //   );  

    //   return { recaptchaToken, error: null };
    // } catch(e: unknown) {
    //   // NOTE: Sometimes executeRecaptcha fails, and we don't want it to prevent users from using 
    //   //       the app, so we return a string that will be recognized on the server, but is a bit
    //   //       obfuscated (since this comment won't end up in the prod build of the source code).
    //   //       The server needs to expect either a valid recaptcha token, or a string prefixed
    //   //       with "EXECUTE_RECAPTCHA_FAILED - Error:", in which case it will let the request 
    //   //       through, but it's unlikely bots will figure that out, so it's fine for our use case.
    //   return { recaptchaToken: `EXECUTE_RECAPTCHA_FAILED - Error: ${e?.message}`, error: e };
    // }
  }, [/*executeRecaptcha*/]);

  const sendLoginCode = useCallback(
    async (args: {
      credential: string,
    }) => {
      const { credential } = args;

      try {
        setUnauthenticatedCredentials({
          provider,
          credential:
            provider === `phone_number`
              ? (parsePhoneNumber(credential).e164 as string)
              : credential,
          authenticated: false,
        });

        setIsFetchingSendRecaptchaToken(true);

        const { recaptchaToken } = await getRecaptchaToken();

        const result = await _sendLoginCode({
          credential, 
          recaptchaToken,
        });

        setIsFetchingSendRecaptchaToken(false);

        return result;
      } catch(e: unknown) {
        setIsFetchingSendRecaptchaToken(false);

        throw e;
      }
    },
    [_sendLoginCode, getRecaptchaToken, provider, setUnauthenticatedCredentials]
  );

  const verifyLoginCode = useCallback(
    async (args: {
      credential: string, 
      loginCode: string,
    }) => {
      const { credential, loginCode } = args;

      try {
        setIsFetchingVerifyRecaptchaToken(true);

        const { recaptchaToken } = await getRecaptchaToken();
  
        const result = await _verifyLoginCode({
          credential, 
          code: toValidInteger(loginCode) as number,
          recaptchaToken,
          onBeforeLogin: ({ tokenResponse }) => {
            const { identityId, userId } = makeSessionDetails({
              expiresAt: tokenResponse.expires_at,
              idToken: tokenResponse.id_token,
            });

            setUnauthenticatedCredentials({
              provider,
              credential:
                provider === `phone_number`
                  ? (parsePhoneNumber(credential).e164 as string)
                  : credential,
              authenticated: true,
              identityId,
              userId,
            });
          }
        });
  
        if ((!result?.tokenResponse) && !!loginCodeInputRef.current) {
          loginCodeInputRef.current.reset();
        }

        setIsFetchingVerifyRecaptchaToken(false);
  
        return result;
      } catch(e: unknown) {
        setIsFetchingVerifyRecaptchaToken(false);

        throw e;
      }
      
    },
    [_verifyLoginCode, getRecaptchaToken, provider, setUnauthenticatedCredentials]
  );

  const prevIsSending = usePrevious(isSending);
  const prevIsVerifying = usePrevious(isVerifying);

  useEffect(() => {
    if (prevIsSending === false && isSending === true) {
      event(
        new OtpSendStarted({
          provider,
          credential,
        })
      );
    } else if (prevIsSending === true && isSending === false) {
      if (errorMessage) {
        event(
          new OtpSendFailed({
            provider,
            credential,
            errorMessage,
          })
        );
      } else {
        event(
          new OtpSendSucceeded({
            provider,
            credential,
          })
        );
      }
    }

    if (prevIsVerifying === false && isVerifying === true) {
      event(
        new OtpVerifyStarted({
          provider,
          credential,
        })
      );
    } else if (prevIsVerifying === true && isVerifying === false) {
      if (errorMessage) {
        event(
          new OtpVerifyFailed({
            provider,
            credential,
            errorMessage,
          })
        );
      } else {
        // NOTE: This never fires, because auth session state unmounts this component
        event(
          new OtpVerifySucceeded({
            provider,
            credential,
          })
        );
      }
    }
  }, [isSending, isVerifying]);

  const sendLoginCodeButtonDisabled = (!isCredentialValid) || isFetchingSendRecaptchaToken || isSending;
  const verifyLoginCodeButtonDisabled = (!isLoginCodeValid) || isFetchingVerifyRecaptchaToken || isVerifying;

  return (
    <Layout
      helperTexts={
        uiState === `ENTER_CREDENTIAL`
          ? helperTexts.ENTER_CREDENTIAL
          : helperTexts.OTP_SENT
      }
      helperSubtext={
        uiState === `ENTER_CREDENTIAL`
          ? helperSubtext.ENTER_CREDENTIAL
          : helperSubtext.OTP_SENT
      }
      errorMessage={errorMessage}
      content={
        
        <form
          style={{
            width: `100%`,
          }}
          onSubmit={e => {
            e.preventDefault();

            if (uiState === `ENTER_CREDENTIAL` && !sendLoginCodeButtonDisabled) {
              sendLoginCode({ credential });
            } else if (uiState === `OTP_SENT` && !verifyLoginCodeButtonDisabled) {
              verifyLoginCode({ credential, loginCode });
            } 
          }}
        >
          <div
            style={{
              width: `100%`,
            }}
          >
            {credentialComponent(hookRet)}

            <LoginCodeInput
              show={uiState === `OTP_SENT`}
              disabled={isFetchingSendRecaptchaToken || isFetchingSendRecaptchaToken || isSending || isVerifying || isSuccess}
              value={loginCode}
              onChange={(loginCode) => {
                setLoginCode({ loginCode });
              }}
              onComplete={(loginCode) => {
                verifyLoginCode({ credential, loginCode });
              }}
              inputRef={loginCodeInputRef}
            />
          </div>

          <div style={{ height: 10 }} />

          <div
            style={{
              width: `100%`,
            }}
          >
            <SendLoginCodeButton
              show={uiState === `ENTER_CREDENTIAL`}
              showOtherOptions={showOtherOptions}
              disabled={sendLoginCodeButtonDisabled}
              isSending={isFetchingSendRecaptchaToken || isSending}
              onPress={() => {
                sendLoginCode({ credential });
              }}
              onCancel={() => {
                onCancel();
              }}
            />

            <VerifyLoginCodeButton
              show={uiState === `OTP_SENT`}
              disabled={verifyLoginCodeButtonDisabled}
              isVerifying={isFetchingVerifyRecaptchaToken || isVerifying}
              isSuccess={isSuccess}
              onPress={() => {
                verifyLoginCode({ credential, loginCode });
              }}
              resendDisabled={isFetchingVerifyRecaptchaToken || isVerifying || isSuccess}
              onPressResend={async () => {
                await sendLoginCode({ credential });
              }}
              onCancel={() => {
                resetState();
              }}
            />
          </div>
        </form>
      }
    />
  );
}

export { Login };
