import { SummaryCard } from "./SummaryCard";
import { ReactComponent as IconSteeringWheel } from "../../../src/assets/icons/icon-steering-wheel.svg";
import { TextSkeleton } from "../skeletons/TextSkeleton";
import useVehicles from "../../queries/vehicles/useVehicles";
import _ from "lodash";
import { useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import { useHandleError } from "../../lib/error-handling";
import { useAlertModal } from "../modals/alert-modal";
import { useLiveNow } from "../../lib/useLiveNow";
import { isActive } from "../../lib/utils";
import { useCallback } from "react";
import useDeleteVehicle from "../../queries/vehicles/useDeleteVehicle";
import { event } from "../../events/core/helpers";
import { EventSubCategories } from "../../events/event-sub-categories";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";

export type Props = {
  skeletonMode?: `PROMPT` | `EMPTY`;
  vehicleId: number | null | undefined;
  showEditIcon?: boolean;
  onClick?: () => any;
  showDeleteButton?: boolean;
  showParkedIndicator?: boolean;
  disabledIfParked?: boolean;
  disabled?: boolean;
};

function VehicleCard(props: Props) {
  const { skeletonMode, vehicleId, showEditIcon = false, onClick, showDeleteButton = false, showParkedIndicator = false, disabledIfParked = false, disabled } = props;

  const handleError = useHandleError();
  const alert = useAlertModal();

  const { query: vehiclesQuery, vehicles } = useVehicles();
  const { parkableTransactions } = useRecentParkableTransactions();
  const { mutation } = useDeleteVehicle({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: () => {
      vehiclesQuery.refetch();
    }
  });

  const vehicle = _.find(vehicles, { id: vehicleId });
  const now = useLiveNow();
  const isParked = parkableTransactions.filter(pt => pt.vehicle_id === vehicleId && isActive(pt, now)).length > 0;
  const deleteButtonDisabled = isParked;

  const onClickDelete = useCallback(() => {
    if (isParked) {
      alert.show({
        title: [`Cannot`, `Delete`],
        message: `An active or processing parking session is still using this vehicle. Please try again later.`,
      });
    } else {
      alert.show({
        title: [`Are You`, `Sure?`],
        message: `Are you sure you want to delete this vehicle?`,
        buttons: [
          {
            contentTop: `Delete`,
            type: `primary-red`,
            onClick: () => {
              event(
                new ButtonPress({
                  buttonName: `Delete Vehicle`,
                  buttonSlug: `VEHICLE.DELETE`,
                  subCategory: EventSubCategories.VEHICLES,
                })
              );

              mutation.mutate({
                vehicleId,
              });
            },
          },
          {
            contentTop: `Cancel`,
            type: `tertiary-transparent`,
          }
        ],
      });
    }
  }, [isParked, alert, mutation, vehicleId]);

  let rightTopContent = null;
  let rightBottomContent = null;

  if (skeletonMode === `PROMPT`) {
    rightTopContent = (
      <div>
        <span
          className={`uppercase`}
          style={{
            color: `#0A84FF`,
          }}
        >
          Next:
        </span>
        {` `}Enter License Plate...
      </div>
    );
  } else if (skeletonMode === `EMPTY` || (!!vehicleId && !vehicle)) {
    rightTopContent = (
      <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
    );
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (vehicle) {
    rightTopContent = (
      <div
        className={`overflow-hidden whitespace-nowrap text-ellipsis`}
        style={{
          maxWidth: `150px`,
        }}
      >
        {`${vehicle?.name ?? vehicle.license_plate}${showParkedIndicator && isParked ? ` (Parked)`:``}`}
      </div>
    )
  }

  if (skeletonMode === `PROMPT` || skeletonMode === `EMPTY`) {
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (vehicle) {
    rightBottomContent = `${vehicle?.name ? `${vehicle.license_plate} - `:``}${vehicle.country_code} / ${vehicle.state_code}`;
  }

  return (
    <SummaryCard
      icon={
        <IconSteeringWheel
          className={`relative`}
          style={{
            top: `-1px`,
            width: `14px`,
            // height: `11px`,
          }}
        />
      }
      topContent={`License Plate`}
      rightTopContent={rightTopContent}
      rightBottomContent={rightBottomContent}
      showEditIcon={showEditIcon}
      showDeleteButton={showDeleteButton && (!disabledIfParked || !isParked)}
      deleteButtonDisabled={deleteButtonDisabled || mutation.isLoading || mutation.isSuccess || (disabledIfParked && isParked)}
      onClickDelete={onClickDelete}
      onClick={onClick}
      disabled={disabled || mutation.isLoading || mutation.isSuccess || (disabledIfParked && isParked)}
    />
  );
}

export { VehicleCard };