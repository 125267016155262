import { useQuery } from "@tanstack/react-query";
import { environment, getGraphqlEndpoint } from "../../config/config";
import { request } from 'graphql-request';
import queryKeys from "../query-keys";
import { UserSettingsDocument, UserSettingsQuery } from "../../generatedQueries";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { Auth } from "../../auth/core/Auth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export default function useUserSettings() {
  const auth = useMultiAuth();
  // const handleError = useDefaultQueryErrorHandler();

  const query = useQuery<UserSettingsQuery["user_settings_all"]>(
    queryKeys.UserSettings(auth.session?.sessionDetails.userId ?? -1),
    async ({ signal }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: UserSettingsDocument,
        variables: {},
        requestHeaders,
        signal,
      })).user_settings_all;
    },
    {
      enabled: !!auth.session,
      staleTime: environment.isProduction ? 5 * 60 * 1000 : 0, // 5min
      // onError: (error) => {
      //   handleError(error);
      // },
    }
  );

  // useThrowQueryErrorToBoundary(query);

  return {
    query,
    userSettings: query.data ?? null,
  };
}
