import { ReactNode } from "react";
import { ReactComponent as IconEdit } from "../../../src/assets/icons/icon-edit.svg";
import { ReactComponent as IconTrashCan } from "../../../src/assets/icons/icon-trash-can-solid.svg";

export type Props = {
  topContent: ReactNode;
  icon: ReactNode;
  rightTopContent: ReactNode;
  rightBottomContent: ReactNode;
  showEditIcon?: boolean;
  onClick?: () => any;
  showDeleteButton?: boolean;
  deleteButtonDisabled?: boolean;
  onClickDelete?: (disabled: boolean) => any;
  disabled?: boolean;
};

function SummaryCard(props: Props) {
  const { topContent, icon, rightTopContent, rightBottomContent, showEditIcon = false, onClick, showDeleteButton = false, deleteButtonDisabled = false, onClickDelete = () => {}, disabled } = props;

  return (
    <div
      className={`w-full block rounded-md relative`}
      style={{
        backgroundColor: `#fff`,
      }}
    >
      <button
        className={`w-full block rounded-md relative p-2 ${disabled ? `opacity-50`:``}`}
        style={{
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
        }}
        onClick={(e) => {
          e.preventDefault();
          if (onClick) {
            onClick();
          }
        }}
        disabled={disabled === undefined ? !onClick : disabled}
      >
        <div
          className={`text-left uppercase`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `10px`,
            lineHeight: `14px`,
            color: `#989FB4`,
            paddingBottom: `5px`,
          }}
        >
          {topContent}
        </div>
        <div
          className={`flex flex-row`}
          style={{
            
          }}
        >
          <div
            className={`flex justify-start items-center`}
            style={{
              height: `19px`,
              width: `25px`,
            }}
          >
            {icon}
          </div>
          <div
            className={`w-full`}
            style={{
              
            }}
          >
            <div
              className={`w-full text-left`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `16px`,
                lineHeight: `20px`,
                color: `#0E2A48`,
              }}
            >
              {rightTopContent}
            </div>
            <div
              className={`w-full text-left uppercase`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `10px`,
                lineHeight: `14px`,
                color: `#0E2A48`,
              }}
            >
              {rightBottomContent}
            </div>
          </div>
        </div>

        {showEditIcon && (
          <div
          className={`absolute top-0 right-0 bottom-0`}
          >
            <IconEdit
              className={`opacity-50 mr-2 mt-2`}
            />
          </div>
        )}
      </button>

      {showDeleteButton && (
          <button
            className={`absolute top-0 right-0 bottom-0 flex items-center justify-center rounded-md`}
            style={{
              minWidth: `50px`,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickDelete(deleteButtonDisabled);
            }}
          >
            <IconTrashCan
              className={`${deleteButtonDisabled ? `opacity-50`:``}`}
              style={{
                width: `15px`,
              }}
            />
          </button>
        )}
    </div>
  );
}

export { SummaryCard };