import _ from "lodash";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { useParkableTransactionPriceInfo } from "../../../../lib/useParkableTransactionPriceInfo";
import useParkable from "../../../../queries/parkables/useParkable";
import { formatMoney } from "../../../../lib/utils";
import { TextSkeleton } from "../../../../components/skeletons/TextSkeleton";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { DownloadAppExplainer } from "../../../../components/modals/download-app-explainer/DownloadAppExplainer";
import { useState } from "react";

export type Props = {

};

function Summary(props: Props) {
  const {} = props;

  const [showAppExplainer, setShowAppExplainer] = useState(false);
  const session = useSessionInProgress();

  const { parkable } = useParkable({ parkableId: session.sessionInProgress?.sessionParameters.parkableId, poll: true });
  const { parkableTransactions } = useRecentParkableTransactions();
  const parkableTransaction = _.find(parkableTransactions, { id: session.sessionInProgress?.parkableTransactionId });

  const { price, fee, total, walletSavings } = useParkableTransactionPriceInfo({
    parkable: parkableTransaction ?
      {
        price: parkableTransaction?.snapshot_price,
        price_interval: parkableTransaction?.snapshot_price_interval,
        price_interval_unit: parkableTransaction?.snapshot_price_interval_unit,
      } :
      parkable,
    time: session.sessionInProgress?.sessionParameters.time,
    walletFee: parkableTransaction ? 
      parkableTransaction.snapshot_fee
      : parkable?.parking_authority.wallet_fee,
    cardFee: parkableTransaction ? 
      parkableTransaction.snapshot_fee
      : parkable?.parking_authority.card_fee,
    applyFee: !session.sessionInProgress?.parkableTransactionId,
  });

  return (
    <div
      className={`w-full text-left flex flex-col px-2 ${!session.sessionInProgress ? `opacity-50`:``}`}
      style={{
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        fontSize: `12px`,
        lineHeight: `18px`,
        color: `#989FB4`,
      }}
    >
      <div
        className={`w-full flex mb-1`}
        style={{
  
        }}
      >
        <div
          className={`pr-4`}
          style={{
            minWidth: `100px`,
          }}
        >
          Parking
        </div>
        <div
          className={`flex-grow text-right`}
          style={{
            
          }}
        >
          {_.isInteger(price) && formatMoney(price)}
          {!_.isInteger(price) && <TextSkeleton fontSize={`12px`} lineHeight={`18px`} />}
        </div>
      </div>
      <div
        className={`w-full flex  mb-1`}
        style={{
  
        }}
      >
        <div
          className={`pr-4`}
          style={{
            minWidth: `100px`,
          }}
        >
          <span

          >
            Processing Fee
          </span>
          <span

          >
            {/** TODO Why is there a fee button */}
          </span>
        </div>
        <div
          className={`flex-grow text-right`}
          style={{
    
          }}
        >
          {_.isInteger(fee) && formatMoney(fee)}
          {!_.isInteger(fee) && <TextSkeleton fontSize={`12px`} lineHeight={`18px`} />}
        </div>
      </div>
      <div
        className={`w-full flex`}
        style={{
  
        }}
      >
        <div
          className={``}
          style={{
            color: `#0E2A48`,
            minWidth: `100px`,
          }}
        >
          Total
        </div>
        <div
          className={`flex-grow text-right`}
          style={{
            color: `#0E2A48`,
          }}
        >
          {_.isInteger(total) && formatMoney(total)}
          {!_.isInteger(total) && <TextSkeleton fontSize={`12px`} lineHeight={`18px`} />}
        </div>
      </div>

      {!!walletSavings && (
        <button
          className={`uppercase py-1 px-1 mt-3 mb-2 rounded`}
          style={{
            color: `#fff`,
            backgroundColor: `#0b84ff`,
          }}
          onClick={(e) => {
            e.preventDefault();
            setShowAppExplainer(true);
          }}
        >
          How do I save money with the EZwallet?
        </button>
      )}

      <DownloadAppExplainer
        open={showAppExplainer}
        onClose={() => setShowAppExplainer(!showAppExplainer)}
      />
    </div>
  );
}

export { Summary };