import { useCallback, useEffect, useRef, useState } from "react";
import { useMultiAuth } from "../../core/useMultiAuth";
import { AuthRequest as AuthRequestManager, TokenResponse } from "../AuthRequest";
import { Navigate, useParams } from "react-router-dom";
import { reportError } from "../../../lib/utils";
import { OAuthFailed } from "../../../events/event-types/auth/OAuthFailed";
import { event } from "../../../events/core/helpers";
import { OAuthSucceeded } from "../../../events/event-types/auth/OAuthSucceeded";
import { useUnauthenticatedCredentials } from "../../../events/useUnauthenticatedCredentials";
import { makeSessionDetails } from "../../core/Auth";

export type Provider = `apple` | `google` | `phone_number` | `email` | `unknown`;

export type State = {
  state: `IDLE` | `IN_PROGRESS` | `CANCELLED` | `ERROR` | `SUCCESS`;
  errorMessage?: string | null | undefined;
  internalErrorMessage?: string | null | undefined;
};

function AuthCallbackScreen() {
  const params = useParams() as { provider: Provider };
  const auth = useMultiAuth();
  const { set: setUnauthenticatedCredentials } =
    useUnauthenticatedCredentials();
  const AuthRequest = useRef(new AuthRequestManager()).current;

  const provider = params.provider ?? `unknown`;

  const [state, setState] = useState<State>({
    state: `IDLE`,
    errorMessage: null,
    internalErrorMessage: null,
  });

  const { state: uiState, errorMessage, internalErrorMessage } = state;

  const handle = useCallback(async () => {
    let tokenResponse: TokenResponse | undefined = undefined;

    try {
      if (![`apple`, `google`].includes(provider)) {
        setState((state) => ({
          ...state,
          state: `ERROR`,
          errorMessage: `Unknown Error, Try Again`,
        }));
  
        return;
      }
      
      setState((state) => ({
        ...state,
        state: `IN_PROGRESS`,
      }));
  
      const result = await AuthRequest.handleCallback();
  
      if (result.status === `SUCCESS` && result.tokenResponse) {
        tokenResponse = result.tokenResponse;

        const { identityId, userId, tokenPayload } = makeSessionDetails({
          idToken: tokenResponse.idToken,
          expiresAt: tokenResponse.expiresAt,
        });

        const socialEmail = tokenPayload["https://hasura.io/jwt/claims"]["x-hasura-credential"];

        if (socialEmail) {
          setUnauthenticatedCredentials({
            provider: provider as `apple` | `google`,
            credential: socialEmail,
            authenticated: true,
            identityId,
            userId,
          });
        }

        auth.setSession({
          idToken: result.tokenResponse.idToken,
          expiresAt: result.tokenResponse.expiresAt,
        });

        if (provider !== `unknown`) {
          auth.providers.setLastLoggedIn(provider);
        }

        event(
          new OAuthSucceeded({
            provider: provider as `apple` | `google`,
          })
        );
  
        setState((state) => ({
          ...state,
          state: `SUCCESS`,
        }));
      } else {
        const errorMessage = result.error ?? `Unknown Error, Try Again`;

        event(
          new OAuthFailed({
            provider: provider as `apple` | `google`,
            idToken: tokenResponse?.idToken,
            expiresAt: tokenResponse?.expiresAt,
            errorMessage,
          })
        );

        setState((state) => ({
          ...state,
          state: `ERROR`,
          errorMessage,
          internalErrorMessage: result.internalError,
        }));
      }
    } catch (e: unknown) {
      event(
        new OAuthFailed({
          provider: provider as `apple` | `google`,
          idToken: tokenResponse?.idToken,
          expiresAt: tokenResponse?.expiresAt,
          errorMessage: e?.message,
        })
      );

      reportError(e);

      setState((state) => ({
        ...state,
        state: `ERROR`,
        errorMessage: `Unknown Error, Try Again`,
      }));
    }
  }, [provider, AuthRequest, auth, setUnauthenticatedCredentials]);

  const handledRef = useRef<boolean>(false);

  useEffect(() => {
    if (handledRef.current) return;

    handledRef.current = true;

    handle();
  }, []);

  if (uiState === `IDLE`) {
    return null;
  } else if (uiState === `SUCCESS`) {
    return <Navigate to={`/sessions`} replace />;
  } else if (uiState === `ERROR`) {
    const errorParams: string[] = [];

    if (errorMessage) {
      errorParams.push(`error=${encodeURIComponent(errorMessage)}`); 
    }

    if (internalErrorMessage) {
      errorParams.push(`internal_error=${encodeURIComponent(internalErrorMessage)}`); 
    }

    const errorParamsStr = errorParams.join('&');
    const errorStr = errorParams.length > 0 ? `?${errorParamsStr}` : '';

    return <Navigate to={`/login${errorStr}`} replace />;
  }
}

export { AuthCallbackScreen };