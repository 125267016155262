import { request } from 'graphql-request';
import { useQuery } from "@tanstack/react-query";
import { RecentParkableTransactionsDocument, RecentParkableTransactionsQuery } from "../../generatedQueries";
import { environment, getGraphqlEndpoint, parking } from '../../config/config';
import { serverDatetimeToMoment, simulateNetworkError } from '../../lib/utils';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useMemo } from 'react';
import queryKeys from '../query-keys';
import { Auth } from '../../auth/core/Auth';
import { useMultiAuth } from '../../auth/core/useMultiAuth';
import env from '../../config/env';
import { getDeviceHeaders } from '../../http/getDeviceHeaders/getDeviceHeaders';

export type ParkableTransaction = ReturnType<typeof useRecentParkableTransactions>["parkableTransactions"][number];

async function fetchRecentParkableTransactions(args: { signal?: AbortSignal | null }) {
  const { signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: RecentParkableTransactionsDocument,
    variables: {
      dateFrom: moment
        .utc()
        // Get the further back of these 2 configs, then filter client-side below
        .subtract(
          // Math.max(
          //   24 * 60 * 60, // 24 hrs in seconds,
          //   72 * 60 * 60, // 72 hrs in seconds
          // ),
          30 * 24 * 60 * 60, // 30 days
          "seconds"
        )
        .format("YYYY-MM-DDTHH:mm:ss"),
      limit: 25,
    },
    requestHeaders,
    signal,
  })).parkable_transactions;
}

const testExpiryDatetime = moment.utc().add(30, "seconds");

function useRecentParkableTransactions() {
  const auth = useMultiAuth();

  const query = useQuery<RecentParkableTransactionsQuery["parkable_transactions"]>(
    queryKeys.RecentParkableTransactions(auth.session?.sessionDetails.userId ?? -1),
    async ({ signal }) => {
      return await fetchRecentParkableTransactions({ signal });
    },
    {
      enabled: !!auth.session,
      staleTime: environment.isProduction ? 1 * 60 * 1000 : 0,
      refetchOnMount: false,
      refetchInterval: parking.recentParkableTransactionsRefetchInterval,
      // onSuccess(data) {

      // },
      // onError: (error) => {
      //   if (options?.onError) {
      //     options.onError(error);
      //   }
      //   // else {
      //   //   handleError(error);
      //   // }
      // },
    }
  );

  const data = query.data;

  const allParkableTransactions = useMemo(() => (
    _.chain(data ?? [])
      .sortBy([
        (pt) =>
          serverDatetimeToMoment(pt.created_at).unix(),
      ])
      .reverse()
      .value()
    ), [data])
    /**
     * Use this to simulate a certain session expiring in 30s
     * 
     * .map(pt => {
      if (!environment.isProduction && pt.id === 800163) {
        const endTimeUtc = testExpiryDatetime;
        const time = 20 * 60;
        const startTime = endTimeUtc.clone().subtract(time, "seconds");

        pt.created_at = startTime.toISOString();
        pt.expires_at = endTimeUtc.toISOString();
        pt.expired_at = null;
        pt.cancelled_at = null;
        pt.time = time;
      }

      return pt;
    })*/;

  const parkableTransactions = useMemo(() => (
    _.chain(allParkableTransactions)
      // Only show the last 24hrs for recents
      .filter((pt) => serverDatetimeToMoment(pt.created_at).isAfter(moment.utc().subtract(24, "hours")))
      .sortBy([
        (pt) =>
          serverDatetimeToMoment(pt.created_at).unix(),
      ])
      .reverse()
      .uniqBy((pt) => pt.vehicle_id)
      .value()
    ), [allParkableTransactions]);

  // if (env(`DEBUG`)) {
  //   console.log(`useRecentParkableTransactions - allParkableTransactions`, allParkableTransactions);
  // }

  return useMemo(() => ({
    query,
    parkableTransactions,
    allParkableTransactions,
  }), [query, parkableTransactions, allParkableTransactions]);
}

export { useRecentParkableTransactions };