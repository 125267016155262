import history from "history";
import { useNavigation, useLocation, useNavigate, NavLink, useParams } from "react-router-dom";
import helpOptions from "./helpOptions";
import _ from "lodash";
import { ReactComponent as IconArrow } from "../../../../src/assets/icons/arrow-right-solid.svg";
import { useMultiAuth } from "../../../auth/core/useMultiAuth";

function BreadCrumbs() {
  const auth = useMultiAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const { optionSlug } = useParams() as { optionSlug?: string };

  const helpOption = _.find(helpOptions, { slug: optionSlug });

  let backButton = null;
  if (false /*location.key !== 'default'*/) {
    backButton = (
      <button
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        Back
      </button>
    );
  } else if (auth.session) {
    backButton = (
      <NavLink
        to={`/sessions`}
      >
        Sessions
      </NavLink>
    );
  } else {
    backButton = (
      <NavLink
        to={`/login`}
      >
        Login
      </NavLink>
    );
  }
  
  if (auth.isLoading) return null;

  return (
    <div
      className={`w-full flex items-center mb-4`}
      style={{
        fontFamily: "Roboto",
        fontWeight: 900,
        fontSize: `14px`,
        lineHeight: `16px`,
        color: "#0E2A48",
      }}
    >
      {backButton}

      <IconArrow
        className={/*`absolute`*/` opacity-50 mx-2`}
        style={{
          height: `12px`,
          // top: `15px`,
          // right: `3px`,
        }}
      />

      <NavLink
        to={`/help`}
      >
        Help
      </NavLink>

      {!!helpOption && (
        <>
          <IconArrow
            className={/*`absolute`*/` opacity-50 mx-2`}
            style={{
              height: `12px`,
              // top: `15px`,
              // right: `3px`,
            }}
          />

          <NavLink
            to={`/help/${helpOption.slug}`}
          >
            {helpOption.headingText}
          </NavLink>
        </>
      )}
    </div>
  );
}

export { BreadCrumbs };