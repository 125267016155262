export type Props = {
  message: string;
};

function GeneralUserMessage(props: Props) {
  const { message } = props;

  return (
    <div
      className={`w-full flex items-center justify-center text-center p-4`}
      style={{
        fontFamily: `Roboto, sans-serif`,
        fontWeight: 700,
        fontSize: `18px`,
        color: `#0E2A48`,
        // minHeight: `100vh`,
        // minHeight: `-webkit-fill-available`,
        minHeight: `100dvh`,
      }}
    >
      {message}
    </div>
  );
}

export { GeneralUserMessage };