import moment, { Moment } from "moment-timezone";
import { useState } from "react";
import useInterval from "./useInterval";

function useLiveNow() {
  const [now, setNow] = useState<Moment>(moment.utc());

  useInterval(() => {
    setNow(moment.utc());
  }, 1000);

  return now;
}

export { useLiveNow };