import { useMemo } from "react";
import useUser from "../../queries/users/useUser";
import env from "../../config/env";
import _ from "lodash";

function useAccountPrerequisites() {
  const { user } = useUser();

  // Testing
  if (user) {
    // user.locked_at = "x";
    // user.is_legacy_migration = true;
    // user.legacyMigration = { completed_at : null };
    // user.beta_features_enabled = false;
    // user.terms_of_service_last_accepted = null;
    // user.first_name = null;
    // user.last_name = null;
  }

  return useMemo(() => {
    const items = {
      ACCOUNT_LOCKED: {
        show: !!user && !!user.locked_at,
      },
      LEGACY_MIGRATION: {
        show: !!user && !!user.is_legacy_migration && !user.legacyMigration?.completed_at,
      },
      REQUIRES_BETA_FEATURES: {
        show: env<boolean>(`BETA_USERS_ONLY`, null, 'bool') === true && !!user && !user.beta_features_enabled,
      },
      TERMS_OF_SERVICE: {
        show: !!user && !user.terms_of_service_last_accepted,
      },
      NAME: {
        show: !!user && (!_.trim(user.first_name) || !_.trim(user.last_name)),
      },
    };

    return {
      isLoading: !user,
      requiresSetup: !!user && _.filter(items, item => item.show).length > 0,
      items,
      user,
    }
  }, [user]);
}

export { useAccountPrerequisites };