import { DeletePaymentMethodMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  paymentMethodId?: number;
};

export type ConstructorArgs = {
  mutationResponse: DeletePaymentMethodMutation["deletePaymentMethod"];
} & Partial<EventConstructorArgs>;

class DeletePaymentMethodResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `DELETE_PAYMENT_METHOD.RESPONSE`,
      data: {
        paymentMethodId: mutationResponse?.deletedPaymentMethodId,
      },
    });
  }
}

export { DeletePaymentMethodResponse };
