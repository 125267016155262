import { ExtendTimeMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  parkableTransactionId?: number;
  time?: number;
  lastTimeExtendedAt?: string;
};

export type ConstructorArgs = {
  mutationResponse: ExtendTimeMutation["extendTime"];
} & Partial<EventConstructorArgs>;

class ExtendTimeResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
      type: `EXTEND_TIME.RESPONSE`,
      data: {
        parkableTransactionId: mutationResponse?.parkableTransaction?.id,
        time: mutationResponse?.parkableTransaction?.time,
        lastTimeExtendedAt:
          mutationResponse?.parkableTransaction?.last_time_extended_at,
      },
    });
  }
}

export { ExtendTimeResponse };
