import { useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import usePaymentMethods from "../../queries/payment-methods/usePaymentMethods";
import useUser from "../../queries/users/useUser";
import useVehicles from "../../queries/vehicles/useVehicles";

function PreLoader() {
  useUser();
  useRecentParkableTransactions();
  useVehicles();
  usePaymentMethods();

  return null;
}

export { PreLoader };