import { Country, State } from "countries-state-list";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";
import { auth, environment, errorReporting, getTimeDiscrepancy } from "../config/config";
import {
  PhoneNumberFormat as FORMAT,
  PhoneNumberUtil,
} from "google-libphonenumber";
import { sleep } from "./sleep";
import { GraphQLAPIErrorResponse, GraphQLErrorResponse, parseReactQueryError } from "./error-handling";
import * as Sentry from "@sentry/react";
import { AppError } from "../errors/AppError";
import { GraphQlError } from "../errors/GraphQlError";

const phoneUtil = PhoneNumberUtil.getInstance();

export function toValidInteger(number: unknown): number | null {
  const asString = _.trim(_.toString(number));
  const asInt = _.toInteger(asString);

  return _.toString(asInt) === asString ? asInt : null;
}

export function serverDatetimeToMoment(
  datetime: string,
  local = false
): Moment {
  const _datetime = moment.utc(datetime);
  const timeDiscrepancy = getTimeDiscrepancy();
  const _datetimeWithDiscrepancy = _datetime
    .clone()
    .add(timeDiscrepancy, "seconds");

  // if (!defaultConfig.environment.isProduction) {
  //   console.log("serverDatetimeToMoment", {
  //     _datetime: _datetime.toISOString(),
  //     timeDiscrepancy,
  //     _datetimeWithDiscrepancy: _datetimeWithDiscrepancy.toISOString(),
  //   });
  // }

  return local
    ? datetimeToLocal(_datetimeWithDiscrepancy)
    : _datetimeWithDiscrepancy;
}

export function datetimeToLocal(datetime: Moment) {
  return datetime.clone().tz("America/New_York");
}

/**
 *
 * @param _duration
 * @param round null or 'up' or 'down' to round seconds
 * @returns
 */
export function durationAsHuman(
  _duration,
  modifyHours = (hours, minutes, seconds) => (hours > 0 ? hours : null),
  modifyMinutes = (hours, minutes, seconds) => {
    const show =
      hours > 0 ||
      minutes > 0 ||
      (hours === 0 && minutes === 0 && seconds === 0);

    return show ? minutes : null;

    // Round up minutes
    // return show ?
    //   (minutes >= 1) ? minutes + 1 : minutes
    //   : null;
  },
  modifySeconds = (hours, minutes, seconds) =>
    hours === 0 && minutes === 0 && seconds > 0 ? seconds : null
) {
  const duration = _duration.clone();
  const hours = Math.max(duration.hours(), 0);
  const minutes = Math.max(duration.minutes(), 0);
  const seconds = Math.max(duration.seconds(), 0);
  const hoursModified = modifyHours(hours, minutes, seconds);
  const minutesModified = modifyMinutes(hours, minutes, seconds);
  const secondsModified = modifySeconds(hours, minutes, seconds);

  // if (duration.asSeconds() > 0) {
  //   console.log('durationAsHuman', 'hours:', hours, hoursModified, 'minutes:', minutes, minutesModified, 'seconds:', seconds, secondsModified)
  // }

  const hoursStr = `${Math.floor(hoursModified)} ${
    Math.floor(hoursModified) === 1 ? "HR" : "HRS"
  }`;
  const displayHours = hoursModified !== null;
  const minutesStr = `${minutesModified} ${
    minutesModified === 1 ? "MIN" : "MINS"
  }`;
  const displayMinutes = minutesModified !== null;
  const secondsStr = `${secondsModified} ${
    Math.floor(secondsModified) === 1 ? "SEC" : "SECS"
  }`;
  const displaySeconds = secondsModified !== null;

  let display = "";

  if (displayHours) {
    display = `${hoursStr}${displayMinutes || displaySeconds ? " " : ""}`;
  }

  if (displayMinutes) {
    display = `${display}${minutesStr}${displaySeconds ? " " : ""}`;
  }

  if (displaySeconds) {
    display = `${display}${secondsStr}`;
  }

  return {
    hours,
    hoursStr,
    displayHours,
    minutes,
    minutesStr,
    displayMinutes,
    seconds,
    secondsStr,
    displaySeconds,
    display,
  };
}

export function makeDuration(interval: number, intervalSeconds: number) {
  const oneHour = 60 * 60;
  const oneMinute = 60;
  const durationSeconds = interval * intervalSeconds;
  const durationHours =
    durationSeconds >= oneHour ? durationSeconds / oneHour : 0;
  const durationMinutes = Math.floor(durationSeconds / oneMinute);
  const durationHoursRemainderSeconds =
    durationHours > 0
      ? durationSeconds - Math.floor(durationHours % oneHour) * oneHour
      : 0;
  const durationRemainderMins =
    durationHoursRemainderSeconds > 0
      ? durationHoursRemainderSeconds / oneMinute
      : 0;

  let display = "";

  if (durationHours > 0) {
    display = `${Math.floor(durationHours)} ${
      Math.floor(durationHours) === 1 ? "HR" : "HRS"
    }`;

    if (durationRemainderMins > 0) {
      display = `${display}, ${durationRemainderMins} ${
        durationRemainderMins === 1 ? "MIN" : "MINS"
      }`;
    }
  } else {
    display = `${durationMinutes} ${durationMinutes === 1 ? "MIN" : "MINS"}`;
  }

  return {
    human: display,
    inSeconds: durationSeconds,
    inHours: durationHours,
    inHoursMinutes: [
      Math.floor(durationHours),
      durationHours > 0 ? durationRemainderMins : durationMinutes,
    ],
  };
}

/**
 * @param interval
 * @param interval_unit min or hr (hour?)
 * @returns
 */
export function timeLimitAsSeconds(interval, intervalUnit) {
  if (intervalUnit === "min") {
    return interval * 60;
  }

  return interval * 60 * 60;
}

export function divide(a: number, b: number) {
  if (a === 0 || b === 0) return 0;

  return a / b;
}

export function parsePhoneNumber(phoneNumber?: string | null) {
  try {
    const allowedCountries = ["US", "CA", "VI", "PR", "MX"];

    const parsedNumbers = allowedCountries.map((country: string) => {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(
        _.toString(phoneNumber),
        country
      );
      const isValid = phoneUtil.isValidNumberForRegion(parsedNumber, country);

      return {
        country,
        parsedNumber,
        isValid,
      };
    });

    const parsedNumberData =
      parsedNumbers.filter((d) => d.isValid)[0] ?? parsedNumbers[0];
    const parsedNumber = parsedNumberData.parsedNumber;
    const isValid = parsedNumberData.isValid;

    return {
      isValid,
      dialingCode: isValid ? "+1" : null,
      nationalNumber: isValid
        ? _.toString(parsedNumber.getNationalNumber())
        : null,
      e164: isValid
        ? _.toString(phoneUtil.format(parsedNumber, FORMAT.E164))
        : null,
    };
  } catch (e: unknown) {
    const isMaybeLibPhoneNumberError = _.toString(e?.stack).includes("phone");
    const isInvalidPhoneNumberError = _.toString(e?.message).includes(
      "The string supplied did not seem to be a phone number"
    );

    if (isMaybeLibPhoneNumberError || isInvalidPhoneNumberError) {
      if (!isInvalidPhoneNumberError) {
        console.warn(e);
      }
    } else {
      throw e;
    }

    return {
      isValid: false,
      dialingCode: null,
      nationalNumber: null,
      e164: null,
    };
  }
}

export function backoffRetry(
  failureAttempt: number,
  maxDelay: number = 30 * 1000
) {
  return Math.min(
    failureAttempt > 1 ? 2 ** failureAttempt * 1000 : 1000,
    maxDelay
  );
}

export function maybeRetryMutation<TError = unknown>(
  failureCount: number,
  error: TError,
  maxFailures: number = 3
) {
  return failureCount < maxFailures && isRetriableError(error);
}

export function isRetriableError<TError = unknown>(error: TError) {
  const isLaravel500Error =
    error.response?.errors?.[0]?.extensions?.internal?.response?.status === 500;
  const isMaybeOther500Error =
    (error.status ?? error.response?.status ?? -1) >= 500;

  return isLaravel500Error || isMaybeOther500Error;
}

/**
 * @param interval
 * @param interval_unit min or hr (hour?)
 * @returns
 */
export function intervalAsSeconds(interval: number, interval_unit: string) {
  if (interval_unit === "min") {
    return interval * 60;
  }

  return interval * 60 * 60;
}

// TODO Finish isValidInterval
export function isValidInterval(interval: number, intervalUnit: string, time: number | null | undefined) {
  if (time === 0 || time === null || time === undefined) return false;

  const intervalSeconds = intervalAsSeconds(interval, intervalUnit);

  return Math.floor(time / intervalSeconds) === time / intervalSeconds;
}

/**
 * Evaluate card account info (number, expiry, cvv, etc) to determine if it's valid and what type it is, etc
 *
 * Sources:
 * - https://pocketsense.com/issuer-credit-card-number-12967.html
 * - https://smallbusiness.chron.com/identify-credit-card-account-number-61050.html
 *
 * @param cardNumber
 * @returns
 */
export function evaluateCardAccount(args: {
  cardNumber?: string | number | null | undefined;
  expiryMonth?: string | null;
  expiryYear?: string | null;
  cvv?: string | null;
  postalCode?: string | null;
}) {
  const { cardNumber, expiryMonth, expiryYear, cvv, postalCode } = args;

  //
  // Card Number
  //

  let isCardNumberValid: boolean = false;
  let isExpiryMonthValid: boolean = false;
  let isExpiryYearValid: boolean = false;
  let isCvvValid: boolean = false;
  let isPostalCodeValid: boolean = false;
  let issuerIdentifer: string = "";
  let cardHolderSpecificNumbers: string = "";
  let checkDigit: string = "";
  let cardIssuers: {
    id: string;
    name: string;
  }[] = [];
  let cardIndustries: {
    id: string;
    name: string;
  }[] = [];

  if (cardNumber) {
    const asString = _.toString(cardNumber);
    const asNumber = _.toNumber(cardNumber);

    const minDigits = 13;
    const maxDigits = 19;

    isCardNumberValid =
      asString === _.toString(asNumber) &&
      asString.length >= minDigits &&
      asString.length <= maxDigits;

    // Issuer Identifer - Financial Institution (digits 1-6, but only need the first 2)
    issuerIdentifer = asString.length >= 1 ? asString.slice(0, 6) : "";
    // Cardholder-Specific Numbers - Digits 7-18 (6-11 digits long)
    cardHolderSpecificNumbers = asString.length > 6 ? asString.slice(6) : "";
    // Check Digit - This number validates the card using a mathematical algorithm known as
    //  the Luhn algorithm. While this check digit doesn't guarantee protection against fraud,
    //  it is designed to help prevent it.
    checkDigit = isCardNumberValid ? asString.slice(-1) : "";

    const issuers = {
      MASTERCARD: { id: "MASTERCARD", name: "MasterCard" },
      VISA: { id: "VISA", name: "Visa" },
      DISCOVER: { id: "DISCOVER", name: "Discover" },
      AMERICAN_EXPRESS: { id: "AMERICAN_EXPRESS", name: "American Express" },
      MAESTRO: { id: "MAESTRO", name: "Maestro" },
      DINERS_CLUB: { id: "DINERS_CLUB", name: "Diner's Club" },
      CARTE_BLANCHE: { id: "CARTE_BLANCHE", name: "Carte Blanche" },
    };

    const industries = {
      AIRLINE: { id: "AIRLINE", name: "Airline" },
      ENTERTAINMENT: { id: "ENTERTAINMENT", name: "Entertainment" },
      TRAVEL: { id: "TRAVEL", name: "Travel" },
      BANKING: { id: "BANKING", name: "Banking" },
      FINANCIAL: { id: "FINANCIAL", name: "Financial" },
      MERCHANDISING: { id: "MERCHANDISING", name: "Merchandising" },
      GAS: { id: "GAS", name: "Gas" },
      HEALTHCARE: { id: "HEALTHCARE", name: "Healthcare" },
      COMMUNICATIONS: { id: "COMMUNICATIONS", name: "Communications" },
      NATIONAL_IDENTIFIER: { id: "NATIONAL_IDENTIFIER", name: "National" },
    };

    // Major Industry Identifiers (first digit)
    const miis = {
      // The number zero has been set aside for future industries that might enter the market
      "0": () => ({ issuers: [], industries: [] }),
      "1": () => ({ issuers: [], industries: [industries.AIRLINE] }),
      "2": () => ({
        issuers: [issuers.MASTERCARD],
        industries: [industries.AIRLINE],
      }),
      "3": () => {
        const secondDigit = asString.length >= 2 ? asString.slice(1, 2) : "";
        // const isAmex = ["4", "7"].includes(secondDigit);
        const isDinersOrCarteBlanche = ["0", "6", "8"].includes(secondDigit);
        let cardIssuers = [issuers.AMERICAN_EXPRESS];
        if (isDinersOrCarteBlanche)
          cardIssuers = [issuers.DINERS_CLUB, issuers.CARTE_BLANCHE];

        return {
          issuers: cardIssuers,
          industries: [
            industries.ENTERTAINMENT,
            industries.TRAVEL,
            industries.BANKING,
          ],
        };
      },
      "4": () => ({
        issuers: [issuers.VISA],
        industries: [industries.BANKING, industries.FINANCIAL],
      }),
      // Can also be Maestro, but too lazy to figure it out
      "5": () => ({
        issuers: [issuers.MASTERCARD],
        industries: [industries.BANKING, industries.FINANCIAL],
      }),
      // Can also be Maestro, but too lazy to figure it out
      "6": () => ({
        issuers: [issuers.DISCOVER],
        industries: [industries.BANKING, industries.MERCHANDISING],
      }),
      "7": () => ({ issuers: [], industries: [industries.GAS] }),
      "8": () => ({
        issuers: [],
        industries: [industries.HEALTHCARE, industries.COMMUNICATIONS],
      }),
      "9": () => ({
        issuers: [],
        industries: [industries.NATIONAL_IDENTIFIER],
      }),
    };

    cardIssuers = miis[issuerIdentifer.slice(0, 1)]
      ? miis[issuerIdentifer.slice(0, 1)]().issuers
      : [];

    cardIndustries = miis[issuerIdentifer.slice(0, 1)]
      ? miis[issuerIdentifer.slice(0, 1)]().industries
      : [];
  }

  //
  // Expiry
  //

  const currentYear = _.toNumber(moment.utc().format("YYYY"));

  if (expiryYear) {
    isExpiryYearValid = _.isString(expiryYear)
      ? new RegExp("^\\d\\d$").test(expiryYear) || new RegExp("^\\d\\d\\d\\d$").test(expiryYear)
      : false;
    isExpiryYearValid =
      isExpiryYearValid &&
      _.toNumber(`${_.toString(expiryYear).length === 2 ? `20`:``}${_.toString(expiryYear)}`) >= currentYear;
  }

  if (expiryMonth) {
    const currentMonth = _.toNumber(moment.utc().format("MM"));
    isExpiryMonthValid = _.isString(expiryMonth)
      ? new RegExp("^\\d\\d$").test(expiryMonth)
      : false;
    if (
      _.toNumber(`${_.toString(expiryYear).length === 2 ? `20`:``}${_.toString(expiryYear)}`) === currentYear &&
      _.toNumber(expiryMonth) < currentMonth
    ) {
      isExpiryMonthValid = false;
    }
  }

  //
  // CVV
  //

  if (cvv) {
    isCvvValid = _.isString(cvv) ? new RegExp("^[0-9]{3,4}$").test(cvv) : false;
  }

  //
  // Postal Code (only the first 4-5 digits, not the +4)
  //

  // TODO Maybe loosen this up for non-US/CA postal codes
  if (postalCode) {
    isPostalCodeValid = _.isString(postalCode)
      ? new RegExp("^[0-9]{4,5}$").test(postalCode)
      : false;
  }

  return {
    issuerIdentifer,
    cardHolderSpecificNumbers,
    checkDigit,

    isCardNumberValid,
    isExpiryMonthValid,
    isExpiryYearValid,
    isCvvValid,
    isPostalCodeValid,
    isValid:
      isCardNumberValid &&
      isExpiryMonthValid &&
      isExpiryYearValid &&
      isCvvValid &&
      isPostalCodeValid,
    issuers: cardIssuers,
    industries: cardIndustries,
  };
}

/**
 * NOTE: MXM uses the following parameters when determining cards in test environment: https://mxmerchant.com/mx6/documentation
 */
export function mxmCardTypeToIssuer(
  cardType: string
): { id: string; name: string } | null {
  const typeMap = {
    MasterCard: { id: "MASTERCARD", name: "MasterCard" },
    Visa: { id: "VISA", name: "Visa" },
    Discover: { id: "DISCOVER", name: "Discover" },
    "American Express": { id: "AMERICAN_EXPRESS", name: "American Express" },
  };

  return typeMap[cardType] ?? null;
}

export function paymentMethodIsExpired(
  expiryMonth: string,
  expiryYear: string
) {
  if (expiryMonth.length === 0 || expiryYear.length === 0) {
    return false;
  }

  const fullExpiryYear =
    expiryYear.length === 2
      ? `${moment.utc().format("YYYY").slice(0, 2)}${expiryYear}`
      : expiryYear;

  return moment
    .utc({ month: _.toNumber(expiryMonth), year: _.toNumber(fullExpiryYear) })
    .endOf("month")
    .isSameOrBefore(moment.utc());
}

export function formatMoney(
  cents: number | null | undefined,
  currencyPrefix: boolean = true
) {
  if (!_.isNumber(cents)) return "";

  let formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cents / 100);

  if (!currencyPrefix) {
    formatted = formatted.slice(1);
  }

  return formatted;
}

export function strToMoneyInt(moneyStr: string): number {
  return _.toNumber(moneyStr) * 100;
}

export function moneyIntToStr(moneyInt: number): string {
  return formatMoney(moneyInt, false);
}

export function parkablePriceInfo(
  parkable: {
    price: number;
    price_interval: number;
    price_interval_unit: string;
    [key: string]: any;
  },
  time: number,
  cardFee: number,
  walletFee: number,
  applyFee: boolean,
) {
  let price = null;
  if (parkable && time) {
    const rateIntervalSeconds = intervalAsSeconds(
      parkable.price_interval,
      parkable.price_interval_unit
    );
    price = (time / rateIntervalSeconds) * parkable.price;
  }

  let fee = null;
  if (!applyFee) {
    fee = 0;
  } else {
    fee = cardFee;
  }

  let total = null;
  if (price !== null && fee !== null) {
    total = price + fee;
  }

  let walletSavings = 0;
  if (_.isNumber(cardFee) && _.isNumber(walletFee) && cardFee > walletFee) {
    walletSavings = cardFee - walletFee;
  }

  return {
    price,
    fee,
    total,
    walletSavings,
  };
}

/**
 * Returns true if the error is a Hasura JWT Expired error
 * 
 * @param error
 * @param graphQlErrorResponse
 * @returns
 */
export function isJwtExpiredError(
  error: unknown,
  graphQlErrorResponse?: GraphQLAPIErrorResponse | GraphQLErrorResponse | null
) {
  const _graphQlErrorResponse =
    graphQlErrorResponse !== undefined
      ? graphQlErrorResponse
      : parseReactQueryError(error, false);

  return (
    // Likely a Hasura Error
    _graphQlErrorResponse?.extensions?.isApplicationGraphQlError === false &&
    // JWT Expired (shouldn't happen with our token refresh interval, but handle it just in case)
    _graphQlErrorResponse.extensions?.code === "invalid-jwt" &&
    // Has the correct error text (this is how we differentiate from the jwt missing error)
    (error?.message ?? "").includes("Expired")
  );
}

/**
 * Returns true if the error is a Hasura JWT Missing error
 * 
 * @param error
 * @param graphQlErrorResponse
 * @returns
 */
export function isJwtMissingError(
  error: unknown,
  graphQlErrorResponse?: GraphQLAPIErrorResponse | GraphQLErrorResponse | null
) {
  const _graphQlErrorResponse =
    graphQlErrorResponse !== undefined
      ? graphQlErrorResponse
      : parseReactQueryError(error, false);

  return (
    // Likely a Hasura Error
    _graphQlErrorResponse?.extensions?.isApplicationGraphQlError === false &&
    // JWT Expired (shouldn't happen with our token refresh interval, but handle it just in case)
    _graphQlErrorResponse.extensions?.code === "invalid-jwt" &&
    // Has the correct error text (this is how we differentiate from the jwt expired error)
    (error?.message ?? "").includes("Could not verify JWT")
  );
}

/**
 * Returns true if the error is a Hasura API Requests per Minute error
 *
 * Should only happen in Free Cloud Tier for dev envs
 *
 * Example error: http://zsl.io/BUIqiB
 *
 * @param error
 * @param graphQlErrorResponse
 * @returns
 */
export function isHasuraRpmError(
  error: unknown,
  graphQlErrorResponse?: GraphQLAPIErrorResponse | GraphQLErrorResponse | null
) {
  const _graphQlErrorResponse =
    graphQlErrorResponse !== undefined
      ? graphQlErrorResponse
      : parseReactQueryError(error, false);

  return (
    // Likely a Hasura Error
    _graphQlErrorResponse?.extensions?.isApplicationGraphQlError === false &&
    _graphQlErrorResponse.extensions?.code === "tenant-limit-exceeded"
  );
}

/**
 * Throw this inside of a react-query fetch function and it should trigger an error modal and sign you out
 */
export function simulateJwtExpiredError() {
  const error = new Error();
  error.request = {};
  error.response = {
    errors: [
      {
        extensions: {
          path: "$",
          code: "invalid-jwt",
        },
        message: "Could not verify JWT: JWT Expired",
      },
    ],
  };
  throw error;
}

/**
 * Throw this inside of a react-query fetch function and it should trigger an error modal and sign you out
 */
export function simulateHasuraRpmLimitError() {
  const error = new Error();
  error.request = {};
  error.response = {
    errors: [
      {
        extensions: {
          path: "$",
          code: "tenant-limit-exceeded",
        },
        message: "hasura cloud limit of 60 requests/minute exceeded",
      },
    ],
  };
  throw error;
}

export function isNetworkError(error: unknown) {
  const errorMessage = _.isString(error) ? error : _.toString(error?.message);

  const isNetworkError =
    errorMessage.toLowerCase().includes("network request failed") ||
    errorMessage.toLowerCase().includes("network error") ||
    error?.response?.status === 502 || // Bad Gateway
    error?.response?.status === 503 || // Service Unavailable
    error?.response?.status === 504 || // Gateway Timeout
    (error?.request && !error?.response); // Incomplete request, likely network failure

  return isNetworkError;
}

export function simulateNetworkError() {
  if (environment.isProduction) return;
  
  throw new Error("network error");
}

export function isPromise(obj: unknown | Promise<any>) {
  // @ts-ignore
  return typeof obj === "object" && _.isFunction(obj?.then);
}

export async function callWithRetry<TRetVal extends any>(
  fn: (args: {
    lastError: unknown | undefined;
    abortRetries: () => void;
  }) => Promise<TRetVal>,
  tries = 3,
  delayMs = 1000,
  reportErrors = true,
  depth = 0,
  lastError: unknown | undefined = undefined
): Promise<TRetVal> {
  try {
    const abortRetries = () => {
      const abortError = new Error();
      abortError.abortRetries = true;
      throw abortError;
    };

    return await fn({ lastError, abortRetries });
  } catch (e: unknown) {
    if (e?.abortRetries === true) {
      throw lastError;
    }

    if (reportErrors) {
      reportError(e);
    }

    if (depth >= tries) {
      throw e;
    }

    await sleep(2 ** depth * delayMs);

    return callWithRetry(fn, tries, delayMs, reportErrors, depth + 1, e);
  }
}

export async function reportError(
  error: unknown,
  context?: Record<string | number, any>
) {
  try {
    //
    // Full Context Object
    //
    const globalFinalContext = {};

    let eventFinalContext = {
      // @ts-ignore
      ...error?.context,
      ...context,
    };

    //
    // Tags
    //

    const globalFinalTags = {};

    let eventFinalTags = {};

    //
    // Normalize to catch edge cases where things that are not errors are thrown
    //
    let errorObj = error;
    if (_.isString(error)) {
      errorObj = new Error(error);
    } else if (_.isPlainObject(error)) {
      errorObj = new Error("Unknown Plain Object Error");
      errorObj = _.assign(errorObj, error);

      eventFinalContext = {
        ...eventFinalContext,
        unknown_error_obj_props: {
          // @ts-ignore
          ...error,
        },
      };
    }

    const appError = AppError.toAppError(errorObj);

    if (appError) {
      eventFinalContext = {
        ...eventFinalContext,
        ...appError.context,
      };

      eventFinalTags = {
        ...eventFinalTags,
        ...appError.tags,
      };
    }

    //
    // GraphQL Errors
    //
    if (GraphQlError.isGraphQlError(errorObj)) {
      const graphQlError = new GraphQlError(error);

      eventFinalContext = {
        ...eventFinalContext,
        ...graphQlError.context,
      };

      eventFinalTags = {
        ...eventFinalTags,
        ...graphQlError.tags,
      };

      errorObj = graphQlError;
    } 
    //
    // Generic HTTP Request Errors
    //
    else if (
      // @ts-ignore
      !eventFinalContext.request && 
      !eventFinalContext.response && 
      // @ts-ignore
      (errorObj?.request || errorObj?.response)
    ) {
      // @ts-ignore
      eventFinalContext.request = errorObj?.request;
      // @ts-ignore
      eventFinalContext.response = errorObj?.response;
    }

    //
    // Unauthenticated Credentials
    //

    let unauthenticatedCredentials;
    try {
      unauthenticatedCredentials = JSON.parse(
        (await localStorage.getItem(
          `${auth.unauthenticatedCredentials.storageKeyNamespace}.${auth.unauthenticatedCredentials.storageKey}`
        )) ?? ``
      );

      if (
        unauthenticatedCredentials?.value?.unauthenticatedData?.knownProviders
      ) {
        // TODO [H] Make sure we keep this type in sync with the AuthProvider type in the auth lib
        const knownProviders = unauthenticatedCredentials?.value
          ?.unauthenticatedData?.knownProviders as {
          provider: `apple` | `google` | `email` | `phone_number`;
          credential: string;
          identityId?: number;
          userId?: number;
          authenticated: boolean;
          lastUsed: number;
        }[];
        const identityIds = _.chain(knownProviders)
          .sortBy(`lastUsed`)
          .reverse()
          .map((knownProvider) => knownProvider.identityId)
          .value();
        const userIds = _.chain(knownProviders)
          .sortBy(`lastUsed`)
          .reverse()
          .map((knownProvider) => knownProvider.userId)
          .value();
        const phoneNumbers = _.chain(knownProviders)
          .filter((knownProvider) => knownProvider.provider === `phone_number`)
          .sortBy(`lastUsed`)
          .reverse()
          .map((knownProvider) => knownProvider.credential)
          .value();
        const emails = _.chain(knownProviders)
          .filter((knownProvider) => knownProvider.provider !== `phone_number`)
          .sortBy(`lastUsed`)
          .reverse()
          .map((knownProvider) => knownProvider.credential)
          .value();
        const sentryUser = Sentry.getCurrentHub().getScope().getUser();
        const newSentryUser = {
          ...sentryUser,
          id: toValidInteger(sentryUser?.id)
            ? sentryUser?.id
            : userIds.length > 0
            ? _.toString(userIds[0])
            : ``,
          identity_id: sentryUser?.identity_id
            ? sentryUser?.identity_id
            : identityIds[0] ?? ``,
          phone_number:
            parsePhoneNumber(
              sentryUser?.phone_number
                ? sentryUser?.phone_number
                : phoneNumbers[0] ?? ``
            ).e164 ?? ``,
          email: sentryUser?.email ? sentryUser?.email : emails[0] ?? ``,
          known_providers: knownProviders,
        };

        //
        // Update Sentry User Context
        //

        Sentry.setUser(newSentryUser);
      }
    } catch (e: unknown) {
      // @ts-ignore
      if (!String(e?.message).includes(`JSON Parse error`)) {
        console.error(e);
      }
    }

    let graphQlErrorResponse;
    try {
      graphQlErrorResponse = parseReactQueryError(error, false);
    } catch (error2: unknown) {
      console.error(
        // @ts-ignore
        `Failure to parseReactQueryError() - ${error2?.message ?? ``}`
      );
    }

    // TODO Turn this into a proper ignore list
    let ignoreError = false;
    if (
      error === "No current user" ||
      (errorReporting.reportJwtExpiredErrors && isJwtExpiredError(error, graphQlErrorResponse)) ||
      (errorReporting.reportJwtMissingErrors && isJwtMissingError(error))
      ) {
      ignoreError = true;
    }

    if (ignoreError) return null;

    //
    // Merge Final Context and Tags
    //

    const finalContext = { ...globalFinalContext, ...eventFinalContext };
    const finalTags = { ...globalFinalTags, ...eventFinalTags };

    //
    // Log During Development
    //

    if (!environment.isProduction) {
      // @ts-ignore
      console.error(`reportError - ${errorObj?.message}`, {
        originalError: errorObj,
        finalContext,
      });
    }

    //
    // Report to Sentry
    //

    let sentryEventId: string | null = null;

    Sentry.getCurrentHub().withScope((scope) => {
      scope.setExtras(finalContext);
      scope.setTags(finalTags);

      sentryEventId = Sentry.captureException(errorObj);
    });

    return sentryEventId;
  } catch (error3: unknown) {
    let errorObj = error;
    if (_.isString(error)) {
      errorObj = new Error(error);
    }

    return Sentry.captureException(errorObj, {
      // @ts-ignore
      extra: { reportErrorFailure: error3?.message ?? null },
    });
  }
}

export function getCountries(
  isoCodes: string[] = ["US", "CA", "VI", "PR", "MX"]
) {
  return _.chain(Country.getAllCountries())
    .filter((c) => (isoCodes ? isoCodes.includes(c.isoCode) : true))
    .value();
}

export function getCountry(isoCode: string) {
  return Country.getCountryByCode(isoCode);
}

export function getStates(countryIsoCode: string) {
  return State.getStatesOfCountry(countryIsoCode);
}

export function getState(
  countryIsoCode: string | null | undefined,
  stateIsoCode: string | null | undefined
) {
  if (!countryIsoCode || !stateIsoCode) return null;

  return State.getStateByCodeAndCountry(stateIsoCode, countryIsoCode);
}

export function isActive(parkableTransaction: Record<string, any>, now?: Moment) {
  return !parkableTransaction.cancelled_at &&
    serverDatetimeToMoment(parkableTransaction.expires_at)
      .clone()
      .isAfter(now ?? moment.utc());
}

export function scrollToTop() {
  // window.scrollTo({ top: 0, left: 0});
  window.scroll({ top: 0, left: 0, behavior: `instant` });
}