import { ReactNode, useEffect, useRef } from "react";

import { event } from "../core/helpers";

import { useLocation } from 'react-router-dom';
import { ScreenFocused } from "../event-types/common/ScreenFocused";

export type Props = {
  children?: ReactNode;
};

function DispatchScreenFocused(props: Props) {
  const { children = null } = props;

  const location = useLocation()

  const lastPathnameRef = useRef<string | undefined>();

  useEffect(() => {
    if (location?.pathname && location?.pathname !== lastPathnameRef.current) {
      lastPathnameRef.current = location.pathname;

      event(new ScreenFocused({
        prevRouteName: lastPathnameRef.current,
        routeName: location?.pathname,
      }));
    }
  }, [location]);

  return children;
}

export { DispatchScreenFocused };
