import useParkable from "../../../../queries/parkables/useParkable";

export type Props = {
  parkable?: (Pick<NonNullable<ReturnType<typeof useParkable>["parkable"]>, 'price' | 'parking_authority_id'> & { [key: string]: any }) | null | undefined;
};

function ErieEclipseRateExplainer(props: Props) {
  const { parkable } = props;

  const isEnabled = parkable?.data?.erie_eclipse_rate_enabled === true;

  if (!isEnabled) return null;

  return (
    <div
      style={{
        textAlign: "right",
        marginTop: '3px',
        fontFamily: "Roboto, sans-serif",
        color: "#0d2a48",
        fontWeight: "bold",
        fontSize: '12px',
        lineHeight: '18px',
        textTransform: "uppercase",
      }}
    >
      * Erie Eclipse Event Rate
    </div>
  );
}

export { ErieEclipseRateExplainer };
