/**
 * Auth error base class
 */
abstract class AuthError extends Error {
  public humanMessage: string;

  constructor(message: string) {
    super(message);
    this.name = "AuthError";
    this.humanMessage = "Unknown Error";
  }
}

export { AuthError };
