import { CreateMxmCustomerMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  mxmCustomerId?: number;
};

export type ConstructorArgs = {
  mutationResponse: CreateMxmCustomerMutation["createMxmCustomer"];
} & Partial<EventConstructorArgs>;

class CreateMxmCustomerResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `CREATE_MXM_CUSTOMER.RESPONSE`,
      data: {
        mxmCustomerId: mutationResponse?.user?.mxm_customer_id,
      },
    });
  }
}

export { CreateMxmCustomerResponse };
