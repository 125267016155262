import { SummaryCard } from "./SummaryCard";
import { ReactComponent as IconMeter } from "../../../src/assets/icons/icon-meter.svg";
import useParkable from "../../queries/parkables/useParkable";
import { TextSkeleton } from "../skeletons/TextSkeleton";
import { formatMoney } from "../../lib/utils";
import { ParkableTransaction } from "../../queries/parkable-transactions/useRecentParkableTransactions";

export type Props = {
  skeletonMode?: `PROMPT` | `EMPTY`;
  parkableId: number | null | undefined;
  session?: ParkableTransaction | null | undefined;
  showEditIcon?: boolean;
  onClick?: () => any;
  disabled?: boolean;
};

function ParkableCard(props: Props) {
  const { skeletonMode, parkableId, session, showEditIcon = false, onClick, disabled } = props;

  const { parkable } = useParkable({ parkableId, poll: true });

  const price = session?.snapshot_price ?? parkable?.price;
  const priceInterval = session?.snapshot_price_interval ?? parkable?.price_interval;
  const priceIntervalUnit = session?.snapshot_price_interval_unit ?? parkable?.price_interval_unit;

  const areaName = parkable?.parkable_parkable_groups[0]?.parkable_group.name;

  let rightTopContent = null;
  let rightBottomContent = null;
  
  if (skeletonMode === `PROMPT`) {
    rightTopContent = (
      <div>
        <span
          className={`uppercase`}
          style={{
            color: `#0A84FF`,
          }}
        >
          Next:
        </span>
        {` `}Choose Meter / Zone...
      </div>
    );
  } else if (skeletonMode === `EMPTY` || (!!parkableId && !parkable)) {
    rightTopContent = (
      <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
    );
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (parkable) {
    rightTopContent = parkable.identifier;
  }

  if (skeletonMode === `PROMPT` || skeletonMode === `EMPTY`) {
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (parkable) {
    rightBottomContent = (
      <div
        className={`w-full flex flex-row`}
      >
        <div>
          {`${areaName ? `${areaName} - `:``}${parkable.parking_authority.city}, ${parkable.parking_authority.state}`}
        </div>
        <div
          className={`flex-grow text-right`}
          style={{

          }}
        >
          Rate: {formatMoney(price)} / {priceInterval} {priceIntervalUnit}
        </div>
      </div>
    )
  }

  return (
    <SummaryCard
      icon={
        <IconMeter
          className={`relative`}
          style={{
            top: `-1px`,
            width: `14px`,
            // height: `11px`,
          }}
        />
      }
      topContent={`Meter / Zone #`}
      rightTopContent={rightTopContent}
      rightBottomContent={rightBottomContent}
      showEditIcon={showEditIcon}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export { ParkableCard };