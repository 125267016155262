import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import logoUrl from "../../../../../src/assets/logo-meterez.png";
import appIconUrl from "../../../../../src/assets/android-chrome-192x192.png";
import robotoBold from "../../../../../src/assets/fonts/Roboto/Roboto-Bold.ttf";
// import useParkableTransaction from "../../../../queries/parkable-transactions/useParkableTransaction";
import { datetimeToLocal, formatMoney, makeDuration, parkablePriceInfo, serverDatetimeToMoment } from "../../../../lib/utils";
import { ParkableTransaction } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";

Font.register({ family: 'Roboto', fonts: [
  { src: robotoBold },
]});

export type Props = {
  // parkableTransactionId?: number;
  parkableTransaction?: ParkableTransaction;
};

function ReceiptDocument(props: Props) {
  const { /*parkableTransactionId,*/ parkableTransaction: pt } = props;
  // const { parkableTransaction: pt } = useParkableTransaction({ id: parkableTransactionId });

  if (!pt) return null;

  const duration = makeDuration(pt.time, 1);
  const cardType = pt.payment_method?.mx_merchant_payment_method_metas?.[0].meta_card_account_card_type;
  const cardLast4 = pt.payment_method?.mx_merchant_payment_method_metas?.[0].meta_card_account_last_4;
  const cardExpiryMonth = pt.payment_method?.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_month;
  const cardExpiryYear = pt.payment_method?.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_year;  
  const cardInfo = (!!cardType && !!cardLast4 && !!cardExpiryMonth && !!cardExpiryYear) ?
    `${cardType} ending in ${cardLast4} (Exp: ${cardExpiryMonth}/${cardExpiryYear})` : null;

  const { price, fee, total } = parkablePriceInfo(
    {
      price: pt.snapshot_price,
      price_interval: pt.snapshot_price_interval,
      price_interval_unit: pt.snapshot_price_interval_unit,
    },
    pt.time,
    pt.snapshot_fee,
    pt.snapshot_fee,
    true
  );

  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4" style={styles.page}>
        <View style={styles.logoSection}>
          <Image 
            src={logoUrl}
            style={styles.logoImage}
          />
        </View>
        <View style={styles.divider} />
        
        <View style={[styles.subHeading]}>
          <Text
            style={styles.text}
          >
            Your meterEZ Parking Receipt
          </Text>
        </View>

        <View style={[styles.body]}>
          
          <DetailRow heading={`Session ID`} value={pt.id} highlight />
          
          <DetailRow heading={`Date / Time`} value={`${datetimeToLocal(serverDatetimeToMoment(pt.created_at)).format("MMM D @ h:mm a")}`} />

          <DetailRow heading={`Duration`} value={duration.human} highlight />

          <DetailRow heading={`Meter / Zone`} value={`${pt.parkable.identifier} - ${pt.parkable.parking_authority.city}, ${pt.parkable.parking_authority.state} (${formatMoney(pt.snapshot_price)} / ${pt.snapshot_price_interval} ${pt.snapshot_price_interval_unit})`} />

          <DetailRow heading={`License Plate`} value={pt.snapshot_license_plate} highlight />

          <DetailRow heading={`Payment Type`} value={pt.payment_method_id ? cardInfo : `EZwallet`} />

          <DetailRow heading={`Parking Price`} value={formatMoney(price)} highlight />

          <DetailRow heading={`Processing Fee`} value={formatMoney(fee)} />

          <DetailRow heading={`Total`} value={formatMoney(total)} highlight />

        </View>

        <View style={styles.footer}>
          <View style={styles.footerCol}>
            <Text
              style={styles.text}
            >
              meterEZ LLC
            </Text>
          </View>
          
          <View style={styles.footerCol}>
            <Text
              style={styles.text}
            >
              1001 State St. Suite 103B
            </Text>
          </View>

          <View style={styles.footerCol}>
            <Image 
              src={appIconUrl}
              style={styles.iconImage}
            />
            <Text
              style={styles.text}
            >
              https://get.meterez.com
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export type DetailRowProps = {
  heading: string;
  value: string | number;
  highlight?: boolean;
};

function DetailRow(props: DetailRowProps) {
  const { heading, value, highlight } = props;

  return (
    <View style={[styles.detailRow, highlight ? styles.detailRowHighlight : null]}>
      <View style={styles.detailHeading}>
        <Text
          style={styles.text}
        >
          {heading}:
        </Text>
      </View>
      <View style={styles.detailValue}>
        <Text
          style={styles.text}
        >
          {value}
        </Text>
      </View>
    </View>
  );
}

export { ReceiptDocument };

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    backgroundColor: "#fff",
    color: "#0f2a48",
  },
  logoSection: {
    flexShrink: 1,
    display: `flex`,
    justifyContent: `center`,
    alignItems: "center",
    padding: 50,
  },
  subHeading: {
    textTransform: "uppercase",
    flexShrink: 1,
    margin: 10,
    padding: 10,
    textAlign: "center",
  },
  body: {
    marginHorizontal: 40,
    display: `flex`,
    flexGrow: 1,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  detailRow: {
    paddingHorizontal: 6,
    paddingTop: 3,
    width: '100%',
    display: 'flex',
    flexDirection: "row",
    marginBottom: 5,
  },
  detailRowHighlight: {
    backgroundColor: '#E7EAF7',
  },
  detailHeading: {
    
  },
  detailValue: {
    display: "flex",
    flexGrow: 1,
    alignItems: "flex-end"
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.5,
  },
  divider: {
    marginLeft: `auto`,
    marginRight: `auto`,
    width: `90%`,
    borderBottomWidth: 2,
    borderColor: `#E7EAF7`,
  },
  logoImage: {
    width: 150,
  },
  iconImage: {
    width: 20,
    height: 20,
    marginRight: 5,
    position: "relative",
    top: -3
  },
  footer: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    display: "flex",
    flexDirection: "row",
  },
  footerCol: {
    display: `flex`,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    marginHorizontal: 10,
  },
});