import { GeneralUserMessage } from "../../components/GeneralUserMessage";
import { Layout } from "../../components/Layout";

function LegacyMigration() {
  return (
    <Layout
      content={
        <GeneralUserMessage
          message={`Please download and open the meterEZ app from the app store to complete your migration from the old meterEZ system before using the web app.`}
        />
      }
    />
  );
}

export { LegacyMigration };