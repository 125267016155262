import { useCallback, useEffect, useMemo } from "react";
import { useRemember } from "./useRemember";
import { useAlertModal } from "../components/modals/alert-modal";

const armKey = "notifications.warning.armed--v4";
const displayedKey = "notifications.warning.displayed--v4";

export type Args = {
  warnOnMount?: boolean;
};

function useNotificationsWarning(args?: Args) {
  const { warnOnMount = false } = args ?? {};

  const alert = useAlertModal();

  const { getValue: getArmed, setValue: setArmed } = useRemember<boolean>({
    key: armKey,
    ttl: 60 * 60 * 24 * 30, // 30 days
  });

  const { getValue: getDisplayed, setValue: setDisplayed } = useRemember<boolean>({
    key: displayedKey,
    ttl: 60 * 60 * 24 * 30, // 30 days
  });

  useEffect(() => {
    if (warnOnMount) {
      setTimeout(() => {
        if (getArmed() && !getDisplayed()) {
          alert.show({
            title: [`Important`, `Warning`],
            message: `The Delivery and Timeliness of Text Message Notifications cannot be guaranteed. Please pay attention to your session expiry.`,
          });
          setDisplayed(true);
        }
      }, 500);
    }
  }, []);

  const armWarning = useCallback(() => {
    if (!getDisplayed()) {
      setArmed(true);
    }
  }, [getDisplayed, setArmed]);

  return useMemo(() => {
    return {
      armWarning,
    };
  }, [armWarning]);
}

export { useNotificationsWarning };