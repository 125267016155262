import { Fragment } from "react";
import { ParkableTransaction } from "../../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { PaymentMethodCard } from "../../../../../components/cards/PaymentMethodCard";
import usePaymentMethods, { PaymentMethod } from "../../../../../queries/payment-methods/usePaymentMethods";
import { CardsListLoadingSkeleton } from "../../../../../components/skeletons/HomeSessionsLoadingSkeleton copy";
import { TextSkeleton } from "../../../../../components/skeletons/TextSkeleton";
import { UiState } from "../../../../../components/skeletons/UiState";

export type Props = {
  paymentMethodsQuery: ReturnType<typeof usePaymentMethods>["query"];
  paymentMethods: PaymentMethod[];
  recentParkableTransactions: ParkableTransaction[];
  onClickPaymentMethod: (paymentMethod: PaymentMethod) => any;
  disabled: boolean;
};

function Choose(props: Props) {
  const {
    paymentMethodsQuery,
    paymentMethods, 
    recentParkableTransactions, 
    onClickPaymentMethod,
    disabled,
  } = props;

  return (
    <div className={`w-full mb-2`}>
      <UiState
        errorText={`Oops, we're having trouble loading your recent cards`}
        tryAgainAction={`RELOAD_PAGE`}
        query={paymentMethodsQuery}
        items={paymentMethods}
        loadingComponent={
          <>
            <div
              className={`text-center mb-2`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `16px`,
                lineHeight: `20px`,
                color: `#0E2A48`,
              }}
            >
              <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
            </div>

            <CardsListLoadingSkeleton />
          </>
        }
        noItemsComponent={null}
      >
        <div
          className={`text-center mb-2`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `16px`,
            lineHeight: `20px`,
            color: `#0E2A48`,
          }}
        >
          Or Choose a Recent Card
        </div>

        <div className={`w-full mb-4`}>
          {paymentMethods.map((paymentMethod, i) => (
            <Fragment key={paymentMethod.id}>
              <PaymentMethodCard
                paymentMethodId={paymentMethod.id}
                showEditIcon={false}
                showDeleteButton
                onClick={() => {
                  onClickPaymentMethod(paymentMethod);
                }}
              />

              {i < (paymentMethods.length - 1) && (
                <div className={`mb-2`}/>
              )}
            </Fragment>
          ))}
        </div>
      </UiState>
    </div>
  );
}

export { Choose };