import _ from "lodash";

import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  token: string | null | undefined;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class VaultCardResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { token, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `VAULT_CARD.RESPONSE`,
      data: {
        token: VaultCardResponse.maskToken(token),
      },
    });
  }

  public static maskToken = (token: Data["token"]) => {
    if (!_.isString(token)) return token;

    return `${token.substring(0, 5)}...${token.substring(
      token.length - 5,
      token.length + 1
    )}`;
  };
}

export { VaultCardResponse };
