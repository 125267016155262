import _ from "lodash";
import { environment } from "../../../config/config";
import useParkingAuthorities from "../../../queries/parking-authorities/useParkingAuthorities";

const erieParkingAuthorityIds = {
  local: 100,
  development: 100,
  demo: 100,
  preview: 2,
  production: 2,
};

const meadvilleParkingAuthorityIds = {
  local: 101,
  development: 101,
  demo: 101,
  preview: 102,
  production: 102,
};

// TODO Move these to server fields
const additionalContactInfo = [
  {
    parkingAuthorityId: erieParkingAuthorityIds[environment.envName], // Erie
    phone: "(814) 456-7588",
    websiteUrl: "https://erieparking.com/",
    hours: "Mon-Th 8am-4:30pm",
    addressLine1: "25 East 10th Street (2nd Floor)",
  },
  {
    parkingAuthorityId:
      meadvilleParkingAuthorityIds[environment.envName], // Meadville
    contactName: "Parking Enforcement",
    phone: "(814) 333-3314",
    websiteUrl: "https://www.cityofmeadville.org/",
    hours: "Mon-Fr 9am-4pm",
    addressLine1: "894 Diamond Park Sq #101, Meadville, PA 16335",
  },
];

export type Props = object;

export default function ParkingAuthorityContactInfo(props: Props) {
  const { query: parkingAuthoritiesQuery, parkingAuthorities } =
    useParkingAuthorities();

  return (
    <div
      className={`w-full`}
    >
      <div
        className={`w-full mb-4`}
      >
        <div
          className={`w-full`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          Please use the contact information below for the city where you
          received the parking ticket:
        </div>
      </div>

      {parkingAuthorities.map((p) => {
        const contactInfo = _.find(additionalContactInfo, {
          parkingAuthorityId: p.id,
        });

        if (!contactInfo) return null;

        const {
          contactName = null,
          phone = null,
          websiteUrl = null,
          hours = null,
          addressLine1 = null,
        } = contactInfo ?? {};

        return (
          <div 
            key={p.id}
            className={`w-full mb-10`}
          >
            <div>
            <div
              className={`w-full mb-2`}
              style={{
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: `18px`,
                lineHeight: `21px`,
                color: "#0E2A48",
              }}
            >
                {p.city}, {p.state}
              </div>
            </div>

            {!!contactName && (
              <div
                className={`flex items-center w-full mb-2`}
              >
                <div
                  className={``}
                  style={{
                    
                  }}
                >
                  <div
                    className={`mr-2`}
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: 700,
                      fontSize: `14px`,
                      lineHeight: `16px`,
                      color: "#0E2A48",
                      minWidth: `80px`,
                    }}
                  >
                    Contact:
                  </div>
                </div>
                <div
                  className={`w-full`}
                >
                  <div
                    className={`w-full`}
                  >
                    <div
                      className={`flex items-center w-full`}
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 700,
                        fontSize: `12px`,
                        lineHeight: `14px`,
                        color: "#0E2A48",
                      }}
                    >
                      {contactName}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`flex w-full mb-2`}
            >
              <div
                className={`mr-2`}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `16px`,
                  color: "#0E2A48",
                  minWidth: `80px`,
                }}
              >
                Phone:
              </div>
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  const phoneCleaned = phone.replace(/[^0-9]/g, "");
                  const phoneFormatted = `tel:+1${phoneCleaned}`;

                  window.open(phoneFormatted);
                }}
              >
                <div
                  className={``}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: `12px`,
                    lineHeight: `14px`,
                    color: "#0E2A48",
                  }}
                >
                  {phone}
                </div>
              </button>
            </div>

            <div
              className={`flex w-full  mb-2`}
            >
              <div
                className={`mr-2`}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `16px`,
                  color: "#0E2A48",
                  minWidth: `80px`,
                }}
              >
                Website:
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.open(websiteUrl, "_blank");
                }}
              >
                <div
                  className={``}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: `12px`,
                    lineHeight: `14px`,
                    color: "#0E2A48",
                  }}
                >
                  {websiteUrl}
                </div>
              </button>
            </div>

            <div
              className={`flex w-full mb-2`}
            >
              <div>
                <div
                  className={`mr-2`}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: `14px`,
                    lineHeight: `16px`,
                    color: "#0E2A48",
                    minWidth: `80px`,
                  }}
                >
                  Address:
                </div>
              </div>
              <div>
                <div>
                  <div
                     className={``}
                     style={{
                       fontFamily: "Roboto",
                       fontWeight: 700,
                       fontSize: `12px`,
                       lineHeight: `14px`,
                       color: "#0E2A48",
                     }}
                  >
                    {addressLine1}
                  </div>
                </div>
                <div>
                  <div
                     className={``}
                     style={{
                       fontFamily: "Roboto",
                       fontWeight: 700,
                       fontSize: `12px`,
                       lineHeight: `14px`,
                       color: "#0E2A48",
                     }}
                  >
                    {p.city}, {p.state} {p.postal_code}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`flex w-full mb-2`}
            >
              <div
                className={`mr-2`}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `16px`,
                  color: "#0E2A48",
                  minWidth: `80px`,
                }}
              >
                Hours:
              </div>
              <div
                className={``}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `14px`,
                  color: "#0E2A48",
                }}
              >
                {hours}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { ParkingAuthorityContactInfo };