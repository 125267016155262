import _ from "lodash";
import { parkablePriceInfo } from "./utils";

export type Args = {
  parkable: {
    price: number;
    price_interval: number;
    price_interval_unit: string;
    [key: string]: any;
  } | null | undefined;
  time: number | null | undefined;
  walletFee: number | null | undefined;
  cardFee: number | null | undefined;
  applyFee: boolean;
};

function useParkableTransactionPriceInfo(args: Args) {
  const { parkable, time, walletFee, cardFee, applyFee } = args;

  if (!parkable || !time || !_.isInteger(walletFee) || !_.isInteger(cardFee)) return {
    price: null,
    fee: null,
    total: null,
    walletSavings: null,
  };

  const { price, fee, total, walletSavings } = parkablePriceInfo(
    parkable,
    time,
    cardFee,
    walletFee,
    applyFee
  );

  return {
    price,
    fee,
    total, 
    walletSavings,
  };
}

export { useParkableTransactionPriceInfo };