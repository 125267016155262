import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { VehicleCard } from "../../../../../components/cards/VehicleCard";
import { Heading1 } from "../../../../../components/headings/Heading1";

export type Props = {
  vehicleId?: number | null | undefined;
  errorMessage?: string;
  onCancel: () => any;
};

function Confirm(props: Props) {
  const { vehicleId, errorMessage, onCancel } = props;

  return (
    <div className={`w-full mb-2`}>
      <Heading1>
        Confirm your License Plate
      </Heading1>

      {!!errorMessage && (
        <div
          className={`w-full uppercase flex flex-col items-center justify-center mt-2 mb-2 text-center`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `12px`,
            lineHeight: `18px`,
            color: `#FF3B30`,
            height: `30px`,
          }}
        >
          {errorMessage}
        </div>
      )}

      <div
        className={`mb-2`}
      >
        <VehicleCard
          vehicleId={vehicleId}
        />
      </div>

      <div
        className={`w-full text-center mb-2`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `12px`,
          lineHeight: `16px`,
          color: `red`,
        }}
      >
        IMPORTANT: Your license plate must be 100% correct to avoid parking tickets
      </div>

      <div className={`w-full mb-2`}>
        <FullWidthButton
          contentTop={`Use a Different Plate`}
          type={`tertiary-transparent`}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
}

export { Confirm };