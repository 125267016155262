import { ReactNode, useEffect } from "react";

import { event } from "../core/helpers";
import { OnlineStateChanged } from "../event-types/common/OnlineStateChanged";
import { useNetwork } from "../../lib/useNetwork";
import usePrevious from "../../lib/usePrevious";

export type Props = {
  children?: ReactNode;
};

function DispatchOnlineState(props: Props) {
  const { children = null } = props;

  const { isOnline: online } = useNetwork();

  const prevOnline = usePrevious(online);
  useEffect(() => {
    if (
      typeof prevOnline === `boolean` &&
      typeof online === `boolean` &&
      online !== prevOnline
    ) {
      event(
        new OnlineStateChanged({
          prevOnline,
          online,
        })
      );
    }
  }, [online]);

  return children;
}

export { DispatchOnlineState };
