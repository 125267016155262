import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { FullWidthButton } from "../../components/buttons/FullWidthButton";
import { ErrorMessageText } from "./error-modal/ErrorModal";
import { reportError } from "../utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../events/event-sub-categories";
import { getRequirements } from "../requirements";

export type Props = {
  identifier?: string;
  resetKeys?: unknown[];
  // onResetKeysChange?: // TODO
  children: JSX.Element | JSX.Element[] | null;
};

export default function ErrorBoundary(props: Props) {
  const { identifier, resetKeys } = props;

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ReactErrorBoundary
          onError={(error: Error, info: { componentStack: string }) => {
            console.error(error, info);
            
            reportError(error, {
              error_boundary_identifier: identifier,
            });
          }}
          onReset={reset}
          resetKeys={resetKeys}
          // onResetKeysChange={() => {}}
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorFallbackUi
              error={error}
              resetErrorBoundary={resetErrorBoundary}
              identifier={identifier}
            />
          )}
        >
          {props.children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

export type ErrorFallbackUiProps = {
  identifier?: string;
  error: any;
  resetErrorBoundary: (...args: unknown[]) => void;
};

export function ErrorFallbackUi(props: ErrorFallbackUiProps) {
  const { identifier, error, resetErrorBoundary } = props;

  const { localStorage: isLocalStorageEnabled, cookies: isCookiesEnabled } = getRequirements();
  
  const isFeaturesDisabled = !isLocalStorageEnabled || !isCookiesEnabled;
  const featureDisabledStrings: string[] = [];

  if (!isLocalStorageEnabled) {
    featureDisabledStrings.push("Local Storage");
  }

  if (!isCookiesEnabled) {
    featureDisabledStrings.push("Cookies");
  }

  const featureDisabledString = featureDisabledStrings.join(' and ');

  const errorTitleParts = [`An`, `Unknown Error`, `Occurred`];

  const errorTitleEls = errorTitleParts.map((part: string | null, i: number) => (
    <span
      style={{
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: `18px`,
        lineHeight: `18px`,
        color: 
          i % 2 === 0 ? "rgba(14, 42, 72, 1)" : "#FF3B30",
        marginRight: 3,
      }}
      key={`${i}${part}`}
    >
      {part}
    </span>
  ));

  return (
    <div
        className={`w-full flex flex-col items-center justify-center px-4`}
        style={{
          // minHeight: `100vh`,
          // minHeight: `-webkit-fill-available`,
          minHeight: `100dvh`,
        }}
      >
        <div
          className={`w-full text-center`}
          style={{
            maxWidth: `350px`,
          }}
        >
          <div
            className={`mb-4`}
            style={{
              
            }}
          >
            {errorTitleEls}
          </div>

          {isFeaturesDisabled && (
            <div
              className={`mb-4`}
              style={{
                
              }}
            >
              You have {featureDisabledString} disabled in your browser. The meterEZ Web App requires {featureDisabledString} in order to function. Please enable {featureDisabledString}, then refresh the page and try again.
            </div>
          )}

          <div
            className={``}
          >
              {/* {_.isString(errorMessage) && (
                <ErrorMessageText errorMessage={errorMessage} />
              )}
              {!_.isString(errorMessage) && errorMessage} */}
            </div>

            <div
              className={``}
              style={
                {
                  
                }
              }
            >
            <div
              className={`mb-4`}
            >
              <FullWidthButton
                type={`primary-red`}
                contentTop={"Try Again"}
                onClick={() => {
                  resetErrorBoundary();
                }}
              />
            </div>
            <div>
              <FullWidthButton
                type={`tertiary-transparent`}
                contentTop={"Sign Out"}
                onClick={async () => {
                  // event(
                  //   new ButtonPress({
                  //     buttonName: `Sign Out`,
                  //     buttonSlug: `ERROR_BOUNDARY.LOG_OUT`,
                  //     subCategory: EventSubCategories.AUTH,
                  //   })
                  // );

                  await Auth.getInstance().invalidateSession();
                  resetErrorBoundary();
                }}
              />
            </div>
          </div>
        </div>
      </div>
  );
}
