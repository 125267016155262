import { useMutation } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { getMxmEndpoint } from "../../config/config";
import { environment } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { event } from "../../events/core/helpers";
import { VaultCardRequest } from "../../events/event-types/payment-methods/VaultCardRequest";
import { VaultCardResponse } from "../../events/event-types/payment-methods/VaultCardResponse";
// import { simulateValidationError } from "../../lib/error-handling/error-handlers/mxm/mxmUtils";
import { CreditCardVaultFailedError } from "../../errors/CreditCardVaultFailedError";

type CardAccount = {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  avsZip: string;
  avsStreet?: string;
  cvv: string;
};

type VaultCardOptions = {
  isProduction: boolean;
  mxmEndpoint: string;
  limitedUseToken: string;
  customerId: number;
  cardAccount: CardAccount;
};

export default function useVaultMxmCard() {
  const mutation = useMutation<
    string,
    unknown,
    {
      limitedUseToken: string;
      customerId: number;
      cardAccount: CardAccount;
    }
  >(
    async (variables) => {
      return await vaultCard({
        isProduction: environment.isProduction,
        mxmEndpoint: getMxmEndpoint(),
        ...variables,
      });
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      // ...options,
    }
  );

  return {
    mutation,
    token: mutation.data,
  };
}

/**
 * NOTE: This MXM endpoint only returns a "token" string, and none of the other card details,
 *       so we'll either need to make a follow-up API call to get all the card info (ideally),
 *       or use our client-side last 4, expiry, card type, etc initially, then do a follow-up
 *       API call async after the fact for certainty.
 */
export async function vaultCard(options: VaultCardOptions): Promise<string> {
  const { limitedUseToken, mxmEndpoint, customerId, cardAccount } = options;

  const url = `${mxmEndpoint}/vault?token=${encodeURIComponent(
    limitedUseToken
  )}&customerid=${encodeURIComponent(customerId)}`;
  const body = {
    ...cardAccount,
  };

  //
  // Testing (I enable this to trigger a 400 error similar to invalid card numbers)
  //
  // if (!environment.isProduction) {
  //   url = `${url} xxxx`;
  // }
  //
  //

  const lastFour = _.toString(
    cardAccount.number.slice(cardAccount.number.length - 4)
  );

  event(
    new VaultCardRequest({
      cardAccount: {
        ...cardAccount,
        cvv: `xxx`,
        number: `xxxxxxxxxxxx${lastFour}`,
      },
    })
  );

  const resp = await axios
    .post<any, AxiosResponse<{ token: string } | string>>(url, {
      ...body,
    })
    .catch((e) => {
      throw new CreditCardVaultFailedError({
        error: e,
        cardAccount,
        includeSensitiveData: !environment.isProduction,
      });
    });

  // Docs says it's data.token, but seems to be data in practice, so we check both
  const token: string | undefined = _.isObject(resp.data)
    ? resp.data.token
    : resp.data;

  event(
    new VaultCardResponse({
      token,
    })
  );

  return token;
}
