import googleIcon from "../../../../../../src/assets/btn_google_light_normal_ios.svg";

export type Props = {
  onPress?: () => any;
};

function GoogleButton(props: Props) {
  const { onPress = () => {} } = props;

  return (
    <button
      className={`flex flex-row justify-start items-center w-full`}
      style={{
        height: `50px`,
        backgroundColor: `#4285f4`,
        padding: 0,
        margin: 0,
      }}
      onClick={onPress}
    >
      <span
        className={`inline-block overflow-hidden`}
        style={{
          height: `50px`,
          width: `62px`,
        }}  
      >
        <img
          src={googleIcon} 
          alt={`Sign in with Google`} 
          style={{
            height: `51px`,
            width: `51px`,
            marginTop: `-1px`,
            marginBottom: `-1px`,
            marginLeft: `-1px`,
          }}  
        />
      </span>
      <span
        className={`inline-block flex flex-grow justify-start pl-2 items-center w-full text-center`}
        style={{
          height: `50px`,
          backgroundColor: `#4285f4`,
          fontFamily: `Roboto, sans-serif`,
          fontWeight: 700,
          fontSize: `18px`,
          color: `white`,
        }}
      >
        Sign in with Google
      </span>
    </button>
  );
}

export { GoogleButton };
