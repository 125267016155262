import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { CreateMxmCustomerDocument, CreateMxmCustomerMutation, CreateMxmCustomerMutationVariables } from "../../generatedQueries";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import useCachePrimer from "../useCachePrimer";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { CreateMxmCustomerRequest } from "../../events/event-types/payment-methods/CreateMxmCustomerRequest";
import { CreateMxmCustomerResponse } from "../../events/event-types/payment-methods/CreateMxmCustomerResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    CreateMxmCustomerMutation["createMxmCustomer"],
    unknown,
    CreateMxmCustomerMutationVariables
  >,
  "mutationFn"
>;

export default function useCreateMxmCustomer(options?: Options) {
  const cachePrimer = useCachePrimer();

  const mutation = useMutation<
    CreateMxmCustomerMutation["createMxmCustomer"],
    unknown,
    CreateMxmCustomerMutationVariables
  >(
    async () => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: CreateMxmCustomerDocument,
        variables: {},
        requestHeaders,
        // signal,
      })).createMxmCustomer;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new CreateMxmCustomerRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new CreateMxmCustomerResponse({
            mutationResponse: resp,
          })
        );

        cachePrimer.User.replaceById(resp?.user);

        if (options?.onSuccess) {
          options.onSuccess(resp, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    user: mutation.data?.user ?? null,
  };
}
