import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { DeleteVehicleDocument, DeleteVehicleMutation, DeleteVehicleMutationVariables } from "../../generatedQueries";
import useCachePrimer from "../useCachePrimer";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { DeleteVehicleRequest } from "../../events/event-types/vehicles/DeleteVehicleRequest";
import { DeleteVehicleResponse } from "../../events/event-types/vehicles/DeleteVehicleResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    DeleteVehicleMutation["deleteVehicle"],
    unknown,
    DeleteVehicleMutationVariables
  >,
  "mutationFn"
>;

export default function useDeleteVehicle(options: Options) {
  const cachePrimer = useCachePrimer();
  const auth = useMultiAuth();

  const mutation = useMutation<
    DeleteVehicleMutation["deleteVehicle"],
    unknown,
    DeleteVehicleMutationVariables
  >(
    async ({ vehicleId }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };
    
      return (await request({
        url: getGraphqlEndpoint() as string,
        document: DeleteVehicleDocument,
        variables: {
          vehicleId,
        },
        requestHeaders,
        // signal,
      })).deleteVehicle;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new DeleteVehicleRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new DeleteVehicleResponse({
            mutationResponse: resp,
          })
        );

        cachePrimer.Vehicles.removeById(
          [resp?.vehicle?.id as number],
          auth.session?.sessionDetails.userId ?? -1
        );

        if (options.onSuccess) {
          options.onSuccess(resp, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    vehicle: mutation.data?.vehicle ?? null,
  };
}
