import { GeneralUserMessage } from "../../components/GeneralUserMessage";
import { Layout } from "../../components/Layout";

function RequiresBetaFeatures() {
  return (
    <Layout
      content={
        <GeneralUserMessage
          message={`Please contact the meterEZ administrator to enable Beta Testing on your account.`}
        />
      }
    />
  );
}

export { RequiresBetaFeatures };