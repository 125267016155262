import { useRef, useEffect } from 'react';

export default function useInterval(callback: unknown, delay: number | null) {
  const savedCallback = useRef();
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (delay !== null) {
      intervalRef.current = setInterval(tick, delay);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [delay]);
}

