// @ts-nocheck
import _ from "lodash";

export type EnvNames = 
  `DEBUG` |
  `EVENTS_DEBUG` |
  `ENV` |
  `SENTRY_DSN` |
  `BETA_USERS_ONLY` |
  `GOOGLE_RECAPTCHA_SITE_KEY`;

let displayedDebug = false;

export default function env<TDefault = any>(
  name?: EnvNames,
  defaultValue?: TDefault,
  cast: `string` | `int` | `bool` = `string`
) {
  const rawViteEnv = import.meta.env ?? {};
  const processEnv = typeof(process) !== `undefined` ? (process?.env) ?? {} : {};

  const viteEnv = _.reduce(rawViteEnv, (env, v, k) => {
    const key = k.includes('VITE_') ? k.substring(5) : k;

    return {
      ...env,
      [key]: v,
      [k]: v,
    };
  }, {} as ImportMetaEnv);
  const env = { ...processEnv, ...viteEnv };

  if (asBool(env?.[`DEBUG`]) === true && !displayedDebug) {
    console.log(`Debug Mode Enabled`);
    console.log('env', { rawViteEnv, viteEnv, processEnv, env });
    displayedDebug = true;
  }

  if (!name) return env ?? {};

  const value = env?.[name];

  if (value === undefined) return defaultValue;

  switch(cast) {
    case `string`:
      return _.toString(value);
    case `int`:
      return _.toInteger(value);
    case `bool`:
      return asBool(value);
  }
}

function asBool(value: any) {
  return (value ?? "").toLowerCase() === `true` || (value ?? "").toLowerCase() === `yes` || (value ?? "").toLowerCase() === `1`;
}