import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import request from "graphql-request";
import { getGraphqlEndpoint } from "../../config/config";
import { DeletePaymentMethodDocument, DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables } from "../../generatedQueries";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { DeletePaymentMethodRequest } from "../../events/event-types/payment-methods/DeletePaymentMethodRequest";
import { DeletePaymentMethodResponse } from "../../events/event-types/payment-methods/DeletePaymentMethodResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    DeletePaymentMethodMutation["deletePaymentMethod"],
    unknown,
    Omit<DeletePaymentMethodMutationVariables, "userId">
  >,
  "mutationFn"
>;

export default function useDeletePaymentMethod(options?: Options) {
  const mutation = useMutation<
    DeletePaymentMethodMutation["deletePaymentMethod"],
    unknown,
    Omit<DeletePaymentMethodMutationVariables, "userId">
  >(
    async ({
      paymentMethodId,
    }: Omit<DeletePaymentMethodMutationVariables, "userId">) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: DeletePaymentMethodDocument,
        variables: {
          paymentMethodId,
        },
        requestHeaders,
        // signal,
      })).deletePaymentMethod;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new DeletePaymentMethodRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new DeletePaymentMethodResponse({
            mutationResponse: resp,
          })
        );

        if (options?.onSuccess) {
          options?.onSuccess(resp, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    deletedPaymentMethodId: mutation.data?.deletedPaymentMethodId ?? null,
  };
}
