import { useCallback, useRef } from "react";
import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { AuthRequest as AuthRequestManager } from "../../../AuthRequest";
import { GoogleButton } from "./GoogleButton";
import { event } from "../../../../../events/core/helpers";
import { ButtonPress } from "../../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../../events/event-sub-categories";

export type Props = {
  onChangeState?: (
    state: `IDLE` | `IN_PROGRESS` | `CANCELLED` | `ERROR` | `SUCCESS`,
    errorMessage: string | null
  ) => any;
};

function Choose(props: Props) {
  const { onChangeState = () => {} } = props;

  const AuthRequest = useRef(new AuthRequestManager()).current;

  const signIn = useCallback(async () => {
    event(
      new ButtonPress({
        buttonName: `Sign in with Google`,
        buttonSlug: `SIGN_IN_WITH_GOOGLE`,
        subCategory: EventSubCategories.AUTH,
      })
    );

    onChangeState(`IN_PROGRESS`, null);

    await AuthRequest.prompt({ provider: `google` });
  }, [AuthRequest, onChangeState]);

  return (
    <div
      style={{
        width: `100%`,
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <GoogleButton
        onPress={signIn}
      />
    </div>
  );
}

export { Choose };
