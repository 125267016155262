import { ReactComponent as IconMeter } from "../../../src/assets/icons/icon-meter.svg";
import { useSessionStatus } from "../../lib/useSessionStatus";
import { ParkableTransaction, useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import useCancelParkableTransaction from "../../queries/parkable-transactions/useCancelParkableTransaction";
import { useSessionInProgress } from "../../lib/session-in-progress/useSessionInProgress";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../queries/query-keys";
import { useHandleError } from "../../lib/error-handling";
import { useAlertModal } from "../modals/alert-modal";
import { useCallback } from "react";
import useCachePrimer from "../../queries/useCachePrimer";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";

export type Props = {
  parkableTransaction: ParkableTransaction;
};

function ParkableTransactionCard(props: Props) {
  const { parkableTransaction } = props;

  const auth = useMultiAuth();
  const queryClient = useQueryClient();
  const handleError = useHandleError();
  const alert = useAlertModal();

  const session = useSessionInProgress();
  const cachePrimer = useCachePrimer();

  const { query } = useRecentParkableTransactions();
  const { mutation } = useCancelParkableTransaction({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async (response, variables, context) => {
      const parkableTransaction = response?.parkableTransaction;

      cachePrimer.RecentParkableTransactions.replaceById([parkableTransaction]);
      // cachePrimer.ParkableTransaction.replaceById(
      //   parkableTransaction.user_id,
      //   parkableTransaction
      // );

      const userId = auth.session?.sessionDetails.userId;
      queryClient.invalidateQueries(queryKeys.RecentParkableTransactions(userId ?? -1));

      query.refetch();
    }
  });

  const {
    isActive, 
    canExtendTime,
    cancelledDatetime,
    expiresDatetime,
    remainingHuman,
  } = useSessionStatus({ parkableTransaction });

  const onClickCancel = useCallback(() => {
    alert.show({
      title: [`Are You`, `Sure?`],
      message: `Are you sure you want to cancel this parking session? Refunds will not be issued for unused time.`,
      buttons: [
        {
          contentTop: `Yes, Cancel Session`,
          type: `primary-red`,
          onClick: () => {
            event(new ButtonPress({
              buttonName: `Yes, Cancel Session`,
              buttonSlug: `CANCEL_PARKABLE_TRANSACTION`,
            }));
            
            mutation.mutate({
              parkableTransactionId: parkableTransaction.id,
            });
          },
        },
        {
          contentTop: `Cancel`,
          type: `tertiary-transparent`,
        }
      ],
    });
  }, [alert, mutation, parkableTransaction]);

  const colorActive = `#0A84FF`;
  const colorInactive = `#FF3B30`;
  const color = isActive ? colorActive : colorInactive;

  return (
    <div
      className={`w-full rounded-md p-2 ${isActive ? `pb-0`:``}`}
      style={{
        borderWidth: `1px`,
        borderColor: `#E7EAF7`,
        backgroundColor: `#fff`,
      }}
    >
      <div
        className={`flex pb-1 mb-1`}
        style={{
          borderBottomWidth: `1px`,
          borderColor: `#E7EAF7`,
        }}
      >
        <span
          className={`uppercase mr-2 overflow-hidden whitespace-nowrap text-ellipsis inline-block`}
          style={{
            fontFamily: `Roboto, sans-serif`,
            fontWeight: 700,
            fontSize: `18px`,
            color,
            maxWidth: `150px`,
          }}
        >
          {parkableTransaction.snapshot_license_plate}
        </span>
        <span
          className={`whitespace-nowrap inline-block`}
          style={{
            fontFamily: `Roboto, sans-serif`,
            fontWeight: 700,
            fontSize: `18px`,
            color: `#0E2A48`,
          }}
        >
          is {isActive ? `Parked` : (parkableTransaction.cancelled_at ? `Cancelled` : `Expired`)}
        </span>
      </div>
      <div className={`flex flex-row`}>
        <div className={`flex flex-col flex-grow`}>
          <div>
            <span
              className={`uppercase mr-1`}
              style={{
                fontFamily: `Roboto, sans-serif`,
                fontWeight: 700,
                fontSize: `12px`,
                color: `#0E2A48`,
              }}
            >
              {isActive ? `Expires` : (parkableTransaction.cancelled_at ? `Cancelled` : `Expired`)} at
            </span>
            <span
              className={`uppercase mr-1`}
              style={{
                fontFamily: `Roboto, sans-serif`,
                fontWeight: 700,
                fontSize: `12px`,
                color,
              }}
            >
              {(cancelledDatetime ?? expiresDatetime)
                .clone()
                .tz("America/New_York")
                // .second(0)
                .format("h:mm A")}
            </span>
            {/* {isActive && (
              <span
                className={`uppercase mr-1`}
                style={{
                  fontFamily: `Roboto, sans-serif`,
                  fontWeight: 700,
                  fontSize: `12px`,
                  color: `#0E2A48`,
                }}
              >
                in
              </span>
            )} */}
          </div>
          {isActive && (
            <div>
              <span
                className={`uppercase`}
                style={{
                  fontFamily: `Roboto, sans-serif`,
                  fontWeight: 700,
                  fontSize: `24px`,
                  color: `#0A84FF`,
                  marginRight: `5px`,
                }}
              >
                {remainingHuman}
              </span>

              <span
                className={`uppercase mr-1 relative`}
                style={{
                  fontFamily: `Roboto, sans-serif`,
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `16px`,
                  color: `#0E2A48`,
                  top: `-1px`,
                }}
              >
                Left
              </span>
            </div>
          )}
        </div>
        <div
          className={`flex flex-row pt-1`}
          style={{
            fontFamily: `Roboto, sans-serif`,
            fontWeight: 700,
            fontSize: `12px`,
            color: `#0E2A48`,
          }}
        >
          <IconMeter
            className={`mr-2 relative`}
            style={{
              top: `1px`,
            }}
          />
          {parkableTransaction.parkable.identifier}
        </div>
      </div>
      <div
        className={`w-full flex flex-row justify-end`}
        style={{
          // backgroundColor: 'red'
        }}
      >
        {canExtendTime && (
          <button
            className={`uppercase ml-2 p-2`}
            style={{
              fontFamily: `Roboto, sans-serif`,
              fontWeight: 700,
              fontSize: `10px`,
              lineHeight: `14px`,
              color,
            }}
            onClick={(e) => {
              e.preventDefault();
              session.startNew(parkableTransaction.id);
              session.navigate();
            }}
            disabled={mutation.isLoading || mutation.isSuccess}
          >
            + Add Time
          </button>
        )}

        {isActive && (
          <button
            className={`uppercase ml-2 p-2`}
            style={{
              fontFamily: `Roboto, sans-serif`,
              fontWeight: 700,
              fontSize: `10px`,
              lineHeight: `14px`,
              color: colorInactive,
            }}
            onClick={(e) => {
              e.preventDefault();
              onClickCancel();
            }}
            disabled={mutation.isLoading || mutation.isSuccess}
          >
            {mutation.isLoading ? `Cancelling...`: (mutation.isSuccess ? `Cancelled` : `Cancel`)}
          </button>
        )}
      </div>
    </div>
  );
}

export { ParkableTransactionCard };