import { ReactNode } from 'react';
import { ReactComponent as Logo } from '../../../src/assets/logo-meterez.svg';
import _ from 'lodash';

export type Props = {
  title?: string | (string | null)[] | JSX.Element | JSX.Element[] | null;
};

function TitleHeader(props: Props) {
  const { title } = props;

  let titleParts: (string | null)[] = [];
  let titleEls: JSX.Element | JSX.Element[] = [];

  if (
    _.isArray(title) &&
    _.filter(title, (et) => _.isString(et)).length === 0
  ) {
    titleEls = title as JSX.Element | JSX.Element[];
  } else {
    if (_.isArray(title)) {
      titleParts = title as (string | null)[];
    } else if (_.isString(title)) {
      titleParts = [title];
    }

    titleEls = titleParts.map((part: string | null, i: number) => (
      <span
        className={`inline-block`}
        style={{
          fontFamily: "Roboto",
          fontWeight: 900,
          fontSize: `21px`,
          lineHeight: `21px`,
          color:
            i % 2 === 0 ? "rgba(14, 42, 72, 1)" : "rgba(10, 132, 255, 1)",
          marginRight: 7,
        }}
        key={`${i}${part}`}
      >
        {part}
      </span>
    ));
  }

  return (
    <div
      className={`flex justify-center`}
      style={{
        transform: `translate3d(0,0,0)`,
      }}
    >
      {titleEls}
    </div>
  );
}

export { TitleHeader };