import { createContext } from "react";

import type { MultiAuthContextValue } from "./useProvideMultiAuth";

const defaultValue: MultiAuthContextValue = {} as MultiAuthContextValue;

const MultiAuthContext = createContext(defaultValue);

export default MultiAuthContext;

export { MultiAuthContextValue };
