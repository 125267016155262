import { SummaryCard } from "./SummaryCard";
import { ReactComponent as IconClock } from "../../../src/assets/icons/icon-clock.svg";
import useParkable from "../../queries/parkables/useParkable";
import { TextSkeleton } from "../skeletons/TextSkeleton";
import { useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import { datetimeToLocal, makeDuration, serverDatetimeToMoment } from "../../lib/utils";
import { useLiveNow } from "../../lib/useLiveNow";
import { useMemo } from "react";

export type Props = {
  skeletonMode?: `PROMPT` | `EMPTY`;
  parkableId: number | null | undefined;
  time: number | null | undefined;
  session: ReturnType<typeof useRecentParkableTransactions>["parkableTransactions"][number] | null | undefined;
  showEditIcon?: boolean;
  onClick?: () => any;
  disabled?: boolean;
};

function TimeCard(props: Props) {
  const { skeletonMode, parkableId, time, session, showEditIcon = false, onClick, disabled } = props;

  const { parkable } = useParkable({ parkableId, poll: true });

  const duration = time ? makeDuration(1, time) : null;

  const now = useLiveNow();
  const startDatetime = useMemo(() => {
    return session
      ? serverDatetimeToMoment(session.expires_at)
      : now;
  }, [now, session]);

  const parkUntil = useMemo(() => {
    if (!time) return null;

    return datetimeToLocal(startDatetime)
      .clone()
      .add(time, "seconds")
      .format("LT");
  }, [startDatetime, time]);

  let rightTopContent = null;
  if (skeletonMode === `PROMPT`) {
    rightTopContent = (
      <div>
        <span
          className={`uppercase`}
          style={{
            color: `#0A84FF`,
          }}
        >
          Next:
        </span>
        {` `}Choose Parking Duration...
      </div>
    );
  } else if (skeletonMode === `EMPTY`) {
    rightTopContent = (
      <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
    );
  } else if (parkable || session) {
    rightTopContent = duration?.human;
  }

  let rightBottomContent = null;
  if (skeletonMode === `PROMPT` || skeletonMode === `EMPTY`) {
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (parkable || session) {
    rightBottomContent = `${session ? `Extend` : `Park`} Until ${parkUntil}`
  }

  return (
    <SummaryCard
      icon={
        <IconClock
          className={`relative`}
          style={{
            top: `-1px`,
            width: `14px`,
            // height: `11px`,
          }}
        />
      }
      topContent={session ? `Extend Time` : `Time`}
      rightTopContent={rightTopContent}
      rightBottomContent={rightBottomContent}
      showEditIcon={showEditIcon}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

export { TimeCard };