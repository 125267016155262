import { useEffect } from "react";
import usePrevious from "./usePrevious";
import { scrollToTop } from "./utils";

function useScrollToTopOnChange(value: unknown) {
  const prev = usePrevious(value);
  useEffect(() => {
    if (prev && prev !== value) {
      setTimeout(() => {
        scrollToTop();
      }, 50);
    }
  }, [value]);
}

export { useScrollToTopOnChange };