import { ReactNode } from "react";

import { Auth, Storage } from "./Auth";
import { useProvideMultiAuth } from "./useProvideMultiAuth";
import MultiAuthContext from "./MultiAuthContext";
import { Args as UseProvidersArgs } from "./useProviders";

export type Props = {
  storage: Storage;
  providers: Omit<UseProvidersArgs, `storage`>;
  useExchangeCognitoTokens?: (args: { auth: InstanceType<typeof Auth> }) => {
    isLoading: boolean;
    isExchanged: boolean;
  };
  children: ReactNode;
};

function MultiAuthProvider(props: Props) {
  const { storage, providers, useExchangeCognitoTokens, children } = props;

  const contextValue = useProvideMultiAuth({
    storage,
    providers,
    useExchangeCognitoTokens,
  });

  return (
    <MultiAuthContext.Provider value={contextValue}>
      {children}
    </MultiAuthContext.Provider>
  );
}

export { MultiAuthProvider };
