import { EventsError } from "./EventsError";

class MalformedEventsQueueError extends EventsError {
  public retrievedData: any;

  constructor(retrievedData: any) {
    super("Malformed Events Queue");
    this.name = "MalformedEventsQueueError";
    this.humanMessage =
      "The events queue data retrieved from storage was not recognizable";
    this.retrievedData = retrievedData;
  }
}

export { MalformedEventsQueueError };
