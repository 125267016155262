import { Layout } from "../layout/Layout";
import { Choose as ChooseApple } from "../providers/apple/Choose";
import { Choose as ChooseGoogle } from "../providers/google/Choose";
import { Choose as ChooseEmail } from "../providers/email/Choose";
import { Choose as ChoosePhoneNumber } from "../providers/phone-number/Choose";
import { Provider, useProviders } from "../../../core/useProviders";
import { Fragment, ReactNode, useState } from "react";
import { HelperText } from "../helper-text/HelperText";

export type Props = {
  providers: ReturnType<typeof useProviders>;
  errorMessage?: string | null | undefined;
  statusMessage?: string | null | undefined;
  onChoose?: (provider: `apple` | `google` | `phone_number` | `email`) => any;
  onChangeState?: (
    provider: `apple` | `google` | `phone_number` | `email`,
    state: `IDLE` | `IN_PROGRESS` | `CANCELLED` | `ERROR` | `SUCCESS`,
    errorMessage: string | null
  ) => any;
};

function ChooseProvider(props: Props) {
  const {
    providers,
    errorMessage,
    statusMessage,
    onChoose = () => {},
    onChangeState = () => {},
  } = props;

  const { lastLoggedIn, otherProviders } = providers;

  const [uiState, setUiState] = useState(`IDLE`);

  const chooseApple = (
    <ChooseApple
      onChangeState={(uiState, errorMessage) => {
        setUiState(uiState);
        onChangeState(`apple`, uiState, errorMessage);
      }}
    />
  );

  const chooseGoogle = (
    <ChooseGoogle
      onChangeState={(uiState, errorMessage) => {
        setUiState(uiState);
        onChangeState(`google`, uiState, errorMessage);
      }}
    />
  );

  const chooseEmail = (
    <ChooseEmail
      onChoose={() => {
        onChoose(`email`);
      }}
      // onChangeState={onChangeState}
    />
  );

  const choosePhoneNumber = (
    <ChoosePhoneNumber
      onChoose={() => {
        onChoose(`phone_number`);
      }}
      // onChangeState={onChangeState}
    />
  );

  const providersMap: Record<Provider, ReactNode> = {
    apple: chooseApple,
    google: chooseGoogle,
    email: chooseEmail,
    phone_number: choosePhoneNumber,
  };

  return (
    <Layout
      helperTexts={[[`Choose a`, `Sign-In Option`]]}
      // helperSubtext={`Testing some long helper subtext`}
      errorMessage={errorMessage}
      statusMessage={statusMessage}
      content={
        <div
          style={{
            width: `100%`,
            position: 'relative',
          }}
        >
          {!!lastLoggedIn && !!providersMap[lastLoggedIn] && (
            <div
              className={`w-full flex flex-col items-center`}
            >
              <HelperText texts={[`Last Used`]} color1={`#b4b9c8`} />

              {providersMap[lastLoggedIn]}

              <div style={{ height: `20px` }} />

              <HelperText texts={[`Other Options`]} color1={`#b4b9c8`} />
            </div>
          )}

          {otherProviders.map((provider, i) => {
            if (!providersMap[provider]) return null;

            return (
              <Fragment key={provider}>
                {providersMap[provider]}

                {i < otherProviders.length - 1 && (
                  <div key={i} style={{ height: `10px` }} />
                )}
              </Fragment>
            );
          })}

          <div style={{ height: `10px` }} />

          {(uiState === `IN_PROGRESS` || uiState === `SUCCESS`) && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: `rgba(255,255,255,0.5)`,
              }}
            />
          )}
        </div>
      }
    />
  );
}

export { ChooseProvider };
