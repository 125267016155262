import { Navigate, useNavigate, useParams } from "react-router-dom";
import { LogoHeader } from "../../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useEffect } from "react";
import { Progress } from "../components/Progress";
import { ReactComponent as IconEdit } from "../../../../../src/assets/icons/icon-edit.svg";
import { ParkableCard } from "../../../../components/cards/ParkableCard";
import { Heading1 } from "../../../../components/headings/Heading1";
import { VehicleCard } from "../../../../components/cards/VehicleCard";
import { TimeCard } from "../../../../components/cards/TimeCard";
import { PaymentMethodCard } from "../../../../components/cards/PaymentMethodCard";
import { Summary } from "../components/Summary";
import useCreateParkableTransaction from "../../../../queries/parkable-transactions/useCreateParkableTransaction";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import useExtendTime from "../../../../queries/parkable-transactions/useExtendTime";
import _ from "lodash";
import { toValidInteger } from "../../../../lib/utils";
import { RedirectOnExtendSessionInvalid } from "../components/RedirectOnExtendSessionInvalid";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../../../queries/query-keys";
import { useHandleError } from "../../../../lib/error-handling";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import useCachePrimer from "../../../../queries/useCachePrimer";
import { UiBlock } from "../../../../components/ui-blocks/UiBlock";
import { Auth } from "../../../../auth/core/Auth";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";

export type Props = {

};

function Review(props: Props) {
  const { } = props;

  const queryClient = useQueryClient();
  const handleError = useHandleError();
  const cachePrimer = useCachePrimer();

  const { parkableTransactionId: parkableTransactionIdStr } = useParams() as { parkableTransactionId?: number };
  const parkableTransactionId = toValidInteger(parkableTransactionIdStr);
  const isExtend = !!parkableTransactionId;
  const navigate = useNavigate();
  const session = useSessionInProgress();
  let sessionValid = false;
  if (session.sessionInProgress) {
    const { time } = session.sessionInProgress.sessionParameters;

    sessionValid = !!time;
  }

  const { query, parkableTransactions } = useRecentParkableTransactions();
  const parkableTransaction = _.find(parkableTransactions, { id: parkableTransactionId });
  
  const { mutation, parkableTransaction: extendedParkableTransaction } = useExtendTime({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async (response, variables, context) => {
      const parkableTransaction = response?.parkableTransaction;

      cachePrimer.RecentParkableTransactions.replaceById([parkableTransaction]);
      // cachePrimer.ParkableTransaction.replaceById(
      //   parkableTransaction.user_id,
      //   parkableTransaction
      // );
      // cachePrimer.Wallet.invalidate(parkableTransaction?.user_id);

      const userId = (await Auth.getInstance().getSession())?.sessionDetails.userId;
      queryClient.invalidateQueries(queryKeys.RecentParkableTransactions(userId ?? -1));

      query.refetch();
      session.cancel();
      navigate("/sessions", { replace: true });
    },
  });

  useEffect(() => {
    if (
      !session.stateRef.current.sessionInProgress || 
      !session.stateRef.current.sessionInProgress.parkableTransactionId || 
      !parkableTransactionId
    ) {
      navigate("/sessions", { replace: true });
      return;
    }
  }, []);

  if (!session.sessionInProgress || !parkableTransaction) {
    return null;
  }

  return (
    <>
      <ScreenLayout
        header={
          <TitleHeader title={[`Extend`, `Session`]} />
        }
        content={
          <div
            className={`w-full flex flex-grow flex-col items-center`}
          >
            <Progress />

            <Heading1>
              Review and Pay
            </Heading1>

            <div
              className={`w-full mb-2`}
            >
              <TimeCard
                parkableId={session.sessionInProgress.sessionParameters.parkableId}
                time={session.sessionInProgress.sessionParameters.time}
                session={parkableTransaction}
                onClick={() => {
                  session.navigate(`TIME`);
                }}
                showEditIcon={true}
                disabled={mutation.isLoading}
              />
            </div>

            <div
              className={`w-full mb-4`}
            >
              <PaymentMethodCard
                paymentMethodId={isExtend ? 
                  parkableTransaction.payment_method_id :
                  session.sessionInProgress.sessionParameters.paymentMethodId
                }
                onClick={() => {
                  // TODO Warn they can't change the payment method
                }}
                showEditIcon={false}
                disabled={true}
              />
            </div>

            <div
              className={`w-full mb-4`}
            >
              <Summary />
            </div>

            <div
              className={`w-full flex flex-col items-center`}
            >
              <FullWidthButton
                contentTop={mutation.isLoading ? `Confirming Payment...` : `Confirm Payment`}
                onClick={() => {
                  if (session.sessionInProgress) {
                    event(
                      new ButtonPress({
                        buttonName: `Confirm Payment`,
                        buttonSlug: `EXTEND_TIME.CONFIRM`,
                        subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                      })
                    );

                    const { time } = session.sessionInProgress.sessionParameters;

                    mutation.mutate({
                      idempotentKey: session.sessionInProgress.idempotentKey,
                      parkableTransactionId: parkableTransaction?.id,
                      time: time as number,
                    });
                  }
                }}
                disabled={!sessionValid || mutation.isLoading}
                disabledIfOffline
                showOfflineIndicator
              />

              <div className={`mb-4`} />

              <FullWidthButton
                contentTop={`Cancel Extension`}
                type={`tertiary-transparent`}
                onClick={() => {
                  event(
                    new ButtonPress({
                      buttonName: `Cancel Extension`,
                      buttonSlug: `EXTEND_TIME.CANCEL`,
                      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                    })
                  );

                  session.cancel();
                  navigate(`sessions`);
                }}
                disabled={mutation.isLoading}
              />
            </div>

            <UiBlock
              show={mutation.isLoading || mutation.isSuccess}
              text={[`Please`, `Wait`, `...`]}
              subText={`You are not parked until you see your time updated on the home screen.`}
            />
          </div>
        }
      />
      <RedirectOnExtendSessionInvalid />
    </>
  );
}

export { Review };