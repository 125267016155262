import { Navigate, useNavigate } from "react-router-dom";
import { LogoHeader } from "../../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Parkables, searchParkables } from "../../../../queries/parkables/searchParkables";
import { isActive, toValidInteger } from "../../../../lib/utils";
import { Search } from "../components/parkable/Search";
import { Choose } from "../components/parkable/Choose";
import { Confirm } from "../components/parkable/Confirm";
import useParkable from "../../../../queries/parkables/useParkable";
import { Progress } from "../components/Progress";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import { useScrollToTopOnChange } from "../../../../lib/useScrollToTopOnChange";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { useLiveNow } from "../../../../lib/useLiveNow";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";
import { ParkableNotFound } from "../../../../events/event-types/parkable-transactions/ParkableNotFound";

export type SearchState = {
  searchText: string;
  parkables: Parkables | null;
  searching: boolean;
  errorMsg: string | null;
};

export type Props = {

};

function Parkable(props: Props) {
  const {  } = props;

  const navigate = useNavigate();
  const session = useSessionInProgress();
  const parkableId = session.sessionInProgress?.sessionParameters.parkableId;
  const { query, parkable } = useParkable({ parkableId });
  const { parkableTransactions } = useRecentParkableTransactions();
  const now = useLiveNow();
  const isParked = parkable?.type === `meter` && parkableTransactions.filter(pt => pt.parkable_id === parkableId && isActive(pt, now)).length > 0;
  const errorMessage = isParked ? `One of your vehicles is already parked here` : null;

  const [searchState, setSearchState] = useState<SearchState>({
    searchText: ``,
    parkables: null,
    searching: false,
    errorMsg: null,
  });

  const search = useCallback(async () => {
    const identifier = toValidInteger(searchState.searchText);

    if (!identifier) return;
    if (searchState.searching) return;

    try {
      setSearchState((state) => ({
        ...state,
        parkables: null,
        searching: true,
        errorMsg: null,
      }));

      const parkables = await searchParkables({ identifier });

      if (parkables.length === 1) {
        session.set({
          parkableId: parkables[0].id,
        });
        setSearchState((state) => ({
          ...state,
          parkables,
          searching: false,
        }));
      } else if (parkables.length > 1) {
        setSearchState((state) => ({
          ...state,
          parkables,
          searching: false,
        }));
      } else {
        event(new ParkableNotFound({
          identifier,
        }));

        setSearchState((state) => ({
          ...state,
          parkables: null,
          searching: false,
          errorMsg: `Meter not Found`,
        }));
      }
    } catch (error: unknown) {
      setSearchState((state) => ({
        ...state,
        searching: false,
        errorMsg: `Error Searching, Try Again`,
      }));
    }
  }, [searchState, session]);

  useEffect(() => {
    if (!session.stateRef.current.sessionInProgress) {
      navigate("/sessions", { replace: true });
    }
  }, []);

  let uiState = `SEARCH`;
  if (parkableId) {
    uiState = `CONFIRM`;
  } else if (searchState.parkables) {
    uiState = `CHOOSE`;
  }

  const submitDisabled = uiState !== `SEARCH` || !searchState.searchText || !searchState.searchText || searchState.searching;

  const onSubmit = useCallback(() => {
    if (submitDisabled) return;

    search();
  }, [submitDisabled, search]);

  useScrollToTopOnChange(uiState);

  if (!session.sessionInProgress) {
    return null;
  }

  return (
    <ScreenLayout
      header={
        <TitleHeader title={[`New`, `Session`]} />
      }
      content={
        <form
          className={`w-full flex flex-grow flex-col items-center`}
          onSubmit={e => {
            onSubmit();
            e.preventDefault();
          }}
        >
          <div
            className={`w-full flex flex-grow flex-col items-center`}
          >
            <Progress />
            
            {uiState === `SEARCH` && (
              <Search
                searchText={searchState.searchText}
                onChangeSearchText={(searchText) => {
                  setSearchState((state) => ({
                    ...state,
                    searchText,
                  }));
                }}
                disabled={searchState.searching}
                errorMsg={searchState.errorMsg}
              />
            )}

            {uiState === `CHOOSE` && !!searchState.parkables && (
              <Choose
                parkables={searchState.parkables}
                onClick={(parkable) => {
                  session.set({
                    parkableId: parkable.id,
                  });
                }}
                onCancel={() => {
                  session.set({
                    parkableId: undefined,
                  })
                  setSearchState({
                    searchText: ``,
                    parkables: null,
                    searching: false,
                    errorMsg: null,
                  });
                  // TODO Re-focus search field
                }}
              />
            )}

            {uiState === `CONFIRM` && (
              <Confirm
                parkableId={session.sessionInProgress?.sessionParameters.parkableId}
                errorMessage={errorMessage}
                onCancel={() => {
                  session.set({
                    parkableId: undefined,
                  })
                  setSearchState({
                    searchText: ``,
                    parkables: null,
                    searching: false,
                    errorMsg: null,
                  });
                  // TODO Re-focus search field
                }}
              />
            )}

            <div
              className={`w-full flex flex-col items-center`}
            >
              {uiState === `SEARCH` && (
                <FullWidthButton
                  contentTop={searchState.searching ? `Searching...` : `Search`}
                  onClick={onSubmit}
                  disabled={submitDisabled}
                  disabledIfOffline
                  showOfflineIndicator
                />
              )}

              {uiState === `CONFIRM` && (
                <FullWidthButton
                  contentTop={`Continue`}
                  onClick={() => session.navigate(`VEHICLE`)}
                  disabled={!parkable || isParked}
                />
              )}

              <div className={`mb-4`} />

              <FullWidthButton
                contentTop={`Cancel Parking`}
                type={`tertiary-transparent`}
                onClick={() => {
                  event(
                    new ButtonPress({
                      buttonName: `Cancel Parking`,
                      buttonSlug: `CREATE_PARKABLE_TRANSACTION.CANCEL`,
                      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                    })
                  );

                  session.cancel();
                  navigate(`sessions`);
                }}
                disabled={searchState.searching}
              />
            </div>
          </div>
        </form>
      }
    />
  );
}

export { Parkable };