import { GeneralUserMessage } from "../../components/GeneralUserMessage";
import { Layout } from "../../components/Layout";

function AccountLocked() {
  return (
    <Layout
      content={
        <GeneralUserMessage
          message={`Your account has been locked by an administrator.`}
        />
      }
    />
  );
}

export { AccountLocked };