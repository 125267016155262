import { Fragment } from "react";
import helpOptions from "./helpOptions";
import { FullWidthButton } from "../../../components/buttons/FullWidthButton";
import { ReactComponent as IconArrow } from "../../../../src/assets/icons/arrow-right-solid.svg";
import { ScreenLayout } from "../../../components/layouts/ScreenLayout";
import { LogoHeader } from "../../../components/headers/LogoHeader";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "./Breadcrumbs";

function Help() {
  const navigate = useNavigate();

  return (
    <ScreenLayout
      header={<LogoHeader />}
      content={
        <div
          className={`w-full py-2`}
        >
          <BreadCrumbs />
          
          <div
            className={`mb-4`}
            style={{
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: `14px`,
              lineHeight: `16px`,
              color: "#0E2A48",
            }}
          >
            Welcome to meterEZ Help / Support. Please choose an option below so we
            can best assist you.
          </div>

          <div
            className={`w-full`}
          >
            {helpOptions.map(({ slug, linkStyle, text }, i) => (
                <Fragment key={i}>
                  <LinkCard
                    linkStyle={linkStyle}
                    text={text}
                    onClick={() => {
                      // TODO Use url-friendly slugs instead of integers
                      navigate(`/help/${slug}`);
                    }}
                    key={i}
                  />
                  {i < helpOptions.length - 1 && (
                    <div style={{ height: `10px` }} />
                  )}
                </Fragment>
              ))}
          </div>

          <div
            className={`w-full flex justify-center`}
          >
            <div>
              <FullWidthButton
                contentTop={`Terms of Service`}
                type={`tertiary-transparent`}
                onClick={() => {
                  window.open("https://www.meterez.com/terms-of-use/", "_blank");
                }}
              />
            </div>
            <div>
              <FullWidthButton
                  contentTop={`Privacy Policy`}
                  type={`tertiary-transparent`}
                  onClick={() => {
                    window.open("https://www.meterez.com/privacy-policy/", "_blank");
                  }}
                />
            </div>
          </div>
        </div>
      }
    />
  );
}

export type LinkCardProps = {
  linkStyle: "default" | "subdued";
  text: string;
  onClick?: () => any;
};

export function LinkCard(props: LinkCardProps) {
  const { linkStyle, text, onClick = () => { } } = props;

  if (linkStyle === "subdued") {
    return (
      <FullWidthButton
        contentTop={text}
        type={`tertiary-transparent`}
        onClick={onClick}
      />
    );
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={`flex w-full rounded-md px-2 py-1`}
      style={{
        borderWidth: `1px`,
        borderColor: "#E7EAF7",
        minHeight: `50px`,
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div
        className={`pr-3 flex-grow text-left`}
      >
        <div
          className={``}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          {text}
        </div>
      </div>
      <div>
        <IconArrow
          style={{
            width: `15px`,
            height: `15px`,
          }}
        />
      </div>
    </button>
  );
}

export { Help };