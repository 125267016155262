import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";

export type Data = {
  buttonName: string;
  buttonSlug: string;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class ButtonPress extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { buttonName, buttonSlug, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      type: `BUTTON_PRESS`,
      data: {
        buttonName,
        buttonSlug,
      },
    });
  }
}

export { ButtonPress };
