import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  provider: `phone_number` | `email`;
  credential: string;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class OtpVerifySucceeded extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { provider, credential, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.AUTH,
      type: `OTP_VERIFY_SUCCEEDED`,
      data: {
        provider,
        credential,
      },
    });
  }
}

export { OtpVerifySucceeded };
