import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { request } from 'graphql-request';

import { RequestPaymentLimitedUseTokenDocument, RequestPaymentLimitedUseTokenMutation, RequestPaymentLimitedUseTokenMutationVariables, StoreVaultedCardAccountDocument, StoreVaultedCardAccountMutation, StoreVaultedCardAccountMutationVariables } from "../../generatedQueries";
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation, simulateNetworkError } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { RequestPaymentLimitedUseTokenRequest } from "../../events/event-types/payment-methods/RequestPaymentLimitedUseTokenRequest";
import { RequestPaymentLimitedUseTokenResponse } from "../../events/event-types/payment-methods/RequestPaymentLimitedUseTokenResponse";
import { simulateValidationError } from "../../lib/error-handling/error-handlers/mxm/mxmUtils";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
  RequestPaymentLimitedUseTokenMutation["requestPaymentLimitedUseToken"],
    unknown,
    RequestPaymentLimitedUseTokenMutationVariables
  >,
  "mutationFn"
>;

export default function useRequestPaymentLimitedUseToken(options?: Options) {
  const mutation = useMutation<
    RequestPaymentLimitedUseTokenMutation["requestPaymentLimitedUseToken"],
    unknown,
    RequestPaymentLimitedUseTokenMutationVariables
  >(
    async () => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };
    
      // Acquire Limited Use Token from MXM
      return (await request({
        url: getGraphqlEndpoint() as string,
        document: RequestPaymentLimitedUseTokenDocument,
        variables: {},
        requestHeaders,
        // signal,
      })).requestPaymentLimitedUseToken;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      // ...options,

      onMutate: (variables) => {
        event(
          new RequestPaymentLimitedUseTokenRequest({
            mutationVariables: {},
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new RequestPaymentLimitedUseTokenResponse({
            mutationResponse: {
              token: resp.token,
            },
          })
        );
      },
    }
  );

  return {
    mutation,
    token: mutation.data?.token ?? null,
  };
}
