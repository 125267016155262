import { ContactForm } from "./ConactForm";
import { ParkingAuthorityContactInfo } from "./ParklingAuthorityContactInfo";

export default [
  /**
   * Signup / Login Issues
   */
  {
    slug: `signup-login-issues`,
    linkStyle: "default",
    text: `I'm having signup or login issues`,
    headingText: `Signup / Login Issues`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          If you are having trouble signing up or logging in, please use the
          form below to describe the issue in as much detail as possible:
        </div>
        <ContactForm
          messageHeadingText={
            <div className={`w-full`}>
              Please provide as much detail as possible about the issues you are seeing - ie.<br />
              - What phone number you're trying to use to sign up<br />
              - What the is doing vs. what you expect it to do
            </div>
          }
          messagePlaceholderText={`Describe the issue(s) here`}
          ticketMessagePrefix={`Signup / Login Issues`}
        />
      </div>
    ),
  },

  /**
   * Parking Tickets
   */
  {
    slug: `parking-tickets`,
    linkStyle: "default",
    text: `I've received a parking ticket in error`,
    headingText: `Parking Tickets`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          For assistance with Parking Tickets, please contact your local Parking
          Authority. meterEZ does not issue parking tickets -- we only provide
          mobile payment services.
        </div>
        <ParkingAuthorityContactInfo />
      </div>
    ),
  },

  /**
   * Phone Number Change
   */
  {
    slug: `change-phone-number`,
    linkStyle: "default",
    text: `I need to change my phone number`,
    headingText: `Change Phone Number`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          Please fill out the form below to request a phone number change:
        </div>
        <ContactForm
          messageHeadingText={
            <div className={`w-full`}>
              Please provide the following in order for us to assist you:<br />
              - Your old phone number<br />
              - Your new phone number
            </div>
          }
          messagePlaceholderText={`Provide Old # / New # here`}
          ticketMessagePrefix={`Change Phone Number (Old # / New #)`}
        />
      </div>
    ),
  },

  /**
   * Account Charges
   */
  {
    slug: `account-charges`,
    linkStyle: "default",
    text: `I have questions about charges on my account`,
    headingText: `Account Charges`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          If you have questions about charges on your card, please use the form below:
        </div>
        <ContactForm
          messageHeadingText={`Please provide as much detail as possible about the related charges, when they occurred, etc`}
          messagePlaceholderText={`Describe your question here`}
        />
      </div>
    ),
  },

  /**
   * Refunds
   */
  {
    slug: `refund`,
    linkStyle: "default",
    text: `I'd like to request a refund`,
    headingText: `Refunds`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          <div className={`w-full`}>
            meterEZ will issue refunds for a limited number of circumstances, and according to your local Parking Authority's policies.<br /><br />
              Refund Policy:<br />
              meterEZ "is not permitted" to issue refunds for the following reasons, unless otherwise directed by the local Parking Authority:<br />
              - Unused time on a parking session<br />
              - Entering the incorrect meter number<br />
              - General user error<br /><br />
              meterEZ "will" issue refunds for the following reasons:<br />
              - meterEZ app issues that cause incorrect charges<br />
              - Returning unused EZwallet funds<br />
              - Other case-by-case scenarios at meterEZ's discretion
          </div>
        </div>
        <ContactForm
          revealText={`I have read the above terms, and would still like to request a refund`}
          formHeadingText={`Please fill out the form below:`}
          messageHeadingText={`Why are you requesting a refund?`}
          messagePlaceholderText={`Provide your reason here`}
          ticketMessagePrefix={`Refund Request`}
        />
      </div>
    ),
  },

  /**
   * Bugs
   */
  {
    slug: `app-issues`,
    linkStyle: "default",
    text: `The meterEZ app is not working correctly`,
    headingText: `meterEZ App Issues`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          If the meterEZ app is not working correctly, please fill out the form
          below and describe the issues you are seeing in as much detail as you
          can:
        </div>
        <ContactForm
          messageHeadingText={
            <div className={`w-full`}>
              Please provide as much detail as possible about the issues you are seeing - ie.<br />
              - What screen(s) you were on when the issue occurred<br />
              - Any error messages you are seeing<br />
              - What the app is doing vs. what you expect it to do
            </div>
          }
          messagePlaceholderText={`Describe the issue(s) here`}
          ticketMessagePrefix={`meterEZ App Issues`}
        />
      </div>
    ),
  },

  /**
   * Delete Account
   */
  {
    slug: `delete-account`,
    linkStyle: "default",
    text: `I'd like to delete my account`,
    headingText: `Account Deletion`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          If you would like to request that we premanently delete your account
          and personal information, please use the form below:
        </div>
        <ContactForm
          messageHeadingText={`Please let us know why you're requesting to delete your account so we can use this information to improve the meterEZ app and experience.`}
          messagePlaceholderText={`Describe your reason here`}
          ticketMessagePrefix={`Account Deletion`}
        />
      </div>
    ),
  },

  /**
   * Other
   */
  {
    slug: `general`,
    linkStyle: "subdued",
    text: `None of these options are helpful`,
    headingText: `General Support`,
    detailText: (isGuest: boolean) => (
      <div
        className={`w-full`}
      >
        <div
          className={`mb-4`}
          style={{
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `16px`,
            color: "#0E2A48",
          }}
        >
          Please make sure you've reviewed the options on the previous screen
          before contacting us about general issues in order for us to provide
          support as quickly as possible. If the options on the previous screen
          were not helpful and you still need to contact meterEZ support, you
          can use the general support form.
        </div>
        <ContactForm
          revealText={`I have reviewed the options on the previous screen and still need help`}
          showWebsiteUrl
          messageHeadingText={`What can we help you with?`}
          messagePlaceholderText={`Please provide as much detail as possible.`}
          ticketMessagePrefix={`General Support`}
        />
      </div>
    ),
  },
];
