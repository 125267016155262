import {
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { ReactNode } from "react";
import { ReceiptDocument } from "./ReceiptDocument";
import { ParkableTransaction } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";

export type Props = {
  // parkableTransactionId?: number;
  parkableTransaction?: ParkableTransaction;
  fileName?: string;
  children?: ReactNode;
};

function ReceiptDownloadLink(props: Props) {
  const { /*parkableTransactionId,*/ parkableTransaction, fileName, children } = props;

  // if (!parkableTransactionId) return null;
  if (!parkableTransaction) return null;

  const document = (
    <ReceiptDocument
      // parkableTransactionId={parkableTransactionId} 
      parkableTransaction={parkableTransaction}
    />
  )
  
  if (!document) return null;

  return (
    <PDFDownloadLink
      document={document}
      fileName={fileName ?? `meterez-receipt`}
    >
      {children}
    </PDFDownloadLink>
  );
}

export { ReceiptDownloadLink };