import _ from "lodash";

import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { RequestPaymentLimitedUseTokenMutation } from "../../../generatedQueries";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  token?: string;
};

export type ConstructorArgs = {
  mutationResponse: RequestPaymentLimitedUseTokenMutation["requestPaymentLimitedUseToken"];
} & Partial<EventConstructorArgs>;

class RequestPaymentLimitedUseTokenResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `REQUEST_PAYMENT_LIMITED_USE_TOKEN.RESPONSE`,
      data: {
        token: RequestPaymentLimitedUseTokenResponse.maskToken(
          mutationResponse.token
        ),
      },
    });
  }

  public static maskToken = (token: string) => {
    if (!_.isString(token)) return token;

    return `${token.substring(0, 3)}...${token.substring(
      token.length - 3,
      token.length + 1
    )}`;
  };
}

export { RequestPaymentLimitedUseTokenResponse };
