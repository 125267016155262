import { DocumentNode, parse } from "graphql";
import { ClientError } from "graphql-request";
import _ from "lodash";

import { AppError } from "./AppError";
import { GraphQlErrorContext, GraphQlResponseHasuraExtensions } from "./types";

/**
 * Clones error and enriches it for better Sentry fingerprinting and consistent context
 */
class GraphQlError extends AppError {
  public originalError: ClientError | null = null;
  public query: DocumentNode | null = null;
  public operationNames: string[] = [];
  public variables: Record<string, any> = {};

  // @ts-ignore
  public context: GraphQlErrorContext;
  public tags: Record<string, any> = {};

  public static isGraphQlError(error: unknown) {
    // @ts-ignore
    return !!error?.request?.query;
  }

  constructor(error: unknown) {
    if (!GraphQlError.isGraphQlError(error)) {
      // @ts-ignore
      throw new Error(`Error is not a GraphQL Error - ${error?.message ?? ""}`);
    }

    const originalError = error as ClientError;

    const query = parse((originalError?.request.query ?? "") as string);

    const queries = query.definitions.map((d) => ({
      query: d.loc?.source?.body,
      // @ts-ignore
      operationName: d?.name?.value as string | null | undefined,
    }));
    const operationNames = queries
      .map((q) => q.operationName)
      .filter((name) => !!name)
      .sort() as string[];
    const variables = originalError.request.variables ?? {};

    const firstError = originalError?.response?.errors?.[0];
    const extensions =
      firstError?.extensions as GraphQlResponseHasuraExtensions;

    const grahqlQlRespStatus = originalError.response?.status;
    const serverErrorMessage = firstError?.message;
    const hasuraInternalErrorMessage = extensions?.internal?.error;
    const laravelDebugEnabledErrorMessage =
      extensions?.internal?.response?.body?.message;
    const laravelErrorMessage = extensions?.userMessage;
    const laravelErrorUserMessage = extensions?.message;

    const fingerprintErrorMessage =
      laravelDebugEnabledErrorMessage ??
      laravelErrorMessage ??
      serverErrorMessage ??
      hasuraInternalErrorMessage ??
      laravelErrorUserMessage;

    const errorMsg = `Op: ${operationNames.join(
      ", "
    )} - ${fingerprintErrorMessage}`;

    super(errorMsg);

    // @ts-ignore
    const { request, response } = this.scrubData({
      request: originalError.request,
      response: originalError.response,
    });

    this.name = `GraphQL Error`;
    this.originalError = originalError;

    this.stack = this.originalError.stack;

    this.query = query;
    this.operationNames = operationNames;
    this.variables = variables;

    this.context = {
      // This could have sensitive data in it
      // originalError: this.originalError,
      request,
      response,
      query: request.query,
      operationNames: this.operationNames,
      variables: this.variables,
      errors: response.errors,
      grahqlQlRespStatus,
      serverErrorMessage,
      hasuraInternalErrorMessage,
      laravelDebugEnabledErrorMessage,
      laravelErrorMessage,
      laravelErrorUserMessage,
      extensions,
    };

    this.tags = {
      "graphql.responseStatus": grahqlQlRespStatus,
      "graphql.operationNames": this.operationNames.join(", "),
      "hasura.internalCode": extensions.code,
    };

    this.tags = _.chain(this.tags)
      .map((v, k) => ({ k, v }))
      .filter((o) => o !== undefined && o !== null)
      .keyBy("k")
      .mapValues("v")
      .value();
  }

  protected scrubData = (error: ClientError) => {
    return {
      request: { ...error.request },
      response: { ...error.response },
    };

    // NOTE: We use Axios for card numbers, so this doesn't apply here atm
    // const scrubbers: DataScrubber[] = [
    //   // CreditCardScrubber
    // ];

    // let input: DataScrubberArgs = {
    //   request: { ...error.request },
    //   response: { ...error.response },
    // };

    // scrubbers.forEach(function (scrubber) {
    //   input = scrubber.scrub(input);
    // });

    // return input;
  };
}

export { GraphQlError };
