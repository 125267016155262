import { useContext } from "react";

import AlertModal from "./AlertModal";
import AlertModalContext from "./AlertModalContext";

export default function GlobalAlertModal() {
  const { state, hide } = useContext(AlertModalContext);

  return (
    <AlertModal
      {...state}
      onRequestClose={() => {
        hide();
      }}
    />
  );
}

export { GlobalAlertModal };
