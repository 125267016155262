import { ReactNode, useCallback } from "react";
import { useNetwork } from "../../lib/useNetwork";
import { useAlertModal } from "../modals/alert-modal";
import { ReactComponent as IconWifi } from "../../../src/assets/icons/wifi-slash-solid.svg" ;
import { ReactComponent as IconWifiWhite } from "../../../src/assets/icons/wifi-slash-solid-white.svg" ;

export type Props = {
  contentTop?: ReactNode;
  contentBottom?: ReactNode;
  iconLeft?: ReactNode;
  onClick?: () => any;
  onClickDisabled?: () => any;
  disabled?: boolean;
  disabledIfOffline?: boolean;
  showOfflineIndicator?: boolean;
  type?: 
    `primary-blue` 
    | `primary-red` 
    | `secondary-white` 
    // | `secondary-white-transparent` 
    // | `secondary-white-blue`
    | `tertiary-transparent`
    // | `tertiary-transparent-white`
    // | `tertiary-transparent-red`
};

function FullWidthButton(props: Props) {
  const {
    contentTop,
    contentBottom,
    iconLeft,
    onClick = () => {}, 
    onClickDisabled = () => {}, 
    disabled = false,
    disabledIfOffline = false,
    showOfflineIndicator = false,
    type = `primary-blue`,
  } = props;

  const { isOnline } = useNetwork();
  const alert = useAlertModal();

  let buttonStyle: React.CSSProperties = {};

  let color = `#fff`;
  let backgroundColor = `#0A84FF`;
  let contentTopFontStyle: React.CSSProperties = {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 700,
    fontSize: `16px`,
    lineHeight: `20px`,
  };
  let contentBottomFontStyle: React.CSSProperties = {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 700,
    fontSize: `10px`,
    lineHeight: `14px`,
  };

  if (type === `tertiary-transparent`) {
    color = `#989FB4`;
    backgroundColor = `transparent`;
    contentTopFontStyle = {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      fontSize: `12px`,
      lineHeight: `18px`,
      textTransform: `uppercase`,
    };
  } else if (type === `primary-red`) {
    backgroundColor = `#FF3B30`;
  } else if (type === `secondary-white`) {
    color = `#0d2a48`;
    backgroundColor = `#fff`;
    buttonStyle = {
      borderWidth: `1px`,
      borderColor: `#E7EAF7`,
      borderStyle: `solid`,
    };
  }

  const onClickOffline = useCallback(() => {
    alert.show({
      title: [`No`, `Internet`],
      message: `You are currently offline. Some features in meterEZ will be disabled until your internet connection is restored.`
    });
  }, [alert]);

  return (
    <button
      onClick={(e) => {
        e.preventDefault();

        if (!isOnline && disabledIfOffline) {
          onClickOffline();
          return;
        }

        if (disabled) {
          onClickDisabled();
        } else if (onClick) {
          onClick();
        }
      }}
      // disabled={disabled}
      className={`w-full display-block flex flex-col items-center justify-center rounded-md border-none px-3 relative ${disabled || (disabledIfOffline && !isOnline) ? `opacity-50`:``}`}
      style={{
        height: `50px`,
        backgroundColor,
        color,
        ...buttonStyle,
      }}
    >
      {!!contentTop && (
        <div
          className={``}
          style={contentTopFontStyle}
        >
          {contentTop}
        </div>
      )}
      {!!contentBottom && (
        <div
          className={`text-xs leading-3`}
          style={contentBottomFontStyle}
        >
          {contentBottom}
        </div>
      )}

      {!!iconLeft && (
        <div
          className={`absolute top-0 left-0 bottom-0 pl-3 flex flex-col justify-center items-start opacity-80`}
          style={{
            
          }}
        >
          {iconLeft}
        </div>
      )}

      {(!isOnline) && showOfflineIndicator && disabledIfOffline && (
        <div
          className={`absolute top-0 right-0 bottom-0 pr-3 flex flex-col justify-center items-center opacity-80`}
          style={{

          }}
        >
          {![`primary-blue`, `primary-red`].includes(type) && (
            <IconWifi
              style={{
                height: `20px`,
              }}
            />
          )}
          {[`primary-blue`, `primary-red`].includes(type) && (
            <IconWifiWhite
              style={{
                height: `20px`,
              }}
            />
          )}
          <div
            className={`uppercase`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `10px`,
              lineHeight: `10px`,
              color: [`primary-blue`, `primary-red`].includes(type) ?
                `#fff` : `#0d2a48`,
              marginTop: `3px`,
            }}
          >
            Offline
          </div>
        </div>
      )}
    </button>
  );
}

export { FullWidthButton };