import { useState } from "react";
import faviconImgUrl from "../../../src/assets/android-chrome-192x192.png";
import { DownloadAppExplainer } from "../modals/download-app-explainer/DownloadAppExplainer";

function DownloadAppButton() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
        className={`flex uppercase mx-4`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `12px`,
          lineHeight: `14px`,
          color: `#0d2b48`,
        }}
      >
        <div
          className={`mr-2 relative`}
        >
          <img 
            src={faviconImgUrl} 
            alt="App Icon" 
            style={{
              width: `20px`,
              height: `auto`,
            }}
          />
        </div>
        <div
          className={`relative`}
          style={{
            top: `3px`,
          }}
        >
          Get the App
        </div>
      </button>
      
      <DownloadAppExplainer 
        open={open} 
        onClose={() => setOpen(!open)}
      />
    </>
  );
}

export { DownloadAppButton };