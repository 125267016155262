import _ from "lodash";
import { useCallback } from "react";
import { useErrorBoundary } from "react-error-boundary";
import * as appConfig from "../../config/config";

import { useErrorModal } from "./error-modal";
import {
  GraphQLAPIErrorResponse,
  GraphQLErrorResponse,
  parseReactQueryError,
} from "./utilities";
import { isJwtExpiredError, isJwtMissingError, reportError } from "../utils";
import { useMultiAuth } from "../../auth/core/useMultiAuth";

export type ErrorHandlerResult = {
  errorModal?: Parameters<ReturnType<typeof useErrorModal>["show"]>[0];
  report?: boolean;
  propogateToErrorBoundary?: boolean;
};

export type ErrorHandler = {
  /**
   * return false to opt out of handling the error, otherwise return an object with optional errorModal and report props
   */
  handler: (args: {
    appConfig: typeof appConfig;
    auth: ReturnType<typeof useMultiAuth>;
    error: unknown;
    // TODO Add types for ErrorHandler Context
    context?: any;
    graphQlErrorResponse: GraphQLAPIErrorResponse | GraphQLErrorResponse | null;
  }) => false | ErrorHandlerResult | Promise<false | ErrorHandlerResult>;
};

export const globalErrorHandlers: ErrorHandler[] = [];

export const defaultErrorModalHandler: ErrorHandler = {
  handler: ({ error }) => {
    return {
      errorModal: {
        error,
      },
      report: true,
      propogateToErrorBoundary: false,
    };
  },
};

export const defaultErrorBoundaryHandler: ErrorHandler = {
  handler: () => {
    return {
      report: true,
      propogateToErrorBoundary: true,
    };
  },
};

/**
 * By default, show an error modal and report the error, but don't propagate to the nearest ErrorBoundary
 *
 * This is typical behavior for React Query Mutations
 */
export const defaultErrorHandler: ErrorHandler = defaultErrorModalHandler;

// TODO Check errorHandler object reference and abort if the handler is already registered
export function registerErrorHandler(errorHandler: ErrorHandler) {
  globalErrorHandlers.push(errorHandler);
}

export type UseHandleErrorOptions = {
  errorHandlers?: ErrorHandler[];
};

export function useHandleError(options: UseHandleErrorOptions = {}) {
  const { errorHandlers: hookErrorHandlers = [] } = options;

  const auth = useMultiAuth();

  const { show } = useErrorModal();
  const { showBoundary: _propogateToErrorBoundary } = useErrorBoundary();

  /**
   * Returns true if handled
   */
  const handleError = useCallback(
    async (
      error: unknown,
      context?: any,
      errorHandlers: ErrorHandler[] = []
    ) => {
      // TODO Turn this into a proper ignroe list
      let ignoreError = false;
      if (isJwtExpiredError(error) || isJwtMissingError(error)) {
        ignoreError = true;
      }

      let isDefaultErrorHandler = true;
      let errorHandlerResult: ErrorHandlerResult = defaultErrorHandler.handler({
        appConfig,
        auth,
        error,
        context,
        graphQlErrorResponse: parseReactQueryError(error, false),
      }) as ErrorHandlerResult;

      const allHandlers = [
        ...errorHandlers,
        ...hookErrorHandlers,
        ...globalErrorHandlers,
      ];

      for (let i = 0; i < allHandlers.length; i++) {
        if (!isDefaultErrorHandler) {
          continue;
        }

        const errorHandler = allHandlers[i];

        const { handler } = errorHandler;

        const result = await handler({
          appConfig,
          auth,
          error,
          context,
          graphQlErrorResponse: parseReactQueryError(error, false),
        });

        if (result !== false) {
          errorHandlerResult = result;
          isDefaultErrorHandler = false;
        }
      }

      if (ignoreError) {
        reportError(error);

        return true;
      } else if (errorHandlerResult) {
        const { errorModal, report, propogateToErrorBoundary } =
          errorHandlerResult;

        if (errorModal) {
          show(errorModal);
        }

        // We only report here if we're not proagating it to the ErrorBoundary, because the ErrorBoundary also
        // automatically reports the error
        if (report && !propogateToErrorBoundary) {
          if (error instanceof Error) {
            const graphQlErrorResponse = parseReactQueryError(error, false);

            reportError(error);

            if (!appConfig.environment.isProduction) {
              console.error(graphQlErrorResponse ?? error);
            }
          }
        }

        if (error instanceof Error && propogateToErrorBoundary) {
          _propogateToErrorBoundary(error);
        }

        if (isDefaultErrorHandler) {
          const {
            environment: { isProduction },
          } = appConfig;

          if (!isProduction) {
            const graphQlErrorResponse = parseReactQueryError(error, false);
            console.error(graphQlErrorResponse ?? error);
          }
        }

        return true;
      }

      return false;
    },
    [auth, show, _propogateToErrorBoundary, hookErrorHandlers]
  );

  return handleError;
}
