import _ from "lodash";
import moment from "moment-timezone";

export type Storage = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  [key: string]: any;
};

let defaultStorage: Storage = {
  getItem: (key: string) => {
    throw new Error(`Missing Storage when calling getItem - key: ${key}`);
  },
  setItem: (key: string, value: string) => {
    throw new Error(`Missing Storage when calling setItem - key: ${key}`);
  },
  removeItem: (key: string) => {
    throw new Error(`Missing Storage when calling removeItem - key: ${key}`);
  },
};

// TODO Figure out how to get dynamic setting of storage in web vs RN
defaultStorage = {
  getItem: async (key: string) => {
    return localStorage.getItem(key);
  },
  setItem: async (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  removeItem: async (key: string) => {
    localStorage.removeItem(key);
  },
};

/**
 * Default TTL - 1 year
 */
const defaultTtl = 365 * 24 * 60 * 60;

export type Options<TValue extends any> = {
  storage?: Storage;
  namespace?: string;
  key: string;
  ttl?: number; // Seconds
  value?: TValue; // Must be able to be serialized into a JSON string
};

class Remember<TValue = any> {
  protected static defaultStorage: Storage = defaultStorage;

  protected storage: Storage;
  protected namespace?: string;
  protected key: string;
  protected fullKey: string;
  protected ttl?: number; // Seconds

  public static setDefaultStorage = (storage: Storage) => {
    Remember.defaultStorage = storage;
  };

  public constructor(options: Options<TValue>) {
    const { storage, namespace, key, ttl = defaultTtl } = options;

    this.storage = storage ?? defaultStorage;
    this.namespace = namespace;
    this.key = key;
    this.ttl = ttl;
    this.fullKey = namespace ? `${namespace}.${key}` : key;
  }

  protected getStorage = () => this.storage ?? Remember.defaultStorage;

  public clearValue = async () => {
    await this.getStorage().removeItem(this.fullKey);
  };

  public getValue = async () => {
    let value = undefined;

    try {
      const jsonValue = await this.getStorage().getItem(this.fullKey);

      if (jsonValue !== null) {
        const parsedValue = JSON.parse(jsonValue);

        if (_.isNumber(parsedValue?.expiryTimestamp)) {
          const expiryTimestamp = parsedValue.expiryTimestamp;
          const isExpired = moment.unix(expiryTimestamp).isBefore(moment());

          if (isExpired) {
            await this.clearValue();
          } else {
            value = parsedValue.value;
          }
        }
      }
    } catch (error: unknown) {
      console.error(error);
    }

    return value as TValue | undefined;
  };

  public setValue = async (value: TValue) => {
    try {
      const jsonValue = JSON.stringify({
        value,
        expiryTimestamp: moment.utc().clone().add(this.ttl, "seconds").unix(),
      });

      await this.getStorage().setItem(this.fullKey, jsonValue);
    } catch (error: unknown) {
      console.error(error);
    }
  };
}

export { Remember };
