import { Modal, SelectPicker } from "rsuite";
import { getCountries, getStates } from "../../../../../lib/utils";
import { useEffect } from "react";
import _ from "lodash";

export type Props = {
  selectedCountry?: string | null | undefined;
  onSelectCountry?: (selectedCountry?: string) => unknown;
  selectedState?: string | null | undefined;
  onSelectState?: (selectedState?: string) => unknown;
  onCancel?: () => unknown;
};

function CountryStateSelect(props: Props) {
  const {
    selectedCountry,
    onSelectCountry = () => {},
    selectedState,
    onSelectState = () => {}, 
  } = props;

  const countries = getCountries().map(country => ({ label: country.name, value: country.isoCode }));
  const states = getStates(selectedCountry).map(state => ({ label: state.name, value: state.isoCode }));

  useEffect(() => {
    if (!_.find(states, (state) => state.value === selectedState)) {
      onSelectState();
    }
  }, [selectedCountry]);

  return (
    <div
      className={`flex`}
      style={{

      }}
    >
      <div
        className={`basis-0 flex-grow mr-1 rounded-md flex items-center`}
        style={{
          height: `50px`,
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          backgroundColor: `#fff`,
        }}
      >
        <SelectPicker
          className={`w-full`}
          data={countries}
          value={selectedCountry}
          onChange={(selectedCountry) => {
            onSelectCountry(selectedCountry);
          }}
          onClean={() => {
            onSelectCountry();
            onSelectState();
          }}
          size="lg"
          appearance="subtle"
          renderMenuItem={(label, item) => {
            return (
              <span
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `20px`,
                }}
              >
                {item.label}
              </span>
            );
          }}
          label={<span
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `14px`,
              lineHeight: `20px`,
              // textTransform: "uppercase",
              opacity: `70%`,
            }}
          >
            Country
          </span>}
          labelKey={`value`}
          renderValue={(value, item, selectedElement) => {
            return (
              <span
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `20px`,
                }}
              >
                {selectedElement}
              </span>
            );
          }}
        />
      </div>
      <div
        className={`basis-0 flex-grow ml-1 rounded-md flex items-center`}
        style={{
          height: `50px`,
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          backgroundColor: `#fff`,
        }}
      >
        <SelectPicker
          className={`w-full`}
          data={states}
          value={selectedState}
          onChange={(selectedState) => {
            onSelectState(selectedState);
          }}
          onClean={() => {
            onSelectState();
          }}
          size="lg"
          appearance="subtle"
          renderMenuItem={(label, item) => {
            return (
              <span
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `20px`,
                }}
              >
                {item.label}
              </span>
            );
          }}
          label={<span
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `14px`,
              lineHeight: `20px`,
              // textTransform: "uppercase",
              opacity: `70%`,
            }}
          >
            State
          </span>}
          labelKey={`value`}
          renderValue={(value, item, selectedElement) => {
            return (
              <span
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `20px`,
                }}
              >
                {selectedElement}
              </span>
            );
          }}
        />
      </div>
    </div>
  );
}

export { CountryStateSelect };