import _ from "lodash";
import { DataScrubber, ErrorEvent } from "./types";

export const NoiseReducer: DataScrubber = {
  scrub: (event, hint) => {
    const _event = { ...event };

    if (isCardDeclinedError(_event)) {
      console.log(`Dropping PAYMENT_DECLINED_EXCEPTION Event`);

      return null;
    }

    if (isInvalidCardNumbersError(_event)) {
      console.log(`Dropping Card number is invalid Event`);

      return null;
    }

    return _event;
  },
};

function isCardDeclinedError(event: ErrorEvent) {
  return (
    // @ts-ignore
    event?.extra?.response?.errors?.[0]?.extensions?.code ===
    "PAYMENT_DECLINED_EXCEPTION"
  );
}

function isInvalidCardNumbersError(event: ErrorEvent) {
  // @ts-ignore
  return _.toString(event?.extra?.response?.data?.details?.[0] ?? "").includes(
    "Card number is invalid"
  );
}
