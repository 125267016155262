import { ReactComponent as Logo } from '../../../src/assets/logo-meterez.svg';
import { ReactComponent as LogoGrayscale } from '../../../src/assets/logo-meterez-grayscale.svg';

export type Props = {
  type?: `black-blue` | `grayscale`;
  style?: React.CSSProperties;
};

function LogoHeader(props: Props) {
  const { type = `black-blue`, style } = props;

  return (
    <div
      className={`flex justify-center`}
      style={{
        transform: `translate3d(0,0,0)`,
        ...style,
      }}
    >
      {type === `black-blue` && (
        <Logo
          style={{
            transform: `translate3d(0,0,0)`,
          }}
        />
      )}
      {type === `grayscale` && (
        <LogoGrayscale
          style={{
            transform: `translate3d(0,0,0)`,
            ...style,
          }}
        />
      )}
    </div>
  );
}

export { LogoHeader };