import _ from "lodash";

export type GraphQLErrorResponse = {
  message: string;
  extensions: Record<string, any> & {
    // Signal to know for sure that the error response is a known App Server API Error Response
    //  - If the extensions object is available, it's assumed it's some other Hasura error
    isApplicationGraphQlError: boolean;
  };
};

export type GraphQLAPIErrorResponse = {
  message: string;
  extensions: GraphQLErrorResponse["extensions"] & {
    // Application Error Code - Always provided if available
    code: string | number | null;
    // Actual error message - Null if sensitiveDataFiltered = true
    message: string | null;
    // Stack Trace - Null if sensitiveDataFiltered = true or unavailable
    trace: string | null;
    // PHP Exception Error Code (generally not used) - Null if sensitiveDataFiltered = true or unavailable
    errorCode: string | number | null;
    // Code line on server where error occurred - Null if sensitiveDataFiltered = true or unavailable
    line: number | null;
    // User-Friendly Message - Null if unavailable
    userMessage: string;
    // Error context - Data contained is error specific and sensitive data will be ommitted if sensitiveDataFiltered = true
    context: Record<string, any>;
    // true if sensitive data is filtered (typically in production environments)
    sensitiveDataFiltered: boolean;
  };
};

/**
 * NOTE: All the react-query hooks use axios, so the error responses are axios errors
 *  - Will rethrow if not an axios error
 *  - Will return null if graphql errors are not found or are in an unexpected format
 *
 * @param error
 * @returns
 */
export function parseReactQueryError(
  error?: unknown,
  rethrowUnrecognized: boolean = true
): GraphQLAPIErrorResponse | GraphQLErrorResponse | null {
  // @ts-ignore
  if (!_.isObject(error?.response)) {
    if (rethrowUnrecognized) {
      throw error;
    } else {
      return null;
    }
  }

  if (
    // @ts-ignore
    error?.response?.errors?.[0]?.extensions?.isApplicationGraphQlError !== true
  ) {
    // @ts-ignore
    if (_.isPlainObject(error?.response?.errors?.[0]?.extensions)) {
      const graphQlErrorResponse = // @ts-ignore
        error.response.errors[0] as GraphQLErrorResponse;
      // Manually add this and set it to false so the consumer can reliably check it
      graphQlErrorResponse.extensions.isApplicationGraphQlError = false;

      return { ...graphQlErrorResponse };
    }

    return null;
  }

  const graphQlErrorResponse = // @ts-ignore
    error?.response?.errors?.[0] as GraphQLAPIErrorResponse;

  return { ...graphQlErrorResponse };
}
