import { useEffect } from "react";
import { isValidInterval } from "../../../../lib/utils";
import usePrevious from "../../../../lib/usePrevious";

export type Args = {
  enabled?: boolean;
  setInitial?: boolean;
  time?: number | null | undefined;
  parkable?: {
    price: number;
    price_interval: number;
    price_interval_unit: string;
    time_limit: number;
    time_limit_unit: string;
    [key: string]: any;
  } | null | undefined,
  onChange?: () => any;
};

function useResetOnParkableChange(args: Args) {
  const { enabled = true, setInitial = true, time, parkable, onChange = () => {} } = args;

  const rateFingerprint = `${parkable?.price}|${parkable?.price_interval}|${parkable?.price_interval_unit}|${parkable?.time_limit}|${parkable?.time_limit_unit}`;

  const prevRateFingerprint = usePrevious(rateFingerprint);

  // Set a default value if it's not set yet and attempt to reset values if meter/rate changes
  useEffect(() => {
    if (!enabled) return;

    const pendingSetInitial = setInitial && !!parkable && typeof time !== 'number';

    const timeSelectionIsInvalid =
      time &&
      parkable &&
      !isValidInterval(
        parkable.price_interval,
        parkable.price_interval_unit,
        time
      );

    const parkableRateChanged =
      prevRateFingerprint !== undefined &&
      rateFingerprint !== prevRateFingerprint;

    if (pendingSetInitial || timeSelectionIsInvalid || parkableRateChanged) {
      onChange();
    }
  }, [enabled, time, rateFingerprint]);
}

export { useResetOnParkableChange}