import { ReactNode } from "react";

export type Props = {
  style?: React.CSSProperties;
  className?: string;
  children: ReactNode;
};

function Heading1(props: Props) {
  const { style, className, children } = props;

  return (
    <h1
      className={`text-center mb-2 ${className}`}
      style={{
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        fontSize: `16px`,
        lineHeight: `20px`,
        color: `#0E2A48`,
        ...style,
      }}
    >
      {children}
    </h1>
  );
}

export { Heading1 };