import _ from "lodash";
import { UserQuery } from "../../../generatedQueries";
import { Auth } from "../../../auth/core/Auth";

export type LogOutReasonCode =
  | "USER_MISSING"
  | "IDENTITY_MISSING"
  | "CREDENTIAL_MISMATCH"
  | "USER_PENDING_DELETION"
  | "USER_DELETED"
  | "USER_LOCKED"
  | "USER_FRAUDULENT";

export type Args<TUser extends UserQuery["users"][number] | null> = {
  user: TUser;
  // Return false to abort logout
  beforeLogOut?: (
    user: TUser,
    reason: LogOutReasonCode
  ) => Promise<boolean | null | undefined | void>;
};

// TODO Implement log out messages on auth screen so the user knows why they were logged out
// TODO Look into ways we can graceful handle things like phone number change without logging the user out

/**
 * This catches the response when fetching the current user and checks it for reasons the user
 * should be promptly logged out. Possibilities are listed below in code comments.
 *
 * This is implemented in src/queries/users/fetchUser.ts until we find a better place to put it
 * where it will catch the user response before it makes it to any parts of the app that
 * might make poor assumptions about the state of the identity / user objects
 *
 * @returns
 */
async function maybeLogOutUser<TUser extends UserQuery["users"][number] | null>(
  args: Args<TUser>
): Promise<TUser | null> {
  const { user, beforeLogOut = () => {} } = args;

  const auth = await Auth.getInstance();

  const session = await auth.getSession();

  if (!session) {
    return null;
  }

  // Logged In Session Data

  const identityId = session.sessionDetails.identityId;
  const provider =
    session.sessionDetails.tokenPayload["https://hasura.io/jwt/claims"][
      "x-hasura-provider"
    ];
  const credential =
    session.sessionDetails.tokenPayload["https://hasura.io/jwt/claims"][
      "x-hasura-credential"
    ];
  const identity = _.find(user?.identities, { id: identityId });

  // User object missing

  if (!user) {
    const abort = (await beforeLogOut(user, "USER_MISSING")) === false;

    if (!abort) {
      console.log(`maybeLogOutUser - Logging User Out - User object missing`, {
        user,
        session,
      });

      // TODO
      await auth.logOut();

      return null;
    }
  }

  // Identity object missing
  // - Possible Scenarios
  //   - User changed their phone number, so we need to delete the old identity
  //     in case that phone number gets recycled

  if (!identity) {
    const abort = (await beforeLogOut(user, "IDENTITY_MISSING")) === false;

    if (!abort) {
      console.log(
        `maybeLogOutUser - Logging User Out - Identity object missing`,
        {
          user,
          session,
        }
      );

      await auth.logOut();

      return null;
    }
  }

  // The credential in the identity no longer matches the credential in the token
  // - Possible Scenarios
  //   - User changed their phone number or email, so we updated it in the Identity object

  if (identity && ["phone_number", "email"].includes(provider)) {
    const identityCredential =
      provider === "phone_number" ? identity?.phone_number : identity?.email;

    if (identityCredential !== credential) {
      const abort = (await beforeLogOut(user, "CREDENTIAL_MISMATCH")) === false;

      if (!abort) {
        console.log(
          `maybeLogOutUser - Logging User Out - The credential in the identity no longer matches the credential in the token - Token: ${credential}, Identity: ${identityCredential}`,
          {
            user,
            session,
          }
        );

        await auth.logOut();

        return null;
      }
    }
  }

  // Account Anonymized (aka. Soft-Deleted)

  if (user?.anonymized_at) {
    const abort = (await beforeLogOut(user, "USER_DELETED")) === false;

    if (!abort) {
      console.log(
        `maybeLogOutUser - Logging User Out - Account Marked for Anonymization (aka. Soft-Deletion) or Anonymized`,
        {
          user,
          session,
        }
      );

      await auth.logOut();

      return null;
    }
  }

  // Account Marked for Anonymization (aka. Soft-Deletion)

  if (user?.pending_deletion_at) {
    const abort = (await beforeLogOut(user, "USER_PENDING_DELETION")) === false;

    if (!abort) {
      console.log(
        `maybeLogOutUser - Logging User Out - Account Marked for Anonymization (aka. Soft-Deletion) or Anonymized`,
        {
          user,
          session,
        }
      );

      await auth.logOut();

      return null;
    }
  }

  // Account locked

  if (user?.locked_at) {
    const abort = (await beforeLogOut(user, "USER_LOCKED")) === false;

    if (!abort) {
      console.log(`maybeLogOutUser - Logging User Out - Account locked`, {
        user,
        session,
      });

      await auth.logOut();

      return null;
    }
  }

  // Account marked as Fraudulent

  if (user?.fraud_review_status === "FAILED") {
    const abort = (await beforeLogOut(user, "USER_FRAUDULENT")) === false;

    if (!abort) {
      console.log(
        `maybeLogOutUser - Logging User Out - Account marked as Fraudulent`,
        {
          user,
          session,
        }
      );

      await auth.logOut();

      return null;
    }
  }

  return user;
}

export { maybeLogOutUser };
