function BackgroundGradient() {
  return (
    <div
      className={`fixed top-0 right-0 left-0`}
      style={{
        height: `100dvh`,
        // background: `rgb(255,255,255)`,
        background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,242,253,1) 100%)`,
        zIndex: -1,
      }}
    />
  );
}

export { BackgroundGradient };