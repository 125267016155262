import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { VehiclesDocument, VehiclesQuery } from "../../generatedQueries";
import queryKeys from "../query-keys";
import { simulateNetworkError } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type Vehicle = VehiclesQuery["vehicles"][number];

async function fetchVehicles(args: { signal?: AbortSignal | null }) {
  const { signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: VehiclesDocument,
    variables: {},
    requestHeaders,
    signal,
  })).vehicles;
}

export default function useVehicles() {
  const auth = useMultiAuth();
  // const handleError = useDefaultQueryErrorHandler();

  const query = useQuery<VehiclesQuery["vehicles"]>(
    queryKeys.Vehicles(auth.session?.sessionDetails.userId ?? -1),
    async ({ signal }) => {
      return await fetchVehicles({ signal });
    },
    {
      enabled: !!auth.session,
      staleTime: 5 * 60 * 1000, // 5min
      // onError: (error) => {
      //   handleError(error);
      // },
    }
  );

  // useThrowQueryErrorToBoundary(query);

  const vehicles = useMemo(() => {
    if (!_.isArray(query.data)) return [];

    return _.chain(query.data)
      .uniqBy("id")
      .filter((v) => !v.deleted_at)
      .value();
  }, [query.data]);

  return {
    query,
    vehicles,
  };
}
