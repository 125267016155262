import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { AuthProvider } from "../../types";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  provider: AuthProvider;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class OAuthSucceeded extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { provider, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.AUTH,
      type: `OAUTH_SUCCEEDED`,
      data: {
        provider,
      },
    });
  }
}

export { OAuthSucceeded };
