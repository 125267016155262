export type Props = {
  value?: boolean;
  onChange?: (value?: boolean) => any;
  disabled?: boolean;
  isError?: boolean;
};

function Switch(props: Props) {
  const { value, onChange = () => {}, disabled = false, isError = false } = props;

  return (
    <button
      className={`flex items-center ${value ? `justify-start`:`justify-end`} block relative rounded-full ${disabled ? `opacity-50`:``}`}
      style={{
        height: `21px`,
        width:  `50px`,
        // borderWidth: `1px`,
        // borderColor: `#E7EAF7`,
        backgroundColor: `#E7EAF7`,
        paddingLeft: `4px`,
        paddingRight: `4px`,
      }}
      onClick={(e) => {
        e.preventDefault();
        onChange(!value);
      }}
      disabled={disabled}
    >
      {value && (
        <span
          className={`uppercase`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 900,
            fontSize: `12px`,
            lineHeight: `14px`,
            color: isError ? `#c4302a` : `#989FB4`,
          }}
        >
          On
        </span>
      )}

      <div
        className={`rounded-full absolute top-0 ${value ? `right-0`:`left-0`}`}
        style={{
          height: `21px`,
          width:  `21px`,
          backgroundColor: isError ? `#c4302a` : (value ? `#0A84FF` : `#989FB4`),
        }}
      />

      {!value && (
        <span
          className={`uppercase`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 900,
            fontSize: `12px`,
            lineHeight: `14px`,
            color: isError ? `#c4302a` : `#989FB4`,
          }}
        >
          Off
        </span>
      )}
    </button>
  );
}

export { Switch };