import { ReactNode } from "react";
import { SessionInProgressContext } from "./SessionInProgressContext";
import { useProvideSessionInProgress } from "./useProvideSessionInProgress";

export type Props = {
  children: ReactNode;
};

function SessionInProgressContextProvider(props: Props) {
  const { children } = props;

  const contextValue = useProvideSessionInProgress();

  return (
    <SessionInProgressContext.Provider value={contextValue}>
      {children}
    </SessionInProgressContext.Provider>
  );
}

export { SessionInProgressContextProvider };