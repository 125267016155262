import { useEffect, useState } from "react";
import { LogoHeader } from "../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../components/layouts/ScreenLayout";
import { ChooseProvider } from "../components/choose-provider/ChooseProvider";
import { Login as LoginWithPhoneNumber } from "../components/providers/phone-number/Login";
import { Login as LoginWithEmail } from "../components/providers/email/Login";
import { useMultiAuth } from "../../core/useMultiAuth";
import { useLocation, useSearchParams } from "react-router-dom";
import env from "../../../config/env";

export type State = {
  state: `IDLE` | `IN_PROGRESS` | `CANCELLED` | `ERROR` | `SUCCESS`;
  errorMessage: string | null;
};

function AuthScreen() {
  const { providers } = useMultiAuth();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);

  const { lastSelected, setLastSelected } = providers;

  // const searchParams = new URLSearchParams(window.location.search);
  const urlError = searchParams.get(`error`) ?? null as string | null;
  const urlInternalError = searchParams.get(`internal_error`) ?? null as string | null;

  const [state, setState] = useState<State>({
    state: `IDLE`,
    errorMessage: urlInternalError ?? urlError ?? null,
  });

  useEffect(() => {
    let replaceUrl: boolean = false;

    if (searchParams.has(`error`)) {
      searchParams.delete('error');
      replaceUrl = true;
    }

    if (searchParams.has(`internal_error`)) {
      searchParams.delete('internal_error');
      replaceUrl = true;
    }

    if (replaceUrl) {
      setSearchParams(searchParams);
    }
  }, []);

  const { state: uiState, errorMessage } = state;

  // let statusMessage = null;
  // if (uiState === `IN_PROGRESS`) {
  //   statusMessage = "Logging in...";
  // } else if (uiState === `SUCCESS`) {
  //   statusMessage = "Success! One Moment...";
  // }

  return (
    <ScreenLayout
      header={<LogoHeader />}
      content={
        <div
        className={`w-full`}
          style={{

          }}
        >
      {lastSelected === null && (
        <ChooseProvider
          providers={providers}
          errorMessage={errorMessage}
          onChoose={(provider) => {
            setLastSelected(provider);
          }}
          onChangeState={(provider, uiState, errorMessage) => {
            setState((state) => ({
              ...state,
              state: uiState,
              errorMessage,
            }));
          }}
        />
      )}

      {lastSelected === `phone_number` && (
        <LoginWithPhoneNumber
          onCancel={() => {
            setLastSelected(null);
          }}
          onChangeState={(uiState, errorMessage) => {
            setState((state) => ({
              ...state,
              state: uiState,
              errorMessage,
            }));
          }}
        />
      )}

      {lastSelected === `email` && (
        <LoginWithEmail
          onCancel={() => {
            setLastSelected(null);
          }}
          onChangeState={(uiState, errorMessage) => {
            setState((state) => ({
              ...state,
              state: uiState,
              errorMessage,
            }));
          }}
        />
      )}

      {(uiState === `IN_PROGRESS` || uiState === `SUCCESS`) && (
        <div
          className={`absolute top-0 right-0 bottom-0 left-0`}
          style={{
            // backgroundColor: `rgba(255,255,255,0.5)`,
          }}
        />
      )}
        </div>
      }
      showBottomBar
      />
  );
}

export { AuthScreen };