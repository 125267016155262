import { useMutation } from "@tanstack/react-query";
import { request } from 'graphql-request';

import { StoreVaultedCardAccountDocument, StoreVaultedCardAccountMutation, StoreVaultedCardAccountMutationVariables } from "../../generatedQueries";
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { StoreVaultedCardAccountRequest } from "../../events/event-types/payment-methods/StoreVaultedCardAccountRequest";
import { StoreVaultedCardAccountResponse } from "../../events/event-types/payment-methods/StoreVaultedCardAccountResponse";
import { simulateValidationError } from "../../lib/error-handling/error-handlers/mxm/mxmUtils";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export default function useStoreVaultedCardAccount() {
  const mutation = useMutation<
    StoreVaultedCardAccountMutation["storeVaultedCardAccount"],
    unknown,
    StoreVaultedCardAccountMutationVariables
  >(
    async (variables) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: StoreVaultedCardAccountDocument,
        variables,
        requestHeaders,
        // signal,
      })).storeVaultedCardAccount;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      // ...options,

      onMutate: (variables) => {
        event(
          new StoreVaultedCardAccountRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new StoreVaultedCardAccountResponse({
            mutationResponse: resp,
          })
        );
      },
    }
  );

  return {
    mutation,
    alreadyExists: mutation.data?.alreadyExists ?? null,
    paymentMethod: mutation.data?.paymentMethod ?? null,
  };
}
