import _ from "lodash";
import { useCallback, useEffect, useMemo } from "react";

import { AuthProvider, UnauthenticatedCredentials } from "./core/types";
import { auth } from "../config/config";
import { Remember } from "../lib/remember";
import { useRemember } from "../lib/useRemember";

export const storageKey =
  auth.unauthenticatedCredentials.storageKey;

export type Data = {
  unauthenticatedData: UnauthenticatedCredentials;
};

const remember = new Remember<Data>({
  key: auth.unauthenticatedCredentials.storageKey,
  namespace: auth.unauthenticatedCredentials.storageKeyNamespace,
});

export async function getUnauthenticatedCredentials() {
  return await remember.getValue();
}

function useUnauthenticatedCredentials() {
  const { isLoaded, value, getValue, setValue } = useRemember<Data>({
    remember,
  });

  const get = useCallback(async () => {
    return await getValue();
  }, [getValue]);

  const set = useCallback(
    async (authProvider: Omit<AuthProvider, `lastUsed`>) => {
      const fullAuthProvider: AuthProvider = {
        ...authProvider,
        lastUsed: Date.now() / 1000,
      };

      const value = await getValue();
      const prevKnownProviders = [
        ...(value?.unauthenticatedData.knownProviders ?? []),
        fullAuthProvider,
      ];

      const knownProviders: Record<string, AuthProvider> = {};

      prevKnownProviders.forEach((knownProvider) => {
        const { provider, credential, authenticated, lastUsed } = knownProvider;

        if (knownProviders[`${provider}|${credential}`]) {
          if (knownProviders[`${provider}|${credential}`].lastUsed < lastUsed) {
            knownProviders[`${provider}|${credential}`].lastUsed = lastUsed;
          }

          if (authenticated) {
            knownProviders[`${provider}|${credential}`].authenticated = true;
          }
        } else {
          knownProviders[`${provider}|${credential}`] = knownProvider;
        }
      });

      const unauthenticatedData = {
        knownProviders: _.chain(knownProviders)
          .values()
          .sortBy(`lastUsed`)
          .reverse()
          // Take 4 most recent (which should be the max anyway)
          .slice(0, 4)
          .reverse()
          .value(),
      };

      await setValue({
        ...value,
        unauthenticatedData,
      });
    },
    [getValue, setValue]
  );

  return useMemo(() => {
    return {
      get,
      set,
      isLoaded,
      value,
    };
  }, [get, set, isLoaded, value]);
}

export { useUnauthenticatedCredentials };
