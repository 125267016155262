import moment, { Moment } from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { useLiveNow } from "./useLiveNow";
import { divide } from "./utils";

export type State = {
  lastTryAt: Moment | null;
  numTries: number;
};

export type Args = {
  initialTries?: number;
  maxTries?: number;
  /** @var delay Seconds */
  delay?: number;
};

function useResendWait(args?: Args) {
  const { initialTries = 0, maxTries = 5, delay = 10 } = args ?? {};

  const now = useLiveNow();

  const [state, setState] = useState<State>({
    lastTryAt: initialTries > 0 ? moment.utc() : null,
    numTries: initialTries,
  });

  const { lastTryAt, numTries } = state;

  const incrementTries = useCallback(() => {
    setState((state) => ({
      ...state,
      lastTryAt: moment.utc(),
      numTries: state.numTries + 1,
    }));
  }, []);

  const reset = useCallback(() => {
    setState({
      lastTryAt: null,
      numTries: 0,
    });
  }, []);

  let nextTryAt: Moment | null = null;
  let canResend = false;
  let canResendIn = 0;
  let canResendNow = false;

  if (numTries < maxTries) {
    canResend= true;

    if (lastTryAt) {
      nextTryAt = lastTryAt.clone().add(delay, `seconds`);
    } else {
      nextTryAt = now;
    }

    canResendIn =
      nextTryAt.isSameOrAfter(moment.utc())
        ? Math.floor(divide(nextTryAt.clone().diff(now), 1000))
        : 0;

    canResendNow = canResendIn === 0;
  }

  return useMemo(() => {
    return {
      ...state,
      now,
      nextTryAt,
      canResend,
      canResendIn,
      canResendNow,
      incrementTries,
      reset,
    };
  }, [
    state,
    now,
    nextTryAt,
    canResend,
    canResendIn,
    canResendNow,
    incrementTries,
    reset,
  ]);
}

export { useResendWait };