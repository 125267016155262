import { ReactNode, useEffect, useRef } from "react";

import { event } from "../core/helpers";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { AuthSessionChanged } from "../event-types/auth/AuthSessionChanged";
import env from "../../config/env";

export type Props = {
  children?: ReactNode;
};

function DispatchAuthSessionChanged(props: Props) {
  const { children = null } = props;

  const { onChangeSession } = useMultiAuth();

  const wasSignedInRef = useRef<boolean | null>(null);
  useEffect(() => {
    const remove = onChangeSession(async (session) => {
      try {
        if (wasSignedInRef.current && !session) {
          event(
            new AuthSessionChanged({
              isLoggedIn: false,
            })
          );
        } else if (wasSignedInRef.current === false && !!session) {
          event(
            new AuthSessionChanged({
              isLoggedIn: true,
              sessionDetails: session.sessionDetails,
            })
          );
        }

        if (env(`DEBUG`) && (!!session) !== wasSignedInRef.current) {
          console.log(`Auth - Session`, session);
        }
      } catch (e: unknown) {
        reportError(e);
      }

      wasSignedInRef.current = !!session;
    });

    return () => {
      remove();
    };
  }, []);

  return children;
}

export { DispatchAuthSessionChanged };
