import { useNavigate } from "react-router-dom";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useEffect } from "react";
import { TimePicker } from "../components/time/TimePicker";
import useParkable from "../../../../queries/parkables/useParkable";
import { formatMoney, intervalAsSeconds, isValidInterval } from "../../../../lib/utils";
import { Progress } from "../components/Progress";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import { useParkableTransactionPriceInfo } from "../../../../lib/useParkableTransactionPriceInfo";
import { ParkableCard } from "../../../../components/cards/ParkableCard";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";
import usePrevious from "../../../../lib/usePrevious";
import { useResetOnParkableChange } from "./useResetOnParkableChange";
import { ErieEclipseRateExplainer } from "../components/ErieEclipseRateExplainer";

export type Props = {

};

// TODO Make sure everything is based on the parkableTransaction snapshot for time extensions

function Time(props: Props) {
  const {  } = props;

  const navigate = useNavigate();
  const session = useSessionInProgress();

  const parkableId = session.sessionInProgress?.sessionParameters.parkableId;

  const { parkable } = useParkable({ parkableId, poll: true });

  const { price, fee, total, walletSavings } = useParkableTransactionPriceInfo({
    parkable,
    time: session.sessionInProgress?.sessionParameters.time,
    walletFee: parkable?.parking_authority.wallet_fee,
    cardFee: parkable?.parking_authority.card_fee,
    applyFee: true,
  });

  useEffect(() => {
    if (!session.stateRef.current.sessionInProgress) {
      navigate("/sessions", { replace: true });
      return;
    }

    const { parkableId, vehicleId } = session.stateRef.current.sessionInProgress.sessionParameters;

    if (!parkableId || !vehicleId) {
      navigate("/sessions", { replace: true });
    }
  }, []);

  useResetOnParkableChange({
    enabled: !!session?.sessionInProgress?.idempotentKey,
    time: session.sessionInProgress?.sessionParameters.time,
    parkable,
    onChange: () => {
      session.set({
        time: intervalAsSeconds(
          parkable.price_interval,
          parkable.price_interval_unit
        ),
      });
    }
  });

  if (!session.sessionInProgress) {
    return null;
  }

  if (!parkableId) return null;

  const time = parkable ? 
    (
      isValidInterval(
        parkable.price_interval,
        parkable.price_interval_unit,
        session.sessionInProgress.sessionParameters.time,
      ) ? 
        session.sessionInProgress.sessionParameters.time
        : null
    ) : null;

  return (
    <ScreenLayout
      header={
        <TitleHeader title={[`New`, `Session`]} />
      }
      content={
        <div
          className={`w-full flex flex-grow flex-col items-center`}
        >
          <Progress />

          <div
            className={`w-full`}
          >
            <div
              className={`text-center mb-4`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `16px`,
                lineHeight: `20px`,
                color: `#0E2A48`,
              }}
            >
              Choose your Parking Duration
            </div>

            <div
              className={`mb-6`}
              style={{ position: 'relative' }}
            >
              <ParkableCard
                parkableId={parkableId}
              />

              <ErieEclipseRateExplainer parkable={parkable} />
              
            </div>
            
            {!!parkable && (
              <TimePicker
                parkable={parkable}
                time={time}
                onChangeTime={(time) => {
                  session.set({
                    time,
                  });
                }}
              />
            )}
          </div>

          <div
            className={`w-full flex flex-col items-center mt-4`}
          >
            <FullWidthButton
              contentTop={`Continue`}
              contentBottom={`${formatMoney(price)} + Processing Fee`}
              onClick={() => session.navigate(`PAYMENT`)}
              disabled={!time}
            />

            <div className={`mb-4`} />

            <FullWidthButton
              contentTop={`Cancel Parking`}
              type={`tertiary-transparent`}
              onClick={() => {
                event(
                  new ButtonPress({
                    buttonName: `Cancel Parking`,
                    buttonSlug: `CREATE_PARKABLE_TRANSACTION.CANCEL`,
                    subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                  })
                );

                session.cancel();
                navigate(`sessions`);
              }}
              // disabled={...}
            />
          </div>
        </div>
      }
    />
  );
}

export { Time };