import { reportError } from "../../lib/utils";
import { OperatingSystem } from "./types";

function getOperatingSystem(): OperatingSystem | null {
  try {
    // @ts-expect-error Some aren't known types on window
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return `android`;
    }

    if (/iphone/i.test(userAgent) || /ipad/i.test(userAgent) || /ipod/i.test(userAgent)) {
      return `ios`;
    }

    if (/windows phone/i.test(userAgent)) {
        return `windows_phone`;
    }

    // if (navigator.appVersion.indexOf("Win") !== -1) {
    //   return `windows`
    // }

    if (/macintosh/i.test(userAgent)) {
      return `macos`;
    }

    // if (navigator.appVersion.indexOf("Linux")!=-1) {
    //   return `linux`;
    // }
  } catch (error: unknown) {
    reportError(error);
  }
  
  return null;
}

export { getOperatingSystem }