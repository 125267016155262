import _ from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import ErrorModalContext, { defaultState } from "./ErrorModalContext";
import type { ErrorModalErrorProps } from "./types";

type Props = {
  children?: any;
};

export default function ErrorModalProvider(props: Props) {
  const [state, setState] = useState<ErrorModalErrorProps>(defaultState);

  const isShowingRef = useRef(false);

  const onCloseRef = useRef<Function>();

  const show = useCallback(
    (
      args: ErrorModalErrorProps & {
        onClose?: () => any;
      }
    ) => {
      if (isShowingRef.current === true) return;

      const { error, errorTitle, errorMessage, onClose } = args;

      isShowingRef.current = true;
      setState({
        error,
        errorTitle,
        errorMessage,
      });

      onCloseRef.current = onClose;
    },
    [setState]
  );

  const hide = useCallback(() => {
    setState(defaultState);
    isShowingRef.current = false;

    if (_.isFunction(onCloseRef.current)) {
      onCloseRef.current();
      delete onCloseRef.current;
    }
  }, [setState]);

  const context = useMemo(() => {
    return {
      state,
      show,
      hide,
    };
  }, [state, show, hide]);

  return (
    <ErrorModalContext.Provider value={context}>
      {props.children}
    </ErrorModalContext.Provider>
  );
}

export { ErrorModalProvider };
