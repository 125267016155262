import { useQuery } from "@tanstack/react-query";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { ParkableDocument, ParkableQuery } from "../../generatedQueries";
import queryKeys from "../query-keys";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type Parkable = ParkableQuery["parkables_by_pk"];

type Options = {
  parkableId: number | null | undefined;
  poll?: boolean;
};

async function fetchParkable(args: { parkableId: number, signal?: AbortSignal | null }) {
  const { parkableId, signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: ParkableDocument,
    variables: {
      parkableId: parkableId ?? -1,
    },
    requestHeaders,
    signal,
  })).parkables_by_pk;
}

export default function useParkable({ parkableId, poll = false }: Options) {
  const auth = useMultiAuth();

  const query = useQuery<ParkableQuery["parkables_by_pk"]>(
    queryKeys.Parkable(parkableId ?? -1),
    async ({ signal }) => {
      return await fetchParkable({ parkableId: parkableId  as number, signal });
    },
    {
      enabled: !!auth.session && !!parkableId,
      staleTime: 1 * 60 * 1000, // 1 min,
      refetchInterval: poll ? 5000 : false,
      // onError: (error) => {
      //   handleError(error);
      // },
    }
  );

  return {
    query,
    parkable: query.data ?? null,
  };
}
