import { useEffect, useMemo, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import uuid from 'uuid-random';

export type API = {
  reset: () => any;
};

export type Props = {
  disabled?: boolean;
  inputRef?: (ref: API | null) => any;
  onChange?: (loginCode: string) => any;
  onComplete?: (loginCode: string) => any;
};

function LoginCodeInput(props: Props) {
  const { disabled, inputRef = () => {}, onChange = () => {}, onComplete = () => {} } = props;

  const [mountKey, setMountKey] = useState(uuid());

  const api = useMemo(() => {
    return {
      reset: () => setMountKey(uuid()),
    };
  }, [setMountKey]);

  useEffect(() => {
    inputRef(api);

    return () => {
      inputRef(null);
    }
  }, [api]);

  return (
    <ReactCodeInput
      key={mountKey}
      type={`number`}
      fields={4}
      onChange={onChange}
      onComplete={onComplete}
      fieldWidth={50}
      fieldHeight={50}
      autoFocus
      // title={null}
      // loading={false}
      className={`login-code-input`}
      // values={[]}
      // placeholder={null}
      disabled={disabled}
    />
  );
}

export { LoginCodeInput };