import { ReactNode } from "react";
import { FullWidthButton } from "../../components/buttons/FullWidthButton";
import { ReactComponent as Logo } from '../../../src/assets/logo-meterez.svg';
import { useScrollToTopOnMount } from "../../lib/useScrollToTopOnMount";
import { useNavigate } from "react-router-dom";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../events/event-sub-categories";

export type Props = {
  showLogo?: boolean;
  headingText?: string;
  subHeadingText?: string;
  content: ReactNode;
  mainButton?: ReactNode;
};

function Layout(props: Props) {
  const { showLogo = true, headingText, subHeadingText, content, mainButton } = props;

  const navigate = useNavigate();

  useScrollToTopOnMount();
  const auth = useMultiAuth();

  return (
    <div
      className={`w-full flex flex-col items-center`}
      style={{
        // minHeight: `100vh`,
        // minHeight: `-webkit-fill-available`,
        minHeight: `100dvh`,
        backgroundColor: `white`,
      }}
    >
      <div
        className={`w-full flex flex-col flex-grow`}
        style={{
          maxWidth: `350px`,
        }}
      >
        {showLogo && (
          <div
            className={`mx-4 mt-4 uppercase mb-4`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `12px`,
              lineHeight: `14px`,
            }}
          >
            <Logo style={{ height: `21px` }} />

            <div
              className={`mt-2`}
            >
              Account Setup
            </div>
          </div>
        )}

        {headingText && (
          <div
            className={`mx-4 mb-1`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 900,
              fontSize: `18px`,
              lineHeight: `21px`,
            }}
          >
            {headingText}
          </div>
        )}

        {subHeadingText && (
          <div
            className={`mx-4`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `12px`,
              lineHeight: `14px`,
            }}
          >
            {subHeadingText}
          </div>
        )}

        <div
          className={`flex flex-grow mx-4 mt-4 mb-4`}
        >
          {content}
        </div>

        <div
          className={`flex flex-col px-2`}
        >
          <div
            className={`w-full mb-2`}
          >
            {mainButton}
          </div>
          <div
            className={`w-full flex flex-row`}
          >
            <div
              className={`flex-grow`}
            >
              <FullWidthButton
                contentTop={`Help`}
                type={`tertiary-transparent`}
                onClick={() => {
                  navigate("/help");
                  // window.open('https://www.meterez.com/help/', '_blank');
                }}
              />
            </div>
            <div
              className={`flex-grow`}
            >
              <FullWidthButton
                contentTop={`Sign Out`}
                type={`tertiary-transparent`}
                onClick={() => {
                  event(
                    new ButtonPress({
                      buttonName: `Sign Out`,
                      buttonSlug: `LAYOUT.LOG_OUT`,
                      subCategory: EventSubCategories.AUTH,
                    })
                  );

                  auth.invalidateSession();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Layout };