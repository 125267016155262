export type Props = {
  fontSize: string;
  lineHeight: string;
  containerClassname?: string;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  textClassname?: string;
};

function TextSkeleton(props: Props) {
  const { fontSize, lineHeight, containerStyle, containerClassname, textStyle, textClassname } = props;

  return (
    <div
      className={`w-full flex items-center relative ${containerClassname}`}
      style={{
        fontFamily: "Roboto, sans-serif",
        fontSize,
        lineHeight,
        ...containerStyle,
      }}
    >
      <div className={`opacity-0`}>
        ABC123
      </div>

      <div
        className={`w-full rounded opacity-10 ${textClassname} absolute left-0 right-0`}
        style={{
          height: fontSize,
          backgroundColor: `#000`,
          ...textStyle,
        }}
      />
    </div>
  );
}

export { TextSkeleton };