import { reportError } from "../../lib/utils";
import { Browser } from "./types";

function getBrowser(): Browser | null {
  try {
    // @ts-expect-error Some aren't known types on window
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/chrome/i.test(userAgent) && !/chromium/i.test(userAgent) && !/Edg\./i.test(userAgent)) {
      return `chrome`;
    }

    if (/chromium/i.test(userAgent)) {
      return `chromium`;
    }

    if (/firefox/i.test(userAgent)) {
      return `firefox`;
    }

    if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent) && !/chromium/i.test(userAgent)) {
      return `safari`;
    }

    if (/opera/i.test(userAgent) || /opr/i.test(userAgent)) {
      return `opera`;
    }
  } catch (error: unknown) {
    reportError(error)
  }

  return null;
}

export { getBrowser }