import { CreateVehicleMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  vehicleId?: number;
};

export type ConstructorArgs = {
  mutationResponse: CreateVehicleMutation["insert_vehicles_one"];
} & Partial<EventConstructorArgs>;

class CreateVehicleResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.VEHICLES,
      type: `CREATE_VEHICLE.RESPONSE`,
      data: {
        vehicleId: mutationResponse?.id,
      },
    });
  }
}

export { CreateVehicleResponse };
