import { Modal } from "rsuite";
import { ReactComponent as IconEzWallet } from "../../../../src/assets/icons/ez-wallet.svg";
import faviconImgUrl from "../../../../src/assets/android-chrome-192x192.png";
import appStoreIconAppleUrl from "../../../../src/assets/app-store-btn-apple.png";
import appStoreIconGoogleUrl from "../../../../src/assets/app-store-btn-google.png";
import { FullWidthButton } from "../../buttons/FullWidthButton";

export type Props = {
  open?: boolean;
  onClose?: () => any;
};

function DownloadAppExplainer(props: Props) {
  const { open, onClose } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      dialogClassName={`px-4`}
      dialogStyle={{

      }}
    >
      <div
        className={`uppercase text-center mx-auto px-2 pt-6`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `12px`,
          lineHeight: `14px`,
          color: `#0d2b48`,
          maxWidth: `350px`,
        }}
      >
        {/**
         * Text - Park Cheaper ?
         */}
        <div
          className={`mb-6`}
          style={{
            
          }}
        >
          <div
            className={`mb-1`}
            style={{
              
            }}
          >
            Frequent Parkers, Want To
          </div>
          <div
            className={`capitalize`}
            style={{
              fontWeight: 900,
              fontSize: `25px`,
              lineHeight: `32px`,
              color: `#0d2b48`,
            }}
          >
            <span
              className={`inline-block mr-1`}
              style={{
                color: `#0b84ff`,
              }}
            >
              Park
            </span>
            <span
              className={`inline-block mr-1`}
              style={{
                
              }}
            >
              Cheaper
            </span>
            <span
              className={`inline-block`}
              style={{
                color: `#0b84ff`,
              }}
            >
              ?
            </span>
          </div>
        </div>

        {/**
         * Text - Download App / EZwallet
         */}
        <div
          className={``}
          style={{
            
          }}
        >
          <div
            className={`flex justify-center items-center mb-2`}
            style={{
              
            }}
          >
            <div
              className={`mr-2 relative`}
            >
              <img 
                src={faviconImgUrl} 
                alt="App Icon" 
                style={{
                  width: `20px`,
                  height: `auto`,
                }}
              />
            </div>
            <div
              className={`relative`}
              style={{
                top: `1px`,
              }}
            >
              Download the App (below)
            </div>
          </div>

          <div
            className={`flex justify-center items-center mb-3`}
            style={{
              
            }}
          >
            <div
              className={`mr-2 relative`}
              style={{
                top: `2px`,
              }}
            >
              to use
            </div>
            <div>
              <IconEzWallet />
            </div>
          </div>

          <div
            className={`mr-3 relative mb-8 capitalize`}
            style={{
              top: `1px`,
            }}
          >
            <span
              className={`inline-block mr-1`}
              style={{
                
              }}
            >
              And your processing fee is up to
            </span>
            <span
              className={`inline-block`}
              style={{
                color: `#0b84ff`,
              }}
            >
              1/2 off!
            </span>
          </div>
        </div>

        {/**
         * Text - App Features
         */}
        <div
          className={`mr-3 relative text-left`}
          style={{
            top: `1px`,
          }}
        >
          App Features:
        </div>

        <div
          className={`mr-3 relative text-left capitalize mb-8`}
          style={{
            top: `1px`,
          }}
        >
          <ul
            className={`list-disc pl-4 pt-2`}
          >
            <li
              className={`mb-1`}
            >
              Save money with EZwallet
            </li>
            <li
              className={`mb-1`}
            >
              Export your Parking History
            </li>
            <li
              className={`mb-1`}
            >
              Personalize your Vehicles and Cards
            </li>
          </ul>
        </div>

        {/**
         * App Store Links
         */}
        <div
          className={`w-full mb-2`}
        >
          Download Here
        </div>
        <div
          className={`flex justify-center items-center mb-4`}
        >
          <div
            className={`mx-2 relative`}
          >
            <a href={`https://itunes.apple.com/us/developer/meterez-llc/id1357663197?mt=8`} target={`_blank`}>
              <img 
                src={appStoreIconAppleUrl} 
                alt="Apple App Store Icon" 
                style={{
                  width: `auto`,
                  height: `35px`,
                }}
              />
            </a>
          </div>
          <div
            className={`mx-2 relative`}
          >
            <a href={`https://play.google.com/store/apps/details?id=com.meterez`} target={`_blank`}>
              <img 
                src={appStoreIconGoogleUrl} 
                alt="Apple App Store Icon" 
                style={{
                  width: `auto`,
                  height: `35px`,
                }}
              />
            </a>
          </div>
        </div>

        {/**
         * Bottom Buttons
         */}

         <div>
          <FullWidthButton
            contentTop={`Close`}
            type={`tertiary-transparent`}
            onClick={onClose}
          />
         </div>
      </div>
    </Modal>
  );
}

export { DownloadAppExplainer };