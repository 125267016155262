import _ from "lodash";
// import InputMask from "react-input-mask";
import { ReactComponent as IconPhone } from "../../../../src/assets/icons/circle-phone-solid.svg";
import { useRef } from "react";
import { IMaskInput } from "react-imask";

export type Props = {
  value?: string;
  onChange?: (value: string) => any;
  disabled?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  style?: React.CSSProperties;
};

function PhoneInput(props: Props) {
  const { value: _value = ``, onChange = () => {}, disabled = false, autoFocus = false, error = false, style } = props;

  const inputRef = useRef();

  const value: string | null | undefined = _.toString(_value).includes("+1")
    ? _.toString(_value).substring(2)
    : _value;

  return (
    <div className={`w-full relative`} style={style}>
      <div className={`absolute top-0 left-2 bottom-0 pl-2 flex items-center`}>
        <IconPhone
          className={`absolute`}
          style={{
            height: `20px`,
            width: `20px`,
          }}
        />
      </div>

      <IMaskInput
        name="phone_number"
        id="phone_number"
        autoFocus={false}
        inputMode="numeric"
        placeholder="Mobile Phone Number"
        value={_.toString(value).replace(/\D/g,'')}
        disabled={disabled}
        className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `20px`,
          lineHeight: `24px`,
          height: `50px`,
          borderWidth: `1px`,
          borderColor: error ? `red` : `#E7EAF7`,
          color: `#0E2A48`,
        }}

        mask={`(000) 000-0000`}
        radix="_"
        unmask={true} // true|false|'typed'
        // ref={ref}
        // inputRef={inputRef}  // access to nested input
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        onAccept={
          // depending on prop above first argument is
          // `value` if `unmask=false`,
          // `unmaskedValue` if `unmask=true`,
          // `typedValue` if `unmask='typed'`
          (value, mask) => {
            const newValue = _.toString(value).replace(/\D/g,'');
          
          // localStorage.setItem(`last_known_phone_number`, newValue);
          onChange(newValue);
        }}
      />

      {/* <InputMask 
        name="phone_number"
        id="phone_number"
        // inputRef={ref => {
        //   if (ref) {
        //     setTimeout(() => {
        //       ref.focus();
        //     }, 500);
        //   }
        // }}
        // autoComplete="off"
        autoFocus={false}
        inputMode="numeric"
        mask="(999) 999-9999"
        maskChar="_"
        placeholder="Mobile Phone Number"
        onChange={(event) => {
          const newValue = _.toString(event.target.value).replace(/\D/g,'');
          
          // localStorage.setItem(`last_known_phone_number`, newValue);
          onChange(newValue);
        }}
        // defaultValue={localStorage.getItem(`last_known_phone_number`) ?? ``}
        value={_.toString(value).replace(/\D/g,'')}
        disabled={disabled}
        // autoFocus={autoFocus}
        className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `20px`,
          lineHeight: `24px`,
          height: `50px`,
          borderWidth: `1px`,
          borderColor: error ? `red` : `#E7EAF7`,
          color: `#0E2A48`,
        }}
      /> */}
    </div>
  );
}

export { PhoneInput };