import { Switch } from "./Switch";
import useManageUserSettings from "../../queries/user-settings/useManageUserSettings";
import { ReactComponent as IconBell } from "../../../src/assets/icons/bell-solid.svg";
import { useNetwork } from "../../lib/useNetwork";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";

function NotificationsSwitch() {
  const { userSettings, updateUserSettings, isLoading, isSaving, isSaveError, isSaved } = useManageUserSettings();

  const { isOnline } = useNetwork();

  return (
    <div
      className={`flex items-center`}
    >
      <IconBell
        style={{
          height: `18px`,
          marginRight: `5px`,
        }}
      />

      <Switch
        value={userSettings?.notificationsSmsEnabled === true}
        onChange={() => {
          const enabled = !userSettings?.notificationsSmsEnabled;

          event(new ButtonPress({
            buttonName: `SMS Notifications - ${enabled ? `Off -> On` : `On -> Off`}`,
            buttonSlug: `SMS_NOTIFICATIONS.${enabled ? `ENABLED` : `DISABLED`}`
          }));

          updateUserSettings({
            notificationsSmsEnabled: enabled,
          });
        }}
        disabled={isLoading || isSaving || !isOnline}
        isError={isSaveError}
      />
    </div>
  );
}

export { NotificationsSwitch };