import { useNavigate } from "react-router-dom";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import usePaymentMethods from "../../../../queries/payment-methods/usePaymentMethods";
import { Choose } from "../components/payment-methods/Choose";
import { Confirm } from "../components/payment-methods/Confirm";
import { Progress } from "../components/Progress";
import { EnterNewCard } from "../components/payment-methods/EnterNewCard";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../../../queries/query-keys";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import { Heading1 } from "../../../../components/headings/Heading1";
import { useScrollToTopOnChange } from "../../../../lib/useScrollToTopOnChange";
import { Auth } from "../../../../auth/core/Auth";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";

export type Props = {

};

function PaymentMethod(props: Props) {
  const {  } = props;

  const navigate = useNavigate();
  const session = useSessionInProgress();

  const queryClient = useQueryClient();

  const { parkableTransactions } = useRecentParkableTransactions();
  const { query, paymentMethods } = usePaymentMethods();
  const [showCardForm, setShowCardForm] = useState<boolean>();
  
  useEffect(() => {
    if (!session.stateRef.current.sessionInProgress) {
      navigate("/sessions", { replace: true });
      return;
    }

    const { parkableId, vehicleId, time } = session.stateRef.current.sessionInProgress.sessionParameters;

    if (!parkableId || !vehicleId || !time) {
      navigate("/sessions", { replace: true });
    }
  }, []);

  const paymentMethodId = session.stateRef.current.sessionInProgress?.sessionParameters.paymentMethodId;
  const paymentMethod = paymentMethodId ? _.find(paymentMethods, { id: paymentMethodId }) : null;

  let uiState = `CHOOSE`;
  if (showCardForm) {
    uiState = `ENTER_NEW_CARD`;
  } else if (paymentMethodId) {
    uiState = `CONFIRM`;
  }

  useScrollToTopOnChange(uiState);

  if (!session.sessionInProgress) {
    return null;
  }

  return (
    <ScreenLayout
      header={
        <TitleHeader title={[`New`, `Session`]} />
      }
      content={
        <div
          className={`w-full flex flex-grow flex-col items-center`}
        >
          <Progress />

          {uiState === `CHOOSE` && (
            <div
              className={`w-full mb-6`}
            >
              <Heading1>
                Choose your Payment Method
              </Heading1>

              <FullWidthButton
                contentTop={`Enter New Card`}
                type={`secondary-white`}
                onClick={() => {
                  setShowCardForm(true);
                }}
                disabled={false}
              />
            </div>
          )}

          {uiState === `ENTER_NEW_CARD` && (
            <EnterNewCard
              paymentMethods={paymentMethods}
              onCreate={async (paymentMethod) => {
                const userId = (await Auth.getInstance().getSession())?.sessionDetails.userId;
                queryClient.invalidateQueries(queryKeys.PaymentMethods(userId ?? -1));

                query.refetch();
                setShowCardForm(false);
                session.set({
                  paymentMethodId: paymentMethod.id,
                });
              }}
              onCancel={() => {
                setShowCardForm(false);
              }}
            />
          )}

          {uiState === `CHOOSE` && (
            <Choose
              paymentMethodsQuery={query}
              paymentMethods={paymentMethods}
              recentParkableTransactions={parkableTransactions}
              onClickPaymentMethod={(paymentMethod) => {
                session.set({
                  paymentMethodId: paymentMethod.id,
                });
              }}
              disabled={false}
            />
          )}

          {uiState === `CONFIRM` && (
            <Confirm
              paymentMethodId={paymentMethodId}
              onCancel={() => {
                session.set({
                  paymentMethodId: undefined,
                })
              }}
            />
          )}

          <div
            className={`w-full flex flex-col items-center`}
          >
            {uiState === `CONFIRM` && (
              <FullWidthButton
                contentTop={`Continue`}
                onClick={() => session.navigate(`REVIEW`)}
                disabled={!paymentMethod}
              />
            )}

            <div className={`mb-4`} />

            <FullWidthButton
              contentTop={`Cancel Parking`}
              type={`tertiary-transparent`}
              onClick={() => {
                event(
                  new ButtonPress({
                    buttonName: `Cancel Parking`,
                    buttonSlug: `CREATE_PARKABLE_TRANSACTION.CANCEL`,
                    subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                  })
                );

                session.cancel();
                navigate(`sessions`);
              }}
              disabled={false}
            />
          </div>
        </div>
      }
    />
  );
}

export { PaymentMethod };