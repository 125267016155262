import { useNavigate, useParams } from "react-router-dom";
import { serverDatetimeToMoment, toValidInteger } from "../../../../lib/utils";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { useEffect } from "react";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import _ from "lodash";
import { useSessionStatus } from "../../../../lib/useSessionStatus";
import moment from "moment-timezone";

function RedirectOnExtendSessionInvalid() {
  const { parkableTransactionId: parkableTransactionIdStr } = useParams() as { parkableTransactionId?: number };
  const parkableTransactionId = toValidInteger(parkableTransactionIdStr);
  const navigate = useNavigate();
  const session = useSessionInProgress();
  const { parkableTransactions } = useRecentParkableTransactions();
  const parkableTransaction = _.find(parkableTransactions, { id: parkableTransactionId });
  const { isActive, isLow, canExtendTime } = useSessionStatus({ parkableTransaction });

  useEffect(() => {
    const mismatchedId = (
      parkableTransactionId && 
      session.sessionInProgress?.parkableTransactionId &&
      parkableTransactionId !== session.sessionInProgress?.parkableTransactionId
    );
    const isExpired = (
      !!parkableTransaction &&
      (!isActive)
    );
    const isTooLowToExtend = (
      !!parkableTransaction &&
      isActive &&
      !canExtendTime
    );

    if (mismatchedId || isExpired || isTooLowToExtend) {
      if (session.sessionInProgress) {
        session.cancel();
      }
      
      navigate("/sessions", { replace: true });
    }
  }, [parkableTransactionId, session.sessionInProgress, parkableTransaction, isActive, isLow, canExtendTime, navigate]);

  return null;
}

export { RedirectOnExtendSessionInvalid };