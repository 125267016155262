import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { CancelParkableTransactionDocument, CancelParkableTransactionMutation, CancelParkableTransactionMutationVariables } from "../../generatedQueries";
import request from "graphql-request";
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { CancelParkableTransactionRequest } from "../../events/event-types/parkable-transactions/CancelParkableTransactionRequest";
import { CancelParkableTransactionResponse } from "../../events/event-types/parkable-transactions/CancelParkableTransactionResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    CancelParkableTransactionMutation["cancelParkableTransaction"],
    unknown,
    CancelParkableTransactionMutationVariables
  >,
  "mutationFn"
>;

export default function useCancelParkableTransaction(options: Options) {
  const mutation = useMutation<
    CancelParkableTransactionMutation["cancelParkableTransaction"],
    unknown,
    CancelParkableTransactionMutationVariables
  >(
    async (variables) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };
      
      return (await request({
        url: getGraphqlEndpoint() as string,
        document: CancelParkableTransactionDocument,
        variables: {
          ...variables,
        },
        requestHeaders,
        // signal,
      })).cancelParkableTransaction;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new CancelParkableTransactionRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (response, variables, context) => {
        event(
          new CancelParkableTransactionResponse({
            mutationResponse: response,
          })
        );

        const parkableTransaction = response?.parkableTransaction;

        if (options.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    parkableTransaction: mutation.data?.parkableTransaction ?? null,
  };
}
