import { CreditCardScrubber } from "./CreditCardScrubber";
import { NoiseReducer } from "./NoiseReducer";
import {
  DataScrubber,
  ErrorEvent,
  EventHint,
  SentryBeforeSendCallback,
} from "./types";

export const dataScrubbers: DataScrubber[] = [NoiseReducer, CreditCardScrubber];

export const scrubSentryData: SentryBeforeSendCallback = (
  event: ErrorEvent,
  hint: EventHint
) => {
  let output: ErrorEvent | null = event;

  dataScrubbers.forEach(function (scrubber) {
    if (!output) return;

    try {
      // @ts-ignore
      output = scrubber.scrub(output, hint);
    } catch (e) {
      console.error(`Sentry Data Scrubber Error: `, e);

      throw e;
    }
  });

  return output;
};
