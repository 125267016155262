import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";

import useCreateMxmCustomer from "./useCreateMxmCustomer";
import { StoreVaultedCardAccountMutation } from "../../generatedQueries";
import { backoffRetry, maybeRetryMutation, reportError } from "../../lib/utils";
import useUser from "../users/useUser";
import useCachePrimer from "../useCachePrimer";
import { event } from "../../events/core/helpers";
import { VaultCardRequest } from "../../events/event-types/payment-methods/VaultCardRequest";
import { VaultCardResponse } from "../../events/event-types/payment-methods/VaultCardResponse";
import useRequestPaymentLimitedUseToken from "./useRequestPaymentLimitedUseToken";
import useVaultMxmCard from "./useVaultMxmCard";
import useStoreVaultedCardAccount from "./useStoreVaultedCardAccount";

type CardAccount = {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  avsZip: string;
  avsStreet?: string;
  cvv: string;
};

type VaultCardOptions = {
  isProduction: boolean;
  mxmEndpoint: string;
  limitedUseToken: string;
  customerId: number;
  cardAccount: CardAccount;
};

type MutationVariables = {
  alias: string;
  cardAccount: CardAccount;
  cardType: string;
};

type MutationResponse = Pick<VaultCardOptions, "limitedUseToken"> & {
  paymentMethod: NonNullable<
    StoreVaultedCardAccountMutation["storeVaultedCardAccount"]
  >["paymentMethod"];
  alreadyExists: boolean;
};

type Options = Omit<
  UseMutationOptions<MutationResponse, unknown, MutationVariables>,
  "mutationFn"
>;

export default function useVaultCard(options: Options = {}) {
  const { user } = useUser();
  const cachePrimer = useCachePrimer();

  const { mutation: requestPaymentLimitedUseTokenMutation } =
    useRequestPaymentLimitedUseToken();
  const { mutation: createMxmCustomerMutation } = useCreateMxmCustomer();
  const { mutation: vaultMxmCardMutation } = useVaultMxmCard();
  const { mutation: storeVaultedCardAccountMutation } =
    useStoreVaultedCardAccount();

  const mutation = useMutation<MutationResponse, unknown, MutationVariables>(
    async ({ alias, cardAccount, cardType }: MutationVariables) => {
      const { number, expiryMonth, expiryYear } = cardAccount;
      const lastFour = _.toString(number.slice(number.length - 4));

      const getLimitedUseToken = async () => {
        return (await requestPaymentLimitedUseTokenMutation.mutateAsync({}))
          .token;
      };

      const getCustomerId = async () => {
        if (user?.mxm_customer_id) return user?.mxm_customer_id;

        return (await createMxmCustomerMutation.mutateAsync({}))?.user
          ?.mxm_customer_id;
      };

      const [limitedUseToken, customerId] = (await Promise.all([
        getLimitedUseToken(),
        getCustomerId(),
      ])) as [string, number];

      const cardAccountToken = await vaultMxmCardMutation.mutateAsync({
        limitedUseToken,
        customerId,
        cardAccount,
      });

      //
      // Save the card to the database
      //

      const storeResp = await storeVaultedCardAccountMutation.mutateAsync({
        alias,
        lastFour,
        type: "credit_card",
        cardAccountCardType: cardType,
        cardAccountToken,
        expiryMonth,
        expiryYear,
        postalCode: cardAccount.avsZip,
      });

      const paymentMethod = storeResp?.paymentMethod;
      const alreadyExists = storeResp?.alreadyExists as boolean;

      return {
        limitedUseToken,
        paymentMethod,
        alreadyExists,
      };
    },
    {
      // retry: (failureCount: number, error: unknown) =>
      //   maybeRetryMutation(failureCount, error, 3),
      // retryDelay: (failureAttempt: number) =>
      //   backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onSuccess: (resp, variables, context) => {
        const { paymentMethod } = resp;

        cachePrimer.PaymentMethods.replaceById([paymentMethod]);

        if (options.onSuccess) {
          options.onSuccess(resp, variables, context);
        }
      },
      // onError(error) {
      //   console.log("*** ERROR DATA ***", error?.response?.errors[0]);
      // },
    }
  );

  return {
    mutation,
    paymentMethod: mutation.data ?? null,
  };
}

/**
 * NOTE: This MXM endpoint only returns a "token" string, and none of the other card details,
 *       so we'll either need to make a follow-up API call to get all the card info (ideally),
 *       or use our client-side last 4, expiry, card type, etc initially, then do a follow-up
 *       API call async after the fact for certainty.
 */
export async function vaultCard(options: VaultCardOptions): Promise<string> {
  const { limitedUseToken, mxmEndpoint, customerId, cardAccount } = options;

  const url = `${mxmEndpoint}/vault?token=${encodeURIComponent(
    limitedUseToken
  )}&customerid=${encodeURIComponent(customerId)}`;
  const body = {
    ...cardAccount,
  };

  const lastFour = _.toString(
    cardAccount.number.slice(cardAccount.number.length - 4)
  );

  event(
    new VaultCardRequest({
      cardAccount: {
        ...cardAccount,
        cvv: `xxx`,
        number: `xxxx-xxxx-xxxx-${lastFour}`,
      },
    })
  );

  const resp = await axios
    .post<any, AxiosResponse<{ token: string } | string>>(url, {
      ...body,
    })
    .catch((e) => {
      reportError(e, {
        response: e.response ?? null,
        cardAccount: { ...cardAccount, number: `xxxx-xxxx-xxxx-${lastFour}` },
      });

      throw e;
    });

  // Docs says it's data.token, but seems to be data in practice, so we check both
  const token: string | undefined = _.isObject(resp.data)
    ? resp.data.token
    : resp.data;

  event(
    new VaultCardResponse({
      token,
    })
  );

  return token;
}