import { ReactNode, useEffect } from "react";
import { AccountLocked } from "../items/account-locked/AccountLocked";
import { Name } from "../items/name/Name";
import { RequiresBetaFeatures } from "../items/requires-beta-features/RequiresBetaFeatures";
import { TermsOfService } from "../items/terms-of-service/TermsOfService";
import { useAccountPrerequisites } from "../lib/useAccountPrerequisites";
import { LegacyMigration } from "../items/legacy-migration/LegacyMigration";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import usePrevious from "../../lib/usePrevious";
import { event } from "../../events/core/helpers";
import { AccountSetupStarted } from "../../events/event-types/account-setup/AccountSetupStarted";
import { AccountSetupFinished } from "../../events/event-types/account-setup/AccountSetupFinished";

export type Props = {
  children: ReactNode | ReactNode[];
};

function AccountPrerequisites(props: Props) {
  const { children } = props;

  const auth = useMultiAuth();
  const { isLoading, requiresSetup, items, user } = useAccountPrerequisites();

  const prevRequiresSetup = usePrevious(requiresSetup);
  useEffect(() => {
    if (isLoading) return;

    if (prevRequiresSetup === false && requiresSetup === true) {
      event(new AccountSetupStarted({
        user,
        items,
      }));
    } else if (prevRequiresSetup === true && requiresSetup === false) {
      event(new AccountSetupFinished({
        user,
        items,
      }));
    }
  }, [isLoading, requiresSetup]);

  if (!auth.isLoading && !auth.session) return children;
  if (isLoading) return null;

  if (items.ACCOUNT_LOCKED.show) return (
    <AccountLocked />
  );

  if (items.LEGACY_MIGRATION.show) return (
    <LegacyMigration />
  );

  if (items.REQUIRES_BETA_FEATURES.show) return (
    <RequiresBetaFeatures />
  );

  if (items.TERMS_OF_SERVICE.show) return (
    <TermsOfService />
  );

  if (items.NAME.show) return (
    <Name />
  );

  return children;
}

export { AccountPrerequisites };