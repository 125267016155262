import { useMutation } from "@tanstack/react-query";
import { SubmitEventsPrivateDocument, SubmitEventsPrivateMutation, SubmitEventsPrivateMutationVariables, SubmitEventsPublicDocument, SubmitEventsPublicMutation, SubmitEventsPublicMutationVariables } from "../../generatedQueries";
import { Auth } from "../../auth/core/Auth";
import request from "graphql-request";
import { getGraphqlEndpoint } from "../../config/config";
import moment from "moment-timezone";
import { getBrowserAgentPlatform } from "../../auth/web/getBrowserAgentPlatform";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type Options = object;

export default function useSubmitEvents(options?: Options) {
  const publicMutation = useMutation<
    SubmitEventsPublicMutation["submitEventsPublic"],
    unknown,
    SubmitEventsPublicMutationVariables
  >(
    async ({ eventsBatches, unauthenticatedCredentials, device }) => {
      const deviceHeaders = getDeviceHeaders();
      
      const requestHeaders = {
        ...deviceHeaders,
        // "X-Source": "WEB",
        // "X-Platform": "web",
        // "X-Operating-System": getBrowserAgentPlatform(),
      };

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: SubmitEventsPublicDocument,
        variables: {
          eventsBatches,
          unauthenticatedCredentials,
          device,
          now: moment.utc().unix(),
        },
        requestHeaders,
        // signal,
      })).submitEventsPublic;
    },
    {
      // retry: (failureCount: number, error: unknown) =>
      //   maybeRetryMutation(failureCount, error, 3),
      // retryDelay: (failureAttempt: number) =>
      //   backoffRetry(failureAttempt, 30 * 1000),

      onSuccess: (resp, variables, context) => {
        //
      },
      onError: (error) => {
        reportError(error);
      },
    }
  );

  const privateMutation = useMutation<
    SubmitEventsPrivateMutation["submitEventsPrivate"],
    unknown,
    SubmitEventsPrivateMutationVariables
  >(
    async ({ eventsBatches, device }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();

      const requestHeaders = jwt ? {
        ...deviceHeaders,
        // "X-Source": "WEB",
        // "X-Platform": "web",
        // "X-Operating-System": getBrowserAgentPlatform(),
        Authorization: `Bearer ${jwt}`,
      } : {
        ...deviceHeaders,
        // "X-Source": "WEB",
        // "X-Platform": "web",
        // "X-Operating-System": getBrowserAgentPlatform(),
      };
      
      return (await request({
        url: getGraphqlEndpoint() as string,
        document: SubmitEventsPrivateDocument,
        variables: {
          eventsBatches,
          device,
          now: moment.utc().unix(),
        },
        requestHeaders,
        // signal,
      })).submitEventsPrivate;
    },
    {
      // retry: (failureCount: number, error: unknown) =>
      //   maybeRetryMutation(failureCount, error, 3),
      // retryDelay: (failureAttempt: number) =>
      //   backoffRetry(failureAttempt, 30 * 1000),

      onSuccess: (resp, variables, context) => {
        //
      },
      onError: (error) => {
        reportError(error);
      },
    }
  );

  return {
    publicMutation,
    privateMutation,
  };
}
