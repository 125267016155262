import _ from "lodash";

import { DataScrubber, ErrorEvent } from "./types";

export const CreditCardScrubber: DataScrubber = {
  scrub: (event, hint) => {
    let _event = { ...event };

    if (_event.request && isVaultCardRequest(_event.request)) {
      _event = {
        ..._event,
        request: undefined,
        extra: {
          ..._event.extra,
          "DataScrubber.CreditCardScrubber": true,
        },
      };
    }

    if (_event.extra?.request && isVaultCardRequest(_event.extra.request)) {
      _event = {
        ..._event,
        extra: {
          ..._event.extra,
          request: undefined,
          "DataScrubber.CreditCardScrubber": true,
        },
      };
    }

    // This was meant for GraphQL, which we don't use for card vaulting
    // if (isVaultCardRequest(request)) {
    //   const cardAccount = request?.variables?.cardAccount as CardAccount;

    //   const lastFour = _.toString(
    //     cardAccount.number.slice(cardAccount.number.length - 4)
    //   );

    //   request.variables.cardAccount = {
    //     ...cardAccount,
    //     number: `xxxx-xxxx-xxxx-${lastFour}`,
    //     cvv: `xxx`,
    //   };
    // }

    return _event;
  },
};

function isVaultCardRequest(request: ErrorEvent[`request`]) {
  const possibleUrls = [
    // @ts-ignore
    request._url,
    // @ts-ignore
    request.__sentry_xhr_v2__?.url,
    // @ts-ignore
    request.responseURL,
    // @ts-ignore
    request._perfKey,
  ].filter((url) => !!url);

  let isVaultCardRequest = false;
  possibleUrls.forEach(function (url) {
    if (_.toString(url).includes("checkout/v3/vault")) {
      isVaultCardRequest = true;
    }
  });

  return isVaultCardRequest;
}
