import * as Sentry from "@sentry/react";
import env from "../../../config/env";
import { environment } from "../../../config/config";
// import { ExtraErrorData } from "@sentry/integrations";
import { scrubSentryData } from "../../../errors/data-scrubbers/scrubSentryData";

Sentry.init({
  dsn: (env(`SENTRY_DSN`) as string) ?? ``,
  integrations: [
    // new Sentry.BrowserTracing({
    //   // TODO Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   // tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    // }),
    // new Sentry.Replay(),
    
    // new ExtraErrorData({ depth: 10 }),
  ],
  normalizeDepth: 10,
  // Performance Monitoring
  tracesSampleRate: 0,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  debug: !environment.isProduction,
  environment: environment.envName,
  enabled: true, //environment.isProduction,

  beforeSend: scrubSentryData,
});