import { useState } from "react";
import { ParkableTransaction, useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { datetimeToLocal, isActive, makeDuration, serverDatetimeToMoment } from "../../../../lib/utils";
import { ReactComponent as IconMeter } from "../../../../../src/assets/icons/icon-meter.svg";
import { ReactComponent as IconReceipt } from "../../../../../src/assets/icons/receipt-solid.svg";
import { NavLink } from "react-router-dom";
import { useLiveNow } from "../../../../lib/useLiveNow";
import { ReceiptDownloadLink } from "../receipt/ReceiptDownloadLink";

function RecentSessions() {
  const { allParkableTransactions } = useRecentParkableTransactions();

  const [show, setShow] = useState(false);

  if (allParkableTransactions.length === 0) return null;

  return (
    <div
      className={`w-full mt-4`}
      style={{
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        fontSize: `12px`,
        lineHeight: `14px`,
        color: `#989fb3`,
      }}
    >
      <div
        className={`flex justify-center w-full`}
      >
        <button
          className={`uppercase py-2`}
          style={{
            
          }}
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
          }}
        >
          Receipts & Recent Sessions
        </button>
      </div>

      {show && (
        <div
          className={`w-full mt-2 mb-2 text-center`}
        >
          {allParkableTransactions.map(pt => {
            const duration = makeDuration(pt.time, 1);

            return (
              <div
                key={pt.id}
                className={`flex text-left px-2 py-2`}
                style={{
                  color: `#0d2a48`,
                }}
              >
                {/**
                  * Date
                  */}
                <div
                  className={`flex items-center flex-grow`}
                >
                  {datetimeToLocal(serverDatetimeToMoment(pt.created_at)).format("MMM D @ h:mm a")} ({duration.human})
                </div>

                {/**  
                  * Meter
                  */}
                <div
                  className={`flex items-center`}
                >
                  <div
                    className={`mr-1`}
                  >
                    <IconMeter 
                      className={`relative`}
                      style={{
                        height: `10px`,
                        // top: `1px`,
                      }} 
                    />
                  </div>
                  <div
                    className={``}
                  >
                    {pt.parkable.identifier} ({pt.parkable.parking_authority.city})
                  </div>
                </div>

                {/**  
                  * Receipt
                  */}
                <ReceiptButton parkableTransaction={pt} />
              </div>
            );
          })}

          <div
            className={`italic mt-3`}
          >
            * Download the app to view / export full history
          </div>
        </div>
      )}
    </div>
  );
}

export type ReceiptButtonProps = {
  parkableTransaction: ParkableTransaction;
};

function ReceiptButton(props: ReceiptButtonProps) {
  const { parkableTransaction } = props;

  const now = useLiveNow();
  const active = isActive(parkableTransaction, now);

  return (
    <div
      className={`flex items-center ml-3 ${active ? `opacity-0`:``}`}
      style={{
        minWidth: `23px`,
        minHeight: `22px`,
      }}
    >
      {!active && (
        <ReceiptDownloadLink
          // parkableTransactionId={parkableTransaction.id}
          parkableTransaction={parkableTransaction}
          fileName={`meterez-receipt-ID-${parkableTransaction.id}-${datetimeToLocal(serverDatetimeToMoment(parkableTransaction.created_at)).format("MMM-D-@-h:mm-a")}`}
        >
          <div
            className={`inline-block rounded-md`}
            style={{
              borderWidth: `1px`,
              borderColor: `#E7EAF7`,
              paddingTop: `4px`,
              paddingRight: `6px`,
              paddingBottom: `4px`,
              paddingLeft: `6px`,
              backgroundColor: `#fff`,
            }}
            title={`Receipt`}
          >
            <IconReceipt
              style={{
                height: `12px`,
              }}
            />
          </div>
        </ReceiptDownloadLink>
        // <NavLink
        //   to={`/sessions/${parkableTransaction.id}/receipt`}
        //   target={`_blank`}
        //   className={`inline-block rounded-md`}
        //   style={{
        //     borderWidth: `1px`,
        //     borderColor: `#E7EAF7`,
        //     paddingTop: `4px`,
        //     paddingRight: `6px`,
        //     paddingBottom: `4px`,
        //     paddingLeft: `6px`,
        //   }}
        //   title={`Receipt`}
        // >
        //   <IconReceipt
        //     style={{
        //       height: `12px`,
        //     }}
        //   />
        // </NavLink>
      )}
    </div>
  );
}

export { RecentSessions };