export default {
  Config: () => {
    return [{ entity: "config" }];
  },
  RecentParkableTransactions: (userId: number) => {
    return [{ entity: "parkable_transactions", scope: "recent", userId }];
  },
  ParkableTransactions: (userId: number) => {
    return [{ entity: "parkable_transactions", scope: "history", userId }];
  },
  RParkableTransactions: (userId: number) => {
    return [{ entity: "r_parkable_transactions", userId }];
  },
  RParkableTransaction: (userId: number, id: number) => {
    return [{ entity: "r_parkable_transaction", id }];
  },
  ParkableTransaction: (userId: number, parkableTransactionId: number) => {
    return [
      {
        entity: "parkable_transactions",
        scope: "single",
        userId,
        parkableTransactionId,
      },
    ];
  },
  ProcessingParkableTransactionsCount: (userId: number) => {
    return [
      { entity: "parkable_transactions", scope: "processing_count", userId },
    ];
  },
  QrAssignParkables: (
    cacheBustKey: number,
    parkingAuthorityId: number | null | undefined,
    min: number | null | undefined,
    max: number | null | undefined
  ) => {
    return [
      { entity: "parkables", cacheBustKey, parkingAuthorityId, min, max },
    ];
  },
  QrAssignProgress: (parkingAuthorityId: number | null | undefined) => {
    return [{ entity: "parkables", parkingAuthorityId }];
  },
  QrCode: (codeId: number | null | undefined) => {
    return [{ entity: "qr_codes", codeId }];
  },
  SearchParkables: (identifier: number | null) => {
    return [{ entity: "parkables", identifier, scope: "search" }];
  },
  Parkable: (parkableId: number | null) => {
    return [{ entity: "parkables", parkableId }];
  },
  PaymentMethods: (userId: number | null) => {
    return [{ entity: "payment_methods", userId }];
  },
  Vehicles: (userId: number | null) => {
    return [{ entity: "vehicles", userId }];
  },
  User: (userId: string | number | null | undefined) => {
    return [{ entity: "users", userId }];
  },
  Wallet: (userId: number | null) => {
    return [{ entity: "wallets", userId }];
  },
  UserSettings: (userId: number | null) => {
    return [{ entity: "user_settings", userId }];
  },
  Invoices: (userId: number | null) => {
    return [{ entity: "invoices", userId }];
  },
  ParkingAuthorities: () => {
    return [{ entity: "parking_authorities" }];
  },
};
