import _ from "lodash";
// import InputMask from "react-input-mask";
import { useRef } from "react";
import { evaluateCardAccount } from "../../../lib/utils";
import { IMaskInput } from 'react-imask';

export type Props = {
  value?: string;
  onChange?: (value: string) => any;
  disabled?: boolean;
  isError: boolean;
};

function CreditCardInput(props: Props) {
  const { value = ``, onChange = () => {}, disabled = false, isError } = props;

  const inputRef = useRef();

  const { issuers } = evaluateCardAccount({
    cardNumber: value,
  });
  const cardType = [
    "AMERICAN_EXPRESS",
    "DISCOVER",
    "MASTERCARD",
    "VISA",
  ].includes(issuers[0]?.id ?? "")
    ? issuers[0].id
    : "GENERIC";

  return (
    <div className={`w-full relative`}>
      <IMaskInput
        name="credit_card_number"
        id="credit_card_number"
        autoFocus={false}
        inputMode="numeric"
        placeholder="Card Number"
        value={_.toString(value).replace(/\D/g,'')}
        disabled={disabled}
        className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `20px`,
          lineHeight: `24px`,
          height: `50px`,
          borderWidth: `1px`,
          borderColor: isError ? `red` : `#E7EAF7`,
          color: `#0E2A48`,
        }}

        mask={`0000-0000-0000-0000`}
        radix="_"
        unmask={true} // true|false|'typed'
        // ref={ref}
        // inputRef={inputRef}  // access to nested input
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        onAccept={
          // depending on prop above first argument is
          // `value` if `unmask=false`,
          // `unmaskedValue` if `unmask=true`,
          // `typedValue` if `unmask='typed'`
          (value, mask) => {
            const newValue = _.toString(value).replace(/\D/g,'');
            
            // localStorage.setItem(`last_known_phone_number`, newValue);
            onChange(newValue);
        }}
      />
    </div>
  );

  // return (
  //   <div className={`w-full relative`}>
  //     <InputMask 
  //       name="credit_card_number"
  //       id="credit_card_number"
  //       // inputRef={ref => {
  //       //   if (ref) {
  //       //     setTimeout(() => {
  //       //       ref.focus();
  //       //     }, 500);
  //       //   }
  //       // }}
  //       // autoComplete="off"
  //       autoFocus={false}
  //       mask="9999-9999-9999-9999"
  //       inputMode="numeric"
  //       maskChar="_"
  //       placeholder="Enter Card Number"
  //       onChange={(event) => {
  //         const newValue = _.toString(event.target.value).replace(/\D/g,'');
          
  //         // localStorage.setItem(`last_known_phone_number`, newValue);
  //         onChange(newValue);
  //       }}
  //       // defaultValue={localStorage.getItem(`last_known_phone_number`) ?? ``}
  //       value={_.toString(value).replace(/\D/g,'')}
  //       disabled={disabled}
  //       // autoFocus={autoFocus}
  //       className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
  //       style={{
  //         fontFamily: "Roboto, sans-serif",
  //         fontWeight: 700,
  //         fontSize: `20px`,
  //         lineHeight: `24px`,
  //         height: `50px`,
  //         borderWidth: `1px`,
  //         borderColor: isError ? `red` : `#E7EAF7`,
  //         color: `#0E2A48`,
  //       }}
  //     />
  //   </div>
  // );
}

export { CreditCardInput };