import { createContext } from "react";

import type { ErrorModalErrorProps } from "./types";

export type Context = {
  state: ErrorModalErrorProps;
  show: (
    args: ErrorModalErrorProps & {
      onClose?: () => any;
    }
  ) => any;
  hide: () => any;
};

export const defaultState: ErrorModalErrorProps = {
  error: null,
  errorTitle: null,
  errorMessage: null,
};

const defaultValue = {
  state: defaultState,
  show: ({ }) => { },
  hide: () => { },
};

export const ErrorModalContext = createContext(defaultValue as Context);

export default ErrorModalContext;
