// import * as Clipboard from "expo-clipboard";
import _ from "lodash";
import { memo } from "react";

import type { ErrorModalErrorProps } from "./types";
import { parseReactQueryError } from "..";
import { Modal } from "rsuite";
import { FullWidthButton } from "../../../components/buttons/FullWidthButton";

export type Props = ErrorModalErrorProps & {
  onRequestClose?: () => any;
};

// TODO Allow passing in array of button configs
function ErrorModal(props: Props) {
  const {
    error,
    errorTitle: _errorTitle,
    errorMessage: _errorMessage,

    onRequestClose = () => { },
  } = props;
  // const { showDevTools } = useDevTools();

  const graphQlError = parseReactQueryError(error, false);

  const errorTitle = _errorTitle ??
    graphQlError?.extensions?.context?.title ?? [`Unknown`, `Error`];

  let errorTitleParts: (string | null)[] = [];
  let errorTitleEls: JSX.Element | JSX.Element[] = [];

  if (
    _.isArray(errorTitle) &&
    _.filter(errorTitle, (et) => _.isString(et)).length === 0
  ) {
    errorTitleEls = errorTitle as JSX.Element | JSX.Element[];
  } else {
    if (_.isArray(errorTitle)) {
      errorTitleParts = errorTitle as (string | null)[];
    } else if (_.isString(errorTitle)) {
      errorTitleParts = [errorTitle];
    }

    errorTitleEls = errorTitleParts.map((part: string | null, i: number) => (
      <span
        style={{
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: `18px`,
          lineHeight: `18px`,
          color:
            i % 2 === 0 ? "rgba(10, 132, 255, 1)" : "rgba(14, 42, 72, 1)",
          marginRight: `6px`,
        }}
        key={`${i}${part}`}
      >
        {part}
      </span>
    ));
  }

  // UX Add a link to the support form in this error message, and info on how to contact us on the website if the support form is broken
  const errorMessage =
    _errorMessage ??
    graphQlError?.extensions.userMessage ??
    "Our technical team has been notified. We're sorry for the inconvenience. Please try restarting the meterEZ app, and if the issue continues, contact support.";

  return (
    <Modal
      open={!!error || !!_errorMessage}
      onClose={onRequestClose}
      dialogClassName={`px-4`}
    >
      <div
        className={`w-full`}
        style={{
          // backgroundColor: `red`
        }}
      >
        <div
          className={``}
          style={{
            
          }}
        >
          {errorTitleEls}
        </div>

        <div
          className={``}
        >
            {_.isString(errorMessage) && (
              <ErrorMessageText errorMessage={errorMessage} />
            )}
            {!_.isString(errorMessage) && errorMessage}
          </div>

          <div
            className={``}
            style={
              {
                
              }
            }
          >
            <FullWidthButton
              contentTop={`Close`}
              type={`tertiary-transparent`}
              onClick={() => {
                if (onRequestClose) {
                  onRequestClose();
                }
              }}
            />
          </div>
      </div>
    </Modal>
  );
}

export function ErrorMessageText(props: { errorMessage: string }) {
  const { errorMessage } = props;

  return (
    <div
      className={`mt-2 mb-1`}
      style={
        {
          
        }
      }
    >
      <div
        className={`mt-2 mb-1`}
        style={
          {
            fontFamily: "Roboto",
            fontSize: `14px`,
            lineHeight: `22px`,
            fontWeight: 700,
            color: "#0E2A48",
          }
        }
      >
        {errorMessage}
      </div>
    </div>
  );
}

export default ErrorModal;
export { ErrorModal };
