import { SessionDetails } from "../../../auth/core/Auth";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  isLoggedIn: boolean;
  sessionDetails?: SessionDetails | null | undefined;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class AuthSessionChanged extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { isLoggedIn, sessionDetails, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.AUTH,
      type: `AUTH_SESSION_CHANGED`,
      data: {
        isLoggedIn,
        sessionDetails,
      },
    });
  }
}

export { AuthSessionChanged };
