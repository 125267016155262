import { Portal } from "@gorhom/portal";
import { BackgroundGradient } from "../misc/BackgroundGradient";
import { ScreenLayout } from "../layouts/ScreenLayout";
import { LogoHeader } from "../headers/LogoHeader";
import { HomeSessionsLoadingSkeleton } from "./HomeSessionsLoadingSkeleton";

export type Props = {
  show?: boolean;
};

function AppLoadingSkeleton(props: Props) {
  const { show } = props;

  if (!show) return null;

  return (
    <div
      className={`ui-block fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center text-center`}
      style={{
        zIndex: 9999999,
      }}
    >
      <BackgroundGradient />

      <ScreenLayout
        header={
          <LogoHeader 
            type={`grayscale`} 
            style={{
              opacity: 0.3,
            }} 
          />
        }
        content={
          <div
            className={`w-full flex-grow`}
          >
            <HomeSessionsLoadingSkeleton />
          </div>
        }
      />
    </div>
  );
}

export { AppLoadingSkeleton };