import _ from "lodash";

// TODO MXM Error Handling Tweaks
//  - Handle other possible error messages:
//    - "Input string was not in a correct format." - ie. expiryYear = "AAAA"
//  - Report responseCode to Sentry and maybe to the user

export const testCustomerId = 10000000841248;

export const testCards = [
  // Visa
  {
    number: "4242424242424242",
    issuer: "VISA",
    isDebit: false,
    isPrePaid: false,
  },
  {
    number: "4012888888881881",
    issuer: "VISA",
    isDebit: false,
    isPrePaid: false,
  },
  // Visa - Debit
  {
    number: "4000056655665556",
    issuer: "VISA",
    isDebit: true,
    isPrePaid: false,
  },
  // MasterCard
  {
    number: "5555555555554444",
    issuer: "MASTERCARD",
    isDebit: false,
    isPrePaid: false,
  },
  // MasterCard - Debit
  {
    number: "5200828282828210",
    issuer: "MASTERCARD",
    isDebit: true,
    isPrePaid: false,
  },
  // MasterCard - Pre-Paid
  {
    number: "5105105105105100",
    issuer: "MASTERCARD",
    isDebit: false,
    isPrePaid: true,
  },
  // American Express
  {
    number: "378282246310005",
    issuer: "AMERICAN_EXPRESS",
    isDebit: false,
    isPrePaid: false,
  },
  // American Express - Alt 1
  {
    number: "371449635398431",
    issuer: "AMERICAN_EXPRESS",
    isDebit: false,
    isPrePaid: false,
  },
  // Discover
  {
    number: "6011111111111117",
    issuer: "DISCOVER",
    isDebit: false,
    isPrePaid: false,
  },
  // Discover
  {
    number: "6011000990139424",
    issuer: "DISCOVER",
    isDebit: false,
    isPrePaid: false,
  },
  // Diner's Club
  {
    number: "30569309025904",
    issuer: "DINERS_CLUB",
    isDebit: false,
    isPrePaid: false,
  },
  // Diner's Club
  {
    number: "38520000023237",
    issuer: "DINERS_CLUB",
    isDebit: false,
    isPrePaid: false,
  },
  // JCB
  {
    number: "3530111333300000",
    issuer: "JCB",
    isDebit: false,
    isPrePaid: false,
  },
  // JCB - Alt 1
  {
    number: "3566002020360505",
    issuer: "JCB",
    isDebit: false,
    isPrePaid: false,
  },
];

export const validCard = {
  number: testCards[0].number,
  expiryMonth: "01",
  expiryYear: "2099",
  cvv: "123",
  avsZip: "12345",
};

export const testScenarios = {
  APPROVED_PAYMENT: {
    number: "4100000000000001",
    amount: 900, // less than $10
  },
  DECLINED_PAYMENT: {
    number: "4100000000000001",
    amount: 1100, // more than $10
  },
  INCORRECT_NUMBER: {
    number: "1232424242424242",
    error: {
      message: "Request failed with status code 400",
      name: "AxiosError",
      code: "ERR_BAD_REQUEST",
      response: {
        status: 400,
        statusText: "Bad Request",
        data: {
          errorCode: "ValidationError",
          message: "Validation error happened",
          details: ["Card number is invalid"],
          // responseCode: 'efmipZG9LwJJJVpZZIKRuOQ', // This is an example - This responseCode changes
        },
      },
    },
  },
  INVALID_EXPIRY: {
    expiryMonth: "13",
    // This evidently is not triggering an error in the API, even though the docs say just provide a
    // year in the past. We have to provide something that is blatently wrong like 12345. Providing
    // letters throws a "Input string was not in a correct format." Validation Error
    // expiryYear: "1980",
    expiryYear: "12345",
    error: {
      message: "Request failed with status code 400",
      name: "AxiosError",
      code: "ERR_BAD_REQUEST",
      response: {
        status: 400,
        statusText: "Bad Request",
        data: {
          errorCode: "ValidationError",
          message: "Validation error happened",
          details: [
            "Year, Month, and Day parameters describe an un-representable DateTime.",
          ],
          // responseCode: 'eB6CeQjqwZ5oEfzX0rG9JmA', // This is an example - This responseCode changes
        },
      },
    },
  },
  INVALID_CVV: {
    // cvv: "88",
    cvv: "AA",
    error: {
      message: "Request failed with status code 400",
      name: "AxiosError",
      code: "ERR_BAD_REQUEST",
      response: {
        status: 400,
        statusText: "Bad Request",
        data: {
          errorCode: "ValidationError",
          message: "Validation error happened",
          details: ["*** TBD ***"],
          // responseCode: 'eB6CeQjqwZ5oEfzX0rG9JmA', // This is an example - This responseCode changes
        },
      },
    },
  },
  INVALID_AVS_ZIP: {
    avsZip: "123",
    error: {
      message: "Request failed with status code 400",
      name: "AxiosError",
      code: "ERR_BAD_REQUEST",
      response: {
        status: 400,
        statusText: "Bad Request",
        data: {
          errorCode: "ValidationError",
          message: "Validation error happened",
          details: ["Incorrect zipcode format"],
          // responseCode: 'eB6CeQjqwZ5oEfzX0rG9JmA', // This is an example - This responseCode changes
        },
      },
    },
  },
};

export function simulateValidationError(
  errorDetail: string = "Simulated Validation Error"
) {
  const error = new Error("Request failed with status code 400");
  error.name = "AxiosError";
  error.code = "ERR_BAD_REQUEST";
  error.response = {
    status: 400,
    statusText: "Bad Request",
    data: {
      errorCode: "ValidationError",
      message: "Validation error happened",
      details: [errorDetail],
      responseCode: "ahdyef4edif5fecie3s2ke8", // This is an example - This responseCode changes
    },
  };

  return error;
}

export function simulateNonValidationError(
  errorDetail: string = "Simulated Non-Validation Error"
) {
  const error = new Error("Request failed with status code 400");
  error.name = "AxiosError";
  error.code = "ERR_BAD_REQUEST";
  error.response = {
    status: 400,
    statusText: "Bad Request",
    data: {
      errorCode: "[Unknown Error - Not Sure What Would Show Up Here]",
      message: "An Unknown error happened",
      details: [errorDetail],
      responseCode: "ahdyef4edif5fecie3s2ke8", // This is an example - This responseCode changes
    },
  };

  return error;
}

export function isInvalidCardError(error: Error | unknown) {
  return (
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    error?.response?.data?.details?.[0] ===
    testScenarios.INCORRECT_NUMBER.error.response.data.details[0]
  );
}

export function isInvalidExpiryError(error: Error | unknown) {
  return (
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    error?.response?.data?.details?.[0] ===
    testScenarios.INVALID_EXPIRY.error.response.data.details[0]
  );
}

export function isInvalidCvvError(error: Error | unknown) {
  const validationErrorMessageContainsStrCvv =
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    _.isString(error?.response?.data?.details?.[0]) &&
    new RegExp("^.*\\s+cvv\\s+.*$").test(error.response.data.details[0]);

  const validationErrorIsExactlyAsExpected =
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    error?.response?.data?.details?.[0] ===
    testScenarios.INVALID_CVV.error.response.data.details[0];

  return (
    validationErrorMessageContainsStrCvv || validationErrorIsExactlyAsExpected
  );
}

export function isInvalidAvsZipError(error: Error | unknown) {
  const validationErrorMessageContainsStrZip =
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    _.isString(error?.response?.data?.details?.[0]) &&
    new RegExp("^.*\\s+zip\\s+.*$").test(error.response.data.details[0]);

  const validationErrorIsExactlyAsExpected =
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    error?.response?.data?.details?.[0] ===
    testScenarios.INVALID_AVS_ZIP.error.response.data.details[0];

  return (
    validationErrorMessageContainsStrZip || validationErrorIsExactlyAsExpected
  );
}

export function isUnknownValidationError(error: Error | unknown) {
  return (
    // axios.isAxiosError(error) &&
    error?.response?.data?.errorCode === "ValidationError" &&
    !isInvalidCardError(error) &&
    !isInvalidExpiryError(error) &&
    !isInvalidCvvError(error) &&
    !isInvalidAvsZipError(error)
  );
}

export function isUnknownError(error: Error | unknown) {
  return (
    // axios.isAxiosError(error) &&
    !!error?.response && error?.response?.data?.errorCode !== "ValidationError"
  );
}

export enum ErrorType {
  VALIDATION = "VALIDATION",
  UNKOWN = "UNKOWN",
}

export enum ErrorSubType {
  INVALID_CARD_NUMBER = "INVALID_CARD_NUMBER",
  INVALID_EXPIRY = "INVALID_EXPIRY",
  INVALID_CVV = "INVALID_CVV",
  INVALID_AVS_ZIP = "INVALID_AVS_ZIP",
  UNKNOWN = "UNKNOWN",
}

export const errorTypes = [
  {
    type: ErrorType.VALIDATION,
    subType: ErrorSubType.INVALID_CARD_NUMBER,
    check: isInvalidCardError,
  },
  {
    type: ErrorType.VALIDATION,
    subType: ErrorSubType.INVALID_EXPIRY,
    check: isInvalidExpiryError,
  },
  {
    type: ErrorType.VALIDATION,
    subType: ErrorSubType.INVALID_CVV,
    check: isInvalidCvvError,
  },
  {
    type: ErrorType.VALIDATION,
    subType: ErrorSubType.INVALID_AVS_ZIP,
    check: isInvalidAvsZipError,
  },
  {
    type: ErrorType.VALIDATION,
    subType: ErrorSubType.UNKNOWN,
    check: isUnknownValidationError,
  },
  {
    type: ErrorType.UNKOWN,
    subType: ErrorSubType.UNKNOWN,
    check: isUnknownError,
  },
];

export function evaluateError(
  error: Error | unknown
): { type: ErrorType; subType: ErrorSubType } | null {
  const errorType = _.find(errorTypes, ({ check }) => check(error));

  if (errorType) {
    const { type, subType } = errorType;
    return { type, subType };
  }

  return null;
}
