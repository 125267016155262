import _ from "lodash";

import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { StoreVaultedCardAccountMutationVariables } from "../../../generatedQueries";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  mutationVariables: StoreVaultedCardAccountMutationVariables;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class StoreVaultedCardAccountRequest extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationVariables, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `STORE_VAULTED_CARD_ACCOUNT.REQUEST`,
      data: {
        mutationVariables:
          StoreVaultedCardAccountRequest.maskCardAccount(mutationVariables),
      },
    });
  }

  public static maskCardAccount = (cardAccount: Data["mutationVariables"]) => {
    if (!_.isString(cardAccount.cardAccountToken)) return cardAccount;

    return {
      ...cardAccount,
      cardAccountToken: `${cardAccount.cardAccountToken.substring(
        0,
        3
      )}...${cardAccount.cardAccountToken.substring(
        cardAccount.cardAccountToken.length - 3,
        cardAccount.cardAccountToken.length + 1
      )}`,
    };
  };
}

export { StoreVaultedCardAccountRequest };
