/**
 * Base error class that our application can interact with
 */
class AppError<
  TContext extends Record<string, any> = object,
  Tags extends Record<string, any> = object
> extends Error {
  public readonly isAppError: boolean = true;

  public reportToSentry: boolean = true;
  public bypassSentryDataScrubbing: boolean = false;

  public context: TContext = {} as TContext;
  public tags: Tags = {} as Tags;

  public static toAppError = (error: unknown) => {
    // @ts-ignore
    if (error?.isAppError) {
      return error as InstanceType<typeof AppError>;
    }

    return null;
  };
}

export { AppError };
