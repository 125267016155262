import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Remember } from "./remember";

export type Options<TValue extends any> = {
  remember?: InstanceType<typeof Remember<TValue>>;
  namespace?: string;
  key?: string;
  ttl?: number; // Seconds
  value?: TValue; // Must be able to be serialized into a JSON string
};

export type State<TValue extends any> = {
  value?: TValue;
  isLoaded: boolean;
};

// TODO Maybe make this context powered like react-query so multiple consumers of the same namespace + key get the same value w/o stepping on each other's toes
// TODO Consider what happens when an app version or storage schema changes - How will we delete/migrate old keys?
/**
 * ...
 */
function useRemember<TValue extends any | undefined>(
  options: Options<TValue>
) {
  const {
    remember: rememberInstance,
    key = ``,
    namespace,
    ttl,
    value: newValue,
  } = options;

  if (!key && !rememberInstance) {
    throw new Error(`Missing "remember" or "key" values in useRemember`);
  }

  const remember = useRef(
    rememberInstance ??
      new Remember<TValue>({
        key,
        namespace,
        ttl,
      })
  );

  const [state, setState] = useState<State<TValue>>({
    value: undefined,
    isLoaded: false,
  });

  const { value, isLoaded } = state;

  /**
   * Clear value from storage
   */
  const clearValue = useCallback(async () => {
    try {
      setState((state) => ({
        ...state,
        value: undefined,
      }));

      await remember.current.clearValue();
    } catch (error: unknown) {
      console.error(error);
    }
  }, [setState]);

  // TODO Figure out why calling getValue().then(value => ...) results in value being undefined, but await works
  /**
   * Get value from storage
   */
  const getValue = useCallback(async () => {
    return await remember.current.getValue();
  }, []);

  /**
   * Set value in storage
   */
  const setValue = useCallback(
    async (value: TValue, ttl?: number) => {
      setState((state) => ({
        ...state,
        value,
      }));

      await remember.current.setValue(value);
    },
    [setState]
  );

  /**
   * Restore value from storage (on mount)
   */
  const restoreValue = useCallback(async () => {
    const value = await getValue();

    setState((state) => ({
      ...state,
      value,
      isLoaded: true,
    }));
  }, [getValue]);

  useEffect(() => {
    restoreValue();
  }, []);

  useEffect(() => {
    if (newValue !== undefined) {
      setValue(newValue);
    }
  }, [newValue, setValue]);

  return useMemo(() => {
    return {
      value: newValue ?? value,
      getValue,
      setValue,
      clearValue,
      isLoaded,
    };
  }, [value, newValue, getValue, setValue, clearValue, isLoaded]);
}

export default useRemember;
export { useRemember };