import _ from "lodash";
import { ReactNode } from "react";

export type Props = {
  texts: (string | ReactNode)[];
  color1?: string;
  color2?: string;
};

function HelperText(props: Props) {
  const { texts, color1 = "#0A84FF", color2 = "#0E2A48" } = props;

  const textEls = texts.map((text, i) => {
    let textEl = text;

    if (typeof text === "string") {
      textEl = (

        <span
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `12px`,
            lineHeight: `18px`,
            color: i % 2 === 0 ? color1 : color2,
            textTransform: "uppercase",
            marginRight: texts.length > 1 && i < texts.length - 1 ? 5 : 0,
          }}
          key={i}
        >
          {text}
        </span>
      );
    }

    return textEl;
  });

  return (
    <div
      style={{
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        fontSize: `12px`,
        lineHeight: `18px`,
        color: `#0E2A48`,
        marginBottom: `10px`,
      }}
    >
      {textEls}
    </div>
  );
}

export { HelperText };
