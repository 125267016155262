import { UserQuery } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  user?: UserQuery["users"][number] | null | undefined;
  items?: Record<string, { show: boolean }>;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class AccountSetupStarted extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { user, items, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.ACCOUNT_SETUP,
      type: `ACCOUNT_SETUP_STARTED`,
      data: {
        user,
        items,
      },
    });
  }
}

export { AccountSetupStarted };
