import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { ReactComponent as IconPhone } from "../../../../../../src/assets/icons/circle-phone-solid.svg";
import { event } from "../../../../../events/core/helpers";
import { ButtonPress } from "../../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../../events/event-sub-categories";

export type Props = {
  onChoose?: () => any;
};

function Choose(props: Props) {
  const { onChoose = () => {} } = props;

  return (
    <div
      style={{
        width: `100%`,
      }}
    >
      <FullWidthButton
        contentTop={`Sign in with Phone Number`}
        type={`secondary-white`}
        iconLeft={
          <IconPhone
            style={{
              height: `20px`,
              width: `20px`,
            }}
          />
        }
        onClick={() => {
          event(
            new ButtonPress({
              buttonName: `Sign in with Phone Number`,
              buttonSlug: `SIGN_IN_WITH_PHONE_NUMBER`,
              subCategory: EventSubCategories.AUTH,
            })
          );

          onChoose();
        }}
      />
    </div>
  );
}

export { Choose };
