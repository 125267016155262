import { Query, QueryCache, QueryClient, QueryClientProvider as RQQueryClientProvider, QueryKey } from '@tanstack/react-query';
import { ReactNode, useRef } from 'react';

export type Props = {
  children?: ReactNode;
  onQueryError?: (
    error: unknown,
    query: Query<unknown, unknown, unknown, QueryKey>
  ) => void;
};

export default function QueryClientProvider(props: Props) {
  const { children, onQueryError = () => {} } = props;

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 1000 * 60 * 60 * 24, // 24 Hours
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          onQueryError(error, query);
        },
      }),
    })
  ).current;

  return (
    <RQQueryClientProvider
      client={queryClient}
    >
      {children}
    </RQQueryClientProvider>
  );
}