import { SummaryCard } from "./SummaryCard";
import { ReactComponent as IconCard } from "../../../src/assets/icons/icon-cc-generic.svg";
import { TextSkeleton } from "../skeletons/TextSkeleton";
import usePaymentMethods from "../../queries/payment-methods/usePaymentMethods";
import _ from "lodash";
import { useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import { useLiveNow } from "../../lib/useLiveNow";
import { isActive, paymentMethodIsExpired } from "../../lib/utils";
import useDeletePaymentMethod from "../../queries/payment-methods/useDeletePaymentMethod";
import { useCallback } from "react";
import { useHandleError } from "../../lib/error-handling";
import { useAlertModal } from "../modals/alert-modal";
import useCachePrimer from "../../queries/useCachePrimer";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../events/event-sub-categories";

export type Props = {
  skeletonMode?: `PROMPT` | `EMPTY`;
  paymentMethodId: number | null | undefined;
  showEditIcon?: boolean;
  onClick?: () => any;
  showDeleteButton?: boolean;
  disabled?: boolean;
};

function PaymentMethodCard(props: Props) {
  const { skeletonMode, paymentMethodId, showEditIcon = false, onClick, showDeleteButton = false, disabled } = props;

  const handleError = useHandleError();
  const alert = useAlertModal();
  const cachePrimer = useCachePrimer();
  const auth = useMultiAuth();

  const { query: paymentMethodsQuery, paymentMethods } = usePaymentMethods();
  const { parkableTransactions } = useRecentParkableTransactions();

  const paymentMethod = _.find(paymentMethods, { id: paymentMethodId });
  const now = useLiveNow();
  const isParked = parkableTransactions.filter(pt => pt.payment_method_id === paymentMethodId && isActive(pt, now)).length > 0;
  const deleteButtonDisabled = isParked;
  const isPaymentMethodExpired = paymentMethod ? 
    paymentMethodIsExpired(
      paymentMethod.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_month,
      paymentMethod.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_year
    ) 
    : false;

  const { mutation } = useDeletePaymentMethod({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: (resp, variables, context) => {
      cachePrimer.PaymentMethods.removeById(
        [resp?.deletedPaymentMethodId],
        auth.session?.sessionDetails.userId ?? -1
      );

      paymentMethodsQuery.refetch();
    }
  });

  const onClickDelete = useCallback(() => {
    if (isParked) {
      alert.show({
        title: [`Cannot`, `Delete`],
        message: `An active or processing parking session is still using this payment method. Please try again later.`,
      });
    } else {
      alert.show({
        title: [`Are You`, `Sure?`],
        message: `Are you sure you want to delete this payment method?`,
        buttons: [
          {
            contentTop: `Delete`,
            type: `primary-red`,
            onClick: () => {
              event(
                new ButtonPress({
                  buttonName: `Delete Card`,
                  buttonSlug: `PAYMENT_METHOD.DELETE`,
                  subCategory: EventSubCategories.PAYMENT_METHODS,
                })
              );
              
              mutation.mutate({
                paymentMethodId,
              });
            },
          },
          {
            contentTop: `Cancel`,
            type: `tertiary-transparent`,
          }
        ],
      });
    }
  }, [isParked, alert, mutation, paymentMethodId]);

  let rightTopContent = null;
  let rightBottomContent = null;
  
  if (skeletonMode === `PROMPT`) {
    rightTopContent = (
      <div>
        <span
          className={`uppercase`}
          style={{
            color: `#0A84FF`,
          }}
        >
          Next:
        </span>
        {` `}Choose Payment Method...
      </div>
    );
  } else if (skeletonMode === `EMPTY` || (!!paymentMethodId && !paymentMethod)) {
    rightTopContent = (
      <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
    );
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (paymentMethod) {
    rightTopContent = paymentMethod?.alias ?? paymentMethod?.mx_merchant_payment_method_metas?.[0].meta_card_account_card_type ?? `Credit / Debit Card`;
  }

  if (skeletonMode === `PROMPT` || skeletonMode === `EMPTY`) {
    rightBottomContent = (
      <TextSkeleton fontSize={`10px`} lineHeight={`14px`} />
    );
  } else if (paymentMethod) {
    rightBottomContent = <><span>{paymentMethod?.alias ? `${paymentMethod?.mx_merchant_payment_method_metas?.[0].meta_card_account_card_type} ` : ``}Ending in: {paymentMethod?.mx_merchant_payment_method_metas?.[0].meta_card_account_last_4}</span> - <span style={{ color: isPaymentMethodExpired ? `red`:`#0d2a47`}}>Exp: {paymentMethod?.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_month} / {paymentMethod?.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_year}</span></>
  }

  return (
    <SummaryCard
      icon={
        <IconCard
          className={`relative`}
          style={{
            top: `-1px`,
            width: `14px`,
            // height: `11px`,
          }}
        />
      }
      topContent={`Payment Method`}
      rightTopContent={rightTopContent}
      rightBottomContent={rightBottomContent}
      showEditIcon={showEditIcon}
      showDeleteButton={showDeleteButton}
      deleteButtonDisabled={deleteButtonDisabled || mutation.isLoading || mutation.isSuccess}
      onClickDelete={onClickDelete}
      onClick={onClick}
      disabled={disabled || mutation.isLoading || mutation.isSuccess}
    />
  );
}

export { PaymentMethodCard };