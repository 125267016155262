import { AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";

import { AppError } from "./AppError";

export type CardAccount = {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  avsZip: string;
  avsStreet?: string;
  cvv: string;
};

export type AxiosErrorRequest = {
  responseURL?: string | null | undefined;
  status?: number | null | undefined;
  _headers?: Record<string, any> | null | undefined;
  _sent?: boolean | null | undefined;
  _timedOut?: boolean | null | undefined;
  _url?: string | null | undefined;
  __sentry_xhr_v2__?:
    | {
        body?: string | null | undefined;
        method?: string | null | undefined;
        request_headers?: Record<string, any> | null | undefined;
        status_code?: number | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type AxiosErrorResponse = {
  config?: AxiosRequestConfig | null | undefined;
  data?: AxiosResponse[`data`]; // ie. http://zsl.io/i/kq6oBv
  headers?: Record<string, any> | null | undefined;
  request: AxiosErrorRequest;
  status?: number | null | undefined;
  statusText?: string | null | undefined;
};

export type MaybeAxiosError = {
  code?: string | null | undefined;
  config?: AxiosRequestConfig | null | undefined;
  message?: string | null | undefined;
  name?: string | null | undefined;
  request?: AxiosErrorRequest | null | undefined;
  response?: AxiosErrorResponse | null | undefined;
};

export type Context = {
  cardAccount: CardAccount;
  axiosErrorCode?: MaybeAxiosError[`code`] | null | undefined;
  axiosErrorMessage?: MaybeAxiosError[`message`] | null | undefined;
  axiosErrorName?: MaybeAxiosError[`name`] | null | undefined;
  mxmErrorDetail?: string | null | undefined;
  request:
    | {
        status?: AxiosErrorRequest[`status`];
        headers?: AxiosErrorRequest[`_headers`];
        sent?: AxiosErrorRequest[`_sent`];
        timedOut?: AxiosErrorRequest[`_timedOut`];
        // TODO See if the info in __sentry_xhr_v2__ is redundant, or should be added
      }
    | null
    | undefined;
  response:
    | {
        data: AxiosErrorResponse[`data`];
        headers: AxiosErrorResponse[`headers`];
        status: AxiosErrorResponse[`status`];
        statusText: AxiosErrorResponse[`statusText`];
      }
    | null
    | undefined;
  sensitiveData?:
    | {
        originalError: MaybeAxiosError | null | undefined;
        cardAccount: CardAccount;
      }
    | null
    | undefined;
};

export type Tags = {
  "response.status"?: AxiosErrorResponse[`status`] | null | undefined;
  "response.statusText"?: AxiosErrorResponse[`statusText`] | null | undefined;
};

export type ConstructorArgs = {
  error?: MaybeAxiosError | null | undefined;
  cardAccount: CardAccount;
  includeSensitiveData?: boolean;
};

/**
 * Base error class that our application can interact with
 */
class CreditCardVaultFailedError extends AppError<Context, Tags> {
  public code: MaybeAxiosError[`code`];

  public request: Context[`request`];
  public response: Context[`response`];

  constructor(args: ConstructorArgs) {
    const { error, cardAccount, includeSensitiveData = false } = args;

    const originalErrorMessage = error?.message;

    super(originalErrorMessage ?? `Unknown Reason`);

    this.name = `CreditCardVaultFailedError`;

    this.bypassSentryDataScrubbing = true;
    // TODO Maybe do not report for known 400 errors like invalid card numbers, etc
    this.reportToSentry = true;

    const lastFour = _.toString(
      cardAccount.number.slice(cardAccount.number.length - 4)
    );

    this.context = {
      cardAccount: {
        ...cardAccount,
        cvv: `xxx`,
        number: `xxxxxxxxxxxx${lastFour}`,
      },
      axiosErrorCode: error?.code,
      axiosErrorMessage: error?.message,
      axiosErrorName: error?.name,
      mxmErrorDetail: error?.response?.data?.details?.[0],
      request: {
        status: error?.request?.status,
        // TODO Make sure there isn't sensitive info in the headers (shouldn't be with MXM atm)
        headers: error?.request?._headers,
        sent: error?.request?._sent,
        timedOut: error?.request?._timedOut,
      },
      response: error?.response
        ? {
            data: error?.response?.data,
            headers: error?.response?.headers,
            status: error?.response?.status,
            statusText: error?.response?.statusText,
          }
        : null,
      sensitiveData: includeSensitiveData
        ? {
            originalError: error,
            cardAccount,
          }
        : null,
    };

    this.tags = {
      "response.status": this.context.response?.status,
      "response.statusText": this.context.response?.statusText,
    };

    this.request = this.context.request;
    this.response = this.context.response;
  }
}

export { CreditCardVaultFailedError };
