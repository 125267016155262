import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { Events } from "./core/Events";
import { EventsBatch, SubmitterResponse } from "./core/types";
import { environment } from "../config/config";
import uuid from 'uuid-random';
import usePrevious from "../lib/usePrevious";
import { useNetwork } from "../lib/useNetwork";
import { useMultiAuth } from "../auth/core/useMultiAuth";
import { useUnauthenticatedCredentials } from "./useUnauthenticatedCredentials";
import useSubmitEvents from "../queries/events/useSubmitEvents";
import env from "../config/env";

export type Props = {
  children: ReactNode;
};

function EventsProvider(props: Props) {
  const { children } = props;

  // const appState = useAppState();
  const { isOnline: online } = useNetwork();
  const { isLoading, session, getSession } = useMultiAuth();
  const { get: getUnauthenticatedCredentials } =
    useUnauthenticatedCredentials();

  const isEnabledRef = useRef<boolean>(false);
  const isEnabled = useCallback(() => {
    return isEnabledRef.current;
  }, []);

  const isAuthenticatedRef = useRef<boolean>(false);

  useEffect(() => {
    isEnabledRef.current = online === true && !isLoading;
  }, [online, isLoading, session]);

  useEffect(() => {
    isAuthenticatedRef.current = (!isLoading) && (!!session);
  }, [isLoading, session]);
 
  const {
    publicMutation: { mutateAsync: mutateAsyncPublic  },
    privateMutation:  { mutateAsync: mutateAsyncPrivate },
  } = useSubmitEvents();

  const submitEvents = useCallback(
    async (eventsBatches: EventsBatch[]): Promise<SubmitterResponse> => {
      const isAuthenticated = !!(await getSession());

      const eventsBatchesEncoded = eventsBatches.map(eventsBatch => ({
        ...eventsBatch,
        events: eventsBatch.events.map(event => ({
          ...event,
          data: JSON.stringify(event.data ?? {}),
        })),
      }));

      if (isAuthenticated) {
        const result = await mutateAsyncPrivate({
          // TODO Fix these types
          // @ts-ignore
          eventsBatches: eventsBatchesEncoded,
          // device: deviceRef.current,
        });

        // console.log('mutateAsyncPrivate - result', result)

        // TODO See if it's possible to not have to coerce this
        return result?.result as SubmitterResponse;
      } else {
        const unauthenticatedCredentials = (await getUnauthenticatedCredentials())?.unauthenticatedData ?? null;
        const result = await mutateAsyncPublic({
          // TODO Fix these types
          // @ts-ignore
          eventsBatches: eventsBatchesEncoded,
          unauthenticatedCredentials,
          // device: deviceRef.current,
        });

        // console.log('mutateAsyncPublic - result', result, unauthenticatedCredentials)

        // TODO See if it's possible to not have to coerce this
        return result?.result as SubmitterResponse;
      }
    },
    [mutateAsyncPrivate, mutateAsyncPublic, getUnauthenticatedCredentials, getSession]
  );

  const eventsRef = useRef<InstanceType<typeof Events> | null>(null);

  const isLoadingRef = useRef<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const load = async function () {
      if (isLoadingRef.current) return;

      isLoadingRef.current = true;

      const storage = {
        getItem: async (key: string) => {
          return localStorage.getItem(key);
        },
        setItem: async (key: string, value: string) => {
          localStorage.setItem(key, value);
        },
        removeItem: async (key: string) => {
          localStorage.removeItem(key);
        },
      };

      eventsRef.current = Events.getInstance({
        storage,
        submitter: {
          isEnabled: isEnabled,
          submit: submitEvents,
          onError: (e) => {
            reportError(e);
          }
        },
        makeUuid: uuid,
        debug: env(`EVENTS_DEBUG`, false) as boolean,
        onError: (e) => {
          reportError(e);
        }
      });

      await eventsRef.current.loadEventsQueue();

      eventsRef.current.submitOnEvents([
        `ACCOUNT_SETUP_FINISHED`,
        `OAUTH_FAILED`,
        `OTP_SEND_FAILED`,
        `OTP_VERIFY_FAILED`,
        `AUTH_SESSION_CHANGED`,
        // `PARKED_SPLASH_SHOWN`, // TODO
        `CREATE_PARKABLE_TRANSACTION.RESPONSE`,
        // `TIME_EXTENDED_SPLASH_SHOWN`, // TODO
        `EXTEND_TIME.RESPONSE`,
        `CREATE_SUPPORT_TICKET.RESPONSE`,
      ]);


      setIsLoaded(true);
    };

    load();

    return () => {
      isLoadingRef.current = false;
      setIsLoaded(false);
    }
  }, []);

  // TODO
  // const prevAppState = usePrevious(appState);
  // useEffect(() => {
  //   const becameActive =
  //     prevAppState !== undefined &&
  //     prevAppState !== `active` &&
  //     appState === `active`;
  //   const becameBackground =
  //     prevAppState !== undefined &&
  //     prevAppState !== `background` &&
  //     appState === `background`;

  //   if (becameActive || becameBackground) {
  //     setTimeout(() => {
  //       eventsRef.current?.submitEvents();
  //     }, 500);
  //   }
  // }, [appState]);

  if (!isLoaded) return null;

  return children;
}

export { EventsProvider };
