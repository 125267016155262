import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";
import { PaymentMethodsDocument, PaymentMethodsQuery } from "../../generatedQueries";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import queryKeys from "../query-keys";
import { simulateNetworkError } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type PaymentMethod = PaymentMethodsQuery["payment_methods"][number];

async function fetchPaymentMethods(args: { signal?: AbortSignal | null }) {
  const { signal } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  return (await request({
    url: getGraphqlEndpoint() as string,
    document: PaymentMethodsDocument,
    variables: {},
    requestHeaders,
    signal,
  })).payment_methods;
}

type Options = {
  //
};

export default function usePaymentMethods({ }: Options = {}) {
  const auth = useMultiAuth();
  // const handleError = useDefaultQueryErrorHandler();

  const query = useQuery<PaymentMethodsQuery["payment_methods"]>(
    queryKeys.PaymentMethods(auth.session?.sessionDetails.userId ?? -1),
    async ({ signal }) => {
      return await fetchPaymentMethods({ signal });
    },
    {
      enabled: !!auth.session,
      staleTime: 5 * 60 * 1000, // 5min
      // onError: (error) => {
      //   handleError(error);
      // },
    }
  );

  // useThrowQueryErrorToBoundary(query);

  const paymentMethods = useMemo(() => {
    if (!_.isArray(query.data)) return [];

    return _.chain(query.data)
      .map((paymentMethod) => ({
        ...paymentMethod,
      }))
      .uniqBy("id")
      .filter((pm) => !pm.deleted_at)
      .value();
  }, [query.data]);

  return {
    query,
    paymentMethods,
  };
}
