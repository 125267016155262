function CardsListLoadingSkeleton() {
  return (
    <>
      <div
        className={`w-full rounded-md`}
        style={{
          height: `71px`,
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          opacity: 0.05,
          backgroundColor: `#000`,
        }}
      />

      <div className={`mb-2`} />

      <div
        className={`w-full rounded-md`}
        style={{
          height: `71px`,
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          opacity: 0.05,
          backgroundColor: `#000`,
        }}
      />
    </>
  );
}

export { CardsListLoadingSkeleton };