import { Fragment } from "react";
import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { Parkables } from "../../../../../queries/parkables/searchParkables";
import { event } from "../../../../../events/core/helpers";
import { ButtonPress } from "../../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../../events/event-sub-categories";

export type Props = {
  parkables: Parkables;
  onClick: (parkable: Parkables[number]) => any;
  onCancel: () => any;
};

function Choose(props: Props) {
  const { parkables, onClick, onCancel } = props;

  return (
    <div className={`w-full mb-2`}>
      <div
        className={`text-center mb-2`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `16px`,
          lineHeight: `20px`,
          color: `#0E2A48`,
        }}
      >
        Which city are you in?
      </div>
      <div className={`w-full mb-4`}>
        {parkables.map((parkable, i) => {
          const areaName = parkable.parkable_parkable_groups[0]?.parkable_group.name;

          return (
            <Fragment key={parkable.id}>
              <button
                onClick={(e) => {
                  event(new ButtonPress({
                    buttonSlug: `CHOOSE_CITY`,
                    buttonName: `Choose City: ${parkable.parking_authority.city}, ${parkable.parking_authority.state}`,
                    subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                  }));

                  e.preventDefault();
                  onClick(parkable);
                }}
                className={`w-full block rounded-md text-center`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `16px`,
                  color: `#0E2A48`,
                  borderWidth: `1px`,
                  borderColor: `#E7EAF7`,
                  height: `50px`,
                  backgroundColor: `#fff`,
                }}
              >
                {parkable.parking_authority.city}, {parkable.parking_authority.state} {areaName ? `(${areaName})` : ``}
              </button>

              {i < (parkables.length - 1) && (
                <div className={`mb-2`}/>
              )}
            </Fragment>
          )
        })}
      </div>
      <div className={`w-full`}>
        <FullWidthButton
          contentTop={`Search Again`}
          type={`tertiary-transparent`}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
}

export { Choose };