import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import uuid from 'uuid-random';
import { useRemember } from "../useRemember";
import { environment as env  } from "../../config/config";

export type SessionParameters = {
  parkableId?: number | undefined;
  vehicleId?: number | undefined;
  time?: number | undefined;
  paymentMethodId?: number | undefined;
};

export type SessionInProgress = {
  startedAt: number; // timestamp
  idempotentKey: string;
  parkableTransactionId?: number;
  sessionParameters: SessionParameters;
};

export type State = {
  sessionInProgress: SessionInProgress | null;
};

const emptyStateValue = {
  sessionInProgress: null,
};

function useProvideSessionInProgress() {
  const rnNavigate = useNavigate();
  const {
    getValue: getRememberedValue, 
    setValue: setRememberedValue,
  } = useRemember<State>({
    namespace: `session-in-progress`,
    key: `session-in-progress`,
    ttl: env.isProduction ? 300 /* 5min */ : 9999999,
  });

  const stateRef = useRef<State>(emptyStateValue);
  const [state, setState] = useState<State>(stateRef.current);

  useEffect(() => {
    const recallRememberedValue = async () => {
      const rememberedValue = await getRememberedValue();

      if (rememberedValue) {
        stateRef.current = rememberedValue;
        setState(stateRef.current);
      }
    };

    recallRememberedValue();
  }, []);

  const startNew = useCallback((parkableTransactionId?: number) => {
    const sessionInProgress: SessionInProgress = {
      startedAt: moment.utc().unix(),
      idempotentKey: uuid(),
      parkableTransactionId,
      sessionParameters: {},
    };

    const newState = {
      sessionInProgress,
    }

    stateRef.current = newState;
    setState(newState);
    setRememberedValue(newState);

    return sessionInProgress;
  }, [setRememberedValue]);

  const set = useCallback((sessionParameters: SessionParameters) => {
    if (!stateRef.current.sessionInProgress) {
      throw new Error("Cannot set parameters, there is no Session in progress");
    }

    const newState = {
      ...stateRef.current,
      sessionInProgress: {
        ...(stateRef.current.sessionInProgress as SessionInProgress),
        sessionParameters: {
          ...stateRef.current.sessionInProgress.sessionParameters,
          ...sessionParameters,
        },
      },
    };

    stateRef.current = newState;
    setState(newState);
    setRememberedValue(newState);
  }, []);

  const cancel = useCallback(() => {
    const newState = {
      sessionInProgress: null,
    };

    stateRef.current = newState;
    setState(newState);
    setRememberedValue(newState);
  }, []);

  const navigate = useCallback(async (
    to?: `PARKABLE` | `VEHICLE` | `TIME` | `PAYMENT` | `REVIEW`,
    options?: NavigateOptions
  ) => {
    const session = stateRef.current.sessionInProgress ?? startNew();
    const parkableTransactionId = stateRef.current.sessionInProgress?.parkableTransactionId;
    const isExtend = !!parkableTransactionId;
    const { parkableId, vehicleId, time, paymentMethodId } = session.sessionParameters;

    const routes = isExtend ? 
    {
      PARKABLE: `sessions`,
      VEHICLE: `sessions`,
      TIME: `sessions/extend/${parkableTransactionId}/time`,
      PAYMENT: `sessions`,
      REVIEW: `sessions/extend/${parkableTransactionId}/review`,
    } : {
      PARKABLE: `sessions/new/meter`,
      VEHICLE: `sessions/new/plate`,
      TIME: `sessions/new/time`,
      PAYMENT: `sessions/new/payment`,
      REVIEW: `sessions/new/review`,
    };

    let route = to;
    switch(route) {
      case `VEHICLE`:
        if (isExtend || !parkableId) {
          route = undefined;
        }
        break;
      case `TIME`:
        if (!isExtend && (!parkableId || !vehicleId)) {
          route = undefined;
        }
        break;
      case `PAYMENT`:
        if (isExtend || !parkableId || !vehicleId || !time) {
          route = undefined;
        }
        break;
      case `REVIEW`:
        if (!parkableId || !vehicleId || !time || !paymentMethodId) {
          route = undefined;
        }
        break;
    }

    if (!route) {
      if (
        (isExtend && (!!time)) ||
        (!isExtend && (!!parkableId || !!vehicleId || !!time || !!paymentMethodId))
      ) {
        route = `REVIEW`;
      } else if (!isExtend && !parkableId) {
        route = `PARKABLE`;
      } else if (!isExtend && !vehicleId) {
        route = `VEHICLE`;
      } else if (!time) {
        route = `TIME`;
      } else if (!isExtend && !paymentMethodId) {
        route = `PAYMENT`;
      } else {
        route = `REVIEW`;
      }
    }

    rnNavigate(routes[route], options);
  }, [rnNavigate, startNew]);

  return useMemo(() => ({
    stateRef,
    sessionInProgress: state.sessionInProgress,
    navigate,
    startNew,
    set,
    cancel,
  }), [state, navigate, startNew, set, cancel]);
}

export { useProvideSessionInProgress };