import { LoginEmailInput } from "./LoginEmailInput";
import { Login as BaseLogin } from "../../login/Login";

export type Props = {
  onCancel?: () => any;
  onChangeState?: (
    state: `IDLE` | `IN_PROGRESS` | `CANCELLED` | `ERROR` | `SUCCESS`,
    errorMessage: string | null
  ) => any;
};

function Login(props: Props) {
  const { onCancel = () => {}, onChangeState = () => {} } = props;

  return (
    <BaseLogin
      provider={`email`}
      helperTexts={{
        ENTER_CREDENTIAL: [
          [`Enter your`, `Email Address`],
          [],
          [`We'll send you an`, `Email`],
          [`with a`, `Login Code`],
        ],
        OTP_SENT: [
          [`Check your`, `Email`],
          [],
          [`Enter the`, `Login`, `we sent you`],
        ],
      }}
      helperSubtext={{
        ENTER_CREDENTIAL: null,
        OTP_SENT: `Check Your Spam Folder\n\nMessage may be delayed slightly. \nPlease be patient`,
      }}
      credentialComponent={({
        state: { uiState, isSending, isVerifying, isSuccess, credential },
        setCredential,
      }) => {
        return (
          <LoginEmailInput
            show={uiState === `ENTER_CREDENTIAL`}
            disabled={isSending || isVerifying || isSuccess}
            value={credential}
            onChange={(email) => {
              setCredential({ credential: email });
            }}
          />
        );
      }}
      onCancel={onCancel}
    />
  );
}

export { Login };
