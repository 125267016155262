import { FullWidthButton } from "../../../components/buttons/FullWidthButton";
import { LogoHeader } from "../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../components/layouts/ScreenLayout";
import { ParkableTransactionCard } from "../../../components/cards/ParkableTransactionCard";
import { useRecentParkableTransactions } from "../../../queries/parkable-transactions/useRecentParkableTransactions";
import { Fragment, ReactNode, useEffect } from "react";
import { useSessionInProgress } from "../../../lib/session-in-progress/useSessionInProgress";
import { RedirectIfNotAuthenticated } from "../../../components/misc/RedirectIfNotAuthenticated";
import { useHandleError } from "../../../lib/error-handling";
import { SessionInProgressNag } from "../../../components/nags/SessionInProgressNag";
import { UiBlock } from "../../../components/ui-blocks/UiBlock";
import { NotificationsSwitch } from "../../../components/buttons/NotificationsSwitch";
import { useNotificationsWarning } from "../../../lib/useNotificationsWarning";
import { RecentSessions } from "./components/RecentSessions";
import { BackgroundGradient } from "../../../components/misc/BackgroundGradient";
import { ReactComponent as Logo } from '../../../../src/assets/logo-meterez.svg';
import { ReactComponent as IconMeter } from "../../../../src/assets/icons/icon-meter-white.svg";
import { TimeDisplayNag } from "../../../components/nags/TimeDisplayNag";
import { HomeSessionsLoadingSkeleton } from "../../../components/skeletons/HomeSessionsLoadingSkeleton";
import { UiState } from "../../../components/skeletons/UiState";
import { event } from "../../../events/core/helpers";
import { ButtonPress } from "../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../events/event-sub-categories";
import { useThrowTestError } from "../../../lib/error-handling/useThrowTestError";

function Sessions() {
  const handleError = useHandleError();
  const session = useSessionInProgress();
  
  const { query: parkableTransactionsQuery, parkableTransactions/*: _parkableTransactions*/ } = useRecentParkableTransactions();
  // const parkableTransactions = [];

  useEffect(() => {
    parkableTransactionsQuery.refetch();
  }, []);

  useNotificationsWarning({ warnOnMount: true });

  return (
    <>
      <ScreenLayout
        header={<LogoHeader />}
        headerRight={<NotificationsSwitch />}
        content={
          <div className={`sessions-content w-full flex flex-col flex-grow items-center`}>
            <SessionInProgressNag />
            <TimeDisplayNag />

            <UiState
              errorText={`Oops, we're having trouble loading your parking sessions`}
              tryAgainAction={`RELOAD_PAGE`}
              query={parkableTransactionsQuery}
              items={parkableTransactions}
              loadingComponent={
                <HomeSessionsLoadingSkeleton />
              }
              noItemsComponent={
                <HasNoSessions />
              }
            >
              <HasSessions
                sessions={parkableTransactions}
              />
            </UiState>
          </div>
        }
        showBottomBar
      />
      <RedirectIfNotAuthenticated />
    </>
  );
}

export type HasSessionsProps = {
  sessions: ReturnType<typeof useRecentParkableTransactions>["parkableTransactions"];
};

export function HasSessions(props: HasSessionsProps) {
  const { sessions } = props;

  const session = useSessionInProgress();

  // const throwTestError = useThrowTestError();

  return (
    <>
      <div
        className={`w-full flex flex-col items-center`}
        style={{ marginBottom: `10px` }}
      >
        <FullWidthButton
          contentTop={`+ Park Another Vehicle`}
          onClick={() => {
            // throwTestError();

            event(
              new ButtonPress({
                buttonName: `Park Another Vehicle`,
                buttonSlug: `NO_SESSIONS.PARK_ANOTHER_VEHICLE`,
                subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
              })
            );
            
            session.startNew();
            session.navigate();
          }}
          disabledIfOffline
          showOfflineIndicator
        />
      </div>
    
      <div
        className={`w-full flex flex-col items-center`}
      >
        {sessions.map((pt, i) => (
          <Fragment key={pt.id}>
            <ParkableTransactionCard
              parkableTransaction={pt}
            />

            {i < (sessions.length - 1) && (
              <div style={{ height: `10px` }} />
            )}
          </Fragment>
        ))}
      </div>
      
      <RecentSessions />
    </>
  );
}

export function HasNoSessions() {
  const session = useSessionInProgress();

  // const throwTestError = useThrowTestError();

  return (
    <div
      className={`no-sessions w-full flex items-center flex-grow`}
      style={{
        
      }}
    >
      <div
        className={`w-full flex flex-col items-center rounded-md relative drop-shadow-[0_15px_10px_rgba(0,0,0,0.1)]`}
        style={{
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          paddingTop: `44px`,
          backgroundColor: `#fff`,
        }}
      >
        <div
          className={`absolute rounded-full flex justify-center items-center`}
          style={{
            top: `-25px`,
            width: `50px`,
            height: `50px`,
            backgroundColor: `#0A84FF`,
          }}
        >
          <IconMeter
            className={`relative`}
            style={{
              top: `-1px`,
              width: `15px`,
              height: `auto`,
            }}
          />
        </div>

        <div
          className={`w-full text-center uppercase mb-3`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `12px`,
            lineHeight: `14px`,
            color: `#989FB4`,
          }}
        >
          Welcome to
        </div>

        <div
          className={`w-full flex justify-center mb-3`}
          style={{

          }}
        >
          <Logo
            style={{
              width: `235px`,
              height: `auto`,
            }}
          />
        </div>

        <div
          className={`w-full text-center mb-6`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `18px`,
            lineHeight: `21px`,
            color: `#989FB4`,
          }}
        >
          Mobile Parking Payments
        </div>

        <div
          className={`w-full px-2 pb-2`}
          style={{

          }}
        >
          <FullWidthButton
            contentTop={`+ New Parking Session`}
            onClick={() => {
              // throwTestError();

              event(
                new ButtonPress({
                  buttonName: `New Parking Session`,
                  buttonSlug: `NO_SESSIONS.NEW_PARKING_SESSION`,
                  subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                })
              );

              session.startNew();
              session.navigate();
            }}
            disabledIfOffline
            showOfflineIndicator
          />
        </div>
      </div>
    </div>
  );
}

export { Sessions };