import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Auth, Session, SessionDetails, Storage } from "./Auth";
import { Args as UseProvidersArgs, useProviders } from "./useProviders";

export type MultiAuthContextValue = ReturnType<typeof useProvideMultiAuth>;

export type State = {
  session: { session: Session; sessionDetails: SessionDetails } | null;
  isLoading: boolean;
  isInvalidatingSession: boolean;
};

export type Args = {
  storage: Storage;
  providers: Omit<UseProvidersArgs, `storage`>;
  useExchangeCognitoTokens?: (args: { auth: InstanceType<typeof Auth> }) => {
    isLoading: boolean;
    isExchanged: boolean;
  };
};

function useProvideMultiAuth(args: Args) {
  const {
    storage,
    providers,
    useExchangeCognitoTokens = () => ({ isLoading: false, isExchanged: false }),
  } = args;

  const auth = useRef(Auth.getInstance({ storage }));

  const [state, setState] = useState<State>({
    session: null,
    isLoading: true,
    isInvalidatingSession: false,
  });

  const cognitoTokenExchange = useExchangeCognitoTokens({ auth: auth.current });

  const isLoading = state.isLoading || cognitoTokenExchange.isLoading;

  const providersValue = useProviders({
    storage,
    ...providers,
  });

  const getInstance = useCallback(async () => {
    return auth.current;
  }, []);

  const invalidateSession = useCallback(async () => {
    setState((state) => {
      return {
        ...state,
        isInvalidatingSession: true,
      };
    });

    await auth.current.invalidateSession();

    setState((state) => {
      return {
        ...state,
        isInvalidatingSession: false,
      };
    });
  }, []);

  const onChangeSession = useCallback(
    async (
      sessionWithDetails: {
        session: Session;
        sessionDetails: SessionDetails;
      } | null
    ) => {
      setState((state) => {
        return {
          ...state,
          session: sessionWithDetails,
        };
      });
    },
    []
  );

  useEffect(() => {
    (async () => {
      const sessionWithDetails = await auth.current.getSession();

      setState((state) => {
        return {
          ...state,
          session: sessionWithDetails,
          isLoading: false,
        };
      });
    })();
  }, []);

  useEffect(() => {
    auth.current.onChangeSession(onChangeSession);

    return () => {
      auth.current.offChangeSession();
    };
  }, [onChangeSession]);

  return useMemo(() => {
    return {
      getInstance,
      sendLoginCode: auth.current.sendLoginCode,
      verifyLoginCode: auth.current.verifyLoginCode,
      exchangeToken: auth.current.exchangeToken,
      refreshSession: auth.current.refreshSession,
      getSession: auth.current.getSession,
      setSession: auth.current.setSession,
      onChangeSession: auth.current.onChangeSession,
      offChangeSession: auth.current.offChangeSession,
      invalidateSession,
      ...state,
      isLoading,
      providers: providersValue,
    };
  }, [getInstance, invalidateSession, state, isLoading, providersValue]);
}

export { useProvideMultiAuth };
