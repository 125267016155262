import moment from "moment-timezone";
import { ParkableTransactions } from "../queries/parkable-transactions/recentParkableTransactionsLoader";
import { useLiveNow } from "./useLiveNow";
import { durationAsHuman, serverDatetimeToMoment, timeLimitAsSeconds } from "./utils";
import _ from "lodash";
import { useMemo } from "react";

export type Args = {
  parkableTransaction: ParkableTransactions[number];
};

const remainingLowThreshold = 5 * 60; // 5 mins

function useSessionStatus(args: Args) {
  const { parkableTransaction: pt } = args;

  const now = useLiveNow();

  const startDatetime = serverDatetimeToMoment(pt.created_at);
  const cancelledDatetime = pt.cancelled_at ? serverDatetimeToMoment(pt.cancelled_at) : null;
  const expiresDatetime = startDatetime.clone().add(pt.time, "seconds");
  const endDatetime = cancelledDatetime ? cancelledDatetime : expiresDatetime;

  const isActive = !cancelledDatetime && endDatetime.clone().isAfter(now);

  const remaining = moment.duration(endDatetime.clone().diff(now));
  const remainingSeconds = Math.max(remaining.asSeconds(), 0);
  const isLow = remainingSeconds <= remainingLowThreshold;
  const remainingHuman = durationAsHuman(remaining).display;
  const percentRemaining =
    remainingSeconds > 0 ? (remainingSeconds / pt.time) * 100 : 0;
  const timeLimit =
    pt.parkable.time_limit;
  const timeLimitUnit =
    pt.parkable.time_limit_unit;
  const timeLimitSeconds =
    _.isNumber(timeLimit) && _.isString(timeLimitUnit)
      ? timeLimitAsSeconds(timeLimit, timeLimitUnit)
      : 0;
  const canExtendTime =
    timeLimitSeconds !== null ? (isActive && pt.time < timeLimitSeconds && remainingSeconds > 5) : false;

  return useMemo(() => ({
    startDatetime,
    cancelledDatetime,
    expiresDatetime,
    endDatetime,
    isActive,
    isLow,
    remainingHuman,
    percentRemaining,
    canExtendTime,
  }), [now]);
}

export { useSessionStatus };