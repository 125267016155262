import { ReactNode } from "react";

import { HelperText } from "../helper-text/HelperText";

export type Props = {
  helperTexts?: (string | ReactNode)[][] | null | undefined;
  helperSubtext?: string | null | undefined;
  errorMessage?: string | null | undefined;
  statusMessage?: string | null | undefined;
  content?: ReactNode;
};

function Layout(props: Props) {
  const { helperTexts, helperSubtext, errorMessage, statusMessage, content } = props;

  return (
    <div 
          className={`w-full flex flex-grow flex-col items-center justify-center`}
          style={{
            marginBottom: `30px`,
          }}
        >
          {/* Helper Text(s) */}
          <div
            className={`w-full uppercase flex flex-col items-center justify-center mt-8 mb-4`}
          >
            {(!!helperTexts || !!helperSubtext) && (
              <div
                className={`w-full uppercase flex flex-col items-center justify-center mt-2`}
              >
                {!!helperTexts && 
                  helperTexts.map((texts, i) =>
                    texts.length === 0 ? (
                      <div style={{ height: `10px` }} key={i} />
                    ) : (
                      <HelperText texts={texts} key={i} />
                    )
                  )
                }

                {!!helperSubtext && (
                 <pre
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 700,
                    fontStyle: `italic`,
                    fontSize: `12px`,
                    lineHeight: `14px`,
                    color: `#0E2A48`,
                    textTransform: `none`,
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {helperSubtext}
                </pre> 
                )}
              </div>
            )}
          </div>

          {/* Error Message */}
          <div
            className={`w-full uppercase flex flex-col items-center justify-center`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `12px`,
              lineHeight: `18px`,
              color: `#FF3B30`,
              height: `30px`,
              opacity: errorMessage || statusMessage ? 1 : 0,
              marginBottom: `10px`,
            }}
          >
            {errorMessage ?? statusMessage}
          </div>

          {/* Body */}
          <div className={`w-full`}>
            {content}
          </div>
        </div>
  );
}

export { Layout };
