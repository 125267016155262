import { ReactNode } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FullWidthButton } from "../buttons/FullWidthButton";
import ErrorBoundary from "../../lib/error-handling/ErrorBoundary";
import { UiBlock } from "../ui-blocks/UiBlock";
import { useScrollToTopOnMount } from "../../lib/useScrollToTopOnMount";
import { DownloadAppButton } from "../buttons/DownloadAppButton";
import { RoadBackground } from "../misc/RoadBackground";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { event } from "../../events/core/helpers";
import { ButtonPress } from "../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../events/event-sub-categories";
// import { SideMenu } from "../modals/side-menu/SideMenu";

export type Props = {
  header?: ReactNode;
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
  content?: ReactNode;
  showBottomBar?: boolean;
};

function ScreenLayout(props: Props) {
  const { header, headerLeft, headerRight, content, showBottomBar = false } = props;

  useScrollToTopOnMount();
  
  const navigate = useNavigate();
  const auth = useMultiAuth();

  // const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <ErrorBoundary identifier="screen">
      <div
        className={`w-full flex flex-grow flex-col items-center`}
        style={{
          // minHeight: `100vh`,
          // minHeight: `-webkit-fill-available`,
          minHeight: `100dvh`,
        }}
      >
        {/* <RoadBackground /> */}

        {!!header && (
          <div
            className={`w-full flex items-center justify-center sticky top-0 bg-white`}
            style={{
              height: `40px`,
              zIndex: 1000,
              transform: `translate3d(0,0,0)`,
              borderColor: `#E7EAF7`,
              borderWidth: `1px`,
            }}
          >
            <div
              className={`w-full flex items-center`}
              style={{
                transform: `translate3d(0,0,0)`,
                maxWidth: `350px`,
              }}
            >
              <div
                className={`flex flex-row flex-grow pl-2`}
                style={{
                  width: `100px`,
                }}
              >
                {headerLeft}
              </div>

              {/* <button
                onClick={(e) => {
                  e.preventDefault();
                  setMenuOpen(!menuOpen)};
                }}
              > */}
                {header}
              {/* </button> */}

              <div
                className={`flex flex-row justify-end flex-grow pr-2`}
                style={{
                  width: `100px`,
                }}
              >
                {headerRight}
              </div>
            </div>
          </div>
        )}
        {!!content && (
          <div 
            className={`w-full flex flex-grow flex-col items-center p-2`}
            style={{
              maxWidth: `350px`,
            }}
          >
            {content}
          </div>
        )}

        {showBottomBar && (
          <div className={`w-full flex flex-row justify-center items-center`}>
            <div
              className={`mx-2`}
            >
              <FullWidthButton
                contentTop={`Help`}
                type={`tertiary-transparent`}
                onClick={() => {
                  navigate("/help");
                  // window.open('https://www.meterez.com/help/', '_blank');
                }}
              />
            </div>

            {!!auth.session && (
              <div
                className={`mx-2`}
              >
                <FullWidthButton
                  contentTop={`Sign Out`}
                  type={`tertiary-transparent`}
                  onClick={() => {
                    event(
                      new ButtonPress({
                        buttonName: `Sign Out`,
                        buttonSlug: `SCREEN_LAYOUT.LOG_OUT`,
                        subCategory: EventSubCategories.AUTH,
                      })
                    );

                    auth.invalidateSession();
                  }}
                />
              </div>
            )}

            <DownloadAppButton />
          </div>
        )}

        {/* <SideMenu
          open={menuOpen}
          onClose={() => {
            setMenuOpen(false);
          }}
        /> */}
      </div>
    </ErrorBoundary>
  );
}

export { ScreenLayout };