import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { PaymentMethod } from "../../../../../queries/payment-methods/usePaymentMethods";
import { Heading1 } from "../../../../../components/headings/Heading1";
import { PaymentMethodCard } from "../../../../../components/cards/PaymentMethodCard";

export type Props = {
  paymentMethodId?: number | null | undefined;
  onCancel: () => any;
};

function Confirm(props: Props) {
  const { paymentMethodId, onCancel } = props;

  return (
    <div className={`w-full mb-2`}>
      <Heading1>
        Confirm your Payment Method
      </Heading1>

      <div
        className={`mb-4`}
      >
        <PaymentMethodCard
          paymentMethodId={paymentMethodId}
        />
      </div>
      
      <div className={`w-full mb-2`}>
        <FullWidthButton
          contentTop={`Use a Different Card`}
          type={`tertiary-transparent`}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
}

export { Confirm };