import { ReactNode } from "react";
import { FullWidthButton } from "../buttons/FullWidthButton";

export type Props = {
  errorText: string;
  tryAgainAction: `RELOAD_PAGE` | `REFETCH_QUERY`;
  query: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    [key: string]: any;
  };
  items: unknown[];
  loadingComponent: ReactNode;
  noItemsComponent: ReactNode;
  children?: ReactNode;
};

function UiState(props: Props) {
  const {
    errorText,
    tryAgainAction, 
    query, 
    items, 
    loadingComponent, 
    noItemsComponent, 
    children,
  } = props;

  if (query.isSuccess) {
    if (items.length === 0) {
      return noItemsComponent;
    } else {
      return children;
    }
  }

  let showLoadingSkeleton = false;
  const showRetryButton = query.isError;

  if (
    (query.isLoading && items.length === 0) ||
    query.isError
  ) {
    showLoadingSkeleton = true;
  }
  
  if (showLoadingSkeleton) {
    return (
      <div
        className={`w-full relative`}
        style={{

        }}
      >
        <div
          className={`w-full ${showRetryButton ? `opacity-50`:``}`}
          style={{
  
          }}
        >
          {loadingComponent}
        </div>

        {showRetryButton && (
          <div
            className={`w-full absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center`}
            style={{
    
            }}
          >
            <div
              className={`w-full px-8 mb-2 text-center`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 900,
                fontSize: `14px`,
                lineHeight: `21px`,
              }}
            >
              {errorText}
            </div>

            <div
              className={`w-full px-8`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 900,
                fontSize: `14px`,
                lineHeight: `21px`,
              }}
            >
              <FullWidthButton
                contentTop={`Try Again`}
                onClick={() => {
                  if (tryAgainAction === `REFETCH_QUERY`) {
                    query.refetch();
                  } else {
                    window.location.reload();
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
}

export { UiState };