import { useCallback, useEffect, useState } from "react";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useResendWait } from "../../../../lib/useResendWait";

const maxTries = 5;

export type Props = {
  disabled?: boolean;
  onClick?: () => Promise<any>;
  onClickDisabled?: () => any;
  apiRef?: (api?: {
    reset: ReturnType<typeof useResendWait>["reset"]
  }) => any;
};

function ResendButton(props: Props) {
  const { disabled = false, onClick = async () => {}, onClickDisabled = () => {}, apiRef = () => {} } = props;

  const { now, numTries, lastTryAt, canResend, canResendNow, canResendIn, incrementTries, reset } = useResendWait({ maxTries, initialTries: 1 });

  const [isResending, setIsResending] = useState<boolean>(false);

  let buttonText = `Resend`;
  if (isResending) {
    buttonText = `Resending...`;
  } else if (numTries > 1 && lastTryAt && lastTryAt.isAfter(now.subtract(1, "seconds"))) {
    buttonText = `Resent`;
  } else if (canResend && !canResendNow) {
    buttonText = `Resend in ${canResendIn} sec`;
  } else if (numTries >= maxTries) {
    buttonText = `Resent`;
  }

  const resend = useCallback(async () => {
    try {
      setIsResending(true);

      await onClick();

      setIsResending(false);
    } catch (e: unknown) {
      setIsResending(false);
    }
  }, [onClick]);

  useEffect(() => {
    apiRef({
      reset,
    });

    return () => {
      apiRef(undefined);
    };
  }, [reset]);

  return (
    <FullWidthButton
      contentTop={buttonText}
      type={`tertiary-transparent`}
      disabled={!canResend || !canResendNow || disabled}
      onClick={() => {
        incrementTries();
        resend();
      }}
      onClickDisabled={onClickDisabled}
      disabledIfOffline
      // showOfflineIndicator
    />
  );
}

export { ResendButton };