import { CreateSupportTicketGuestMutationVariables, CreateSupportTicketMutationVariables } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  mutationVariables:
    | CreateSupportTicketMutationVariables
    | CreateSupportTicketGuestMutationVariables;
  isGuest: boolean;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class CreateSupportTicketRequest extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationVariables, isGuest, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.SUPPORT_TICKETS,
      type: `CREATE_SUPPORT_TICKET.REQUEST`,
      data: {
        mutationVariables,
        isGuest,
      },
    });
  }
}

export { CreateSupportTicketRequest };
