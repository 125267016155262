import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import { RootLayout } from "../../components/layouts/RootLayout";
import { Sessions } from "../screens/sessions/Sessions";
import { Parkable } from "../screens/sessions/new/Parkable";
import { Vehicle } from "../screens/sessions/new/Vehicle";
import { Time } from "../screens/sessions/new/Time";
import { Time as TimeExtend } from "../screens/sessions/extend/Time";
import { PaymentMethod } from "../screens/sessions/new/PaymentMethod";
import { Review } from "../screens/sessions/new/Review";
import { Review as ReviewExtend } from "../screens/sessions/extend/Review";
import { SessionInProgressContextProvider } from "../../lib/session-in-progress/SessionInProgressContextProvider";
import { PreLoader } from "../../components/misc/Preloader";
import { ErrorModalProvider } from "../../lib/error-handling/error-modal";
import { AccountPrerequisites } from "../../account-prerequisites/components/AccountPrerequisites";
import ErrorBoundary from "../../lib/error-handling/ErrorBoundary";
// import { TestError } from "../screens/development/TestError";
// import Receipt from "../screens/sessions/receipt/Receipt";
import { Help } from "../screens/help/Help";
import { HelpDetail } from "../screens/help/HelpDetail";
// import { RecaptchaProvider } from "../../anti-spam/RecaptchaProvider";
import { AuthScreen } from "../../auth/web/screens/AuthScreen";
import { MultiAuthProvider } from "../../auth/core/MultiAuthProvider";
import { RedirectIfNotAuthenticated } from "../../components/misc/RedirectIfNotAuthenticated";
import { RedirectIfAuthenticated } from "../../components/misc/RedirectIfAuthenticated";
import { AuthCallbackScreen } from "../../auth/web/screens/AuthCallbackScreen";
import { auth, environment } from "../../config/config";
import { getBrowserAgentPlatform } from "../../auth/web/getBrowserAgentPlatform";
import { DispatchOnlineState } from "../../events/web/DispatchOnlineState";
import { EventsProvider } from "../../events/EventsProvider";
import { DispatchAuthSessionChanged } from "../../events/web/DispatchAuthSessionChanged";
import { DispatchScreenFocused } from "../../events/web/DispatchScreenChanged";

const makeStorage = () => {
  const overrideKey = (key: string) => {
    const envName = environment.envName;

    if (environment.isProduction) {
      return key;
    }

    return `${key}.${envName}`;

  }
  
  return {
    getItem: async (key: string) => {
      return localStorage.getItem(overrideKey(key));
    },
    setItem: async (key: string, value: string) => {
      localStorage.setItem(overrideKey(key), value);
    },
    removeItem: async (key: string) => {
      localStorage.removeItem(overrideKey(key));
    },
  };
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <ErrorModalProvider>
          <MultiAuthProvider
            storage={makeStorage()}
            providers={{
              providers: auth.enabledProviders,
              platform: `web`,
              browserAgentPlatform: getBrowserAgentPlatform(),
              forceMultiProviderMode: true,
            }}
          >
            <EventsProvider>
              <SessionInProgressContextProvider>
                <AccountPrerequisites>
                  <RootLayout />
                  <PreLoader />
                  <DispatchOnlineState />
                  <DispatchAuthSessionChanged />
                  <DispatchScreenFocused />
                </AccountPrerequisites>
              </SessionInProgressContextProvider>
            </EventsProvider>
          </MultiAuthProvider>
        </ErrorModalProvider>
      }
    >
      {/**
       * Dev / Testing
       */}

      {/* <Route
        path="development/test-error"
        element={
          <ErrorBoundary identifier="route:development/test-error">
            <TestError />
          </ErrorBoundary>
        }
      /> */}

      {/**
       * Help
       */}

      <Route
        path="help"
        element={
          <ErrorBoundary identifier="route:help">
            <Help />
          </ErrorBoundary>
        }
      />

      <Route
        path="help/:optionSlug"
        element={
          <ErrorBoundary identifier="route:help/:optionSlug">
            <HelpDetail />
          </ErrorBoundary>
        }
      />

      {/**
       * Auth
       */}

      <Route
        path="login"
        element={
          <ErrorBoundary identifier="route:login">
            {/* <RecaptchaProvider> */}
              <RedirectIfAuthenticated>
                <AuthScreen />
              </RedirectIfAuthenticated>
            {/* </RecaptchaProvider> */}
          </ErrorBoundary>
        }
      />

      <Route
        path="login/callback/:provider"
        element={
          <ErrorBoundary identifier="login/callback/:provider">
            {/* <RecaptchaProvider> */}
              <AuthCallbackScreen />
            {/* </RecaptchaProvider> */}
          </ErrorBoundary>
        }
      />

      {/* <Route
        path="login"
        element={
          <ErrorBoundary identifier="route:login">
            // <RecaptchaProvider>
              <AuthPhone />
            // </RecaptchaProvider>
          </ErrorBoundary>
        }
      />

      <Route
        path="login/confirm"
        element={
          <ErrorBoundary identifier="route:login/confirm">
            <AuthLoginCode />
          </ErrorBoundary>
        }
      /> */}

      {/**
       * Sessions
       */}

      <Route
        path="sessions"
        element={
          <ErrorBoundary identifier="route:sessions">
            <RedirectIfNotAuthenticated>
              <Sessions />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      {/**
       * Session - New
       */}

      <Route
        path="sessions/new/meter"
        element={
          <ErrorBoundary identifier="route:sessions/new/meter">
            <RedirectIfNotAuthenticated>
              <Parkable />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      <Route
        path="sessions/new/plate"
        element={
          <ErrorBoundary identifier="route:sessions/new/plate">
            <RedirectIfNotAuthenticated>
              <Vehicle />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      <Route
        path="sessions/new/time"
        element={
          <ErrorBoundary identifier="route:sessions/new/time">
            <RedirectIfNotAuthenticated>
              <Time />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      <Route
        path="sessions/new/payment"
        element={
          <ErrorBoundary identifier="route:sessions/new/payment">
            <RedirectIfNotAuthenticated>
              <PaymentMethod />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      <Route
        path="sessions/new/review"
        element={
          <ErrorBoundary identifier="route:sessions/new/review">
            <RedirectIfNotAuthenticated>
              <Review />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      {/**
       * Session - Extend
       */}

      <Route
        path="sessions/extend/:parkableTransactionId/time"
        element={
          <ErrorBoundary identifier="route:sessions/extend/:parkableTransactionId/time">
            <RedirectIfNotAuthenticated>
              <TimeExtend />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      <Route
        path="sessions/extend/:parkableTransactionId/review"
        element={
          <ErrorBoundary identifier="route:sessions/extend/:parkableTransactionId/review">
            <RedirectIfNotAuthenticated>
              <ReviewExtend />
            </RedirectIfNotAuthenticated>
          </ErrorBoundary>
        }
      />

      {/**
       * Session - Receipt
       */}

       {/* <Route
        path="sessions/:parkableTransactionId/receipt"
        element={
          <ErrorBoundary identifier="route:sessions/:parkableTransactionId/receipt">
            <Receipt />
          </ErrorBoundary>
        }
      /> */}

      <Route path="" element={<Navigate to="/sessions" replace />} />
      <Route path="*" element={<Navigate to="/sessions" replace />} />
    </Route>
  )
);

export { router };