import { Navigate } from "react-router-dom";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { ReactNode } from "react";

export type Props = {
  children?: ReactNode;
};

function RedirectIfAuthenticated(props: Props) {
  const { children } = props;

  const auth = useMultiAuth();

  if (!auth.isLoading && !!auth.session) {
    return (
      <Navigate to="/sessions" replace />
    );
  }

  return children;
}

export { RedirectIfAuthenticated };