import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { ResendButton } from "../resend-button/ResendButton";

export type Props = {
  show?: boolean;
  disabled?: boolean;
  isVerifying?: boolean;
  isSuccess?: boolean;
  onPress?: () => any;
  resendDisabled?: boolean;
  onPressResend?: () => Promise<any>;
  onCancel?: () => any;
};

function VerifyLoginCodeButton(props: Props) {
  const {
    show = false,
    disabled = false,
    isVerifying = false,
    isSuccess = false,
    onPress = () => {},
    resendDisabled = false,
    onPressResend = async () => {},
    onCancel = () => {},
  } = props;

  if (!show) return null;

  return (
    <div
      style={{
        width: `100%`,
      }}
    >
      <FullWidthButton
        contentTop={
          isVerifying
            ? `Verifying...`
            : (isSuccess
            ? `Signing In...`
            : `Verify Login Code`)
        }
        disabled={disabled || isSuccess}
        disabledIfOffline
        onClick={onPress}
      />

      <div style={{ height: `10px` }} />

      <div
        className={`flex flex-row justify-center items-center`}
        style={{
          width: `100%`,
        }}
      >
        <div
          className={`mx-3`}
          style={{
            
          }}
        >
          <FullWidthButton
            contentTop={`Cancel`}
            type={`tertiary-transparent`}
            disabled={isSuccess}
            disabledIfOffline
            onClick={onCancel}
          />
        </div>
        <div
          className={`mx-3`}
          style={{
            
          }}
        >
          <ResendButton
            onClick={onPressResend}
            disabled={resendDisabled || isSuccess}
          />
        </div>
      </div>
    </div>
  );
}

export { VerifyLoginCodeButton };
