import moment from "moment-timezone";

import {
  MANDATORY_REFRESH_THRESHOLD,
  PROACTIVE_REFRESH_THRESHOLD,
} from "./constants";

// TODO Base this off of issuedAt rather than expiresAt, since we don't know the token TTL in the client
export function shouldProactiveRefresh(expiresAt: number) {
  return moment.utc().unix() >= expiresAt - PROACTIVE_REFRESH_THRESHOLD;
}

export function shouldMandatoryRefresh(expiresAt: number) {
  return moment.utc().unix() >= expiresAt - MANDATORY_REFRESH_THRESHOLD;
}
