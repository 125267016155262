import { useCallback, useEffect, useMemo } from "react";
import { intervalAsSeconds, isValidInterval, makeDuration, timeLimitAsSeconds } from "../../../../../lib/utils";
import _ from "lodash";
import { ReactComponent as IconMinusGray } from "../../../../../../src/assets/icons/icon-minus-circle.svg";
import { ReactComponent as IconMinusBlue } from "../../../../../../src/assets/icons/icon-minus-circle-blue.svg";
import { ReactComponent as IconPlusGray } from "../../../../../../src/assets/icons/icon-plus-circle.svg";
import { ReactComponent as IconPlusBlue } from "../../../../../../src/assets/icons/icon-plus-circle-blue.svg";
import { ParkUntil } from "./ParkUntil";

export type Digits = {
  left: [string, string];
  right: [string, string];
};

export type Props = {
  parkable: {
    price: number;
    price_interval: number;
    price_interval_unit: string;
    time_limit: number;
    time_limit_unit: string;
    [key: string]: any;
  },
  session?: {
    time: number;
  } | null | undefined,
  time: number | null | undefined;
  onChangeTime: (time: number) => any;
};

// TODO Make sure everything is based on the parkableTransaction snapshot for time extensions

function TimePicker(props: Props) {
  const { parkable, session, time, onChangeTime } = props;

  const intervalSeconds = intervalAsSeconds(
    parkable.price_interval,
    parkable.price_interval_unit
  );
  const timeLimitSeconds = timeLimitAsSeconds(parkable.time_limit, parkable.time_limit_unit);
  const maxTimeSeconds = session
    ? timeLimitSeconds - session.time
    : timeLimitSeconds;
  const minInterval = 1; // ie. 20 mins
  const maxInterval = Math.floor(timeLimitSeconds / intervalSeconds);
  const maxAllowedInterval = session
    ? Math.floor(maxTimeSeconds / intervalSeconds)
    : maxInterval;

  let selectedInterval = minInterval;
  if (isValidInterval(parkable.price_interval, parkable.price_interval_unit, time)) {
    selectedInterval = (time as number) / intervalSeconds;
  }

  let quickSelectIntervals = [1];
  const oneHourInIntervals = (1 * 60 * 60) / intervalSeconds;
  const oneAndOneHalfHoursInIntervals = (1.5 * 60 * 60) / intervalSeconds;
  if (oneHourInIntervals === Math.floor(oneHourInIntervals)) {
    quickSelectIntervals = [...quickSelectIntervals, oneHourInIntervals];
  } else if (
    oneAndOneHalfHoursInIntervals === Math.floor(oneAndOneHalfHoursInIntervals)
  ) {
    quickSelectIntervals = [
      ...quickSelectIntervals,
      oneAndOneHalfHoursInIntervals,
    ];
  }
  quickSelectIntervals = _.uniq([
    ...quickSelectIntervals,
    maxAllowedInterval,
  ]).filter((interval) => interval <= maxAllowedInterval);

  const duration = useMemo(() => {
    return makeDuration(selectedInterval, intervalSeconds);
  }, [intervalSeconds, selectedInterval]);

  const quickSelectDurations = useMemo(() => {
    return quickSelectIntervals.map((interval) => ({
      interval,
      duration: makeDuration(interval, intervalSeconds),
    }));
  }, [quickSelectIntervals, intervalSeconds]);

  //
  // General Callbacks
  //

  const decrementDisabled = !(selectedInterval - 1 >= minInterval);
  const decrementInterval = useCallback(() => {
    if (!time) return;

    if (!decrementDisabled) {
      onChangeTime((selectedInterval - 1) * intervalSeconds);
    }
  }, [time, decrementDisabled, selectedInterval, onChangeTime, intervalSeconds]);

  const incrementDisabled = !(selectedInterval + 1 <= maxAllowedInterval);
  const incrementInterval = useCallback(() => {
    if (!time) return;

    if (!incrementDisabled) {
      onChangeTime((selectedInterval + 1) * intervalSeconds);
    }
  }, [time, incrementDisabled, selectedInterval, onChangeTime, intervalSeconds]);

  //
  // Effects
  //

  useEffect(() => {
    if (
      time &&
      parkable &&
      !isValidInterval(
        parkable.price_interval,
        parkable.price_interval_unit,
        time
      )
    ) {
      onChangeTime(minInterval * intervalSeconds);
    }
  }, [time]);

  // TODO Disable Time Picker if !time

  let digits: Digits = { left: ["", ""], right: ["", ""] };
  if (_.isInteger(time)) {
    digits = humanDurationToDigits(makeDuration(selectedInterval, intervalSeconds));
  }

  return (
    <div
      className={`w-full`}
    >
      <div
        className={`w-full flex flex-col relative justify-center items-center`}
        style={{
          height: `60px`,
        }}
      >
        <div
          className={`w-full h-full flex justify-center items-center`}
          style={{
            // backgroundColor: 'green'
          }}
        >
          <div
            className={`flex justify-center items-center h-full flex-grow`}
            style={{
              // backgroundColor: 'blue'
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                decrementInterval();
              }}
              className={`flex items-center justify-end h-full flex-grow pr-3 ${decrementDisabled ? `opacity-30`:``}`}
              style={{
                // backgroundColor: 'red'
              }}
            >
              {decrementDisabled ? <IconMinusGray /> : <IconMinusBlue />}
            </button>
          </div>

          <div
            className={`flex justify-center items-center relative`}
            style={{
              // backgroundColor: 'orange'
            }}
          >
            <div
              className={`w-full absolute flex justify-center items-center`}
              style={{
                top: `-20px`,
                // backgroundColor: 'green'
              }}
            >
              <div
                className={`text-center uppercase`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `16px`,
                  color: digits.left[1] === "0" ? `#989fb4` : `#0E2A48`,
                  width: `30px`,
                  // backgroundColor: 'green'
                }}
              >
                Hrs
              </div>
              <div style={{ width: `15px` }}/>
              <div
                className={`text-center justify-center items-center uppercase`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `16px`,
                  color: digits.left[1] === "0" ? `#989fb4` : `#0E2A48`,
                  width: `30px`,
                }}
              >
                Mins
              </div>
            </div>
            
            <div className={`flex justify-center items-center`}>
              <div
                className={`text-center justify-center items-center`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `24px`,
                  lineHeight: `24px`,
                  color: digits.left[0] === "0" ? `#989fb4` : `#0E2A48`,
                  width: `15px`,
                }}
              >
                {digits.left[0]}
              </div>
              <div
                className={`text-center justify-center items-center`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `24px`,
                  lineHeight: `24px`,
                  color: digits.left[1] === "0" ? `#989fb4` : `#0E2A48`,
                  width: `15px`,
                }}
              >
                {digits.left[1]}
              </div>
            </div>
            <div
              className={`flex text-center justify-center items-center relative`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `24px`,
                lineHeight: `24px`,
                color: `#989fb4`,
                top: `-1px`,
                width: `15px`,
              }}
            >
              :
            </div>
            <div className={`flex justify-center items-center`}>
              <div
                className={`text-center justify-center items-center`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `24px`,
                  lineHeight: `24px`,
                  color: `#0E2A48`,
                  width: `15px`,
                }}
              >
                {digits.right[0]}
              </div>
              <div
                className={`text-center justify-center items-center`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `24px`,
                  lineHeight: `24px`,
                  color: `#0E2A48`,
                  width: `15px`,
                }}
              >
                {digits.right[1]}
              </div>
            </div>
          </div>

          <div
            className={`flex justify-center items-center h-full flex-grow relative`}
            style={{
              // backgroundColor: 'blue'
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                incrementInterval();
              }}
              className={`flex items-center justify-start h-full flex-grow pl-3 ${incrementDisabled ? `opacity-30`:``}`}
              style={{
                // backgroundColor: 'red'
              }}
            >
              {incrementDisabled ? <IconPlusGray /> : <IconPlusBlue />}
            </button>

            {incrementDisabled && (
              <div
                className={`absolute left-12 text-center uppercase`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `12px`,
                  lineHeight: `16px`,
                  color: `red`
                }}
              >
                <div
                  className={`text-center`}
                >
                  Time Limit
                </div>
                <div
                  className={`text-center`}
                >
                  {parkable?.time_limit} {parkable?.time_limit_unit === 'min' ? 'MINS':'HRS'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ParkUntil
        time={time}
        session={session}
      />

      {quickSelectDurations.length > 0 && (
        <div
          className={`w-full flex mt-4`}
        >
          {quickSelectDurations.map(({ interval, duration }, i) => {
            let color = `#0d2a48`;
            let backgroundColor = `#fff`;
            let borderColor = `#E7EAF7`;

            if (interval === selectedInterval) {
              color = `#fff`;
              backgroundColor = `#0E2A48`;
              borderColor = `#0E2A48`;
            }

            return (
              <button
                key={duration.human}
                className={`flex items-center justify-center rounded-md basis-0 flex-grow ${i > 0 ? `mr-1`:``} ${i < (quickSelectDurations.length - 1) ? `mr-1`:``}`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `18px`,
                  color,
                  backgroundColor,
                  borderColor,
                  borderWidth: `1px`,
                  height: `40px`,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onChangeTime(interval * intervalSeconds);
                }}
              >
                {duration.human}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

function humanDurationToDigits(
  duration: ReturnType<typeof makeDuration>
): Digits {
  return {
    left: [
      String(duration.inHoursMinutes[0]).padStart(2, "0").slice(0, 1),
      String(duration.inHoursMinutes[0]).padStart(2, "0").slice(1, 2),
    ],
    right: [
      String(duration.inHoursMinutes[1]).padStart(2, "0").slice(0, 1),
      String(duration.inHoursMinutes[1]).padStart(2, "0").slice(1, 2),
    ],
  };
}

export { TimePicker };