import { request } from 'graphql-request';
import { getGraphqlEndpoint } from '../../config/config';
import { SearchParkablesDocument, SearchParkablesQuery } from '../../generatedQueries';
import { Auth } from '../../auth/core/Auth';
import { event } from '../../events/core/helpers';
import { SearchParkablesRequest } from '../../events/event-types/parkable-transactions/SearchParkablesRequest';
import { SearchParkablesResponse } from '../../events/event-types/parkable-transactions/SearchParkablesResponse';
import { getDeviceHeaders } from '../../http/getDeviceHeaders/getDeviceHeaders';

export type Parkables = SearchParkablesQuery["parkables"];

async function searchParkables(args: { identifier: number }) {
  const { identifier } = args;

  const jwt = (await Auth.getInstance().getSession())?.session.idToken;
  const deviceHeaders = getDeviceHeaders();
  const requestHeaders = jwt ? {
    ...deviceHeaders,
    Authorization: `Bearer ${jwt}`,
  } : { ...deviceHeaders };

  event(new SearchParkablesRequest({
    queryVariables: {
      identifier
    },
  }));

  const resp = await (await request({
    url: getGraphqlEndpoint() as string,
    document: SearchParkablesDocument,
    variables: {
      identifier,
    },
    requestHeaders,
    // signal,
  })).parkables;

  event(new SearchParkablesResponse({
    queryResponse: resp,
  }));

  return resp;
}

export { searchParkables };