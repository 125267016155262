import { Navigate, useParams } from "react-router-dom";
import { toValidInteger } from "../../../lib/utils";
import helpOptions from "./helpOptions";
import _ from "lodash";
import { ReactNode } from "react";
import { LogoHeader } from "../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../components/layouts/ScreenLayout";
import { BreadCrumbs } from "./Breadcrumbs";

function HelpDetail() {
  const { optionSlug } = useParams() as { optionSlug?: string };

  const helpOption = _.find(helpOptions, { slug: optionSlug });

  if (!helpOption) {
    return (
      <Navigate to={"/help"} replace />
    );
  }

  const { headingText, detailText: _detailText = () => { } } = helpOption;
  const detailText = _detailText ? (_detailText() as ReactNode) : null;

  let detailTextEl: ReactNode = null;
  if (_.isString(detailText)) {
    detailTextEl = (
      <div
        className={`mb-4`}
        style={{
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: `14px`,
          lineHeight: `16px`,
          color: "#0E2A48",
        }}
      >
        {detailText}
      </div>
    );
  } else {
    detailTextEl = detailText ?? null;
  }

  return (
    <ScreenLayout
      header={<LogoHeader />}
      content={
        <div
          className={`w-full py-2`}
        >
          <BreadCrumbs />

          <div
            className={`w-full mb-4`}
            style={{
              fontFamily: "Roboto",
              fontWeight: 900,
              fontSize: `18px`,
              lineHeight: `21px`,
              color: "#0E2A48",
            }}
          >
            {headingText}
          </div>

          {detailTextEl}
        </div>
      }
    />
  );
}

export { HelpDetail };