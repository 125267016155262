import "../../lib/error-handling/error-handlers/global/errorHandlers";
import { Outlet } from "react-router-dom";
import useUser from "../../queries/users/useUser";
import { GlobalErrorModal } from "../../lib/error-handling/error-modal";
import { useCallback } from "react";
import { GlobalAlertModal, useAlertModal } from "../modals/alert-modal";
import { PortalHost } from "@gorhom/portal";
import { UiBlock } from "../ui-blocks/UiBlock";
import { BackgroundGradient } from "../misc/BackgroundGradient";
import { AppLoadingSkeleton } from "../skeletons/AppLoadingSkeleton";
import { useNetwork } from "../../lib/useNetwork";
import { useMultiAuth } from "../../auth/core/useMultiAuth";

function RootLayout() {
  const auth = useMultiAuth();
  const { user } = useUser();
  const { isOnline } = useNetwork();
  const alert = useAlertModal();

  const onClickOffline = useCallback(() => {
    alert.show({
      title: [`No`, `Internet`],
      message: `You are currently offline. Some features in meterEZ will be disabled until your internet connection is restored.`
    });
  }, [alert]);

  // Test Error Modal
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleError(new Error("Test Error"));
  //   }, 1000);
  // }, []);

  if (auth.isLoading || (!!auth.session && !user)) {
    return (
      <AppLoadingSkeleton show />
    );
  }

  return (
    <div
      className={`mx-auto flex flex-col items-center`}
      style={{
        // minHeight: `100vh`,
        // minHeight: `-webkit-fill-available`,
        minHeight: `100dvh`,
        paddingBottom: isOnline ? ``:`30px`,
      }}
    >
      <BackgroundGradient />
      <Outlet />
      <GlobalAlertModal />
      <GlobalErrorModal />
      <PortalHost name="ui-block" />
      <UiBlock show={auth.isInvalidatingSession} text="Signing Out..." />

      {!isOnline && (
        <button
          className={`fixed right-0 bottom-0 left-0 px-4 text-center uppercase`}
          style={{
            backgroundColor: `#0883ff`,
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `12px`,
            lineHeight: `18px`,
            color: `#fff`,
            height: `30px`,
          }}
          onClick={(e) => {
            e.preventDefault();
            onClickOffline();
          }}
        >
          You are currently offline
        </button>
      )}
    </div>
  );
}

export { RootLayout };