import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";

import queryKeys from "./query-keys";
import { ParkableTransaction } from "./parkable-transactions/useRecentParkableTransactions";

function mergeBy(a: any[], b: any[], key: string) {
  const merged = _.merge(_.keyBy(a, key), _.keyBy(b, key));
  return _.values(merged);
}

export default function useCachePrimer() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    return {
      User: {
        replaceById: (user: any) => {
          queryClient.setQueryData(queryKeys.User(user.id), (prevUser) => {
            return [_.cloneDeep(user)];
          });
        },
      },
      ParkableTransaction: {
        replaceById: (userId: number, parkableTransaction: any) => {
          if (!parkableTransaction) return;

          queryClient.setQueryData(
            queryKeys.ParkableTransaction(userId, parkableTransaction.id),
            (prevParkableTransaction) => {
              return { ...parkableTransaction };
            }
          );
        },
      },
      RecentParkableTransactions: {
        replaceById: (
          parkableTransactions: ParkableTransaction[]
        ) => {
          if (parkableTransactions.length === 0) return;

          const userId = parkableTransactions[0].user_id;
          const parkableTransactionIds = parkableTransactions.map((p) => p.id);

          queryClient.setQueryData(
            queryKeys.RecentParkableTransactions(userId),
            (_prevParkableTransactions) => {
              const prevParkableTransactions = (_prevParkableTransactions ??
                []) as
                | ParkableTransaction[]
                | undefined
                | null;

              const newParkableTransactions = _.chain(prevParkableTransactions)
                // Clear out old ones by ID
                .reject(
                  (
                    parkableTransaction: ParkableTransaction
                  ) => parkableTransactionIds.includes(parkableTransaction.id)
                )
                // Merge new ones
                .thru((prevParkableTransactions) =>
                  mergeBy(
                    prevParkableTransactions.map((p: any) => ({ ...p })),
                    parkableTransactions.map((p: any) => ({ ...p })),
                    "id"
                  )
                )
                .value();

              return newParkableTransactions;
            }
          );
        },
      },
      Parkable: {
        replaceById: (parkable: any) => {
          queryClient.setQueryData(
            queryKeys.Parkable(parkable.id),
            (prevParkable) => {
              return parkable;
            }
          );
        },
      },
      PaymentMethods: {
        replaceById: (paymentMethods: any[]) => {
          if (paymentMethods.length === 0) return;

          const userId = paymentMethods[0].user_id;
          const paymentMethodIds = paymentMethods.map((p) => p.id);

          queryClient.setQueryData(
            queryKeys.PaymentMethods(userId),
            (prevPaymentMethods) => {
              return (
                _.chain((prevPaymentMethods as unknown[]) ?? [])
                  // Clear out old ones by ID
                  .reject((paymentMethod) =>
                    paymentMethodIds.includes(paymentMethod.id)
                  )
                  // Merge new ones
                  .thru((prevPaymentMethods) =>
                    mergeBy(prevPaymentMethods || [], paymentMethods, "id")
                  )
                  .value()
              );
            }
          );
        },
        removeById: (paymentMethodIds: any[], userId: number) => {
          if (paymentMethodIds.length === 0) return;

          queryClient.setQueryData(
            queryKeys.PaymentMethods(userId),
            (prevPaymentMethods) => {
              return (
                _.chain((prevPaymentMethods as unknown[]) ?? [])
                  // Clear out old ones by ID
                  .reject((paymentMethod) =>
                    paymentMethodIds.includes(paymentMethod.id)
                  )
                  .value()
              );
            }
          );
        },
      },
      Vehicles: {
        replaceById: (vehicles: any[]) => {
          if (vehicles.length === 0) return;

          const userId = vehicles[0].user_id;
          const vehicleIds = vehicles.map((v) => v.id);

          queryClient.setQueryData(
            queryKeys.Vehicles(userId),
            (prevVehicles) => {
              return (
                _.chain((prevVehicles as unknown[]) ?? [])
                  // Clear out old ones by ID
                  .reject((vehicle) => vehicleIds.includes(vehicle.id))
                  // Merge new ones
                  .thru((prevVehicles) => mergeBy(prevVehicles, vehicles, "id"))
                  .value()
              );
            }
          );
        },
        removeById: (vehicleIds: any[], userId: number) => {
          if (vehicleIds.length === 0) return;

          queryClient.setQueryData(
            queryKeys.Vehicles(userId),
            (prevVehicles) => {
              return (
                _.chain((prevVehicles as unknown[]) ?? [])
                  // Clear out old ones by ID
                  .reject((vehicle) => vehicleIds.includes(vehicle.id))
                  .value()
              );
            }
          );
        },
      },
      Wallet: {
        replaceByUserId: (wallet: any) => {
          queryClient.setQueryData(
            queryKeys.Wallet(wallet?.user_id),
            (prevWallet) => {
              return [wallet];
            }
          );
        },
        invalidate: (userId) => {
          queryClient.invalidateQueries(queryKeys.Wallet(userId));
        },
      },
      UserSettings: {
        replaceByKey: (
          userId: number,
          updatedUserSettings: { key: string; value: string | null }[]
        ) => {
          queryClient.setQueryData(
            queryKeys.UserSettings(userId),
            (
              prevUserSettings: { key: string; value: string | null }[] = []
            ) => {
              return _.chain(prevUserSettings)
                .filter(
                  (prevUserSetting) =>
                    _.find(updatedUserSettings, {
                      key: prevUserSetting.key,
                    }) === undefined
                )
                .concat(updatedUserSettings)
                .value();
            }
          );
        },
      },
    };
  }, [queryClient]);
}
