/**
 * Import this in app-bootstrap
 */

import { ErrorHandler, registerErrorHandler } from "../..";
import { isJwtExpiredError, isNetworkError } from "../../../utils";

// import NetInfo from "@react-native-community/netinfo";

/**
 * This catches Hasura JWT error responses globally before the default error handler is triggered
 * for all calls to handleError()
 *
 * Will show a modal and not propagate to the nearest ErrorBoundary
 */
// export const jwtExpiredErrorModalHandler: ErrorHandler = {
//   handler: async ({ auth, error, graphQlErrorResponse }) => {
//     if (isJwtExpiredError(error, graphQlErrorResponse)) {
//       console.warn("invalid-jwt", {
//         expiredJwt: {
//           cognitoAccessToken: { ...auth.cognitoAccessToken },
//           cognitoIdToken: { ...auth.cognitoIdToken },
//           cognitoRefreshToken: { ...auth.cognitoRefreshToken },
//           cognitoJwt: auth.cognitoJwt,
//         },
//       });

//       await auth.signOut();

//       return {
//         errorModal: {
//           error,
//           errorTitle: ["Logged", "Out"],
//           errorMessage: "Your login session has expired. Please log in again.",
//           // onClose: () => {},
//         },
//         report: true,
//         propogateToErrorBoundary: false,
//       };
//     }

//     return false;
//   },
// };
// registerErrorHandler(jwtExpiredErrorModalHandler);

/**
 * This catches our custom "No JWT" error in useGraphqlRequest() hook when getJwt() returns null
 *
 * Will attempt a single call to signOut()
 */
// export const jwtUnavailableErrorModalHandler: ErrorHandler = {
//   handler: async ({ auth, error, graphQlErrorResponse }) => {
//     if (error && error.isNoJwtError === true) {
//       await auth.signOut();

//       return {
//         errorModal: {
//           error,
//           errorTitle: ["Logged", "Out"],
//           errorMessage: "Your login session has expired. Please log in again.",
//           // onClose: () => {},
//         },
//         report: true,
//         propogateToErrorBoundary: false,
//       };
//     }

//     return false;
//   },
// };
// registerErrorHandler(jwtUnavailableErrorModalHandler);

/**
 * This catches dev-env Hasura Cloud Free Tier Requests/min errors so devs know that it's not a prod issue
 */
// export const hasuraRpmLimitErrorModalHandler: ErrorHandler = {
//   handler: ({ appConfig, auth, error, graphQlErrorResponse }) => {
//     if (isHasuraRpmError(error, graphQlErrorResponse)) {
//       if (appConfig.environment.isProduction) {
//         return {
//           errorModal: {
//             error,
//           },
//           report: true,
//           propogateToErrorBoundary: false,
//         };
//       } else {
//         return {
//           errorModal: {
//             error,
//             errorTitle: ["Hasura", "API Limit"],
//             errorMessage:
//               "Hasura cloud limit of 60 requests/minute exceeded - This should happen in development environments on the Hasura Cloud Free Tier.",
//             // onClose: () => {},
//           },
//           report: true,
//           propogateToErrorBoundary: false,
//         };
//       }
//     }

//     return false;
//   },
// };
// registerErrorHandler(hasuraRpmLimitErrorModalHandler);

/**
 * This catches network-related errors and shows a more user-friendly message
 */
export const networkErrorErrorModalHandler: ErrorHandler = {
  handler: async ({ appConfig, auth, error, graphQlErrorResponse }) => {
    if (isNetworkError(error)) {
      const isInternetReachable = false; //(await NetInfo.fetch()).isInternetReachable;

      if (isInternetReachable) {
        return {
          errorModal: {
            error,
            errorTitle: ["Network", "Error"],
            errorMessage:
              "We are having trouble contacting our servers. Our technical team has been notified. If this problem persists, please contact support.",
            // onClose: () => {},
          },
          report: true,
          propogateToErrorBoundary: false,
        };
      } else {
        return {
          errorModal: {
            error,
            errorTitle: ["Poor", "Internet Connection"],
            errorMessage:
              "We are having trouble connecting to the internet. Please make sure you're connected to the Internet and try again.",
            // onClose: () => {},
          },
          report: true,
          propogateToErrorBoundary: false,
        };
      }
    }

    return false;
  },
};
registerErrorHandler(networkErrorErrorModalHandler);
