import { Fragment, useCallback } from "react";
import { ParkableTransaction } from "../../../../../queries/parkable-transactions/useRecentParkableTransactions";
import useVehicles, { Vehicle } from "../../../../../queries/vehicles/useVehicles";
import { CountryModal, CountryStateSelect } from "./CountryStateSelect";
import { VehicleCard } from "../../../../../components/cards/VehicleCard";
import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import useCreateVehicle from "../../../../../queries/vehicles/useCreateVehicle";
import { CardsListLoadingSkeleton } from "../../../../../components/skeletons/HomeSessionsLoadingSkeleton copy";
import { TextSkeleton } from "../../../../../components/skeletons/TextSkeleton";
import { UiState } from "../../../../../components/skeletons/UiState";
import { event } from "../../../../../events/core/helpers";
import { ButtonPress } from "../../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../../events/event-sub-categories";

export type Props = {
  vehiclesQuery: ReturnType<typeof useVehicles>["query"],
  vehicles: Vehicle[];
  recentParkableTransactions: ParkableTransaction[];
  onClickVehicle: (vehicle: Vehicle) => any;
  plateText: string;
  onChangePlateText: (plateText: string) => any;
  selectedCountry?: string | null | undefined;
  onSelectCountry?: (selectedCountry?: string) => unknown;
  selectedState?: string | null | undefined;
  onSelectState?: (selectedState?: string) => unknown;
  disabled: boolean;
  mutation: ReturnType<typeof useCreateVehicle>["mutation"],
  defaultVehicleName: string;
};

function ChooseOrEnterPlate(props: Props) {
  const {
    vehiclesQuery,
    vehicles, 
    recentParkableTransactions, 
    onClickVehicle,
    plateText, 
    onChangePlateText, 
    selectedCountry,
    onSelectCountry = () => {},
    selectedState,
    onSelectState = () => {}, 
    disabled,
    mutation,
    defaultVehicleName,
  } = props;

  const submitDisabled = mutation.isLoading || !plateText || !selectedCountry || !selectedState;

  const onSubmit = useCallback(() => {
    if (submitDisabled) return;

    mutation.mutate({
      values: {
        name: defaultVehicleName,
        license_plate: plateText,
        country_code: selectedCountry,
        state_code: selectedState,
      }
    })
  }, [submitDisabled, mutation, defaultVehicleName, plateText, selectedCountry, selectedState]);

  return (
    <form
      className={`w-full mb-2`}
      onSubmit={e => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <div className={`w-full mb-2`}>
        <div
          className={`text-center mb-2`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `16px`,
            lineHeight: `20px`,
            color: `#0E2A48`,
          }}
        >
          {vehicles.length > 0 ? `Enter a New License Plate` : `Enter your License Plate`}
        </div>

        <div
          className={`mb-2`}
        >
          <input
            name="license_plate"
            id="license_plate"
            type="text"
            // pattern="/d*"
            placeholder="ie. ABC1234"
            value={plateText}
            onChange={(event) => {
              onChangePlateText(event.target.value.toUpperCase());
            }}
            autoComplete="off"
            autoFocus={false}
            disabled={disabled}
            maxLength={8}
            className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `20px`,
              lineHeight: `24px`,
              height: `50px`,
              borderWidth: `1px`,
              borderColor: `#E7EAF7`,
              color: `#0E2A48`,
            }}
          />
        </div>

        <div
          className={`mb-4`}
        >
          <CountryStateSelect
            selectedCountry={selectedCountry}
            onSelectCountry={onSelectCountry}
            selectedState={selectedState}
            onSelectState={onSelectState}
          />
        </div>

        {!!plateText && (
          <div
            className={`w-full text-center mb-4`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `12px`,
              lineHeight: `16px`,
              color: `red`,
            }}
          >
            IMPORTANT: Your license plate must be 100% correct to avoid parking tickets
          </div>
        )}

        <div
          className={`w-full mb-6`}
        >
          <FullWidthButton
            contentTop={mutation.isLoading ? `Confirming...` : `Confirm Plate`}
            onClick={() => {
              event(
                new ButtonPress({
                  buttonName: `Confirm Plate`,
                  buttonSlug: `CHOOSE_OR_ENTER_PLATE.CONFIRM`,
                  subCategory: EventSubCategories.VEHICLES,
                })
              );

              onSubmit();
            }}
            disabled={submitDisabled}
            disabledIfOffline
            showOfflineIndicator
          />
        </div>

        <div
          className={`w-full`}
        >
          <UiState
            errorText={`Oops, we're having trouble loading your recent license plates`}
            tryAgainAction={`RELOAD_PAGE`}
            query={vehiclesQuery}
            items={vehicles}
            loadingComponent={
              <>
                <div
                  className={`text-center mb-2`}
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: 700,
                    fontSize: `16px`,
                    lineHeight: `20px`,
                    color: `#0E2A48`,
                  }}
                >
                  <TextSkeleton fontSize={`16px`} lineHeight={`20px`} />
                </div>

                <CardsListLoadingSkeleton />
              </>
            }
            noItemsComponent={null}
          >
            <div
              className={`text-center mb-2`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `16px`,
                lineHeight: `20px`,
                color: `#0E2A48`,
              }}
            >
              Or choose a Recent License Plate
            </div>

            <div className={`w-full mb-4`}>
              {vehicles.map((vehicle, i) => (
                <Fragment key={vehicle.id}>
                  <VehicleCard
                    vehicleId={vehicle.id}
                    onClick={() => {
                      onClickVehicle(vehicle);
                    }}
                    showEditIcon={false}
                    showDeleteButton
                    showParkedIndicator
                    disabledIfParked
                  />

                  {i < (vehicles.length - 1) && (
                    <div className={`mb-2`}/>
                  )}
                </Fragment>
              ))}
            </div>
          </UiState>
        </div>
      </div>
    </form>
  );
}

export { ChooseOrEnterPlate };