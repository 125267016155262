import { Navigate, useNavigate } from "react-router-dom";
import { LogoHeader } from "../../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useCallback, useEffect } from "react";
import { Progress } from "../components/Progress";
import { ReactComponent as IconEdit } from "../../../../../src/assets/icons/icon-edit.svg";
import { ParkableCard } from "../../../../components/cards/ParkableCard";
import { Heading1 } from "../../../../components/headings/Heading1";
import { VehicleCard } from "../../../../components/cards/VehicleCard";
import { TimeCard } from "../../../../components/cards/TimeCard";
import { PaymentMethodCard } from "../../../../components/cards/PaymentMethodCard";
import { Summary } from "../components/Summary";
import useCreateParkableTransaction from "../../../../queries/parkable-transactions/useCreateParkableTransaction";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../../../queries/query-keys";
import { useHandleError } from "../../../../lib/error-handling";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import useCachePrimer from "../../../../queries/useCachePrimer";
import { UiBlock } from "../../../../components/ui-blocks/UiBlock";
import useVehicles from "../../../../queries/vehicles/useVehicles";
import _ from "lodash";
import { useNotificationsWarning } from "../../../../lib/useNotificationsWarning";
import usePaymentMethods from "../../../../queries/payment-methods/usePaymentMethods";
import { formatMoney, intervalAsSeconds, isValidInterval, paymentMethodIsExpired } from "../../../../lib/utils";
import { useParkableTransactionPriceInfo } from "../../../../lib/useParkableTransactionPriceInfo";
import useParkable from "../../../../queries/parkables/useParkable";
import { Auth } from "../../../../auth/core/Auth";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";
import { useThrowTestError } from "../../../../lib/error-handling/useThrowTestError";
import usePrevious from "../../../../lib/usePrevious";
import { useResetOnParkableChange } from "./useResetOnParkableChange";

export type Props = {

};

function Review(props: Props) {
  const { } = props;

  const queryClient = useQueryClient();
  const handleError = useHandleError();
  // const throwTestError = useThrowTestError();

  const navigate = useNavigate();
  const session = useSessionInProgress();
  let sessionValid = false;
  if (session.sessionInProgress) {
    const { parkableId, vehicleId, time, paymentMethodId } = session.sessionInProgress.sessionParameters;

    sessionValid = !!parkableId && !!vehicleId && !!time && !!paymentMethodId;
  }

  const notificationsWarning = useNotificationsWarning();

  const { query } = useRecentParkableTransactions();
  const { parkable } = useParkable({ parkableId: session.sessionInProgress?.sessionParameters.parkableId, poll: true });
  const { vehicles } = useVehicles();
  const vehicle = _.find(vehicles, { id: session.sessionInProgress?.sessionParameters.vehicleId });
  const { paymentMethods } = usePaymentMethods();
  const paymentMethod = _.find(paymentMethods, { id: session.sessionInProgress?.sessionParameters.paymentMethodId });
  const isPaymentMethodExpired = paymentMethod ? 
    paymentMethodIsExpired(
      paymentMethod.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_month,
      paymentMethod.mx_merchant_payment_method_metas?.[0].meta_card_account_expiry_year
    ) 
    : false;

  const { price, fee, total, walletSavings } = useParkableTransactionPriceInfo({
    parkable,
    time: session.sessionInProgress?.sessionParameters.time,
    walletFee: parkable?.parking_authority.wallet_fee,
    cardFee: parkable?.parking_authority.card_fee,
    applyFee: true,
  });

  const cachePrimer = useCachePrimer();
  
  const { mutation, parkableTransaction } = useCreateParkableTransaction({
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async (response, variables, context) => {
      const parkableTransaction = response?.parkableTransaction;

      cachePrimer.RecentParkableTransactions.replaceById([
        parkableTransaction,
      ]);
      // cachePrimer.ParkableTransaction.replaceById(
      //   parkableTransaction.user_id,
      //   parkableTransaction
      // );
      // cachePrimer.Wallet.invalidate(parkableTransaction?.user_id);

      const userId = (await Auth.getInstance().getSession())?.sessionDetails.userId;
      queryClient.invalidateQueries(queryKeys.RecentParkableTransactions(userId ?? -1));

      query.refetch();
      session.cancel();

      notificationsWarning.armWarning();

      navigate("/sessions", { replace: true });
    },
  });

  useEffect(() => {
    if (!session.stateRef.current.sessionInProgress) {
      navigate("/sessions", { replace: true });
      return;
    }
  }, []);

  useResetOnParkableChange({
    enabled: !!session?.sessionInProgress?.idempotentKey,
    time: session.sessionInProgress?.sessionParameters.time,
    parkable,
    onChange: () => {
      session.set({
        time: intervalAsSeconds(
          parkable.price_interval,
          parkable.price_interval_unit
        ),
      });
    }
  });

  if (!session.sessionInProgress) {
    return null;
  }

  const { parkableId, vehicleId, time, paymentMethodId } = session.sessionInProgress.sessionParameters;

  const vehicleDisabled = !parkableId;
  const vehicleIsNext = !!parkableId && !vehicleId;
  const timeDisabled = !parkableId || !vehicleId;
  const timeIsNext = !!vehicleId && !time;
  const paymentDisabled = !parkableId || !vehicleId || !time || isPaymentMethodExpired;
  const paymentIsNext = !!time && !paymentMethodId;

  return (
    <ScreenLayout
      header={
        <TitleHeader title={[`New`, `Session`]} />
      }
      content={
        <div
          className={`w-full flex flex-grow flex-col items-center`}
        >
          <Progress />

          <Heading1>
            Review and Pay
          </Heading1>

          <div
            className={`w-full mb-2`}
          >
            <ParkableCard
              parkableId={session.sessionInProgress.sessionParameters.parkableId}
              onClick={() => {
                session.navigate(`PARKABLE`);
              }}
              showEditIcon={true}
              disabled={false}
              skeletonMode={parkableId ? undefined : `PROMPT`}
            />
          </div>

          <div
            className={`w-full mb-2`}
          >
            <VehicleCard
              vehicleId={session.sessionInProgress.sessionParameters.vehicleId}
              onClick={() => {
                session.navigate(`VEHICLE`);
              }}
              showEditIcon={!vehicleDisabled}
              disabled={vehicleDisabled}
              skeletonMode={vehicleIsNext ? `PROMPT` : (vehicleDisabled ? `EMPTY` : undefined)}
            />
          </div>

          <div
            className={`w-full mb-2`}
          >
            <TimeCard
              parkableId={session.sessionInProgress.sessionParameters.parkableId}
              time={session.sessionInProgress.sessionParameters.time}
              session={null}
              onClick={() => {
                session.navigate(`TIME`);
              }}
              showEditIcon={!timeDisabled}
              disabled={timeDisabled}
              skeletonMode={timeIsNext ? `PROMPT` : (timeDisabled ? `EMPTY` : undefined)}
            />
          </div>

          <div
            className={`w-full mb-4`}
          >
            <PaymentMethodCard
              paymentMethodId={session.sessionInProgress.sessionParameters.paymentMethodId}
              onClick={() => {
                session.navigate(`PAYMENT`);
              }}
              showEditIcon={!paymentDisabled}
              disabled={paymentDisabled}
              skeletonMode={paymentIsNext ? `PROMPT` : (paymentDisabled ? `EMPTY` : undefined)}
            />
          </div>

          <div
            className={`w-full mb-4`}
          >
            <Summary

            />
          </div>

          <div
            className={`w-full flex flex-col items-center`}
          >
            <FullWidthButton
              contentTop={mutation.isLoading ? `Confirming Payment...` : `Confirm Payment`}
              contentBottom={`${formatMoney(total)}`}
              onClick={() => {
                // throwTestError();

                if (session.sessionInProgress) {
                  event(
                    new ButtonPress({
                      buttonName: `Confirm Payment`,
                      buttonSlug: `CREATE_PARKABLE_TRANSACTION.CONFIRM`,
                      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                    })
                  );

                  const { parkableId, vehicleId, time, paymentMethodId } = session.sessionInProgress.sessionParameters;

                  mutation.mutate({
                    idempotentKey: session.sessionInProgress.idempotentKey,
                    parkableId,
                    vehicleId,
                    time: time as number,
                    paymentMethodId,
                  });
                }
              }}
              disabled={!sessionValid || mutation.isLoading}
              disabledIfOffline
              showOfflineIndicator
            />

            <div className={`mb-4`} />

            <FullWidthButton
              contentTop={`Cancel Parking`}
              type={`tertiary-transparent`}
              onClick={() => {
                event(
                  new ButtonPress({
                    buttonName: `Cancel Parking`,
                    buttonSlug: `CREATE_PARKABLE_TRANSACTION.CANCEL`,
                    subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                  })
                );

                session.cancel();
                navigate(`sessions`);
              }}
              disabled={mutation.isLoading}
              disabledIfOffline
            />
          </div>

          <UiBlock
            show={mutation.isLoading || mutation.isSuccess}
            text={[`Please`, `Wait`, `...`]}
            subText={`You are not parked until you see "${vehicle?.license_plate} is Parked" on the home screen.`}
          />
        </div>
      }
    />
  );
}

export { Review };