import { useSessionInProgress } from "../../lib/session-in-progress/useSessionInProgress";
import { ReactComponent as IconArrow } from "../../../src/assets/icons/arrow-right-solid-white.svg";

export type Props = {

};

function SessionInProgressNag(props: Props) {
  const {} = props;

  const session = useSessionInProgress();

  if (!session.sessionInProgress) return null;

  return (
    <button
      className={`w-full py-2 rounded-md mb-4 flex items-center justify-center`}
      style={{
        fontFamily: `Roboto, sans-serif`,
        fontWeight: 700,
        fontSize: `14px`,
        lineHeight: `18px`,
        color: `#fff`,
        textTransform: "uppercase",
        backgroundColor: `#FF3B30`,
      }}
      onClick={(e) => {
        e.preventDefault();
        session.navigate();
      }}
    >
      {session.sessionInProgress?.parkableTransactionId ? `Finish Your Time Extension` : `Finish Your Session`}
      {` `}
      <IconArrow
        className={`ml-4`}
        style={{
          height: `12px`,
          top: `15px`,
          // right: `6px`,
        }}
      />
    </button>
  );
}

export { SessionInProgressNag };