import _ from "lodash";
import { Fragment, memo } from "react";

import { AlertModalMessageProps } from "./types";
import { FullWidthButton } from "../../buttons/FullWidthButton";
import { Modal } from "rsuite";

export type Props = AlertModalMessageProps & {
  onRequestClose?: () => any;
};

// TODO Allow passing in array of button configs

function AlertModal(props: Props) {
  const {
    title = ["Alert"],
    message,
    buttons = [
      {
        contentTop: `Close`,
        type: `tertiary-transparent`,
        onClick: () => {
          if (props.onRequestClose) {
            props.onRequestClose();
          }
        },
      },
    ],
    onRequestClose = () => { },
  } = props;

  let titleParts: (string | null)[] = [];
  let titleEls: JSX.Element | JSX.Element[] = [];

  if (
    _.isArray(title) &&
    _.filter(title, (et) => _.isString(et)).length === 0
  ) {
    titleEls = title as JSX.Element | JSX.Element[];
  } else {
    if (_.isArray(title)) {
      titleParts = title as (string | null)[];
    } else if (_.isString(title)) {
      titleParts = [title];
    }

    titleEls = titleParts.map((part: string | null, i: number) => (
      <span
        className={`inline-block`}
        style={{
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: `18px`,
          lineHeight: `18px`,
          color:
            i % 2 === 0 ? "rgba(10, 132, 255, 1)" : "rgba(14, 42, 72, 1)",
          marginRight: `6px`,
        }}
        key={`${i}${part}`}
      >
        {part}
      </span>
    ));
  }

  return (
    <Modal
      open={!!message}
      onClose={onRequestClose}
      dialogClassName={``}
      size="xs"
      dialogStyle={{
        
      }}
    >
      <div
        className={`w-full`}
        style={{
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: `18px`,
          lineHeight: `18px`,
          color: `#0d2b48`,
        }}
      >
        <div
          className={``}
          style={{
            
          }}
        >
          {titleEls}
        </div>

        <div
          className={`mb-4`}
        >
            {_.isString(message) && <MessageText message={message} />}
            {!_.isString(message) && message}
          </div>

          <div
            className={``}
            style={
              {
                
              }
            }
          >
            {buttons.map((buttonProps, i) => {
              return (
                <Fragment key={i}>
                  <FullWidthButton
                    type={`primary-blue`}
                    contentTop={`Close`}
                    {...buttonProps}
                    onClick={() => {
                      if (buttonProps.onClick) {
                        buttonProps.onClick();
                      }

                      if (onRequestClose) {
                        onRequestClose();
                      }
                    }}
                    key={i}
                  />

                  {i !== buttons.length - 1 && (
                    <div style={{ marginBottom: 10 }} key={`${i}-spacer`} />
                  )}
                </Fragment>
              );
            })}
          </div>
      </div>
    </Modal>
  );
}

export function MessageText(props: { message: string }) {
  const { message } = props;

  return (
    <div
      className={`mt-2 mb-1`}
      style={
        {
          
        }
      }
    >
      <div
        className={`mt-2 mb-1`}
        style={
          {
            fontFamily: "Roboto",
            fontSize: `14px`,
            lineHeight: `22px`,
            fontWeight: 700,
            color: "#0E2A48",
          }
        }
      >
        {message}
      </div>
    </div>
  );
}

export default AlertModal;
export { AlertModal }
