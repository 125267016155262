import { useCallback, useState } from "react";
import { PaymentMethod } from "../../../../../queries/payment-methods/usePaymentMethods";
import { CreditCardInput } from "../../../../../components/inputs/credit-card/CreditCardInput";
import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { ExpiryInput } from "../../../../../components/inputs/credit-card/ExpiryInput";
import { PostalCodeInput } from "../../../../../components/inputs/credit-card/PostalCodeInput";
import { CvvInput } from "../../../../../components/inputs/credit-card/CvvInput";
import { evaluateCardAccount } from "../../../../../lib/utils";
import useVaultCard from "../../../../../queries/payment-methods/useVaultCard";
import { useHandleError } from "../../../../../lib/error-handling";
import { errorHandlers } from "../../../../../lib/error-handling/error-handlers/mxm/errorHandlers";
import { useAlertModal } from "../../../../../components/modals/alert-modal";
import { event } from "../../../../../events/core/helpers";
import { ButtonPress } from "../../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../../events/event-sub-categories";

export type Props = {
  paymentMethods: PaymentMethod[];
  onCreate: (paymentMethod: PaymentMethod) => any;
  onCancel: () => any;
};

function EnterNewCard(props: Props) {
  const {
    paymentMethods, 
    onCreate,
    onCancel,
  } = props;

  const handleError = useHandleError();
  const alert = useAlertModal();

  const [cardNumber, setCardNumber] = useState<string>(``);
  const [expiryMonthYear, setExpiryMonthYear] = useState<string>(``);
  const [cvv, setCvv] = useState<string>(``);
  const [postalCode, setPostalCode] = useState<string>(``);

  const [showFieldErrors, setShowFieldErrors] = useState<boolean>(false);
  const { issuers, isCardNumberValid, isExpiryMonthValid, isExpiryYearValid, isPostalCodeValid, isCvvValid, isValid } = evaluateCardAccount({
    cardNumber: cardNumber,
    expiryMonth: expiryMonthYear.length >= 2 ? expiryMonthYear.substring(0, 2) : null,
    expiryYear: expiryMonthYear.length === 4 ? expiryMonthYear.substring(2, 4) : null,
    cvv,
    postalCode,
  });
  const cardType = [
    "AMERICAN_EXPRESS",
    "DISCOVER",
    "MASTERCARD",
    "VISA",
  ].includes(issuers[0]?.id ?? "")
    ? issuers[0].id
    : "GENERIC";

  const { mutation, paymentMethod } = useVaultCard({
    onError: (error) => {
      handleError(error, undefined, errorHandlers);
    },
    onSuccess: (resp) => {
      if (resp.alreadyExists) {
        alert.show({
          title: ["Duplicate", "Card"],
          message: "A card with these card numbers already exists. If you want to update a card, please delete it first, then re-enter it.",
        });
      } else {
        onCreate(resp.paymentMethod);
      }
    },
  });

  const submitDisabled = !isValid || mutation.isLoading;

  const onSubmit = useCallback(() => {
    if (submitDisabled) return;

    mutation.mutate({
      alias: "My Card",
      cardAccount: {
        number: cardNumber,
        expiryMonth: expiryMonthYear.substring(0, 2),
        expiryYear: expiryMonthYear.substring(2, 4),
        avsZip: postalCode,
        cvv,
      },
      cardType: evaluateCardAccount({ cardNumber }).issuers[0]?.name,
    });
  }, [submitDisabled, mutation, cardNumber, expiryMonthYear, postalCode, cvv]);

  return (
    <form
      className={`w-full mb-2`}
      onSubmit={e => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <div className={`w-full mb-2`}>
        <div
          className={`text-center mb-2`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `16px`,
            lineHeight: `20px`,
            color: `#0E2A48`,
          }}
        >
          Enter your Card Information
        </div>
        
        <CreditCardInput
          value={cardNumber}
          onChange={(value) => {
            setCardNumber(value);
          }}
          disabled={mutation.isLoading}
          isError={showFieldErrors && !isCardNumberValid}
        />

        <div className={`mb-2`} />

        <ExpiryInput
          value={expiryMonthYear}
          onChange={(value) => {
            setExpiryMonthYear(value);
          }}
          disabled={mutation.isLoading}
          isError={showFieldErrors && (!isExpiryMonthValid || !isExpiryYearValid)}
        />

        <div className={`mb-2`} />

        <CvvInput
          value={cvv}
          onChange={(value) => {
            setCvv(value);
          }}
          disabled={mutation.isLoading}
          isError={showFieldErrors && !isCvvValid}
        />

        <div className={`mb-2`} />

        <PostalCodeInput
          value={postalCode}
          onChange={(value) => {
            setPostalCode(value);
          }}
          disabled={mutation.isLoading}
          isError={showFieldErrors && !isPostalCodeValid}
        />

        <div className={`mb-2`} />

        <div className={`w-full mb-4`}>
          <FullWidthButton
            contentTop={mutation.isLoading ? `Saving...` : `Save Card`}
            onClick={() => {
              event(
                new ButtonPress({
                  buttonName: `Save Card`,
                  buttonSlug: `ENTER_NEW_CARD.CONFIRM`,
                  subCategory: EventSubCategories.PAYMENT_METHODS,
                })
              );

              onSubmit();
            }}
            onClickDisabled={() => {
              setShowFieldErrors(true);
            }}
            disabled={submitDisabled}
            disabledIfOffline
            showOfflineIndicator
          />
        </div>

        <div>
          <FullWidthButton
            contentTop={`Back`}
            type={`tertiary-transparent`}
            onClick={() => {
              event(
                new ButtonPress({
                  buttonName: `Back`,
                  buttonSlug: `ENTER_NEW_CARD.CANCEL`,
                  subCategory: EventSubCategories.PAYMENT_METHODS,
                })
              );

              onCancel()
            }}
            disabled={mutation.isLoading}
          />
        </div>
      </div>
    </form>
  );
}

export { EnterNewCard };