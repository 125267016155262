import moment from "moment-timezone";
import { FullWidthButton } from "../../../components/buttons/FullWidthButton";
import useUpdateUser from "../../../queries/users/useUpdateUser";
import { Layout } from "../../components/Layout";
import { useHandleError } from "../../../lib/error-handling";
import { useMultiAuth } from "../../../auth/core/useMultiAuth";

function TermsOfService() {
  const handleError = useHandleError();
  const auth = useMultiAuth();
  const { mutation } = useUpdateUser({
    onError: (error) => {
      handleError(error);
    },
  });

  return (
    <Layout
      headingText={`Privacy & Terms`}
      subHeadingText={`Please review and accept our Terms and Privacy Policy.`}
      content={
        <div
          className={`flex flex-grow flex-col justify-center`}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 700,
            fontSize: `14px`,
            lineHeight: `18px`,
          }}
        >
          <div
            className={`mb-4`}
          >
            <a href="https://www.meterez.com/terms-of-use/" target="_blank">
              View Terms of Service
            </a>
          </div>
          <div>
            <a href="https://www.meterez.com/privacy-policy/" target="_blank">
              View Privacy Policy
            </a>
          </div>
        </div>
      }
      mainButton={
        <FullWidthButton
          contentTop={mutation.isLoading ? `Accepting...`:`Accept Terms`}
          onClick={() => {
            mutation.mutate({
              id: auth.session?.sessionDetails.userId ?? -1,
              values: {
                terms_of_service_last_accepted: moment.utc().toISOString(),
              },
            });
          }}
          disabled={mutation.isLoading}
          disabledIfOffline
          showOfflineIndicator
        />
      }
    />
  );
}

export { TermsOfService };