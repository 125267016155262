import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";

export type Data = {
  prevOnline: boolean;
  online: boolean;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class OnlineStateChanged extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { prevOnline, online, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      type: `ONLINE_STATE_CHANGED`,
      data: {
        prevOnline,
        online,
      },
    });
  }
}

export { OnlineStateChanged };
