import _ from "lodash";
import { ReactComponent as IconMeterExplainer } from "../../../../../../src/assets/icons/meter-home.svg"

export type Props = {
  searchText: string;
  onChangeSearchText: (searchText: string) => any;
  disabled: boolean;
  errorMsg: string | null;
};

function Search(props: Props) {
  const { searchText, onChangeSearchText, disabled, errorMsg } = props;


  return (
    <div className={`w-full mb-2`}>
      <div
        className={`w-full flex justify-center mb-2`}
        style={{
          
        }}
      >
        <IconMeterExplainer
          className={`relative`}
          style={{
            width: `110px`,
            height: `101px`,
            left: `32px`,
          }}
        />
      </div>
      <div
        className={`text-center mb-2`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `16px`,
          lineHeight: `20px`,
          color: `#0E2A48`,
        }}
      >
        Enter Meter or Zone Number
      </div>
      <div
        className={`w-full uppercase flex flex-col items-center justify-center`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `12px`,
          lineHeight: `18px`,
          color: `#FF3B30`,
          height: `30px`,
        }}
      >
        {errorMsg}
      </div>
      <input
        name="meter_number"
        id="meter_number"
        type="text"
        pattern="/d*"
        inputMode="numeric"
        placeholder="ie. 1234"
        value={searchText}
        onChange={(event) => {
          onChangeSearchText(_.trimStart(_.toString(_.toInteger(event.target.value)), '0'))
        }}
        disabled={disabled}
        maxLength={4}
        className={`w-full text-center flex items-center justify-center rounded-md outline-0`}
        autoComplete="off"
        autoFocus={false}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `20px`,
          lineHeight: `24px`,
          height: `50px`,
          borderWidth: `1px`,
          borderColor: `#E7EAF7`,
          color: `#0E2A48`,
        }}
      />
    </div>
  );
}

export { Search };