import { getBrowser } from "../getBrowser/getBrowser";
import { getOperatingSystem } from "../getOperatingSystem/getOperatingSystem";
import { DeviceHeaders } from "./types";

function getDeviceHeaders(): DeviceHeaders {
  const headers: DeviceHeaders = {
    "X-Source": "meterez.web",
    // "X-App-Version": ,
    // "X-Operating-System-Version": ,
    // "X-Browser-Version": ,

    // Old Values
    // "X-Source": "WEB",
    // "X-Platform": "web",
    // "X-Operating-System": getBrowserAgentPlatform(),
  };

  const operatingSystem = getOperatingSystem();

  if (operatingSystem) {
    headers["X-Operating-System"] = operatingSystem;
  }

  const browser = getBrowser();

  if (browser) {
    headers["X-Browser"] = browser;
  }

  return headers;
}

export { getDeviceHeaders };
