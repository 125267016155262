import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";

export type Data = {
  prevRouteName?: string | null | undefined;
  routeName?: string | null | undefined;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class ScreenFocused extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { prevRouteName, routeName, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      type: `SCREEN_FOCUSED`,
      data: {
        prevRouteName,
        routeName,
      },
    });
  }
}

export { ScreenFocused };
