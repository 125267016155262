import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { CreateParkableTransactionDocument, CreateParkableTransactionMutation, CreateParkableTransactionMutationVariables } from "../../generatedQueries";
import { request } from 'graphql-request';
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { event } from "../../events/core/helpers";
import { CreateParkableTransactionRequest } from "../../events/event-types/parkable-transactions/CreateParkableTransactionRequest";
import { CreateParkableTransactionResponse } from "../../events/event-types/parkable-transactions/CreateParkableTransactionResponse";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

type Options = Omit<
  UseMutationOptions<
    CreateParkableTransactionMutation["createParkableTransaction"],
    unknown,
    CreateParkableTransactionMutationVariables
  >,
  "mutationFn"
>;

export default function useCreateParkableTransaction(options: Options) {
  const mutation = useMutation<
    CreateParkableTransactionMutation["createParkableTransaction"],
    unknown,
    CreateParkableTransactionMutationVariables
  >(
    async (variables) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };
      
      return (await request({
        url: getGraphqlEndpoint() as string,
        document: CreateParkableTransactionDocument,
        variables: {
          ...variables,
          source: 'WEB',
        },
        requestHeaders,
        // signal,
      })).createParkableTransaction;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      ...options,

      onMutate: (variables) => {
        event(
          new CreateParkableTransactionRequest({
            mutationVariables: variables,
          })
        );
      },
      onSuccess: (response, variables, context) => {
        event(
          new CreateParkableTransactionResponse({
            mutationResponse: response,
          })
        );

        const parkableTransaction = response?.parkableTransaction;

        if (options.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    }
  );

  return {
    mutation,
    parkableTransaction: mutation.data ?? null,
  };
}
