import { EventCategory, EventType, SentryBreadcrumbPartial } from "./types";

export type ConstructorArgs<TData = any> = {
  occurredAt?: number; // Timestamp w/ microtime
  category: EventCategory;
  subCategory?: EventCategory;
  type: EventType;
  data?: TData;
};

class Event<TData = any> {
  public eventId?: string;
  public occurredAt?: number; // Timestamp w/ microtime
  public category: EventCategory;
  public subCategory: EventCategory | null;
  public type: EventType;
  public data?: TData;

  public constructor(args: ConstructorArgs<TData>) {
    const { occurredAt, category, subCategory = null, type, data } = args;

    this.occurredAt = occurredAt;
    this.category = category;
    this.subCategory = subCategory;
    this.type = type;
    this.data = data;

    const breadcrumbCatStrParts = [];
    if (this.category) {
      breadcrumbCatStrParts.push();
    }
  }

  public toSentryBreadcrumb = (): SentryBreadcrumbPartial => {
    return {
      type: `default`,
      level: `info`,
      category: `console`,
      message: undefined,
      data: this.data ?? undefined,
    };
  }
}

export { Event };
