import { FullWidthButton } from "../../../../../components/buttons/FullWidthButton";
import { ParkableCard } from "../../../../../components/cards/ParkableCard";
import { Heading1 } from "../../../../../components/headings/Heading1";

export type Props = {
  parkableId: number | null | undefined;
  errorMessage?: string;
  onCancel: () => any;
};

function Confirm(props: Props) {
  const { parkableId, errorMessage, onCancel } = props;

  return (
    <div className={`w-full mb-2`}>
      <div
        className={`w-full mb-4`}
      >
        <Heading1>
          Confirm your Meter / Zone choice
        </Heading1>

        {!!errorMessage && (
          <div
            className={`w-full uppercase flex flex-col items-center justify-center mt-2 mb-2 text-center`}
            style={{
              fontFamily: "Roboto, sans-serif",
              fontWeight: 700,
              fontSize: `12px`,
              lineHeight: `18px`,
              color: `#FF3B30`,
              height: `30px`,
            }}
          >
            {errorMessage}
          </div>
        )}

        <ParkableCard
          // skeletonMode={`EMPTY`}
          // skeletonMode={`PROMPT`}
          parkableId={parkableId}
          showEditIcon={false}
        />
      </div>

      <div className={`w-full mb-2`}>
        <FullWidthButton
          contentTop={`Choose a Different Meter`}
          type={`tertiary-transparent`}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </div>
  );
}

export { Confirm };