import { useNavigate, useParams } from "react-router-dom";
import { LogoHeader } from "../../../../components/headers/LogoHeader";
import { ScreenLayout } from "../../../../components/layouts/ScreenLayout";
import { useSessionInProgress } from "../../../../lib/session-in-progress/useSessionInProgress";
import { FullWidthButton } from "../../../../components/buttons/FullWidthButton";
import { useEffect } from "react";
import { TimePicker } from "../components/time/TimePicker";
import { formatMoney, intervalAsSeconds, isValidInterval, toValidInteger } from "../../../../lib/utils";
import { Progress } from "../components/Progress";
import { useRecentParkableTransactions } from "../../../../queries/parkable-transactions/useRecentParkableTransactions";
import _ from "lodash";
import { RedirectOnExtendSessionInvalid } from "../components/RedirectOnExtendSessionInvalid";
import { TitleHeader } from "../../../../components/headers/TitleHeader";
import useParkable from "../../../../queries/parkables/useParkable";
import { useParkableTransactionPriceInfo } from "../../../../lib/useParkableTransactionPriceInfo";
import { ParkableCard } from "../../../../components/cards/ParkableCard";
import moment from "moment-timezone";
import { event } from "../../../../events/core/helpers";
import { ButtonPress } from "../../../../events/event-types/common/ButtonPress";
import { EventSubCategories } from "../../../../events/event-sub-categories";
import { ErieEclipseRateExplainer } from "../components/ErieEclipseRateExplainer";

export type Props = {

};

// TODO Make sure everything is based on the parkableTransaction snapshot for time extensions

function Time(props: Props) {
  const {  } = props;

  const { parkableTransactionId: parkableTransactionIdStr } = useParams() as { parkableTransactionId?: number };
  const parkableTransactionId = toValidInteger(parkableTransactionIdStr);
  const navigate = useNavigate();
  const session = useSessionInProgress();

  const { query, parkableTransactions } = useRecentParkableTransactions();
  const parkableTransaction = _.find(parkableTransactions, { id: parkableTransactionId });

  const { price, fee, total, walletSavings } = useParkableTransactionPriceInfo({
    parkable: {
      price: parkableTransaction?.snapshot_price,
      price_interval: parkableTransaction?.snapshot_price_interval,
      price_interval_unit: parkableTransaction?.snapshot_price_interval_unit,
    },
    time: session.sessionInProgress?.sessionParameters.time,
    walletFee: parkableTransaction?.snapshot_fee,
    cardFee: parkableTransaction?.snapshot_fee,
    applyFee: false,
  });

  useEffect(() => {
    if (
      !session.stateRef.current.sessionInProgress || 
      !session.stateRef.current.sessionInProgress.parkableTransactionId || 
      !parkableTransactionId
    ) {
      navigate("/sessions", { replace: true });
      return;
    }
  }, []);

  // Set a default value if it's not set yet
  useEffect(() => {
    if (parkableTransaction) {
      const time = session.sessionInProgress?.sessionParameters.time;

      if (
        !time 
        || !isValidInterval(
          parkableTransaction.snapshot_price_interval,
          parkableTransaction.snapshot_price_interval_unit,
          time,
        )
      ) {
        session.set({
          time: intervalAsSeconds(
            parkableTransaction.snapshot_price_interval,
            parkableTransaction.snapshot_price_interval_unit
          ),
        });
      }
    }
  }, [session.sessionInProgress?.sessionParameters.time, session, parkableTransaction]);

  if (!session.sessionInProgress) {
    return null;
  }

  // const { parkableId, vehicleId, time, paymentMethodId } = session.sessionInProgress.sessionParameters;

  if (!session.sessionInProgress || !parkableTransaction) {
    return null;
  }

  const time = isValidInterval(
    parkableTransaction.snapshot_price_interval,
    parkableTransaction.snapshot_price_interval_unit,
    session.sessionInProgress.sessionParameters.time,
  ) ? 
    session.sessionInProgress.sessionParameters.time
    : null;

  return (
    <>
      <ScreenLayout
        header={
          <TitleHeader title={[`Extend`, `Session`]} />
        }
        content={
          <div
            className={`w-full flex flex-grow flex-col items-center`}
          >
            <Progress />

            <div
              className={`w-full`}
            >
              <div
                className={`text-center mb-4`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `16px`,
                  lineHeight: `20px`,
                  color: `#0E2A48`,
                }}
              >
                Choose your Extension Duration
              </div>

              <div
                className={`mb-6`}
              >
                <ParkableCard
                  parkableId={parkableTransaction?.parkable_id}
                />

                <ErieEclipseRateExplainer
                  parkable={{
                    price: parkableTransaction?.snapshot_price,
                    price_interval: parkableTransaction?.snapshot_price_interval,
                    price_interval_unit: parkableTransaction?.snapshot_price_interval_unit,
                    parking_authority_id: parkableTransaction?.parkable?.parking_authority_id,
                  }} 
                />
              </div>
              
              <TimePicker
                parkable={{
                  price: parkableTransaction.snapshot_price,
                  price_interval: parkableTransaction.snapshot_price_interval,
                  price_interval_unit: parkableTransaction.snapshot_price_interval_unit,
                  time_limit: parkableTransaction.snapshot_time_limit,
                  time_limit_unit: parkableTransaction.snapshot_time_limit_unit,
                }}
                session={parkableTransaction}
                time={time}
                onChangeTime={(time) => {
                  session.set({
                    time,
                  });
                }}
              />
            </div>

            <div
              className={`w-full flex flex-col items-center mt-4`}
            >
              <FullWidthButton
                contentTop={`Continue`}
                contentBottom={`${formatMoney(price)}`}
                onClick={() => session.navigate(`PAYMENT`)}
                disabled={!time}
              />

              <div className={`mb-4`} />

              <FullWidthButton
                contentTop={`Cancel Extension`}
                type={`tertiary-transparent`}
                onClick={() => {
                  event(
                    new ButtonPress({
                      buttonName: `Cancel Extension`,
                      buttonSlug: `EXTEND_TIME.CANCEL`,
                      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
                    })
                  );

                  session.cancel();
                  navigate(`sessions`);
                }}
                // disabled={...}
              />
            </div>
          </div>
        }
      />
      <RedirectOnExtendSessionInvalid />
    </>
  );
}

export { Time };