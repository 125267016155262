import { useCallback, useEffect, useMemo, useState } from "react";

import { Storage } from "./Auth";

export type Platform = `android` | `ios` | `web`;
export type BrowserAgentPlatform = `android` | `ios` | `osx` | null;
export type Provider = `phone_number` | `email` | `apple` | `google`;

export function providersNaturalOrder(
  platform: Platform,
  browserAgentPlatform: BrowserAgentPlatform
): Provider[] {
  if (
    platform === `android` ||
    (platform === `web` && browserAgentPlatform === `android`)
  ) {
    return [`google`, `apple`, `phone_number`, `email`];
  } else if (
    platform === `ios` ||
    (platform === `web` && browserAgentPlatform === `ios`) ||
    (platform === `web` && browserAgentPlatform === `osx`)
  ) {
    return [`apple`, `google`, `phone_number`, `email`];
  }
  return [`google`, `apple`, `phone_number`, `email`];
}

export type State = {
  isLoaded: boolean;
  lastSelected: Provider | null;
  lastLoggedIn: Provider | null;
};

export type Args = {
  storage: Storage;
  storageKey?: string;
  platform: Platform;
  browserAgentPlatform?: BrowserAgentPlatform;
  providers?: Provider[];
  forceMultiProviderMode?: boolean;
};

function useProviders(args: Args) {
  const {
    storage,
    storageKey = `auth.provders.v2`,
    platform,
    browserAgentPlatform = null,
    providers: enabledProviders = [`phone_number`, `email`, `apple`, `google`],
    forceMultiProviderMode = false,
  } = args;

  const [state, setState] = useState<State>({
    isLoaded: false,
    lastSelected: null,
    lastLoggedIn: null,
  });

  useEffect(() => {
    const load = async function () {
      const storedState = await loadState(storage, storageKey);

      setState((state) => ({
        ...state,
        ...storedState,
        isLoaded: true,
      }));
    };

    load();
  }, []);

  const { isLoaded } = state;

  let lastSelected: Provider | null =
    state.lastSelected && enabledProviders.includes(state.lastSelected)
      ? state.lastSelected
      : null;

  const lastLoggedIn: Provider | null =
    state.lastLoggedIn && enabledProviders.includes(state.lastLoggedIn)
      ? state.lastLoggedIn
      : null;

  const [allProviders, otherProviders] = useMemo(() => {
    const { lastLoggedIn } = state;

    const naturalOrder = providersNaturalOrder(
      platform,
      browserAgentPlatform
    ).filter((p) => enabledProviders.includes(p));

    const otherProviders =
      lastLoggedIn === null
        ? naturalOrder
        : naturalOrder.filter((p) => p !== lastLoggedIn);

    return [naturalOrder, otherProviders];
  }, [platform, browserAgentPlatform, enabledProviders, state]);

  if (
    allProviders.length === 1 &&
    [`email`, `phone_number`].includes(allProviders[0]) &&
    !forceMultiProviderMode
  ) {
    lastSelected = allProviders[0];
  }

  const setLastSelected = useCallback(
    (provider: Provider | null) => {
      if (!isLoaded) return;

      const newState: State = {
        ...state,
        lastSelected: provider,
      };

      setState(newState);

      persistState(storage, storageKey, newState);
    },
    [isLoaded, state, storage, storageKey]
  );

  const setLastLoggedIn = useCallback(
    (provider: Provider | null) => {
      if (!isLoaded) return;

      const newState: State = {
        ...state,
        lastLoggedIn: provider,
      };

      setState(newState);

      persistState(storage, storageKey, newState);
    },
    [isLoaded, state, storage, storageKey]
  );

  return useMemo(() => {
    return {
      isLoaded,
      allProviders,
      otherProviders,
      lastSelected,
      lastLoggedIn,
      setLastSelected,
      setLastLoggedIn,
    };
  }, [
    isLoaded,
    allProviders,
    otherProviders,
    lastSelected,
    lastLoggedIn,
    setLastSelected,
    setLastLoggedIn,
  ]);
}

const loadState = async function (
  storage: Storage,
  storageKey: string
): Promise<Omit<State, `isLoaded`> | undefined> {
  const stateJsonStr = await storage.getItem(storageKey);

  if (typeof stateJsonStr === `string`) {
    const state = JSON.parse(stateJsonStr);

    if (state?.lastSelected !== `undefined`) {
      return state as State;
    }
  }

  return undefined;
};

const persistState = async function (
  storage: Storage,
  storageKey: string,
  state: State
): Promise<void> {
  const { isLoaded, ...statePartial } = state;

  const stateJsonStr = JSON.stringify(statePartial);

  await storage.setItem(storageKey, stateJsonStr);
};

export { useProviders };
