import signInWithAppleText from "../../../../../../src/assets/sign-in-with-apple-text.png";

export type Props = {
  onPress?: () => any;
};

function AppleButton(props: Props) {
  const { onPress = () => {} } = props;

  return (
    <button
      className={`flex justify-center items-center w-full rounded-md`}
      style={{
        height: `50px`,
        backgroundColor: `black`,
      }}
      onClick={onPress}
    >
      <img
        src={signInWithAppleText} 
        alt={`Sign in with Apple`} 
        style={{
          height: `18px`,
          width: `auto`,
        }}  
      />
    </button>
  );
}

export { AppleButton };
