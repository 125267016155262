import _ from "lodash";
import * as Sentry from "@sentry/react";

import {
  EVENTS_PURGE_COUNT,
  MAX_STORED_EVENTS,
  STORAGE_QUEUE_NEW_SUB_KEY,
  STORAGE_QUEUE_PENDING_SUBMISSION_SUB_KEY,
} from "./constants";
import { MalformedEventsQueueError } from "./errors/MalformedEventsQueueError";
import { EventCategories } from "./event-categories";
import { EventSubCategories } from "./event-sub-categories";
import {
  Event,
  EventsBatch,
  EventsBatchStatus,
  EventsQueue,
  Storage,
  Submitter,
  SubmitterResponse,
} from "./types";

export type Listener = {
  callback: (event: Event) => any;
};

export type Listeners = Record<string, Record<string, Listener>>;

export type ConstructorArgs = {
  storage: Storage;
  storageKey?: string;
  submitter: Submitter;
  makeUuid: () => string;
  debug?: boolean;
  onError?: (e: unknown) => any;
};

/**
 *
 */
class Events {
  protected debug: boolean = false;

  protected static maxSubmissionAttemps = 10;

  protected static instance: Events | null = null;

  protected storage: Storage;
  protected storageKey: string;
  protected submitter: Submitter;
  protected makeUuid: () => string;
  protected onError?: (e: unknown) => any;

  protected listeners: Listeners = {};

  protected queue: EventsQueue = {
    schemaVersion: `1.0`,
    new: {
      lastChangedAt: -1,
      lastStoredAt: -1,
      events: [],
    },
    pendingSubmission: {
      lastChangedAt: -1,
      lastStoredAt: -1,
      eventsBatches: [],
    },
  };

  protected storeNewEventsDebounced: () => any;
  protected storeNewEventsThrottled: () => any;
  protected submitEventsDebounced: () => any;
  protected submitEventsThrottled: () => any;
  protected submitEventsDebouncedSlow: () => any;
  protected submitEventsThrottledSlow: () => any;

  protected submitting: boolean = false;

  public static getInstance(args?: ConstructorArgs) {
    if (Events.instance) {
      return Events.instance;
    }

    if (!args) {
      throw new Error("Events constructor args are required");
    }

    return new Events(args);
  }

  public static async dispatch(event: Event): Promise<Event> {
    return await Events.getInstance().dispatch(event);
  }

  /**
   *
   * @param args
   */
  public constructor(args: ConstructorArgs) {
    const {
      storage,
      storageKey = `events.queue.v4`,
      submitter,
      makeUuid,
      debug,
      onError,
    } = args;

    if (Events.instance) {
      throw new Error("Only one instances of Events is allowed");
    }

    this.storage = storage;
    this.storageKey = storageKey;
    this.submitter = submitter;
    this.makeUuid = makeUuid;
    this.debug = debug ?? this.debug;
    this.onError = onError;

    this.storeNewEventsDebounced = _.debounce(this.storeNewEvents, 1000, {
      leading: true,
      trailing: true,
    });
    this.storeNewEventsThrottled = _.throttle(this.storeNewEvents, 1000, {
      leading: true,
      trailing: true,
    });

    this.submitEventsDebounced = _.debounce(this.submitEvents, 1000, {
      leading: true,
      trailing: true,
    });
    this.submitEventsThrottled = _.throttle(this.submitEvents, 1000, {
      leading: true,
      trailing: true,
    });

    this.submitEventsDebouncedSlow = _.debounce(this.submitEvents, 5000, {
      leading: true,
      trailing: true,
    });
    this.submitEventsThrottledSlow = _.throttle(this.submitEvents, 5000, {
      leading: true,
      trailing: true,
    });

    Events.instance = this;
  }

  /**
   *
   * @param event
   */
  public dispatch = async (event: Event): Promise<Event | undefined> => {
    try {
      const serializedEvent = {
        eventId: this.makeUuid(),
        occurredAt: event.occurredAt ?? Date.now() / 1000,
        category: event.category,
        subCategory: event.subCategory,
        type: event.type,
        data: event.data,
      };

      this.queue.new.events.push(serializedEvent);

      if (this.debug) {
        console.log(
          `[EVENTS.DISPATCH] ${event.type} (${event.category}${
            event.subCategory ? `.${event.subCategory}` : ``
          })`,
          event.data
        );
      }

      this.queue.new.lastChangedAt = Date.now() / 1000;

      await this.storeNewEvents();

      const listeners = this.listeners[event.type] ?? this.listeners[`*`];

      if (listeners) {
        _.each(listeners, ({ callback }) => {
          callback(event);
        });
      }

      // Catch all
      this.submitEventsDebouncedSlow();

      // Convert to Sentry Breadcrumb
      // TODO Move this elsewhere cleaner at some point
      if (event.toSentryBreadcrumb) {
        let breadcrumbCategoryStr = ``;
        const breaccrumbCategoryParts = [];
        if (event.category) {
          breaccrumbCategoryParts.push(event.category);
        }

        if (event.subCategory) {
          breaccrumbCategoryParts.push(event.subCategory);
        }

        breadcrumbCategoryStr = breaccrumbCategoryParts.length > 0 ? 
          ` (${breaccrumbCategoryParts.join('.')})`
          : ``;

        const breadcrumbPartial = event.toSentryBreadcrumb();

        Sentry.getCurrentHub().addBreadcrumb({
          ...breadcrumbPartial,
          event_id: event.eventId,
          message: `Event: ${event.type}${breadcrumbCategoryStr}${breadcrumbPartial.message ? ` - ${breadcrumbPartial.message}`:``}`,
          timestamp: event.occurredAt,
        });
      }

      return serializedEvent;
    } catch (e: unknown) {
      if (this.onError) {
        this.onError(e);
      } else {
        console.error(e);
      }
    }
  };

  // TODO Handle categories/subcategories, since not all even names are necessarily unique
  public on = <TData = any>(
    event: string,
    callback: (event: Event<TData>) => any
  ) => {
    const listenerId = this.makeUuid();
    this.listeners[event] = this.listeners[event] ?? {};
    this.listeners[event][listenerId] = {
      callback,
    };

    return () => {
      if (this.listeners?.[event]?.[listenerId]) {
        delete this.listeners[event][listenerId];
      }
    };
  };

  public onAny = <TData = any>(callback: (event: Event<TData>) => any) => {
    return this.on(`*`, callback);
  };

  public submitOnEvents = (events: string[]) => {
    const callback: Listener["callback"] = (event) => {
      if (events.includes(event.type)) {
        if (this.debug) {
          console.log(`[EVENTS.AUTO_SUBMIT] Running...`);
        }

        this.submitEventsThrottled();
      }
    };

    return this.onAny(callback);
  };

  public loadEventsQueue = async () => {
    // New Events
    try {
      const jsonStr = await this.storage.getItem(
        this.makeStorageKey(STORAGE_QUEUE_NEW_SUB_KEY)
      );

      // TODO Deal with schema changes and migrating or discarding

      if (typeof jsonStr === `string`) {
        const events = JSON.parse(jsonStr) as EventsQueue["new"];

        if (_.isArray(events?.events)) {
          this.queue.new = events;

          if (this.debug) {
            console.log(`[EVENTS.LOADED.NEW] ${this.queue.new.events.length}`);
          }
        } else if (events) {
          throw new MalformedEventsQueueError(events);
        }
      }
    } catch (e: unknown) {
      if (this.onError) {
        this.onError(e);
      } else {
        console.error(e);
      }
    }

    // Pending Submission Events
    try {
      const jsonStr = await this.storage.getItem(
        this.makeStorageKey(STORAGE_QUEUE_PENDING_SUBMISSION_SUB_KEY)
      );

      // TODO Deal with schema changes and migrating or discarding

      if (typeof jsonStr === `string`) {
        const events = JSON.parse(jsonStr) as EventsQueue["pendingSubmission"];

        if (_.isArray(events?.eventsBatches)) {
          this.queue.pendingSubmission = events;

          if (this.debug) {
            const countsByStatus = this.pendingSubmissionCountsByStatus();
            console.log(`[EVENTS.LOADED.PENDING_SUBMISSION] `, countsByStatus);
          }
        } else if (events) {
          throw new MalformedEventsQueueError(events);
        }
      }
    } catch (e: unknown) {
      if (this.onError) {
        this.onError(e);
      } else {
        console.error(e);
      }
    }
  };

  public storeNewEvents = async (force: boolean = false) => {
    if (
      !force &&
      typeof this.queue.new.lastChangedAt === `number` &&
      typeof this.queue.new.lastStoredAt === `number` &&
      this.queue.new.lastStoredAt >= this.queue.new.lastChangedAt
    ) {
      return;
    }

    this.queue.new.lastStoredAt = Date.now() / 1000;

    const jsonStr = JSON.stringify(this.queue.new);

    await this.storage.setItem(
      this.makeStorageKey(STORAGE_QUEUE_NEW_SUB_KEY),
      jsonStr
    );

    if (this.debug) {
      console.log(`[EVENTS.STORED.NEW] ${this.queue.new.events.length} Events`);
    }
  };

  public storePendingEvents = async (force: boolean = false) => {
    if (
      !force &&
      typeof this.queue.pendingSubmission.lastChangedAt === `number` &&
      typeof this.queue.pendingSubmission.lastStoredAt === `number` &&
      this.queue.pendingSubmission.lastStoredAt >=
        this.queue.pendingSubmission.lastChangedAt
    ) {
      return;
    }

    this.queue.pendingSubmission.lastStoredAt = Date.now() / 1000;

    const jsonStr = JSON.stringify(this.queue.pendingSubmission);

    await this.storage.setItem(
      this.makeStorageKey(STORAGE_QUEUE_PENDING_SUBMISSION_SUB_KEY),
      jsonStr
    );

    if (this.debug) {
      const countsByStatus = this.pendingSubmissionCountsByStatus();
      console.log(`[EVENTS.STORED.PENDING_SUBMISSION] `, countsByStatus);
    }
  };

  public submitEvents = async () => {
    if (this.submitting) return;

    try {
      this.submitting = true;

      await this.storePendingEvents();
      await this.storeNewEvents();

      // Move new events to a batch
      if (this.queue.new.events.length > 0) {
        const newBatch: EventsBatch = {
          batchId: this.makeUuid(),
          createdAt: Date.now() / 1000,
          lastAttempt: null,
          numAttempts: 0,
          events: [...this.queue.new.events],
        };

        this.queue.pendingSubmission.eventsBatches.push(newBatch);

        this.queue.new.events = [];

        await Promise.all([
          this.storePendingEvents(true),
          this.storeNewEvents(true),
        ]);
      }

      const eventsCount = this.queue.pendingSubmission.eventsBatches.reduce(
        function (eventsCount, eventsBatch) {
          return eventsCount + eventsBatch.events.length;
        },
        0
      );

      // Purge events if there are too many to store, replace them with a single purge event
      if (eventsCount > MAX_STORED_EVENTS) {
        let purged: number = 0;
        const pendingSubmission: (EventsBatch | null)[] = _.chain(
          this.queue.pendingSubmission.eventsBatches
        )
          .sortBy(`createdAt`)
          .map((eventsBatch) => {
            if (purged >= EVENTS_PURGE_COUNT) {
              return eventsBatch;
            }

            if (
              eventsBatch.status === EventsBatchStatus.SUBMITTED ||
              eventsBatch.status === EventsBatchStatus.FAILED
            ) {
              if (this.debug) {
                console.log(
                  `[EVENTS.PURGE] Purging Batch: ${
                    eventsBatch.batchId
                  } - Status: ${eventsBatch.status ?? `N/A`} with ${
                    eventsBatch.events.length
                  } Events`
                );
              }

              purged += eventsBatch.events.length;

              return null;
            }

            const latestOccurredAt = _.maxBy(eventsBatch.events, `occurredAt`)
              ?.occurredAt as number;

            const purgeEvent: Event<{ numPurged: number }> = {
              eventId: this.makeUuid(),
              occurredAt: latestOccurredAt,
              category: EventCategories.UI,
              subCategory: EventSubCategories.EVENTS,
              type: `EVENTS_PURGED`,
              data: {
                numPurged: eventsBatch.events.length,
              },
            };

            purged += eventsBatch.events.length;

            return {
              ...eventsBatch,
              events: [purgeEvent],
            };
          })
          .value();

        this.queue.pendingSubmission.eventsBatches = pendingSubmission.filter(
          (eventsBatch) => eventsBatch !== null
        ) as EventsBatch[];
      } else {
        if (this.debug) {
          console.log(
            `[EVENTS.PURGE] Skipping Purge, Max Stored: ${MAX_STORED_EVENTS}, Stored: ${eventsCount}`
          );
        }
      }

      await Promise.all([
        this.storePendingEvents(true),
        this.storeNewEvents(true),
      ]);

      let pendingSubmission = this.queue.pendingSubmission.eventsBatches.filter(
        (eventsBatch) =>
          eventsBatch.status === EventsBatchStatus.PENDING_RETRY ||
          eventsBatch.status === undefined
      );
      const pendingSubmissionBatchIds = pendingSubmission.map(
        (eventsBatch) => eventsBatch.batchId
      );

      const previousBatches = this.queue.pendingSubmission.eventsBatches.filter(
        (eventsBatch) =>
          !pendingSubmissionBatchIds.includes(eventsBatch.batchId)
      );

      if (pendingSubmission.length === 0) {
        if (this.debug) {
          console.log(`[EVENTS.SUBMIT] No New Events`);
        }

        this.submitting = false;

        if (this.debug) {
          console.log(
            `[EVENTS.SUBMIT] Total Stored: ${this.recentEvents(99999).length}`
          );
        }

        return;
      } else {
        if (this.debug) {
          console.log(
            `[EVENTS.SUBMIT] Submitting ${pendingSubmission.length} Batches...`
          );
        }
      }

      pendingSubmission = pendingSubmission.map((eventsBatch: EventsBatch) => ({
        ...eventsBatch,
        numAttempts: eventsBatch.numAttempts + 1,
        lastAttempt: Date.now() / 1000,
      }));

      let resp: SubmitterResponse;
      try {
        resp = await this.submitter.submit(pendingSubmission);
      } catch (e: unknown) {
        resp = pendingSubmission.map((eventsBatch) => ({
          batchId: eventsBatch.batchId,
          success: false,
        }));
      }

      const succeededBatchIds = resp
        .filter((result) => result.success === true)
        .map((result) => result.batchId);
      const failedBatchIds = resp
        .filter((result) => result.success === false)
        .map((result) => result.batchId);

      const succeededBatches = pendingSubmission
        .filter((eventsBatch: EventsBatch) =>
          succeededBatchIds.includes(eventsBatch.batchId)
        )
        .map((eventsBatch) => ({
          ...eventsBatch,
          status: EventsBatchStatus.SUBMITTED,
        }));
      const failedBatches = pendingSubmission
        .filter(
          (eventsBatch: EventsBatch) =>
            failedBatchIds.includes(eventsBatch.batchId) &&
            eventsBatch.numAttempts >= Events.maxSubmissionAttemps
        )
        .map((eventsBatch) => ({
          ...eventsBatch,
          status: EventsBatchStatus.FAILED,
        }));
      const retryBatches = pendingSubmission
        .filter(
          (eventsBatch: EventsBatch) =>
            failedBatchIds.includes(eventsBatch.batchId) &&
            eventsBatch.numAttempts < Events.maxSubmissionAttemps
        )
        .map((eventsBatch) => ({
          ...eventsBatch,
          status: EventsBatchStatus.PENDING_RETRY,
        }));

      if (this.debug) {
        succeededBatches.forEach((eventsBatch: EventsBatch) => {
          console.log(
            `[EVENTS.SUBMIT.SUCCESS] BatchID: ${eventsBatch.batchId}, # Events: ${eventsBatch.events.length}, # Attempts: ${eventsBatch.numAttempts}`
          );
        });

        failedBatches.forEach((eventsBatch: EventsBatch) => {
          console.log(
            `[EVENTS.SUBMIT.FAILED] BatchID: ${eventsBatch.batchId}, # Events: ${eventsBatch.events.length}, # Attempts: ${eventsBatch.numAttempts}`
          );
        });

        retryBatches.forEach((eventsBatch: EventsBatch) => {
          console.log(
            `[EVENTS.SUBMIT.PENDING_RETRY] BatchID: ${eventsBatch.batchId}, # Events: ${eventsBatch.events.length}, # Attempts: ${eventsBatch.numAttempts}`
          );
        });
      }

      this.queue.pendingSubmission.eventsBatches = _.uniqBy(
        [
          ...previousBatches,
          ...succeededBatches,
          ...failedBatches,
          ...retryBatches,
        ],
        `batchId`
      );

      this.submitting = false;

      await Promise.all([
        this.storePendingEvents(true),
        this.storeNewEvents(true),
      ]);

      if (this.debug) {
        console.log(
          `[EVENTS.SUBMIT] Total Stored: ${this.recentEvents(99999).length}`
        );
      }
    } catch (e: unknown) {
      this.submitting = false;

      if (this.submitter.onError) {
        this.submitter.onError(e);
      } else {
        console.error(e);
      }

      throw e;
    }
  };

  public recentEvents = (limit: number = 100) => {
    const pendingSubmission: Event[] =
      this.queue.pendingSubmission.eventsBatches.reduce(
        (events, eventsBatch) => [...events, ...eventsBatch.events],
        [] as Event[]
      );
    const events: Event[] = [...pendingSubmission, ...this.queue.new.events];

    return _.chain(events)
      .sortBy(`ocurredAt`)
      .reverse()
      .slice(0, limit)
      .reverse()
      .value();
  };

  protected makeStorageKey(subKey: string) {
    return `${this.storageKey}.${subKey}`;
  }

  protected pendingSubmissionCountsByStatus() {
    return this.queue.pendingSubmission.eventsBatches.reduce(
      (counts, eventsBatch) => {
        const status = eventsBatch.status ?? `PENDING_SUBMISSION`;

        return {
          ...counts,
          [status]: (counts[status] ?? 0) + eventsBatch.events.length,
        };
      },
      {} as Record<
        NonNullable<EventsBatch["status"] | `PENDING_SUBMISSION`>,
        number
      >
    );
  }
}

export { Events };
