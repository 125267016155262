import { useCallback } from "react";
import { networkErrorErrorModalHandler } from "./error-handlers/global/errorHandlers";
import { ErrorHandler, defaultErrorBoundaryHandler, useHandleError } from ".";

/**
 * This overrides the default global JWT -> Default Error Handler behavior by checking the JWT error handler first,
 * then defaulting to a handler that throws to the nearest ErrorBoundary, rather than showing an error modal.
 *
 * This is because if an unknown error occurs in a react-query, it's likely the query needs reset, so we throw
 * to the ErrorBoundary, which on "Retry", refreshes all queries in that scope.
 */
export default function useDefaultQueryErrorHandler() {
  const handleError = useHandleError();

  return useCallback(
    (error: unknown, context?: any, errorHandlers: ErrorHandler[] = []) => {
      return handleError(error, context, [
        ...errorHandlers,
        // jwtExpiredErrorModalHandler,
        // jwtUnavailableErrorModalHandler,
        // hasuraRpmLimitErrorModalHandler,
        networkErrorErrorModalHandler,
        defaultErrorBoundaryHandler,
      ]);
    },
    [handleError]
  );
}
