import { StoreVaultedCardAccountMutation } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  paymentMethodId?: number;
  mxMerchantPaymentMethodMetaId?: number;
};

export type ConstructorArgs = {
  mutationResponse: StoreVaultedCardAccountMutation["storeVaultedCardAccount"];
} & Partial<EventConstructorArgs>;

class StoreVaultedCardAccountResponse extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationResponse, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PAYMENT_METHODS,
      type: `STORE_VAULTED_CARD_ACCOUNT.RESPONSE`,
      data: {
        paymentMethodId: mutationResponse?.paymentMethod?.id,
        mxMerchantPaymentMethodMetaId:
          mutationResponse?.paymentMethod?.mx_merchant_payment_method_metas[0]
            ?.id,
      },
    });
  }
}

export { StoreVaultedCardAccountResponse };
