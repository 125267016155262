import { PhoneInput } from "../../../../../components/inputs/phone/PhoneInput";

export type Props = {
  show?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => any;
};

function LoginPhoneInput(props: Props) {
  const {
    show = true,
    disabled = false,
    value = ``,
    onChange = () => {},
  } = props;

  // const { closeKeyboard } = useKeyboard();

  const showStyle = {};
  const hideStyle = { width: 0, height: 0, overflow: "hidden" };
  const style: React.CSSProperties = show ? showStyle : hideStyle;

  return (
    <PhoneInput
      value={value}
      onChange={(phone) => {
        onChange(phone);
      }}
      disabled={disabled}
      // autoFocus
      style={style}
    />
  );
}

export { LoginPhoneInput };
