import { BrowserAgentPlatform } from "../core/useProviders";

function getBrowserAgentPlatform(): BrowserAgentPlatform | null {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return `android`;
  } else if (/iphone/i.test(userAgent) || /ipad/i.test(userAgent)) {
    return `ios`;
  } else if (/Macintosh/i.test(userAgent)) {
    return `osx`;
  }

  return null;
}

export { getBrowserAgentPlatform };