import { useMemo } from "react";
import { useLiveNow } from "../../../../../lib/useLiveNow";
import { datetimeToLocal, serverDatetimeToMoment } from "../../../../../lib/utils";
import { ParkableTransaction } from "../../../../../queries/parkable-transactions/useRecentParkableTransactions";

export type Props = {
  time: number | null | undefined;
  session: { time: number, [key: string]: any } | null | undefined;
};

function ParkUntil(props: Props) {
  const { time, session } = props;

  const now = useLiveNow();
  const startDatetime = useMemo(() => {
    return session
      ? serverDatetimeToMoment(session.expires_at)
      : now;
  }, [now, session]);

  const parkUntil = useMemo(() => {
    if (!time) return null;

    return datetimeToLocal(startDatetime)
      .clone()
      .add(time, "seconds")
      .format("LT");
  }, [startDatetime, time]);

  return (
    <div
      className={`w-full flex justify-center items-center`}
    >
      <span
        className={`uppercase mr-1`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `14px`,
          lineHeight: `18px`,
          color: `#0E2A48`,
        }}
      >
        {session ? `Extend` : `Park`} until
      </span>
      <span
        className={`uppercase`}
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
          fontSize: `14px`,
          lineHeight: `18px`,
          color: `#0b84ff`,
        }}
      >
        {parkUntil}
      </span>
    </div>
  );
}

export { ParkUntil };