import { AuthError } from "./AuthError";

/**
 * User typed in an invalid OTP, but can still try again
 */
class RateLimitReachedError extends AuthError {
  constructor() {
    super("Rate Limit Reached");
    this.name = "RateLimitReachedError";
    this.humanMessage = "Too Many Attempts, Try Again Later";
  }
}

export { RateLimitReachedError };
