export function getRequirements() {
  const localStorage = isLocalStorageAvailable();
  const cookies = isCookiesAvailable();

  return {
    localStorage,
    cookies,
  };
}

export function isLocalStorageAvailable(){
  const testKey = 'test';

  try {
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
  } catch(e) {
      return false;
  }
}

export function isCookiesAvailable() {
  return navigator.cookieEnabled === true;
}