import { useMutation } from "@tanstack/react-query";
import { CreateSupportTicketDocument, CreateSupportTicketGuestDocument, CreateSupportTicketGuestMutation, CreateSupportTicketGuestMutationVariables, CreateSupportTicketMutation, CreateSupportTicketMutationVariables } from "../../generatedQueries";
import request from "graphql-request";
import { getGraphqlEndpoint } from "../../config/config";
import { backoffRetry, maybeRetryMutation } from "../../lib/utils";
import { Auth } from "../../auth/core/Auth";
import { useMultiAuth } from "../../auth/core/useMultiAuth";
import { event } from "../../events/core/helpers";
import { CreateSupportTicketRequest } from "../../events/event-types/support-tickets/CreateSupportTicketRequest";
import { CreateSupportTicketResponse } from "../../events/event-types/support-tickets/CreateSupportTicketResponse";
import { Events } from "../../events/core/Events";
import { getDeviceHeaders } from "../../http/getDeviceHeaders/getDeviceHeaders";

export type Options = {
  onSuccess?: (
    id: number,
    supportTicket: CreateSupportTicketMutation["createSupportTicket"]["supportTicket"]
  ) => any;
  onError?: (error: unknown) => any;
};

export default function useCreateSupportTicket(options?: Options) {
  const auth = useMultiAuth();
  const isGuest = !auth.session;
  // const handleError = useDefaultQueryErrorHandler();

  const mutation = useMutation<
    CreateSupportTicketMutation["createSupportTicket"],
    unknown,
    CreateSupportTicketMutationVariables
  >(
    async ({ idempotentKey, firstName, lastName, email, phone, message }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      const recentEvents = Events.getInstance().recentEvents();

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: CreateSupportTicketDocument,
        variables: {
          idempotentKey,
          firstName,
          lastName,
          email,
          phone,
          message,
          // device,
          contextData: JSON.stringify({
            recentEvents,
          }),
        },
        requestHeaders,
        // signal,
      })).createSupportTicket;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      onMutate: (variables) => {
        event(
          new CreateSupportTicketRequest({
            mutationVariables: variables,
            isGuest: false,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new CreateSupportTicketResponse({
            mutationResponse: resp,
            isGuest: false,
          })
        );

        if (options?.onSuccess) {
          options.onSuccess(resp?.id, resp?.supportTicket);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
        // else {
        //   handleError(error);
        // }
      },
    }
  );

  const mutationGuest = useMutation<
    CreateSupportTicketGuestMutation["createSupportTicketGuest"],
    unknown,
    CreateSupportTicketGuestMutationVariables
  >(
    async ({ idempotentKey, firstName, lastName, email, phone, message }) => {
      const jwt = (await Auth.getInstance().getSession())?.session.idToken;
      const deviceHeaders = getDeviceHeaders();
      const requestHeaders = jwt ? {
        ...deviceHeaders,
        Authorization: `Bearer ${jwt}`,
      } : { ...deviceHeaders };

      const recentEvents = Events.getInstance().recentEvents();

      return (await request({
        url: getGraphqlEndpoint() as string,
        document: CreateSupportTicketGuestDocument,
        variables: {
          idempotentKey,
            firstName,
            lastName,
            email,
            phone,
            message,
            // device,
            contextData: JSON.stringify({
              recentEvents,
            }),
        },
        requestHeaders,
        // signal,
      })).createSupportTicketGuest;
    },
    {
      retry: (failureCount: number, error: unknown) =>
        maybeRetryMutation(failureCount, error, 3),
      retryDelay: (failureAttempt: number) =>
        backoffRetry(failureAttempt, 30 * 1000),

      onMutate: (variables) => {
        event(
          new CreateSupportTicketRequest({
            mutationVariables: variables,
            isGuest: true,
          })
        );
      },
      onSuccess: (resp, variables, context) => {
        event(
          new CreateSupportTicketResponse({
            mutationResponse: resp,
            isGuest: true,
          })
        );
        
        if (options?.onSuccess) {
          options.onSuccess(resp?.id, null);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
        // else {
        //   handleError(error);
        // }
      },
    }
  );

  // useThrowQueryErrorToBoundary(query);

  return {
    isGuest,
    mutation,
    mutationGuest,
    id: mutation.data?.id ?? null,
    supportTicket: mutation.data?.supportTicket ?? null,
  };
}
