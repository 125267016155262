import { useSessionInProgress } from "../../lib/session-in-progress/useSessionInProgress";
import { ReactComponent as IconArrow } from "../../../src/assets/icons/arrow-right-solid-white.svg";
import { useRecentParkableTransactions } from "../../queries/parkable-transactions/useRecentParkableTransactions";
import _ from "lodash";
import { isActive, serverDatetimeToMoment } from "../../lib/utils";
import moment from "moment-timezone";

export type Props = {

};

function TimeDisplayNag(props: Props) {
  const {} = props;

  const session = useSessionInProgress();

  const { parkableTransactions } = useRecentParkableTransactions();

  const isRecent = !!_.find(parkableTransactions, pt => (
    serverDatetimeToMoment(pt.created_at).isAfter(moment.utc().subtract(5, "minutes"))
    && isActive(pt, moment.utc())
  ));

  if (!isRecent || !!session.sessionInProgress) return null;

  return (
    <div
      className={`w-full py-2 rounded-md mb-4 flex items-center justify-center`}
      style={{
        fontFamily: `Roboto, sans-serif`,
        fontWeight: 700,
        fontSize: `14px`,
        lineHeight: `18px`,
        color: `#fff`,
        textTransform: "uppercase",
        backgroundColor: `#FF3B30`,
      }}
      onClick={() => {
        session.navigate();
      }}
    >
      Time Will Not Display on Meter
    </div>
  );
}

export { TimeDisplayNag };