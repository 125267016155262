import { ErrorHandler } from "@packages/error-handling";
import {
  evaluateError as evaluateMxmError,
  ErrorType as MxmErrorType,
  ErrorSubType as MxmErrorSubType,
} from "./mxmUtils";

export const errorHandlers: ErrorHandler[] = [
  {
    handler: ({ appConfig, error, graphQlErrorResponse, context }) => {
      const mxmErrorType = evaluateMxmError(error);
      const {
        mxm: { reportErrorTypes },
      } = appConfig;

      if (mxmErrorType) {
        const { type, subType } = mxmErrorType;

        switch (subType) {
          case MxmErrorSubType.INVALID_CARD_NUMBER:
            return {
              errorModal: {
                error,
                errorTitle: ["Invalid", "Card Info"],
                errorMessage: "Your Card Number is Invalid",
              },
              // TEST Test if the following syntax works - Typescript didn't fully type-hint it
              report: reportErrorTypes[MxmErrorSubType.INVALID_CARD_NUMBER],
            };
          case MxmErrorSubType.INVALID_EXPIRY:
            return {
              errorModal: {
                error,
                errorTitle: ["Invalid", "Card Info"],
                errorMessage: "Your Card's Expiry Month or Year is Invalid",
              },
              report: reportErrorTypes[MxmErrorSubType.INVALID_EXPIRY],
            };
          case MxmErrorSubType.INVALID_CVV:
            return {
              errorModal: {
                error,
                errorTitle: ["Invalid", "Card Info"],
                errorMessage: "Your Card's CVV (Security Code) is Invalid",
              },
              report: reportErrorTypes[MxmErrorSubType.INVALID_CVV],
            };
          case MxmErrorSubType.INVALID_AVS_ZIP:
            return {
              errorModal: {
                error,
                errorTitle: ["Invalid", "Card Info"],
                errorMessage: "Your Card's Billing Zip Code is Invalid",
              },
              report: reportErrorTypes[MxmErrorSubType.INVALID_AVS_ZIP],
            };
          default:
            if (type === MxmErrorType.VALIDATION) {
              return {
                errorModal: {
                  error,
                  errorTitle: ["Invalid", "Card Info"],
                  errorMessage: "Some of your Card Info is Invalid.",
                },
                report: reportErrorTypes[MxmErrorType.VALIDATION],
              };
            } else if (MxmErrorType.UNKOWN) {
              return {
                errorModal: {
                  error,
                  errorMessage:
                    graphQlErrorResponse?.extensions.userMessage ??
                    "An unknown error occurred when trying to save your Card Info.",
                },
                report: reportErrorTypes[MxmErrorType.UNKOWN],
              };
            }
        }
      }

      // TODO [H] Detect if the error is likely an MXM API error and not from one of the other HTTP/Mutation calls
      //  - If it is, show a more generic "Something Went Wrong" error

      return {
        errorModal: {
          error,
          errorMessage:
            graphQlErrorResponse?.extensions.userMessage ??
            "An unknown error occurred when trying to save your Card Info.",
        },
        report: reportErrorTypes[MxmErrorType.UNKOWN],
      };
    },
  },
];
