import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useHandleError } from "../../../lib/error-handling";
import { useAlertModal } from "../../../components/modals/alert-modal";
import useUser from "../../../queries/users/useUser";
import useCreateSupportTicket from "../../../queries/support-tickets/useCreateSupportTicket";
import { useNavigate } from "react-router-dom";
import { useRemember } from "../../../lib/useRemember";
import uuid from 'uuid-random';
import { parsePhoneNumber } from "../../../lib/utils";
import _ from "lodash";
import { PhoneInput } from "../../../components/inputs/phone/PhoneInput";
import { FullWidthButton } from "../../../components/buttons/FullWidthButton";
import { useMultiAuth } from "../../../auth/core/useMultiAuth";

export type Props = {
  revealText?: ReactNode;
  formHeadingText?: ReactNode;
  messageHeadingText?: ReactNode;
  messagePlaceholderText?: string;
  ticketMessagePrefix?: ReactNode;
  showWebsiteUrl?: boolean;
};

function ContactForm(props: Props) {
  const {
    revealText,
    formHeadingText,
    messageHeadingText,
    messagePlaceholderText,
    ticketMessagePrefix = `General Inquiry`,
    showWebsiteUrl = false,
  } = props;

  const handleError = useHandleError();
  const alertModal = useAlertModal();
  const navigate = useNavigate();

  const { session } = useMultiAuth();

  const identityProvider =
    session?.sessionDetails?.tokenPayload?.["https://hasura.io/jwt/claims"]?.[
      "x-hasura-provider"
    ];
  const identityCredential =
    session?.sessionDetails?.tokenPayload?.["https://hasura.io/jwt/claims"]?.[
      "x-hasura-credential"
    ];
  const identityPhoneNumber =
    identityProvider === "phone_number" ? identityCredential : null;
  const identityEmail =
    identityProvider === "email" ? identityCredential : null;

  const { query: userQuery, user } = useUser();
  const { isGuest, mutation, mutationGuest, id, supportTicket } =
    useCreateSupportTicket({
      onError: (error) => {
        handleError(error);
      },
      onSuccess: () => {
        alertModal.show({
          title: ["Support Ticket", "Submitted"],
          message: `Your support ticket was successfully submitted. The meterEZ team will be in touch as soon as possible.`,
          buttons: [
            {
              contentTop: `Ok`,
              onClick: () => {
                navigate("/help", { replace: true });
              },
            },
          ],
        });
      },
    });

  const {
    value: lastFirstName,
    setValue: setLastFirstName,
    isLoaded: isLastFirstNameLoaded,
  } = useRemember<string>({
    namespace: "support",
    key: "last_used_first_name",
  });

  const {
    value: lastLastName,
    setValue: setLastLastName,
    isLoaded: isLastLastNameLoaded,
  } = useRemember<string>({
    namespace: "support",
    key: "last_used_last_name",
  });

  const {
    value: lastEmail,
    setValue: setLastEmail,
    isLoaded: isLastEmailLoaded,
  } = useRemember<string>({
    namespace: "support",
    key: "last_used_email",
  });

  const {
    value: lastPhone,
    setValue: setLastPhone,
    isLoaded: isLastPhoneLoaded,
  } = useRemember<string>({
    namespace: "support",
    key: "last_used_phone",
  });

  const isLoaded =
    isLastFirstNameLoaded &&
    isLastLastNameLoaded &&
    isLastEmailLoaded &&
    isLastPhoneLoaded;

  const [show, setShow] = useState(!revealText);
  const [idempotentKey] = useState(uuid());

  const [firstName, setFirstName] = useState(lastFirstName ?? user?.first_name);
  const firstNameRef = useRef();
  const [lastName, setLastName] = useState(lastLastName ?? user?.last_name);
  const lastNameRef = useRef();
  const showName = isGuest || !firstName || !lastName;

  const [_email, setEmail] = useState(lastEmail);
  const email = identityEmail ?? _email;
  const emailRef = useRef();

  const [emailConfirm, setEmailConfirm] = useState(lastEmail);
  const emailConfirmRef = useRef();
  let emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.+[A-Za-z]+/.test(
    email ?? ""
  );
  let emailConfirmMatches = emailConfirm === email;
  let showEmail = true;
  if (identityEmail) {
    showEmail = false;
    emailConfirmMatches = true;
    emailIsValid = true;
  }

  const [_phone, setPhone] = useState(
    identityPhoneNumber ?? lastPhone ?? user?.phone_number
  );
  const phone = identityPhoneNumber ?? _phone;
  const phoneRef = useRef();
  const phoneParsed = parsePhoneNumber(phone);
  let phoneIsValid = phoneParsed.isValid;
  let showPhone = true;
  if (identityPhoneNumber) {
    showPhone = false;
    phoneIsValid = true;
  }

  const [message, setMessage] = useState("");
  const messageRef = useRef();

  const [showErrors, setShowErrors] = useState(false);

  const helpUrlParams = _.map(
    {
      user_id: user?.id ?? ``,
      first_name: firstName ?? ``,
      last_name: lastName ?? ``,
      email: email ?? ``,
      phone_number: phoneParsed?.nationalNumber ?? ``,
      message: message ?? ``,
    },
    (v, k) => `${k}=${encodeURIComponent(v)}`
  ).join("&");
  const helpUrl = `https://www.meterez.com/help?${helpUrlParams}`;

  useEffect(() => {
    if (isLoaded) {
      if (!firstName && !!lastFirstName) setFirstName(lastFirstName);
      if (!lastName && !!lastLastName) setLastName(lastLastName);
      if (!email && !!lastEmail) setEmail(lastEmail);
      if (!emailConfirm && !!lastEmail) setEmailConfirm(lastEmail);
      if (!phone && !!lastPhone) setPhone(lastPhone);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (user && isLoaded && !lastEmail && !email && !!user.email) {
      setEmail(user.email);
    }

    if (user && isLoaded && !lastEmail && !phone && !!user.phone_number) {
      setPhone(user.phone_number);
    }
  }, [isLoaded, user]);

  const isMutating = mutation.isLoading || mutationGuest.isLoading;
  const isMutated = mutation.isSuccess || mutationGuest.isSuccess;
  const isError = mutation.isError || mutationGuest.isError;

  const submitDisabled =
    !firstName ||
    !lastName ||
    !emailIsValid ||
    !emailConfirmMatches ||
    !phoneIsValid ||
    !message ||
    isMutating ||
    isMutated;
  
  const onSubmit = useCallback(() => {
    if (submitDisabled) return;

    if (isGuest) {
      mutationGuest.mutate({
        idempotentKey,
        firstName: firstName as string,
        lastName: lastName as string,
        email: email as string,
        phone: phone as string,
        message: `\n\n${ticketMessagePrefix}\n\n${message}`,
      });
    } else {
      mutation.mutate({
        idempotentKey,
        firstName: firstName as string,
        lastName: lastName as string,
        email: email as string,
        phone: phone as string,
        message: `\n\n${ticketMessagePrefix}\n\n${message}`,
      });
    }

    setLastFirstName(firstName as string);
    setLastLastName(lastName as string);
    setLastEmail(email as string);
    setLastPhone(phone as string);
  }, [
    submitDisabled,
    isGuest, 
    mutationGuest, 
    mutation, 
    idempotentKey,
    firstName, 
    setLastFirstName, 
    lastName, 
    setLastLastName, 
    email, 
    setLastEmail, 
    phone, 
    setLastPhone,
    ticketMessagePrefix,
    message
  ]);

  return (
    <form
      className={`w-full`}
      style={{
        // minHeight: `100vh`,
        // minHeight: `-webkit-fill-available`,
        minHeight: `100dvh`,
      }}
      onSubmit={e => {
        onSubmit();
        e.preventDefault();
      }}
    >
      <div
        className={`w-full`}
        style={{

        }}
      >
        {!!revealText && !show && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
            }}
          >
            <div style={{
              color: "#989FB4",
              fontFamily: "Roboto",
              fontSize: `12px`,
              fontWeight: 900,
              lineHeight: `16px`,
              textAlign: "center",
              // letterSpacing: 1.2,
              marginBottom: `20px`,
              paddingRight: `20px`,
              paddingLeft: `20px`,
              textTransform: "uppercase",
              borderWidth: 1,
              borderColor: "#989FB4",
              borderRadius: 8,
              paddingTop: `10px`,
              paddingBottom: `10px`,
            }}>
              {revealText}
            </div>
          </button>
        )}

        {show && (
          <div
            className={`w-full`}
            style={{

            }}
          >
            {!!formHeadingText && (
              <div
                className={`w-full mb-2`}
                style={{
                  
                }}
              >
                <div
                  className={`w-full`}
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: `14px`,
                    lineHeight: `16px`,
                    color: "#0E2A48",
                  }}
                >
                  {formHeadingText}
                </div>
              </div>
            )}

            {showName && (
              <input
                type="text"
                placeholder={`First Name`}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                disabled={isMutating || isMutated}
                autoCapitalize={`words`}
                ref={firstNameRef}
                className={`w-full text-center flex items-center justify-center rounded-md outline-0  mb-2`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `20px`,
                  lineHeight: `24px`,
                  height: `50px`,
                  borderWidth: `1px`,
                  borderColor: showErrors && !firstName ? `red` : `#E7EAF7`,
                  color: `#0E2A48`,
                }}
              />
            )}

            {showName && (
              <input
                type="text"
                placeholder={`Last Name`}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                disabled={isMutating || isMutated}
                autoCapitalize={`words`}
                ref={lastNameRef}
                className={`w-full text-center flex items-center justify-center rounded-md outline-0  mb-2`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `20px`,
                  lineHeight: `24px`,
                  height: `50px`,
                  borderWidth: `1px`,
                  borderColor: showErrors && !lastName ? `red` : `#E7EAF7`,
                  color: `#0E2A48`,
                }}
              />
            )}

            {showEmail && (
              <input
                type="text"
                placeholder={`Email`}
                value={email ?? lastEmail ?? user?.email}
                onChange={e => setEmail(e.target.value)}
                disabled={isMutating || isMutated}
                autoCapitalize={`none`}
                ref={emailRef}
                className={`w-full text-center flex items-center justify-center rounded-md outline-0  mb-2`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `20px`,
                  lineHeight: `24px`,
                  height: `50px`,
                  borderWidth: `1px`,
                  borderColor: showErrors && !emailIsValid ? `red` : `#E7EAF7`,
                  color: `#0E2A48`,
                }}
              />
            )}

            {showEmail && (
              <input
                type="text"
                placeholder={`Confirm Email`}
                value={emailConfirm}
                onChange={e => setEmailConfirm(e.target.value)}
                disabled={isMutating || isMutated}
                autoCapitalize={`none`}
                ref={emailConfirmRef}
                className={`w-full text-center flex items-center justify-center rounded-md outline-0  mb-2`}
                style={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: 700,
                  fontSize: `20px`,
                  lineHeight: `24px`,
                  height: `50px`,
                  borderWidth: `1px`,
                  borderColor: showErrors && emailIsValid && !emailConfirmMatches ? `red` : `#E7EAF7`,
                  color: `#0E2A48`,
                }}
              />
            )}

            {showPhone && (
              <PhoneInput
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                }}
                error={showErrors && !phoneIsValid}
                disabled={isMutating || isMutated}
              />
            )}

            <div
              className={`w-full`}
              style={{

              }}
            >
              <div
                className={`w-full my-2`}
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: `14px`,
                  lineHeight: `16px`,
                  color: "#0E2A48",
                }}
              >
                {messageHeadingText ?? `What can we help you with?`}
              </div>
            </div>

            <textarea
              placeholder={messagePlaceholderText ?? `Provide your message here`}
              value={message}
              onChange={e => setMessage(e.target.value)}
              disabled={isMutating || isMutated}
              autoCapitalize={`sentences`}
              ref={messageRef}
              rows={8}
              className={`w-full text-left flex items-center justify-center rounded-md outline-0 mb-2 py-3 px-2`}
              style={{
                fontFamily: "Roboto, sans-serif",
                fontWeight: 700,
                fontSize: `20px`,
                lineHeight: `24px`,
                borderWidth: `1px`,
                borderColor: showErrors && !message ? `red` : `#E7EAF7`,
                color: `#0E2A48`,
              }}
            />

            <FullWidthButton
              contentTop={
                isMutating ? `Submitting...` : isMutated ? "Submitted!" : "Submit"
              }
              disabled={submitDisabled}
              onClick={onSubmit}
              onClickDisabled={() => {
                setShowErrors(true);

                if (emailIsValid) {
                  setLastEmail(email as string);
                }

                if (phoneIsValid) {
                  setLastPhone(phone as string);
                }
              }}
              disabledIfOffline
              showOfflineIndicator
            />
          </div>
        )}
      </div>
    </form>
  );
} 

export { ContactForm };