import { useContext } from "react";

import ErrorModal from "./ErrorModal";
import ErrorModalContext from "./ErrorModalContext";

export default function GlobalErrorModal() {
  const { state, hide } = useContext(ErrorModalContext);

  return (
    <ErrorModal
      {...state}
      onRequestClose={() => {
        hide();
      }}
    />
  );
}

export { GlobalErrorModal };
