import { CancelParkableTransactionMutationVariables } from "../../../generatedQueries";
import {
  Event,
  ConstructorArgs as EventConstructorArgs,
} from "../../core/Event";
import { EventCategories } from "../../event-categories";
import { EventSubCategories } from "../../event-sub-categories";

export type Data = {
  mutationVariables: CancelParkableTransactionMutationVariables;
};

export type ConstructorArgs = Data & Partial<EventConstructorArgs>;

class CancelParkableTransactionRequest extends Event<Data> {
  public constructor(args: ConstructorArgs) {
    const { mutationVariables, ...baseArgs } = args;

    super({
      ...baseArgs,
      category: EventCategories.UI,
      subCategory: EventSubCategories.PARKABLE_TRANSACTIONS,
      type: `CANCEL_PARKABLE_TRANSACTION.REQUEST`,
      data: {
        mutationVariables,
      },
    });
  }
}

export { CancelParkableTransactionRequest };
